import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

interface IntersectionObserverDisablerType {
  enabled: boolean;
  setEnabled?: (enabled: boolean) => void;
}

export const IntersectionObserverDisablerContext =
  createContext<IntersectionObserverDisablerType>({
    enabled: true,
  });

interface Props {
  enabled: boolean;
  children: ReactNode;
}

export const IntersectionObserverDisablerProvider = ({
  enabled,
  children,
}: Props) => {
  const [listenerEnabled, setListenerEnabled] = useState(enabled);

  const setEnabled = (enabled: boolean) => {
    setListenerEnabled(enabled);
  };

  return (
    <IntersectionObserverDisablerContext.Provider
      value={{
        enabled: listenerEnabled,
        setEnabled,
      }}
    >
      {children}
    </IntersectionObserverDisablerContext.Provider>
  );
};

export const IntersectionObserverDisablerConsumer =
  IntersectionObserverDisablerContext.Consumer;

export const useIntersectionObserverDisabler =
  (): IntersectionObserverDisablerType =>
    useContext(IntersectionObserverDisablerContext);

import { PUBLIC_SWS_ENV } from '@/constants/env';
import {
  ROUTE_COMPANY,
  ROUTE_COMPANY_WITH_EVENTS,
  ROUTE_LOCALE_COMPANY,
  ROUTE_LOCALE_COMPANY_WITH_EVENTS,
} from '@/constants/routes';

export const isPageTranslated = (type: string) =>
  [
    ROUTE_COMPANY,
    ROUTE_COMPANY_WITH_EVENTS,
    ROUTE_LOCALE_COMPANY,
    ROUTE_LOCALE_COMPANY_WITH_EVENTS,
  ].includes(type);

/**
 * Route action types which are 'enabled' to handle locale as a route param.
 *
 * e.g. /:locale/stocks/...
 */
const LOCALE_ROUTE_TYPES = [
  ROUTE_LOCALE_COMPANY,
  ROUTE_LOCALE_COMPANY_WITH_EVENTS,
] as const;
type LocaleRouteType = (typeof LOCALE_ROUTE_TYPES)[number];

/**
 * Determine whether a redux action type is one that supports locale in the url params.
 */
export const isLocaleRouteType = (
  actionType: string | LocaleRouteType
): actionType is LocaleRouteType => {
  return LOCALE_ROUTE_TYPES.includes(actionType as LocaleRouteType);
};

/**
 *
 * ['tr', { isSEOIndexable: false, isExperimental: true, isEnabledOnProd: false }],
 * ^ Non-crawable, experimental banner, enabled in 'dev', 'staging' environment
 * ['tr', { isSEOIndexable: false, isExperimental: true, isEnabledOnProd: true }],
 * ^ Non-crawable, experimental banner, enabled in 'dev', 'staging', 'prod' environment
 * ['tr', { isSEOIndexable: true, isExperimental: true, isEnabledOnProd: true }],
 * ^ Crawable, experimental banner, enabled in 'dev', 'staging', 'prod' environment
 * ['tr', { isSEOIndexable: true, isExperimental: false, isEnabledOnProd: true }],
 * ^ Crawable, enabled in 'dev', 'staging', 'prod' environment
 *
 */
const LOCALE_OPTIONS: [
  'en' | 'es' | 'de' | 'ja' | 'ko' | 'fr' | 'it' | 'nl' | 'tr' | 'sv',
  {
    isSEOIndexable: boolean;
    isExperimental: boolean;
    isEnabledOnProd: boolean;
  },
][] = [
  [
    'en',
    { isSEOIndexable: true, isExperimental: false, isEnabledOnProd: true },
  ],
  [
    'es',
    { isSEOIndexable: true, isExperimental: false, isEnabledOnProd: true },
  ],
  [
    'de',
    { isSEOIndexable: true, isExperimental: false, isEnabledOnProd: true },
  ],
  ['ja', { isSEOIndexable: true, isExperimental: true, isEnabledOnProd: true }],
  ['ko', { isSEOIndexable: true, isExperimental: true, isEnabledOnProd: true }],
  ['fr', { isSEOIndexable: true, isExperimental: true, isEnabledOnProd: true }],
  ['it', { isSEOIndexable: true, isExperimental: true, isEnabledOnProd: true }],
  ['nl', { isSEOIndexable: true, isExperimental: true, isEnabledOnProd: true }],
  ['tr', { isSEOIndexable: true, isExperimental: true, isEnabledOnProd: true }],
  ['sv', { isSEOIndexable: true, isExperimental: true, isEnabledOnProd: true }],
] as const;

function getLocales() {
  return LOCALE_OPTIONS.filter(([locale]) => locale !== 'en')
    .filter(([, { isEnabledOnProd }]) => {
      if (PUBLIC_SWS_ENV === 'prod') {
        /**
         * only return isEnabledOnProd:true locales
         */
        return isEnabledOnProd;
      } else {
        /**
         * always return true for non-prod environments
         */
        return true;
      }
    })
    .map(([locale]) => locale);
}

/**
 * Available route locale language translations.
 *
 * Note: `en` is valid as a language, but not in routing / url values. These use the 'default' non-locale routes instead.
 */

export const SUPPORTED_LOCALES = getLocales();

function getExperimentalLocales() {
  return LOCALE_OPTIONS.filter(([locale]) => locale !== 'en')
    .filter(([, { isExperimental }]) => isExperimental)
    .map(([locale]) => locale);
}
const EXPERIMENTAL_LOCALES = getExperimentalLocales();

type RouteLocale = (typeof SUPPORTED_LOCALES)[number];

export type Locale = RouteLocale | 'en';

export const isSupportedLocale = (
  localeValue?: string | RouteLocale
): localeValue is RouteLocale => {
  return SUPPORTED_LOCALES.includes(
    localeValue as ReturnType<typeof getLocales>[number]
  );
};

export const isTranslationExperimental = (locale: 'en' | RouteLocale) => {
  if (locale === 'en') {
    return false;
  }
  return EXPERIMENTAL_LOCALES.includes(locale);
};

export const isLocaleCrawlable = (locale: 'en' | RouteLocale) => {
  if (locale === 'en') {
    return true;
  }
  return (
    LOCALE_OPTIONS.find(([l]) => l === locale)?.[1].isSEOIndexable ?? false
  );
};

export const isPageSupported = (
  type: string,
  locale: string
): locale is Locale => {
  if (
    isPageTranslated(type) &&
    (locale === 'en' || isSupportedLocale(locale))
  ) {
    return true;
  }
  return false;
};

export const SUPPORTED_NAMESPACES = [
  'web',
  'statementGlossary',
  'page.companyReport',
  'page.companyReport.dividend',
  'page.companyReport.future',
  'page.companyReport.past',
  'page.companyReport.overview',
  'page.companyReport.health',
  'page.companyReport.management',
  'page.companyReport.ownership',
  'page.companyReport.information',
  'page.companyReport.valuation',
] as const;

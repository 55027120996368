import type IndustryAverageStateEntity from '@industyAverages/interfaces/state/IndustryAverageStateEntity';
import type IndustryAverageFieldEntity from '@industyAverages/interfaces/entity/IndustryAverageFieldEntity';
import type IndustryAverageConstituentDetailEntity from '@industyAverages/interfaces/entity/IndustryAverageConstituentDetailEntity';
import type IndustryAverageFieldDesc from '@industyAverages/interfaces/repository/IndustryAverageFieldDesc';
import type IIndustryAverageFieldsRepository from '@industyAverages/repository/IIndustryAverageFields';

import type IIndustryAverageFieldsProvider from './IIndustryAverageFieldsProvider';

class IndustryAverageFieldsProvider implements IIndustryAverageFieldsProvider {
  private _industryAverageFieldsRepo: IIndustryAverageFieldsRepository;

  constructor(industryAverageFieldsRepo: IIndustryAverageFieldsRepository) {
    if (!industryAverageFieldsRepo)
      throw new Error('Cannot instantiate Provider without Repository...');

    this._industryAverageFieldsRepo = industryAverageFieldsRepo;
  }

  getKeyForIndustryAvgFieldDesc(fieldName: string): string {
    return `INDUSTRY_AVG_FIELD_DESC|${fieldName}`;
  }

  getKeyForIndustryAvgConstituent(
    fieldName: string,
    group: string,
    countryISO: string,
    industry: number
  ): string {
    return `INDUSTRY_AVG_FIELD_CONSTITUENTS|${fieldName}|${group}|${countryISO}|${industry}`;
  }

  async getAllIndustryAvgFields(): Promise<
    IndustryAverageStateEntity<IndustryAverageFieldEntity>
  > {
    try {
      const { _industryAverageFieldsRepo, getKeyForIndustryAvgFieldDesc } =
        this;

      //Get All fields
      const AllIndustryAvgFields: readonly IndustryAverageFieldDesc[] =
        await _industryAverageFieldsRepo.getAllFields();

      //Transform the fields
      const entities: Map<string, IndustryAverageFieldEntity> = new Map([]);

      AllIndustryAvgFields.forEach((field) => {
        const fieldKey = getKeyForIndustryAvgFieldDesc(field.name);

        entities.set(fieldKey, {
          key: fieldKey,
          lastUpdated: new Date(),
          id: field.id,
          name: field.name,
          source: field.source,
          type: field.type,
        });
      });

      return Promise.resolve({
        entities,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getConstituentsForIndustryAvgField(
    field: string,
    group: string,
    company?: string,
    countryISO?: string,
    industry?: number
  ): Promise<
    IndustryAverageStateEntity<
      readonly IndustryAverageConstituentDetailEntity[]
    >
  > {
    try {
      const { _industryAverageFieldsRepo, getKeyForIndustryAvgConstituent } =
        this;

      //Get the constituents
      const industryAvgConstituents =
        await _industryAverageFieldsRepo.getConstituentsForField(
          field,
          group,
          company,
          countryISO,
          industry
        );

      //Transform the fields
      const entities: Map<
        string,
        readonly IndustryAverageConstituentDetailEntity[]
      > = new Map([]);

      industryAvgConstituents.forEach((constituent) => {
        const constituentKey = getKeyForIndustryAvgConstituent(
          field,
          group,
          constituent.countryISO.toString(),
          Number(constituent.industryId)
        );

        let constituentDetails;

        if (entities.has(constituentKey)) {
          constituentDetails = entities.get(constituentKey);
        } else {
          constituentDetails = [];
          entities.set(constituentKey, constituentDetails);
        }

        constituentDetails.push({
          key: constituentKey,
          regionId: constituent.regionId,
          countryISO: constituent.countryISO,
          industryId: constituent.industryId,
          industry: constituent.industry,
          companyId: constituent.companyId,
          company: constituent.company,
          uniqueSymbol: constituent.uniqueSymbol,
          [field]: constituent[field],
          group,
        });
      });

      return Promise.resolve({
        entities,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default IndustryAverageFieldsProvider;

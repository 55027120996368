import { createRoutine } from 'redux-saga-routines';
import type { getNews, getPortfolio } from '@simplywallst/services';
import {
  ACTION_ADD_TRANSACTION_FOR_PORTFOLIO_ITEM,
  ACTION_DEFAULT_PORTFOLIO_FETCH,
  ACTION_PORTFOLIO_ADD_COMPANY,
  ACTION_PORTFOLIO_FETCH_PRICE_FOR_ITEM,
  ACTION_PUBLIC_PORTFOLIO_FETCH,
  ACTION_PORTFOLIO_SHARE,
  ACTION_PORTFOLIO_ID_UPDATED,
  ACTION_PORTFOLIO_ITEMS_FETCH,
  ACTION_PORTFOLIO_NEWS_FETCH,
  ACTION_PORTFOLIO_NOT_FOUND,
  ACTION_PORTFOLIO_REMOVE_COMPANY,
  ACTION_REMOVE_TRANSACTION_FOR_PORTFOLIO_ITEM,
  ACTION_SAVE_CURRENT_PORTFOLIO_ID,
  ACTION_PORTFOLIO_HOLDING_SELECTED_SEARCH_RESULT,
  ACTION_PORTFOLIO_HOLDING_CLEAR_SEARCH_RESULT,
  ACTION_PORTFOLIO_DELETE,
  ACTION_PORTFOLIO_HOLDING_REINVEST_DIVIDENED,
  REGENERATE_SECRET_CODE,
  ACTION_TRANSACTIONS_SHOW_MODAL,
  ACTION_TRANSACTIONS_HIDE_MODAL,
} from './constants';

type Response = Awaited<ReturnType<typeof getNews>>['data'];
type GetPortfolioResponse = Awaited<ReturnType<typeof getPortfolio>>['data'];
export const addTransactionForItem = createRoutine(
  ACTION_ADD_TRANSACTION_FOR_PORTFOLIO_ITEM
);

export const clearHoldingsSearchResult = createRoutine(
  ACTION_PORTFOLIO_HOLDING_CLEAR_SEARCH_RESULT
);

export const selectedHoldingsSearchResult = createRoutine(
  ACTION_PORTFOLIO_HOLDING_SELECTED_SEARCH_RESULT
);

export const fetchDefaultPortfolio = createRoutine(
  ACTION_DEFAULT_PORTFOLIO_FETCH
);

export const fetchPortfolio = createRoutine<
  SimplePayloadCreator<{
    id: string;
    isPublic?: boolean;
    secret?: string;
    refresh?: number;
  }>,
  SimplePayloadCreator,
  SimplePayloadCreator<{
    id?: string;
    portfolioData: GetPortfolioResponse['data'][0];
    portfolioNews: any;
    isPublic?: boolean;
  }>,
  SimplePayloadCreator<{
    status: number;
    title: string;
    error: string;
    reason: string;
    linked: boolean;
  }>,
  SimplePayloadCreator
>('@PORTFOLIO/fetchPortfolio', {});

export const batchFetchPortfolio = createRoutine<
  SimplePayloadCreator<{ id: string }>,
  SimplePayloadCreator,
  SimplePayloadCreator,
  SimplePayloadCreator<{ message: string }>,
  SimplePayloadCreator
>('@PORTFOLIO/BATCH_FETCH_PORTFOLIO', {});
export const fetchPublicPortfolio = createRoutine(
  ACTION_PUBLIC_PORTFOLIO_FETCH
);
export const fetchPortfolioShare = createRoutine(ACTION_PORTFOLIO_SHARE);
export const fetchPortfolioItems = createRoutine(ACTION_PORTFOLIO_ITEMS_FETCH);

export interface Portfolio {
  id: string | number;
  name: string;
  sharing: null | boolean;
  url: string;
  provider: null | string;
  provider_business_id: null | string;
  provider_portfolio_id: null | string;
  date_generated: number;
  currency_iso: string;
  secret_code: string;
  items: {
    data: {
      id: number;
      portfolio_id: number;
      unique_symbol: string;
      trading_item_id: number;
      shares: null | boolean;
      reinvest: null | boolean;
      transactions: {
        data: {
          id: number;
          item_id: number;
          type: string;
          date: number;
          amount: number;
          cost: number;
        }[];
      };
      company:
        | undefined
        | {
            data: {
              id: number | string;
              company_id: string;
              trading_item_id: number;
              name: string;
              slug: string;
              exchange_symbol: string;
              ticker_symbol: string;
              unique_symbol: string;
              primary_ticker: boolean;
              last_updated: number;
              canonical_url: string;
              primary_canonical_url: null | string;
              is_searchable: boolean;
            };
          };
    }[];
  };
}

export const fetchPortfolioList = createRoutine<
  SimplePayloadCreator,
  SimplePayloadCreator,
  SimplePayloadCreator<{ portfolios: Portfolio[] }>,
  SimplePayloadCreator,
  SimplePayloadCreator
>('@PORTFOLIO/fetchPortfolioList', {});

export interface FetchPortfolioNewsTrigger {
  id: string;
  isPublic?: boolean;
  secret?: string;
}
export interface FetchPortfolioNewsSuccess {
  id: string;
  portfolioNews: Response['data'];
  secret?: string;
}
export interface FetchPortfolioNewsFailure {
  message?: string;
}

export const fetchPortfolioNews = createRoutine<
  SimplePayloadCreator<FetchPortfolioNewsTrigger>,
  SimplePayloadCreator,
  SimplePayloadCreator<FetchPortfolioNewsSuccess>,
  SimplePayloadCreator<FetchPortfolioNewsFailure>,
  SimplePayloadCreator
>(ACTION_PORTFOLIO_NEWS_FETCH, {});

export const fetchPriceForPortfolioItem = createRoutine(
  ACTION_PORTFOLIO_FETCH_PRICE_FOR_ITEM
);

export const portfolioAddCompany = createRoutine(ACTION_PORTFOLIO_ADD_COMPANY);
export const portfolioNotFound = createRoutine(ACTION_PORTFOLIO_NOT_FOUND);
export const portfolioRemoveCompany = createRoutine(
  ACTION_PORTFOLIO_REMOVE_COMPANY
);

export const removeTransactionForItem = createRoutine(
  ACTION_REMOVE_TRANSACTION_FOR_PORTFOLIO_ITEM
);

export const saveCurrentPortfolioId = createRoutine(
  ACTION_SAVE_CURRENT_PORTFOLIO_ID
);
export const updatePortfolioId = createRoutine(ACTION_PORTFOLIO_ID_UPDATED);
export const deletePortfolio = createRoutine(ACTION_PORTFOLIO_DELETE);

export const reinvestDividend = createRoutine(
  ACTION_PORTFOLIO_HOLDING_REINVEST_DIVIDENED
);

export const regenerateSecretCode = createRoutine(REGENERATE_SECRET_CODE);
export const showTransactionsModal = createRoutine(
  ACTION_TRANSACTIONS_SHOW_MODAL
);
export const hideTransactionsModal = createRoutine(
  ACTION_TRANSACTIONS_HIDE_MODAL
);

export interface FetchPortfolioCsvReportTriggerPayload {
  portfolioId: string;
}

export interface FetchPortfolioCsvReportFailurePayload {
  status?: number;
  message: string;
}

export const fetchPortfolioCsvReport = createRoutine<
  SimplePayloadCreator<FetchPortfolioCsvReportTriggerPayload>, // trigger
  SimplePayloadCreator,
  SimplePayloadCreator, // success
  SimplePayloadCreator<FetchPortfolioCsvReportFailurePayload>,
  SimplePayloadCreator
>('@PORTFOLIO/FETCH_CSV_FILE', {});

import { produce } from 'immer';
import type { State, Summary } from '../interface';
import { reducerErrorLogger } from '@ducks/util';
/** routines */
import type { rawDataCalculation } from '../routines';
import {
  fetchCompany,
  fetchCompetitors,
  fetchCompanyAnalysts,
  fetchCompanyNews,
  fetchCompanyTopShareholders,
} from '../routines';
/** sub reducer */
import {
  summaryReducer,
  competitorSummaryReducer,
  getDefaultSummary,
} from './summary';

import { newsReducer } from './news';

import { formattingReducer } from './formatting';
import { analysisReducer } from './analysis';
import { managementReducer } from './management';
import { shareholderReducer } from './shareholders';
import type { CurrencyISO } from '@/constants/currencies';
import type { ISO2 } from '@/constants/countries';
import {
  fetchPortfolio,
  fetchPortfolioList,
} from '@/components/DeprecatedPortfolio/redux/routines';

import { PublicPortfolioFetch } from '@/components/DeprecatedPortfolios/redux/routines';

import {
  PRIMARY_INDUSTRIES,
  SECONDARY_INDUSTRIES,
  TERTIARY_INDUSTRIES,
} from '@/constants/industries';
import { ROUTE_COMPANY } from '@/constants/routes';
import { getPath } from '@/router/utils';
import { cacheResults } from '@components/Search/redux/routines';

const getDefaultState = (): State => ({
  competitors: {},
  formatting: {},
  news: {},
  events: {}, // todo: deprecate this state
  summary: {},
  urlToIdMapping: {},
  symbolToIdMapping: {},
  analysis: {},
  management: {},
  insiderTransactions: {},
  insiderTransactionsMap: {},
  listings: {},
  analysts: {},
  topShareholders: {},
  classificationStatus: 'active',
});

function reducer(
  state: State = getDefaultState(),
  action: ReturnType<
    | (typeof cacheResults)['trigger']
    | (typeof fetchCompany)['trigger' | 'success']
    | (typeof fetchCompanyAnalysts)['success']
    | (typeof fetchCompanyNews)['success']
    | (typeof fetchCompanyTopShareholders)['success']
    | (typeof fetchCompetitors)['success']
    | (typeof fetchPortfolio)['success']
    | (typeof fetchPortfolioList)['success']
    | (typeof rawDataCalculation)['success']
  >
) {
  const logError = reducerErrorLogger('Company reducer');
  return produce(state, (draftState) => {
    const { type } = action;
    switch (type) {
      case fetchCompany.TRIGGER: {
        const {
          payload: { payload },
        } = action as ReturnType<(typeof fetchCompany)['trigger']>;
        if (payload) {
          const pathname = getPath({
            type: ROUTE_COMPANY,
            payload: {
              isoCode2: payload.isoCode2,
              industry: payload.industry,
              exchange_ticker: payload.exchange_ticker,
              companyname: payload.companyname,
            },
          });
          if (!state.urlToIdMapping[pathname]) {
            draftState.urlToIdMapping[pathname] = '';
          }
        }
        break;
      }
      case fetchCompanyAnalysts.SUCCESS: {
        const { payload } = action as ReturnType<
          (typeof fetchCompanyAnalysts)['success']
        >;
        const companyId = payload.companyId.toLowerCase();
        draftState.analysts[companyId] = payload.brokers.reduce<
          (typeof draftState.analysts)['']
        >(
          (acc, current) => {
            acc.brokers.push(current.estimate_broker_id.toString());
            current.analysts.data.forEach((analystData) => {
              acc.analyst.push(analystData.estimate_analyst_id.toString());
            });
            return acc;
          },
          {
            brokers: [],
            analyst: [],
          }
        );
        break;
      }
      case fetchCompany.SUCCESS: {
        const { payload } = action as ReturnType<
          (typeof fetchCompany)['success']
        >;
        const { canonical_url: canonicalUrl, unique_symbol: uniqueSymbol } =
          payload.companyData;

        const companyId = payload.companyData.company_id.toLowerCase();

        draftState.urlToIdMapping[canonicalUrl] = companyId;

        draftState.symbolToIdMapping[uniqueSymbol] = companyId;

        draftState.summary[companyId] = summaryReducer(
          state.summary[companyId],
          payload.companyData
        );

        if (!payload.companyData.analysis) {
          // bail out
          return;
        }
        draftState.formatting[companyId] = formattingReducer(
          state.formatting[companyId],
          payload.companyData
        );

        draftState.analysis[companyId] = analysisReducer(
          state.analysis[companyId],
          payload.companyData
        );

        draftState.management[companyId] = managementReducer(
          state.management[companyId],
          payload.companyData
        );

        try {
          /** Insider transactions  */
          const {
            insider_transactions: insiderTransactionsRawData,
            listings: listingsRawData,
          } = payload.companyData.analysis.data.extended.data.raw_data.data;
          // Keys
          draftState.insiderTransactions[companyId] =
            insiderTransactionsRawData.data.map((transaction) =>
              transaction.transaction_id.toString()
            );
          // Map
          insiderTransactionsRawData.data.forEach((transaction) => {
            draftState.insiderTransactionsMap[transaction.transaction_id] = {
              filingDate: transaction.trade_date_max || transaction.filing_date, // refer to this explanation: https://gitlab.com/simplywallst/frontend/laravel/-/issues/216#note_510645879
              ownerObjectId: transaction.owner_object_id.toString(),
              transactionType: transaction.transaction_type,
              transactionValue: transaction.transaction_value,
              ownerName: transaction.owner_name,
              ownerType: transaction.owner_type,
              shares: transaction.shares,
              priceMax: transaction.price_max,
            };
          });
          const listingEntries = Object.entries(listingsRawData);
          const listingKeys: string[] = [];
          listingEntries.forEach((entry) => {
            const [key, listing] = entry;
            draftState.listings[key] = {
              canonicalUrl: listing.canonical_url,
              primarySecurity: listing.primary_security,
              exchangeSymbol: listing.exchange_symbol,
              tickerSymbol: listing.ticker_symbol,
              securityName: listing.security_name,
              exchangeName: listing.exchange_name,
              exchangeCountryIso: listing.exchange_country_iso,
              currencyIso: listing.currency_iso,
              startDate: listing.start_date,
            };
            listingKeys.push(key);
          });

          /**
           * Ideally setting the listingKeys should be in summaryReducer. Howerver for
           * performance reasons since we have iterated through the listingsRawData
           * might as well save some cpu cycles by riding on the reducer forEach
           * method.
           */
          draftState.summary[companyId].listings = listingKeys;
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case fetchCompetitors.SUCCESS: {
        const { payload } = action as ReturnType<
          (typeof fetchCompetitors)['success']
        >;
        if (payload) {
          const { companyId, competitors } = payload;
          const competitorKeys: string[] = [];
          competitors.forEach((company) => {
            const { company_id } = company;
            const companyId = company_id.toLowerCase();
            // mapping
            draftState.urlToIdMapping[company.canonical_url] = companyId;
            draftState.symbolToIdMapping[company.unique_symbol] = companyId;

            competitorKeys.push(companyId);
            draftState.summary[companyId] = competitorSummaryReducer(
              state.summary[companyId],
              company
            );
          });
          draftState.competitors[companyId] = competitorKeys;
        }
        break;
      }
      case fetchCompanyNews.SUCCESS: {
        draftState.news = newsReducer(
          state.news,
          action as ReturnType<(typeof fetchCompanyNews)['success']>
        );
        break;
      }
      case cacheResults.TRIGGER: {
        const { payload: companies } = action as ReturnType<
          (typeof cacheResults)['trigger']
        >;
        const reduced = companies.reduce<Record<string, Partial<Summary>>>(
          (acc, current) => {
            const objectId = current.objectID.toLowerCase();
            acc[objectId] = produce(
              acc[objectId] || getDefaultSummary(),
              (draftSummary) => {
                draftState.urlToIdMapping[current.url] = objectId;
                draftState.symbolToIdMapping[current.uniqueSymbol] = objectId;
                draftSummary.companyId = objectId;
                draftSummary.shortName = current.name;
                draftSummary.uniqueSymbol = current.uniqueSymbol;
                // draftSummary.tickerSymbol = current.market;
                draftSummary.canonicalUrl = current.url;
                draftSummary.countryISO = current.exchangeCountryIso as ISO2;
              }
            );
            return acc;
          },
          state.summary
        );
        Object.assign(draftState.summary, reduced);
        break;
      }

      case fetchCompanyTopShareholders.SUCCESS: {
        const { payload } = action as ReturnType<
          (typeof fetchCompanyTopShareholders)['success']
        >;
        const { companyTopShareholders } = payload;
        const companyId = payload.companyId.toLowerCase();
        try {
          draftState.topShareholders[companyId] = shareholderReducer(
            state.topShareholders[companyId],
            companyTopShareholders
          );
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case fetchPortfolio.SUCCESS: {
        /** Portfolio holdings tile */
        const {
          payload: { portfolioData },
        } = action as ReturnType<(typeof fetchPortfolio)['success']>;
        portfolioData.holdings.data.forEach((company) => {
          const companyId = company?.company_id?.toLowerCase();
          if (!(companyId in state.summary)) {
            draftState.summary[companyId] = getDefaultSummary();
          }
          if (!(company.canonical_url in state.urlToIdMapping)) {
            draftState.urlToIdMapping[company.canonical_url] = companyId;
          }
          if (!(company.unique_symbol in state.symbolToIdMapping)) {
            draftState.symbolToIdMapping[company.unique_symbol] = companyId;
          }
          const { [companyId]: draftSummary } = draftState.summary;
          draftSummary.uniqueSymbol = company.unique_symbol;
          draftSummary.tickerSymbol = company.ticker_symbol;
          draftSummary.companyId = companyId;
          draftSummary.canonicalUrl = company.canonical_url;
          draftSummary.shortName = company.short_name;
          draftSummary.isFund = company.is_fund;
          draftSummary.beta = company.beta;
          draftSummary.currencyISO = company.currency_iso as CurrencyISO;

          if (company.primary_industry_id !== null) {
            const id = company.primary_industry_id.toString();
            draftSummary.industryData.primaryId = id;
            if (id in PRIMARY_INDUSTRIES) {
              draftSummary.industryData.primaryId =
                company.primary_industry_id.toString();
              draftSummary.industryData.primaryName =
                PRIMARY_INDUSTRIES[id].label;
            }
          }
          if (company.secondary_industry_id !== null) {
            const id = company.secondary_industry_id.toString();
            draftSummary.industryData.secondaryId = id;
            if (id in SECONDARY_INDUSTRIES) {
              draftSummary.industryData.secondaryName =
                SECONDARY_INDUSTRIES[id].label;
            }
          }
          if (company.tertiary_industry_id !== null) {
            const id = company.tertiary_industry_id.toString();
            draftSummary.industryData.tertiaryId = id;
            if (id in TERTIARY_INDUSTRIES) {
              draftSummary.industryData.tertiaryName =
                TERTIARY_INDUSTRIES[id].label;
            }
          }

          const { value, future, past, health, income } = company.score.data;
          // smh my head
          draftSummary.score.value = value + 1;
          draftSummary.score.future = future + 1;
          draftSummary.score.past = past + 1;
          draftSummary.score.health = health + 1;
          draftSummary.score.income = income + 1;
          draftSummary.snowflakePoints = [
            value + 1,
            future + 1,
            past + 1,
            health + 1,
            income + 1,
          ];

          /**
             One thing that we need is both currency and country ISO and
             the response doesn't provide that. So we'll derive the value
             from the canonical url.
             canonical url is usually `/stocks/:country/:industry/:uniquesymbol/:name`
            */

          draftSummary.countryISO = company.canonical_url
            .split('/')[2]
            .toUpperCase() as ISO2;
        });
        break;
      }
      case PublicPortfolioFetch.SUCCESS:
      case fetchPortfolioList.SUCCESS: {
        /** Portfolio holdings tile */
        const { payload } = action as ReturnType<
          | (typeof fetchPortfolioList)['success']
          | (typeof PublicPortfolioFetch)['success']
        >;
        payload.portfolios.forEach((portfolio) => {
          portfolio.items.data.forEach((item) => {
            if (typeof item.company !== 'undefined') {
              const { data: companyData } = item.company;

              const companyId = companyData.company_id.toString().toLowerCase();
              if (!(companyId in state.summary)) {
                draftState.summary[companyId] = getDefaultSummary();
              }

              /** map the mappings */
              if (!(companyData.canonical_url in state.urlToIdMapping)) {
                draftState.urlToIdMapping[companyData.canonical_url] =
                  companyId;
              }
              if (!(companyData.unique_symbol in state.symbolToIdMapping)) {
                draftState.symbolToIdMapping[companyData.unique_symbol] =
                  companyId;
              }

              draftState.summary[companyId].companyId = companyId;
              draftState.summary[companyId].shortName = companyData.name;
              draftState.summary[companyId].slug = companyData.slug;
              draftState.summary[companyId].exchangeSymbol =
                companyData.exchange_symbol;
              draftState.summary[companyId].uniqueSymbol =
                companyData.unique_symbol;
              draftState.summary[companyId].lastAnalysisUpdate =
                companyData.last_updated;
              draftState.summary[companyId].canonicalUrl =
                companyData.canonical_url;
              draftState.summary[companyId].isSearchable =
                companyData.is_searchable;
            }
          });
        });
        break;
      }
    }
  });
}

export default reducer;

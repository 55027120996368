import axios from "axios";
import { handleChallenge } from "./challenge";
const requestConfigDefaults = () => ({
  payload: {}
});
let defaultBaseUrl = void 0;
let defaultBaseAdapter = void 0;
let preferredLanguage = "en";
let defaultVersion = "unversioned";
function setBaseUrl(baseUrl) {
  defaultBaseUrl = baseUrl;
}
function setBaseAdapter(adapter) {
  defaultBaseAdapter = adapter;
}
function setPreferredLanguage(lang = "en-US") {
  preferredLanguage = lang;
}
function setVersion(version) {
  if (typeof version === "string") {
    defaultVersion = version;
  }
}
function setDefaultBearer(token) {
  if (typeof token === "undefined") {
    axios.defaults.headers.common.Authorization = void 0;
  } else {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}
const queryStringFromObject = (payload = {}) => Object.entries(payload).filter(([, value]) => typeof value !== void 0 && value !== null).map(([key, value]) => {
  if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
    return `${key}=${encodeURIComponent(value)}`;
  }
}).join("&");
const goFetch = (options) => {
  const { method, headers, payload, config } = options;
  const { url, adapter, baseURL, timeout, ...restConfig } = config;
  const api = axios.create({
    baseURL: baseURL || defaultBaseUrl || "https://simplywall.st/",
    timeout: timeout ? timeout : 0,
    headers: {
      Accept: "application/vnd.simplywallst.v2",
      "X-Requested-With": `sws-services/${defaultVersion}`,
      "Accept-Language": preferredLanguage,
      ...headers
    },
    adapter: adapter || defaultBaseAdapter,
    ...restConfig
  });
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response } = error;
      if (response?.headers["cf-mitigated"] === "challenge") {
        handleChallenge();
      }
      throw error;
    }
  );
  if (method === "GET" || method === "HEAD" || method === "DELETE") {
    return api.request({
      method,
      url
    });
  }
  return api.request({
    method,
    url,
    data: payload
  });
};
function get(config) {
  const { payload, headers, ...rest } = config;
  const options = {
    ...requestConfigDefaults(),
    ...rest,
    ...payload ? {
      url: `${rest.url}?${queryStringFromObject(payload)}`
    } : {}
  };
  return goFetch({
    method: "GET",
    config: options,
    headers
  });
}
function head(config) {
  const { headers, ...restConfig } = config;
  const options = {
    ...requestConfigDefaults(),
    ...restConfig
  };
  return goFetch({ method: "HEAD", config: options, headers });
}
const formUrlEncodedConfig = (config, headers = {}) => {
  return {
    ...config,
    payload: queryStringFromObject(config.payload),
    headers: {
      ...headers,
      "Content-Type": "application/x-www-form-urlencoded"
    }
  };
};
const applicationJsonEncodedConfig = (config, headers = {}) => {
  return {
    ...config,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers
    }
  };
};
const applicationFormData = (config, headers = {}) => {
  return {
    ...config,
    payload: config.payload,
    headers: {
      ...headers,
      "Content-Type": "multipart/form-data"
    }
  };
};
function post(config) {
  const { headers, payload, ...restConfig } = config;
  const { headers: preparedHeaders, ...requestConfig } = formUrlEncodedConfig(
    restConfig,
    headers
  );
  return goFetch({
    method: "POST",
    config: requestConfig,
    payload,
    headers: preparedHeaders
  });
}
function postJson(config) {
  const { headers: preparedHeaders, ...requestConfig } = applicationJsonEncodedConfig(config, config.headers);
  return goFetch({
    method: "POST",
    config: requestConfig,
    payload: requestConfig.payload,
    headers: preparedHeaders
  });
}
function postFormData(config) {
  const { headers, payload, ...restConfig } = config;
  const { headers: preparedHeaders, ...requestConfig } = applicationFormData(
    restConfig,
    headers
  );
  return goFetch({
    method: "POST",
    config: requestConfig,
    payload,
    headers: preparedHeaders
  });
}
function del(config) {
  const { headers, payload, ...restConfig } = config;
  const { headers: preparedHeaders, ...requestConfig } = formUrlEncodedConfig(
    restConfig,
    headers
  );
  return goFetch({
    method: "DELETE",
    config: requestConfig,
    headers: preparedHeaders
  });
}
function put(config) {
  const { headers, payload, ...restConfig } = config;
  const { headers: preparedHeaders, ...requestConfig } = applicationJsonEncodedConfig(restConfig, headers);
  return goFetch({
    method: "PUT",
    payload,
    headers: preparedHeaders,
    config: requestConfig
  });
}
function patch(config) {
  const { headers, payload, ...restConfig } = config;
  const { headers: preparedHeaders, ...requestConfig } = applicationJsonEncodedConfig(restConfig, headers);
  return goFetch({
    method: "PATCH",
    headers: preparedHeaders,
    payload,
    config: requestConfig
  });
}
export {
  del,
  get,
  head,
  patch,
  post,
  postFormData,
  postJson,
  put,
  queryStringFromObject,
  setBaseAdapter,
  setBaseUrl,
  setDefaultBearer,
  setPreferredLanguage,
  setVersion
};

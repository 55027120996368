import { createSelector } from 'reselect';
import type { AnalystsState } from './reducers';

const brokersSelector = (state) => {
  const analysts = state.analysts as AnalystsState;
  return analysts.brokersMap;
};

const analystsSelector = (state) => {
  const analysts = state.analysts as AnalystsState;
  return analysts.analystMap;
};

export const getBrokersMap = createSelector(brokersSelector, (map) => map);
export const getAnalystMap = createSelector(analystsSelector, (map) => map);

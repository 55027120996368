import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import type { PersistQueryClientProviderProps } from '@tanstack/react-query-persist-client';
import { persister as browserPersister } from './browserPersister';
import { persister as nativePersister } from './nativePersister';
import { shouldDehydrateQuery } from './utilities';

/**
 *
 * Only used for SSR or RUNTIME_ENV === 'server'
 *
 */
const stubPersister = createSyncStoragePersister({
  storage: {
    getItem: () => null,
    setItem: () => null,
    removeItem: () => null,
  },
});

let persister = stubPersister;

if (RUNTIME_ENV === 'browser') {
  persister = browserPersister;
}

if (RUNTIME_ENV === 'native') {
  persister = nativePersister;
}

export const cacheTime = 1000 * 60 * 60 * 24 * 7;

export const persistQueryClientOptions: PersistQueryClientProviderProps['persistOptions'] =
  {
    persister,
    dehydrateOptions: {
      shouldDehydrateQuery: (query) => {
        return shouldDehydrateQuery(query.queryKey);
      },
    },
    maxAge: cacheTime,
  };

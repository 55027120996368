import { get } from "../methods";
function getDashboardRecentlyViewed(headers = {}) {
  return get({
    url: `dashboard/recently-viewed`,
    headers
  });
}
export {
  getDashboardRecentlyViewed as default
};

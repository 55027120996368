import { createRoutine } from 'redux-saga-routines';
import { put, takeLatest, takeEvery } from 'redux-saga/effects';
import IndustryAverageFieldsRepository from '@components/IndustryAverages/repository/IndustryAverageFields';
import IndustryAverageFieldsProvider from '@components/IndustryAverages/provider/IndustryAverageFieldsProvider';
import IndustryAverageByIndustryAndCountryRepository from '@components/IndustryAverages/repository/IndustryAverageByIndustryAndCountry';
import IndustryAverageByIndustryAndCountryProvider from '@components/IndustryAverages/provider/IndustryAverageByIndustryAndCountryProvider';
import type { AnyAction } from 'redux';

const avgFieldsRepo = new IndustryAverageFieldsRepository();
const avgFieldsProvider = new IndustryAverageFieldsProvider(avgFieldsRepo);
const avgByCountryRepo = new IndustryAverageByIndustryAndCountryRepository();
const avgByCountryProvider = new IndustryAverageByIndustryAndCountryProvider(
  avgByCountryRepo
);

const fieldsRefreshCutOff = 24 * 60 * 60 * 1000; //24 hours
/** @routines */
export const IndustryAverageFields = createRoutine('INDUSTRY_AVG_FIELDS');
export const IndustryAverageFieldConstituents = createRoutine(
  'INDUSTRY_AVG_FIELD_CONSTITUENTS'
);
export const IndustryAverageByCountry = createRoutine(
  'INDUSTRY_AVG_BY_COUNTRY'
);
export const IndustryAverageByCompany = createRoutine(
  'INDUSTRY_AVG_BY_COMPANY'
);

function* fetchIndustryAverageFields({
  payload: { lastUpdated, forceRefresh },
}: AnyAction) {
  try {
    if (Date.now() - lastUpdated > fieldsRefreshCutOff || forceRefresh) {
      //Fetch the fields
      const industryAvgFields =
        yield avgFieldsProvider.getAllIndustryAvgFields();
      yield put(
        IndustryAverageFields.success({
          fieldDetails: industryAvgFields.entities,
        })
      );
    }
  } catch (err) {
    console.error('Failed to get fields for Industry Averages..', err);
    yield put(IndustryAverageFields.failure({ error: err }));
  }
}

function* fetchIndustryAverageFieldConstituents({
  payload: {
    fieldName,
    group,
    countryISO,
    industryId,
    lastUpdated,
    forceRefresh,
  },
}: AnyAction) {
  try {
    if (Date.now() - lastUpdated > fieldsRefreshCutOff || forceRefresh) {
      //Fetch the fields
      const industryAvgFieldConstituents =
        yield avgFieldsProvider.getConstituentsForIndustryAvgField(
          fieldName,
          group,
          countryISO,
          industryId
        );

      yield put(
        IndustryAverageFieldConstituents.success({
          constituentDetails: industryAvgFieldConstituents.entities,
        })
      );
    }
  } catch (err) {
    console.error(
      'Failed to get constituents for Industry Average Field Constituents..',
      err
    );
    yield put(IndustryAverageFieldConstituents.failure({ error: err }));
  }
}

export function* fetchIndustryAveragesForCountry({
  payload: { industryId, countryISO },
}: AnyAction) {
  try {
    const industryAveragesForCountry =
      yield avgByCountryProvider.getIndustryAveragesByCountry(
        industryId,
        countryISO
      );

    yield put(
      IndustryAverageByCountry.success({
        averagesByCountryDetails: industryAveragesForCountry.entities,
      })
    );
  } catch (err) {
    console.error('Failed to get industry averages for Country..', err);
    yield put(
      IndustryAverageByCountry.failure({
        error: err,
        industryId,
        countryISO,
      })
    );
  } finally {
    yield put(IndustryAverageByCountry.fulfill());
  }
}

/*** Actions */
export const getIndustryAveragesForCompany = (companyId: string) => {
  return IndustryAverageByCompany.trigger({
    companyIdentifier: companyId,
    companyData: null,
  });
};

/*** Sagas */

function* watchfetchIndustryAverageFields() {
  yield takeLatest(IndustryAverageFields.TRIGGER, fetchIndustryAverageFields);
}

function* watchfetchIndustryAverageFieldConstituents() {
  yield takeLatest(
    IndustryAverageFieldConstituents.TRIGGER,
    fetchIndustryAverageFieldConstituents
  );
}

function* watchfetchIndustryAverageCountry() {
  yield takeEvery(
    IndustryAverageByCountry.TRIGGER,
    fetchIndustryAveragesForCountry
  );
}

export const exportableSagas = [
  watchfetchIndustryAverageFields,
  watchfetchIndustryAverageFieldConstituents,
  watchfetchIndustryAverageCountry,
];

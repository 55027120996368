import { useCallback, useMemo } from 'react';
import { useLocale } from '@/hooks/useLocale';
import { I18NEXUS_API_KEY, PUBLIC_SWS_ENV } from '@/constants/env';
import { I18NextProvider } from '@simplywallst/hooks/useTranslation';
import { SUPPORTED_LOCALES, SUPPORTED_NAMESPACES } from '@/utils/locale';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';

let translationResource: undefined | Record<string, Record<string, string>>;

export async function fetchLocales(locale: string, ns: string) {
  if (
    typeof translationResource !== 'undefined' &&
    locale in translationResource &&
    ns in translationResource[locale]
  ) {
    return translationResource[locale][ns];
  }
  /**
   * Use this API instead to allow us to use dots in our namespace names (e.g. `web.companyReport`).
   */
  const loadPath = `https://api.i18nexus.com/project_resources/translations/${locale}?api_key=${I18NEXUS_API_KEY}`;
  const raw = await fetch(loadPath);
  const translations = await raw.json();
  translationResource = {
    ...translationResource,
    [locale]: translations,
  };
  if (ns in translations) {
    return translations[ns];
  }
  throw new Error('Namespace not found');
}

interface Props {
  children: ReactNode;
}

/**
 * @description Wrapper for `<I18nextProvider/>` that abstracts
 * initialisation of the i18n instance.
 */
export function LocaleProvider({ children }: Props) {
  const supportedLngs = useMemo(() => ['en', ...SUPPORTED_LOCALES], []);
  const supportedNs = useMemo(() => [...SUPPORTED_NAMESPACES], []);

  const locale = useLocale();
  const resources = useMemo(() => {
    if (RUNTIME_ENV === 'browser') {
      return Object.fromEntries(
        Object.entries(window?.initialI18nStore ?? {}).map(
          ([locale, resource]) => [locale, { ...resource }]
        )
      );
    }

    if (RUNTIME_ENV === 'native') {
      // Preload all locales and namespaces for native
      const allResources = {};
      supportedLngs.forEach((lang) => {
        allResources[lang] = {};
        supportedNs.forEach((ns) => {
          // Using require to ensure webpack bundles all translations
          allResources[lang][ns] = require(
            `@assets/locales/${lang}/${ns}.json`
          );
        });
      });
      return allResources;
    }

    return undefined;
  }, [supportedLngs, supportedNs]);

  const importFn = useCallback<
    NonNullable<ComponentPropsWithoutRef<typeof I18NextProvider>['importFn']>
  >(
    (language, namespace, callback) => {
      const preloadedResource = resources?.[language]?.[namespace];
      if (preloadedResource) {
        return callback(null, preloadedResource);
      }

      if (PUBLIC_SWS_ENV === 'prod') {
        try {
          import(`@assets/locales/${language}/${namespace}.json`)
            .then((locales) => callback(null, locales))
            .catch((error) => callback(error, null));
        } catch (error) {
          if (error instanceof Error) {
            callback(error, null);
          } else {
            callback(new Error('An unknown error has occurred'), null);
          }
        }
      } else {
        fetchLocales(language, namespace)
          .then((locales) => callback(null, locales))
          .catch((error) => callback(error, null));
      }
    },
    [resources]
  );

  return (
    <I18NextProvider
      supportedLngs={supportedLngs}
      resources={resources}
      locale={locale}
      namespace={supportedNs}
      importFn={importFn}
      initOptions={{
        react: {
          useSuspense: true,
        },
      }}
      logLevel={
        PUBLIC_SWS_ENV === 'local' || PUBLIC_SWS_ENV === 'dev'
          ? 'warn'
          : 'error'
      }
    >
      {children}
    </I18NextProvider>
  );
}

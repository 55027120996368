import { get } from "../methods";
function getUser(headers = {}, baseURL) {
  return get({
    url: "api/user",
    headers,
    baseURL
  });
}
export {
  getUser as default
};

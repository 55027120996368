import { createSelector } from 'reselect';
import type { State } from '../interface';
import { getCompanyId } from './utils';
import type { TopShareholders } from '../interface/interface';

type SelectorReturn = State['topShareholders'][''];
export interface SelectorProps {
  companyId?: string;
}

const getTopShareholdersState = (
  state,
  props: SelectorProps
): TopShareholders => {
  const companyId = getCompanyId(state, props);
  const company = state.company as State;
  if (company.topShareholders[companyId]) {
    return company.topShareholders[companyId];
  }
  return {};
};

export const getTopShareholders = createSelector(
  [getTopShareholdersState],
  (topShareholders): SelectorReturn => {
    if (topShareholders === null) return {};
    return topShareholders;
  }
);

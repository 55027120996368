import { createRoutine } from 'redux-saga-routines';
import type { FetchDashboardMyMarketsAndIndustriesPayload } from './interfaces';

export const fetchDashboardMyMarketsAndIndustries = createRoutine<
  FetchDashboardMyMarketsAndIndustriesPayload['trigger'], // trigger
  FetchDashboardMyMarketsAndIndustriesPayload['request'], // request
  FetchDashboardMyMarketsAndIndustriesPayload['success'], //success
  FetchDashboardMyMarketsAndIndustriesPayload['failure'], // failure
  FetchDashboardMyMarketsAndIndustriesPayload['fulfill'] // fulfill
>('@DASHBOARD/FETCH_MY_MARKETS_AND_INDUSTRIES', {});

import { produce } from 'immer';
import { fetchIndustryMarketAverage } from './routines';
import { fetchDashboardMyMarketsAndIndustries } from '@components/Dashboard/redux/routines';

type AverageData = ReturnType<
  (typeof fetchIndustryMarketAverage)['success']
>['payload']['averages']['data']['company']['data'];
type AverageFieldData = AverageData;
type AverageFieldDatum = AverageFieldData[0];

export interface AverageItem {
  count: number;
  value: number;
  source: string;
}

export interface Averages {
  EPS?: AverageItem;
  PB?: AverageItem;
  PE?: AverageItem;
  PEG?: AverageItem;
  ROA?: AverageItem;
  ROE?: AverageItem;
  analystCoverageCount?: AverageItem;
  dividendYield?: AverageItem;
  futureDividendYield?: AverageItem;
  growthPast1y?: AverageItem;
  growthPast5y?: AverageItem;
  leveredBeta?: AverageItem;
  netIncomeGrowthAnnual?: AverageItem;
  return1yrAbs?: AverageItem;
  return1yrTotalReturn?: AverageItem;
  return30d?: AverageItem;
  return3yrAbs?: AverageItem;
  return3yrTotalReturn?: AverageItem;
  return5yrAbs?: AverageItem;
  return5yrTotalReturn?: AverageItem;
  return7d?: AverageItem;
  return90d?: AverageItem;
  revenueGrowthAnnual?: AverageItem;
  dividendYieldP10?: AverageItem;
  dividendYieldP25?: AverageItem;
  dividendYieldP50?: AverageItem;
  dividendYieldP75?: AverageItem;
  dividendYieldP90?: AverageItem;
  dailyReturnStandardDeviation90dMedian?: AverageItem;
  dailyReturnStdDev90P10?: AverageItem;
  dailyReturnStdDev90P90?: AverageItem;
  lastUpdated: number;
}

const toItem = (field: AverageFieldDatum) => ({
  count: field.count,
  value: field.value,
  source: field.source.name,
});

const extractFields = (fields: AverageFieldData) =>
  fields.reduce<Averages>(
    (acc, current) => {
      let field: undefined | keyof Omit<Averages, 'lastUpdated'>;
      switch (current.name) {
        case 'analyst_coverage_count':
          field = 'analystCoverageCount';
          break;
        case 'dividend_yield':
          field = 'dividendYield';
          break;
        case 'eps':
          field = 'EPS';
          break;
        case 'future_dividend_yield':
          field = 'futureDividendYield';
          break;
        case 'growth_past_1y':
          field = 'growthPast1y';
          break;
        case 'growth_past_5y':
          field = 'growthPast5y';
          break;
        case 'levered_beta':
          field = 'leveredBeta';
          break;
        case 'net_income_growth_annual':
          field = 'netIncomeGrowthAnnual';
          break;
        case 'pb':
          field = 'PB';
          break;
        case 'pe':
          field = 'PE';
          break;
        case 'peg':
          field = 'PEG';
          break;
        case 'return_1yr_abs':
          field = 'return1yrAbs';
          break;
        case 'return_1yr_total_return':
          field = 'return1yrTotalReturn';
          break;
        case 'return_30d':
          field = 'return30d';
          break;
        case 'return_3yr_abs':
          field = 'return3yrAbs';
          break;
        case 'return_3yr_total_return':
          field = 'return3yrTotalReturn';
          break;
        case 'return_5yr_abs':
          field = 'return5yrAbs';
          break;
        case 'return_5yr_total_return':
          field = 'return5yrTotalReturn';
          break;
        case 'return_7d':
          field = 'return7d';
          break;
        case 'return_90d':
          field = 'return90d';
          break;
        case 'return1yrAbs':
          field = 'return1yrAbs';
          break;
        case 'revenue_growth_annual':
          field = 'revenueGrowthAnnual';
          break;
        case 'roa':
          field = 'ROA';
          break;
        case 'roe':
          field = 'ROE';
          break;
        case 'dividend_yield_p10':
          field = 'dividendYieldP10';
          break;
        case 'dividend_yield_p25':
          field = 'dividendYieldP25';
          break;
        case 'dividend_yield_p50':
          field = 'dividendYieldP50';
          break;
        case 'dividend_yield_p75':
          field = 'dividendYieldP75';
          break;
        case 'dividend_yield_p90':
          field = 'dividendYieldP90';
          break;
        case 'daily_return_standard_deviation_90d_median':
          field = 'dailyReturnStandardDeviation90dMedian';
          break;
        case 'daily_return_standard_deviation_90d_p10':
          field = 'dailyReturnStdDev90P10';
          break;
        case 'daily_return_standard_deviation_90d_p90':
          field = 'dailyReturnStdDev90P90';
          break;
      }
      if (field) {
        acc[field] = toItem(current);
      }
      return acc;
    },
    {
      lastUpdated: Date.now(),
    }
  );

export interface Country {
  industry: Record<string, Averages>;
  market: Averages;
}

const getDefaultCountry = (): Country => ({
  industry: {},
  market: {
    lastUpdated: -1,
  },
});

export interface State {
  [key: string]: Country;
}
function getDefault(): State {
  return {};
}

export function reducer(
  state = getDefault(),
  action: ReturnType<
    | (typeof fetchIndustryMarketAverage)['success']
    | (typeof fetchDashboardMyMarketsAndIndustries)['success']
  >
) {
  return produce(state, (draft) => {
    const { type } = action;
    switch (type) {
      case fetchIndustryMarketAverage.SUCCESS: {
        const { payload } = action as ReturnType<
          (typeof fetchIndustryMarketAverage)['success']
        >;
        const { averages, exchangeCountryISO } = payload;

        const countryISO = exchangeCountryISO.toLowerCase();
        const industryId = averages.data.company.data[0].industry.toString();
        const industry = extractFields(averages.data.company.data);
        const market = extractFields(averages.data.market.data);

        if (!state[countryISO]) {
          draft[countryISO] = getDefaultCountry();
        }
        draft[countryISO].market = market;
        draft[countryISO].industry[industryId] = industry;

        break;
      }
      case fetchDashboardMyMarketsAndIndustries.SUCCESS:
        const {
          payload: { data },
        } = action as ReturnType<
          (typeof fetchDashboardMyMarketsAndIndustries)['success']
        >;

        data.forEach((n) => {
          const {
            country,
            country_iso: countryISO,
            industry_id: industryId,
          } = n;

          const market = countryISO.toLowerCase();

          if (!state[market] && !draft[market]) {
            draft[market] = getDefaultCountry();
          }

          const data = extractFields(
            ['return_7d', 'return_1yr_abs', 'pe']
              .filter((key) => n[key] !== undefined && n[key] !== null)
              .map((key) => ({
                name: key,
                source: {
                  name: country,
                  type: '',
                  industry: industryId,
                },
                value: n[key],
                count: 0,
                industry: industryId,
                type: '',
              }))
          );

          if (industryId !== 0) {
            const id = industryId.toString();
            if (typeof draft[market].industry[id] === 'undefined') {
              draft[market].industry[id] = {
                lastUpdated: data.lastUpdated,
              };
            }
            draft[market].industry[id].return7d = data.return7d;
            draft[market].industry[id].return1yrAbs = data.return1yrAbs;
            draft[market].industry[id].PE = data.PE;
          }
          if (industryId === 0) {
            draft[market].market.return7d = data.return7d;
            draft[market].market.return1yrAbs = data.return1yrAbs;
            draft[market].market.PE = data.PE;
          }
        });

        break;
    }
  });
}

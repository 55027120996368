import { createRoutine } from 'redux-saga-routines';
import type { getNews } from '@simplywallst/services';
type Response = Awaited<ReturnType<typeof getNews>>['data'];
export interface TriggerPayload {
  canonicalUrl: string;
  symbol?: string;
  companyId?: string;
}

export interface SuccessPayload {
  companyId: string;
  news: Response['data'];
}
export const fetchCompanyNews = createRoutine<
  SimplePayloadCreator<TriggerPayload>,
  SimplePayloadCreator,
  SimplePayloadCreator<SuccessPayload>,
  SimplePayloadCreator<string>,
  SimplePayloadCreator
>('@/pages/CompanyReport/fetchNews', {});

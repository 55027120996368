import { get } from "../methods";
import { removeLeadingSlash } from "../utils";
function getAnalystCoverage_default(req, headers = {}) {
  const { lookupVal, payload } = req;
  return get({
    url: `/api/company/estimates/coverage/${removeLeadingSlash(lookupVal)}`,
    payload,
    headers
  });
}
export {
  getAnalystCoverage_default as default
};

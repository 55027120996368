import { getCompany } from '@simplywallst/services';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { Locale } from '@/hooks/useLocale';
import { useLocale } from '@/hooks/useLocale';
import { useSelector } from 'react-redux';
import { getUrlMapping } from '@/pages/CompanyReport/redux/selectors/utils';
import { useMemo } from 'react';

type IncludeParameters = (
  | 'info'
  | 'score'
  | 'score.snowflake'
  | 'analysis.extended.raw_data'
  | 'analysis.extended.raw_data.insider_transactions'
  | 'statistics'
)[];

const defaultIncludeParameters: IncludeParameters = [
  'info',
  'score',
  'score.snowflake',
  'analysis.extended.raw_data',
  'analysis.extended.raw_data.insider_transactions',
  'statistics',
];

export const STALE_TIME = 6 * 60 * 60 * 1000; // 6 hours
export const RETRY = 3;

export function getQueryKey(
  lookupValue: string,
  locale: Locale = 'en',
  includeParameters: IncludeParameters = defaultIncludeParameters
) {
  return ['company', lookupValue, includeParameters.join(','), locale] as const;
}

export async function queryFn(
  context: QueryFunctionContext<ReturnType<typeof getQueryKey>>
) {
  const { 1: lookupValue, 2: includeParameters } = context.queryKey;
  const response = await getCompany({
    lookupVal: lookupValue,
    payload: {
      include: includeParameters,
      version: '2.0',
      locale: context?.queryKey?.[3],
    },
  });

  if (typeof response.data.data === 'undefined') {
    throw new Error('`data` response is undefined');
  }
  return response.data;
}

/**
 *
 * Custom hook for fetching company data
 *
 * @param lookupValue
 * @param includeParameters
 *
 * lookupValue accepts the following options
 *
 * * canonincal url of a company
 * * company id
 * * unique symbol
 *
 * For `includeParameters` please refer to API rest docs
 *
 */
export function useCompany(
  lookupValue: string,
  includeParameters: IncludeParameters = defaultIncludeParameters
) {
  const locale = useLocale();
  const urlToIdMapping = useSelector(getUrlMapping);

  const normalizedLookUpValue = useMemo(() => {
    const value = lookupValue ? lookupValue.toLowerCase() : '';
    return (
      Object.keys(urlToIdMapping).find(
        (key) => urlToIdMapping[key] === value
      ) || lookupValue
    );
  }, [lookupValue, urlToIdMapping]);

  const { data, status } = useQuery({
    queryKey: getQueryKey(normalizedLookUpValue, locale, includeParameters),
    queryFn,
    staleTime: STALE_TIME,
    retry: RETRY,
    enabled: normalizedLookUpValue !== '',
  });

  if (status === 'success') {
    return { data, status };
  }
  return { status };
}

import styled from 'styled-components';
import { media } from '@simplywallst/ui-core';

const OneColumn = styled('section')`
  color: white;
  position: relative;
  max-width: ${({ theme }) => theme.xlMin};
  width: 100%;
  ${media.lgUp`
    padding: ${({ theme }) =>
      `${theme.x1spacePx}px ${theme.x3spacePx}px ${theme.x3spacePx}px ${theme.x3spacePx}px`};
  `};
`;

export default OneColumn;

import { patch } from "../methods";
function updatePortfolio(options, headers = {}) {
  const { id, payload } = options;
  return patch({
    url: `api/user/portfolio/${id}`,
    payload,
    headers
  });
}
export {
  updatePortfolio as default
};

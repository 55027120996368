import { useQuery } from '@tanstack/react-query';
import { queryFn, getQueryKey } from './queryFn';
import type { Statement, StatementName } from './types';
import { useLocale } from '../useLocale';
import { useMemo } from 'react';

type StatementsMap = Record<StatementName, Statement | never> & {
  sentence?: string;
};

export const transformStatements = (
  data: Awaited<ReturnType<typeof queryFn>> | undefined
): StatementsMap => {
  if (!data) return {} as StatementsMap;
  const statements = data.statements.data.reduce((prev, curr) => {
    let newContext: null | Record<string, string> = {};
    if (
      typeof curr._tooltip_ !== 'undefined' &&
      !Array.isArray(curr._tooltip_) &&
      curr.context
    ) {
      const { _tooltip_: localisedTooltip, context } = curr;
      Object.entries(localisedTooltip || {}).forEach(
        ([localisedKey, localisedValue]) => {
          if (localisedKey in context) {
            if (newContext) {
              newContext[localisedValue] = context[localisedKey];
            }
          }
        }
      );
    } else {
      newContext = curr.context;
    }
    prev[curr.name] = { ...curr, context: newContext };
    return prev;
  }, {}) as StatementsMap;
  if (data.sentence) {
    return { ...statements, sentence: data.sentence };
  }
  return statements;
};

const useCompanyStatementsAPIFormatted = ({
  canonicalURL,
}: {
  canonicalURL?: string;
}) => {
  const locale = useLocale();

  const query = useQuery({
    queryKey: getQueryKey(canonicalURL || '', locale),
    queryFn,
    staleTime: 3600000,
    enabled: Boolean(canonicalURL),
  });

  const transformedData = useMemo(
    () => transformStatements(query.data),
    [query.data]
  );

  return {
    ...query,
    data: transformedData,
  };
};

export default useCompanyStatementsAPIFormatted;

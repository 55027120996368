import styled from 'styled-components';
import { media } from '@simplywallst/ui-core';

import { highchartsFlexboxHack } from '@/styled/shared/shared';

export default styled('section')`
  width: 100%;
  color: white;
  position: relative;
  max-width: ${({ theme }) => theme.lgMax};
  ${media.lgUp`
    padding-top: ${({ theme }) => theme.x2spacePx}px;
    padding-left: ${({ theme }) => theme.sidebarWidth}px;
    padding-right: ${({ theme }) => theme.x2spacePx}px;
  `};
  ${highchartsFlexboxHack};
`;

import { get } from "../methods";
const getDefaultConfig = () => ({
  action: ""
});
const payloadDefaults = () => ({
  offset: 0,
  limit: 100
});
function getIndustryFieldFilter_default(config = getDefaultConfig(), headers = {}) {
  const { action = "", payload } = config;
  return get({
    url: `api/industry/fields/${action}`,
    payload: { ...payloadDefaults, ...payload },
    headers
  });
}
export {
  getIndustryFieldFilter_default as default
};

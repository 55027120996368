import flow from 'lodash/flow';
import { formatDistanceToNow, type FormatDistanceToNowOptions } from 'date-fns';
import {
  formatDistanceLocaleShort,
  type FormatDistanceLocaleShort,
} from '@/constants/translations';
import type { Locale } from '@/hooks/useLocale';

export const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString);
  return new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
  );
};

export const getUTCNumber = flow(getUTCDate, Number);

const defaultCountryISO = 'en';

/**
 * Use a shortened format for the distance to now.
 *
 * @param date - The date to format.
 * @param options - The options to pass to `formatDistanceToNow`.
 * @returns The formatted date.
 */
export const formatDistanceToNowShort = (
  date: number | string,
  options?: Omit<FormatDistanceToNowOptions, 'locale'> & { countryISO?: Locale }
) => {
  const localisedDistances =
    formatDistanceLocaleShort.get(options?.countryISO ?? defaultCountryISO) ??
    (formatDistanceLocaleShort.get(
      defaultCountryISO
    ) as FormatDistanceLocaleShort);

  const formatDistance = (token: string, count: number): string =>
    localisedDistances[token]?.replace('{{count}}', count.toString());

  const result = formatDistanceToNow(date, {
    locale: { formatDistance },
    ...options,
  });

  return result;
};

import { produce } from 'immer';
import { fetchCompanyNews } from '../routines';

type State = Record<string, Record<string, string>>;

function getDefault(): State {
  return {};
}

export function newsReducer(
  state = getDefault(),
  action: ReturnType<(typeof fetchCompanyNews)['success']>
) {
  return produce(state, (draft) => {
    const { type } = action;
    switch (type) {
      case fetchCompanyNews.SUCCESS: {
        const {
          payload: { news, companyId },
        } = action as ReturnType<(typeof fetchCompanyNews)['success']>;
        draft[companyId] = news.reduce((a, b) => {
          a[b.original_url] = b.original_url;
          return a;
        }, {});
        break;
      }
    }
  });
}

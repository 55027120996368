import { uniq, get, pull } from 'lodash';
import { PublicPortfolioFetch } from '@/components/DeprecatedPortfolios/redux/routines';
import { produce, setAutoFreeze } from 'immer';
import { reducerErrorLogger } from '@ducks/util';
import {
  fetchPortfolio,
  fetchPortfolioList,
  fetchPortfolioItems,
  portfolioAddCompany,
  portfolioRemoveCompany,
  addTransactionForItem,
  removeTransactionForItem,
  reinvestDividend,
  regenerateSecretCode,
  fetchPortfolioNews,
} from '@/components/DeprecatedPortfolio/redux/routines';
import listReducer from './listReducer';
import transactionReducer from './transactionReducer';
import portfolioReducer from './portfolioReducer';
import { addRemoveHoldingReducer } from './holdingReducer';
import type { State, Action } from './interface';

setAutoFreeze(false);

const getInitialState = (): State => ({
  entities: {},
  holdingsTable: {},
  holdingsItemIdToCompanyIdMap: {},
  publicKeys: [],
  transactionsTable: {},
  userKeys: [],
  news: {},
});

export default function reducer(
  state = getInitialState(),
  action:
    | ReturnType<
        | (typeof fetchPortfolioNews)['success']
        | (typeof portfolioAddCompany)['fulfill']
      >
    | Action
): State {
  const logError = reducerErrorLogger('Portfolios reducer');
  return produce(state, (draft) => {
    const { type } = action;
    switch (type) {
      case addTransactionForItem.SUCCESS: {
        const { payload } = action as Action;
        const {
          id,
          itemId,
          shareCount,
          transactionCost,
          transactionDate,
          transactionType,
        } = payload;
        if (
          itemId === undefined ||
          id === undefined ||
          transactionCost === undefined ||
          transactionDate === undefined ||
          transactionType === undefined ||
          shareCount === undefined
        ) {
          return;
        }
        try {
          const { transactionsTable } = draft;
          const { transactionsMap } = draft.holdingsTable[itemId];
          const transactionId = id.toString();
          if (transactionsMap) {
            transactionsMap.push(transactionId);
          }
          transactionsTable[transactionId] = {
            transactionId,
            transactionType,
            transactionDate,
            transactionAmount: shareCount,
            transactionCost,
          };
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case removeTransactionForItem.TRIGGER: {
        const { payload } = action as Action;
        const { transactionId } = payload;
        if (!transactionId) return;
        const { transactionsTable } = draft;
        transactionsTable[transactionId].isRemoving = true;
        break;
      }
      case removeTransactionForItem.SUCCESS: {
        const { payload } = action as Action;
        const { itemId, transactionId } = payload;
        if (!itemId || !transactionId) return state;
        try {
          const { transactionsTable } = draft;
          const { transactionsMap = [] } = draft.holdingsTable[itemId];
          const {
            [transactionId]: transactionToRemove,
            ...newTransactionsTable
          } = transactionsTable;
          draft.transactionsTable = newTransactionsTable;
          pull(transactionsMap, transactionId);
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case reinvestDividend.TRIGGER: {
        const { payload } = action as Action;
        const { itemId } = payload;
        if (!itemId) return state;
        try {
          const { holdingsTable } = draft;
          holdingsTable[itemId].isUpdatingReinvest = true;
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case reinvestDividend.SUCCESS: {
        const { payload } = action as Action;
        const { itemId, reinvest } = payload;
        if (!itemId || reinvest === undefined) return state;
        try {
          const { holdingsTable } = draft;
          holdingsTable[itemId].reinvestDividends = reinvest;
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case reinvestDividend.FULFILL: {
        const { payload } = action as Action;
        const { itemId } = payload;
        if (!itemId) return state;
        try {
          const { holdingsTable } = draft;
          holdingsTable[itemId].isUpdatingReinvest = false;
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case portfolioRemoveCompany.TRIGGER: {
        try {
          const { payload } = action as Action;
          const { id, companyId } = payload;
          if (!companyId || !id) return state;
          const safeId = id.toString();
          const selectedPortfolio = state.entities[safeId];
          if (
            !selectedPortfolio ||
            !selectedPortfolio.holdingCompanyIdItemIdMap
          )
            return state;
          const matchedItemId =
            selectedPortfolio.holdingCompanyIdItemIdMap[companyId];
          if (!matchedItemId) return state;
          const { entities, holdingsTable } = draft;
          entities[safeId].isUpdating = true;
          holdingsTable[matchedItemId].isUpdating = true;
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case portfolioRemoveCompany.SUCCESS: {
        try {
          const { payload } = action as Action;
          const { id, companyId } = payload;
          if (!companyId || !id) return state;

          const safeId = id.toString();
          const selectedPortfolio = state.entities[safeId];
          if (!selectedPortfolio) return state;

          const { entities, holdingsItemIdToCompanyIdMap } = draft;
          const matchedPortfolio = entities[safeId];
          const {
            holdingsCount = 0,
            holdingsMap = [],
            holdingsLongIdMap = [],
            holdingCompanyIdItemIdMap = {},
          } = matchedPortfolio;
          pull(holdingsLongIdMap, companyId);
          const { [companyId]: itemId, ...rest } = holdingCompanyIdItemIdMap;
          if (itemId) {
            pull(holdingsMap, itemId);
            matchedPortfolio.holdingCompanyIdItemIdMap = rest;
          }
          matchedPortfolio.holdingsCount = Math.max(0, holdingsCount - 1);
          matchedPortfolio.isUpdating = false;
          const matchedItemIdMap = holdingsItemIdToCompanyIdMap[companyId];
          if (matchedItemIdMap) {
            pull(matchedItemIdMap, itemId);
          }
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case portfolioRemoveCompany.FULFILL: {
        try {
          const { id } = action.payload;
          if (!id) return state;

          const safeId = id.toString();
          const selectedPortfolio = state.entities[safeId];
          if (!selectedPortfolio) return state;

          const { entities } = draft;
          entities[safeId].isUpdating = false;
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case portfolioAddCompany.TRIGGER: {
        try {
          const { id } = action.payload;
          if (!id) return state;
          const safeId = id.toString();
          const { entities } = draft;
          entities[safeId].isUpdating = true;
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case portfolioAddCompany.SUCCESS: {
        try {
          const { payload } = action as Action;
          const { id, companyId, data } = payload;
          if (!companyId || !id || !data) return state;
          const safeId = id.toString();
          const selectedPortfolio = get(state.entities, safeId, null);
          if (!selectedPortfolio) return state;
          const itemId = get(data, 'id');
          if (!itemId) return state;
          const safeItemId = itemId.toString();
          const selectedHolding = get(state.holdingsTable, safeItemId, {});

          const { entities, holdingsTable, holdingsItemIdToCompanyIdMap } =
            draft;
          const reduced = addRemoveHoldingReducer(
            safeItemId,
            companyId,
            holdingsTable
          )(data);
          const matchedPortfolio = entities[safeId];
          if (matchedPortfolio) {
            const {
              holdingsCount = 0,
              holdingsMap = [],
              holdingsLongIdMap = [],
              holdingCompanyIdItemIdMap = {},
            } = matchedPortfolio;
            matchedPortfolio.isUpdating = false;
            matchedPortfolio.holdingsCount = holdingsCount + 1;
            holdingsMap.push(safeItemId);
            holdingsLongIdMap.push(companyId);
            holdingCompanyIdItemIdMap[companyId] = safeItemId;
          }
          holdingsTable[itemId] = {
            ...selectedHolding,
            ...reduced,
          };
          const matchedHoldingsItemIdMap =
            holdingsItemIdToCompanyIdMap[companyId];
          if (matchedHoldingsItemIdMap) {
            matchedHoldingsItemIdMap.push(safeItemId);
          } else {
            holdingsItemIdToCompanyIdMap[companyId] = [safeItemId];
          }
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case portfolioAddCompany.FULFILL: {
        try {
          const { id } = action.payload;
          if (!id) return state;
          const { entities } = draft;
          entities[id].isUpdating = false;
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case fetchPortfolio.SUCCESS: {
        try {
          const { payload } = action as Action;
          const { id, isPublic } = payload;
          if (!id) return state;
          const safeId = id.toString();
          const reduced = portfolioReducer(state, payload);
          if (!reduced) return state;
          const {
            holdingsTable: reducedHoldingsTable,
            entities,
            holdingsItemIdToCompanyIdMap,
          } = reduced;
          if (!entities) return state;

          const portfolio = entities[safeId];
          const storeTo = isPublic ? 'publicKeys' : 'userKeys';
          const { holdingsTable } = draft;
          draft[storeTo] = uniq([...state[storeTo], id.toString()]);
          draft.holdingsTable = {
            ...holdingsTable,
            ...reducedHoldingsTable,
          };
          draft.entities[safeId] = portfolio;
          if (holdingsItemIdToCompanyIdMap) {
            draft.holdingsItemIdToCompanyIdMap = holdingsItemIdToCompanyIdMap;
          }
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case fetchPortfolioItems.SUCCESS: {
        try {
          const { payload } = action as Action;
          const {
            holdingsTable: reducedHoldingsTable,
            transactionsTable: reducedTransactionsTable,
          } = transactionReducer(state, payload);

          const { holdingsTable, transactionsTable } = draft;
          draft.holdingsTable = {
            ...holdingsTable,
            ...reducedHoldingsTable,
          };
          draft.transactionsTable = {
            ...transactionsTable,
            ...reducedTransactionsTable,
          };
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case fetchPortfolioList.SUCCESS: {
        try {
          const { payload } = action as Action;
          const { portfolios } = payload;
          const {
            keys,
            entities: reducedEntities,
            holdingsTable: reducedHoldingsTable,
            holdingsIdMap: reducedHoldingsIdMap,
            transactionsTable: reducedTransactionsTable,
          } = listReducer(state, portfolios);

          //Note: Removed merging of old state with new state
          //Reason: Source of truth is always the server,
          //trust always use the data coming from the server
          //merge was causing problem when something is deleted and is no longer
          //coming from server but still resides in UI state
          //In case, in future, partial merge is required
          //use a different routine

          //Merging entities to avoid public/private entities overriding each other
          const { entities, publicKeys, news } = draft;

          const publicEntities = publicKeys.reduce((a, b) => {
            a[b] = entities[b];
            return a;
          }, {});

          const newsEntities = {};
          //  Pick only entities that exist according to the server
          keys.forEach((n) => (newsEntities[n] = news[n]));
          publicKeys.forEach((n) => (newsEntities[n] = news[n]));

          draft.userKeys = keys;
          draft.entities = { ...publicEntities, ...reducedEntities };
          draft.holdingsTable = reducedHoldingsTable;
          draft.holdingsItemIdToCompanyIdMap = reducedHoldingsIdMap;
          draft.transactionsTable = reducedTransactionsTable;
          draft.news = newsEntities;
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case PublicPortfolioFetch.SUCCESS: {
        try {
          const { payload } = action as ReturnType<
            (typeof PublicPortfolioFetch)['success']
          >;
          const { portfolios } = payload;
          const {
            keys,
            entities: reducedEntities,
            holdingsTable,
            holdingsIdMap,
            transactionsTable,
          } = listReducer(state, portfolios);
          //Note: See reason for removing merge above

          //Merging entities to avoid public/private entities overriding each other
          const { entities, userKeys, news } = draft;

          const userEntities = userKeys.reduce((a, b) => {
            a[b] = entities[b];
            return a;
          }, {});

          const newsEntities = {};
          //  Pick only entities that exist according to the server
          keys.forEach((n) => (newsEntities[n] = news[n]));
          userKeys.forEach((n) => (newsEntities[n] = news[n]));

          draft.publicKeys = keys;
          draft.entities = { ...userEntities, ...reducedEntities };
          draft.holdingsTable = holdingsTable;
          draft.holdingsItemIdToCompanyIdMap = holdingsIdMap;
          draft.transactionsTable = transactionsTable;
          draft.news = newsEntities;
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case regenerateSecretCode.SUCCESS: {
        try {
          const { payload } = action as Action;
          const { id, secretCode } = payload;
          if (!id) return state;
          const safeId = id.toString();
          const { entities } = draft;
          entities[safeId].secretCode = secretCode;
        } catch (error) {
          logError(type, error);
        }
        break;
      }
      case fetchPortfolioNews.SUCCESS: {
        try {
          const { payload } = action as ReturnType<
            (typeof fetchPortfolioNews)['success']
          >;
          const { id, portfolioNews } = payload;
          const { news } = draft;
          news[id] = portfolioNews.map((news) => news.original_url);
        } catch (error) {
          logError(type, error);
        }
        break;
      }
    }
  });
}

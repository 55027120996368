import { pathToAction } from 'redux-first-router';
import routesGenerator from '@/router/routesGenerator';
import queryString from 'query-string';
import { isSupportedLocale } from '@/utils/locale';
import { createSelector } from 'reselect';
import { getLocationPathname } from '@/redux/location/selectors';
import { useSelector } from 'react-redux';

/**
 * useLocale hook is being used in many places throughout the app, especially
 * in the company report and routesGenerator function call is a heavy operation.
 * Memoization using createSelector reduces total duration time
 * from ~200ms down to 1ms on full company report load.
 */
const getLocale = createSelector([getLocationPathname], (pathname) => {
  // Reconstruct the current redux router action from the current path.
  // This implicitly parses the params, so the current locale specified in route can be used.
  const action = pathToAction(pathname, routesGenerator(), queryString);

  if (isSupportedLocale(action.payload?.locale)) {
    return action.payload?.locale;
  }

  return 'en';
});

/**
 * Gets the current locale language value.
 */
export const useLocale = () => {
  return useSelector(getLocale);
};

export type Locale = ReturnType<typeof useLocale>;

import type {
  IToggle as Flag,
  IConfig as UnleashConfig,
  IMutableContext as UnleashContext,
} from '@unleash/proxy-client-react';
import {
  InMemoryStorageProvider,
  FlagProvider as UnleashFlagProvider,
} from '@unleash/proxy-client-react';
import type { ReactNode } from 'react';
import { memo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { EvaluateContext } from '../components/EvaluateContext';

const getUnleashSessionId = () => {
  let currentSessionId: string | null = null;
  // Try and retrieve an existing unleash session ID on client
  if (typeof window !== 'undefined') {
    currentSessionId = window.localStorage.getItem(
      'unleash:repository:sessionId'
    );
  }
  return currentSessionId;
};

const setUnleashSessionId = (sessionId: string) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('unleash:repository:sessionId', sessionId);
  }
};

type Props = {
  children: ReactNode;
  sessionId?: string;
  flags?: Flag[];
  isAnonymous?: boolean;
};

const EvaluateContextMemo = memo(EvaluateContext);

export const FlagsProvider = ({
  children,
  sessionId,
  flags = [],
  isAnonymous,
}: Props) => {
  const [prevContext, setPrevContext] = useState<UnleashContext>({});

  // Set session ID with the following preference;
  //   1. the server provided session ID (set by cookie on server or elsewhere).
  //   2. the stored unleash session ID (stored from earlier session).
  //   3. none, as no previous session ID exists (unleash will generate a session ID internally and store).
  let currentSessionId: string | null | undefined = sessionId;
  if (currentSessionId === undefined || currentSessionId === null) {
    currentSessionId = getUnleashSessionId();
  } else {
    // Set the session ID using the same local store that unleash itself uses.
    // This ensures the session ID's are in sync between any cookie-usage + non-cookie generation.
    setUnleashSessionId(currentSessionId);
  }

  let bootstrap = flags;

  // On clientside, try and hydrate with any ssr flags that have been set. This ensures that as
  // soon as the unleash client loads clientside it has the same variants as were set serverside.
  if (typeof window !== 'undefined' && bootstrap?.length === 0) {
    bootstrap = window.__SWS_UNLEASH_SSR_FLAGS ?? [];
  }

  let config: UnleashConfig = {
    url: SWS_EXTERNAL_UNLEASH_PROXY_URL,
    clientKey: 'sws-unleash',
    appName: 'mono-web',
    refreshInterval: 3600, // 1 hour
  };

  if (RUNTIME_ENV === 'server') {
    config = {
      url: SWS_INTERNAL_UNLEASH_PROXY_URL,
      clientKey: 'sws-unleash',
      appName: 'mono-web-ssr',
      refreshInterval: 3600, // 1 hour
      fetch: require('node-fetch'),
      storageProvider: new InMemoryStorageProvider(),
    };
  }

  return (
    <UnleashFlagProvider
      startClient={false}
      config={{
        ...config,
        // Existence of the bootstrap config with any value but `undefined` runs extra logic in the
        // unleash client internals to do with client start and flag ready state.
        // Annoyingly, only add the bootstrap if we have a non-empty value for it.
        ...(bootstrap?.length > 0
          ? {
              bootstrap,
            }
          : {}),
        // Unleash generates a session ID and transparently stores it _if_ a session ID is not set
        // in context. This means we need to avoid setting any kind of session ID context object,
        // even if it's an undefined value.
        ...(currentSessionId !== null
          ? {
              context: {
                sessionId: currentSessionId,
                properties: {
                  isAnonymous: String(!!isAnonymous),
                },
              },
            }
          : {}),
      }}
    >
      <Helmet>
        {flags?.length > 0 && (
          <script type="text/javascript" key="sws-unleash">
            {/* Set the flags into a window variable from server so they can be hydrated clientside */}
            {`window.__SWS_UNLEASH_SSR_FLAGS = ${JSON.stringify(flags)}`}
          </script>
        )}
      </Helmet>
      <EvaluateContextMemo
        prevContext={prevContext}
        onContextUpdate={setPrevContext}
      />
      {children}
    </UnleashFlagProvider>
  );
};

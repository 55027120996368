import { createRoutine } from 'redux-saga-routines';
import type { getCompanyIndustryAverage } from '@simplywallst/services';
type CompanyIndustryAverageResponse = Awaited<
  ReturnType<typeof getCompanyIndustryAverage>
>['data'];
export interface SuccessPayload {
  companyId: string;
  exchangeCountryISO: string;
  averages: CompanyIndustryAverageResponse;
}

export const fetchIndustryMarketAverage = createRoutine<
  SimplePayloadCreator<string>,
  SimplePayloadCreator,
  SimplePayloadCreator<SuccessPayload>,
  SimplePayloadCreator,
  SimplePayloadCreator
>('@averages/fetch', {});

type SWSGQLErrorType = {
  message?: string;
  path?: string[];
  extensions?: {
    code: string;
    statusCode: number;
    serviceName: string;
    traceId: string;
  };
};

export const isErrors = (errors: unknown): errors is SWSGQLErrorType[] => {
  if (!Array.isArray(errors)) return false;
  if (errors.length === 0) return false;
  return errors.every((e) => {
    if (typeof e !== 'object') return false;
    if (e === null) return false;
    return 'message' in e && 'extensions' in e;
  });
};

export class SWSGQLError extends Error {
  errors: SWSGQLErrorType[] = [];
  status: number;
  code: string = 'UNKNOWN_ERROR';
  message: string = 'Unknown error';

  constructor(errors?: SWSGQLErrorType[], statusCode = 500) {
    super();
    this.status = statusCode;

    if (errors && errors.length > 0) {
      this.errors = errors;

      const [first] = errors;
      const { extensions, message } = first;

      if (extensions) {
        this.status = extensions.statusCode;
        this.code = extensions.code;
      }

      this.message = message ?? 'Unknown error';
    }
  }
}

export const isSWSGQLError = (error: unknown): error is SWSGQLError => {
  if (error === null) return false;
  if (error instanceof Error) {
    if ('errors' in error && 'code' in error && 'status' in error) {
      const { code, status, errors } = error;
      return (
        typeof code === 'string' &&
        typeof status === 'number' &&
        Array.isArray(errors)
      );
    }
  }
  return false;
};

import { createContext } from 'react';
import type { ReactNode } from 'react';

export const Context = createContext<string>('');

interface Props {
  iso?: string;
  children: ReactNode;
}

export const Provider = ({ iso = '', children }: Props) => {
  return <Context.Provider value={iso as string}>{children}</Context.Provider>;
};

export const Consumer = Context.Consumer;

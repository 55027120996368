const holdingsSorting = [
  'value-desc',
  'roi-desc',
  'ticker-a-to-z',
  'company-name-a-to-z',
  'market-cap-desc',
  'performance-7d-desc',
  'discount-asc',
  'discount-desc',
  'total-score-desc',
  'value-score-desc',
  'future-score-desc',
  'past-score-desc',
  'dividend-score-desc',
  'health-score-desc',
  'cash-return-desc',
] as const;

export type HoldingsSorting = (typeof holdingsSorting)[number];

export const isHoldingsSorting = (p: unknown): p is HoldingsSorting =>
  holdingsSorting.includes(p as HoldingsSorting);

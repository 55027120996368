import { ROUTE_COMPANY_WITH_EVENTS } from '@/constants/routes';
import { useLocation } from '../useLocation';
import { useCompany } from './useCompany';
import { useCompanyCanonicalUrl } from './useCompanyCanonicalUrl';
import { getSnowflakePoints } from './utils';

/**
 *
 * Return a company report summary info
 *
 * @param lookupValue
 *
 * * lookupValue accepts the following options
 *
 * * canonincal url of a company
 * * company id
 * * unique symbol
 *
 * If passing a blank `lookupValue` this will use current path as the canonical url
 * for fetching the company data.
 *
 * TODO: refactor this hook and ensure the concern remains encapsulated
 * only within the `summary` data point. Create separate `useAnalysis`, `usePast`, etc hooks.
 */
export function useSummaryV2(lookupValue?: string) {
  const canonicalUrl = useCompanyCanonicalUrl();
  const companyQuery = useCompany(lookupValue || canonicalUrl);
  const location = useLocation();
  if (companyQuery.status === 'success') {
    const data = companyQuery.data.data;

    const allowCrawlers =
      location.type === ROUTE_COMPANY_WITH_EVENTS
        ? false
        : data.is_searchable && data.info.data.fund === false;

    return {
      status: companyQuery.status,
      // company data
      allowCrawlers,
      companyId: data.company_id,
      uniqueSymbol: data.unique_symbol,
      tickerSymbol: data.ticker_symbol,
      exchangeSymbol: data.exchange_symbol,
      exchangeSymbolFiltered:
        data.analysis?.data.extended.data.raw_data.data.company_info
          .exchange_symbol_filtered,
      canonicalUrl: data.canonical_url,
      name: data.info.data.legal_name,
      shortName: data.name,
      coverImage: data.info.data.main_header,
      score: getSnowflakePoints(data),
      classificationStatus: data.classification_status,
      description: data.info.data.description,
      longDescription:
        data.analysis?.data.extended.data.raw_data.data.company_info
          .description,
      isFund: data.info.data.fund,
      website: data.info.data.url,
      latestDividendYield:
        data.analysis?.data.extended.data.analysis.dividend.dividend_yield,
      payoutRatio:
        data.analysis?.data.extended.data.analysis.dividend.payout_ratio,
    };
  }
  return { status: companyQuery.status };
}

import { createSelector } from 'reselect';
import type { State as AveragesState, Averages } from './reducers';
import { get } from 'lodash';
import { getSummary } from '@/pages/CompanyReport/redux/selectors';
import { toPercentage, toSignificant } from '@/utilities/formatting';

export const getRawValueOrDefault =
  (average: Averages) => (prop: keyof Omit<Averages, 'lastUpdated'>) =>
    get(average, prop, { count: 0, value: 0, source: '' });

const getCountryAverages = (averages: AveragesState, countryISO: string) =>
  get(averages, countryISO, {
    industry: {},
    market: { lastUpdated: -1 },
  });

export const getAverageEntities = (state): AveragesState => {
  const { averages }: { averages: AveragesState } = state;
  return averages;
};

export interface Props {
  countryISO: string;
}

const getIndustryAveragesByCountryISO = (
  state,
  { countryISO }: Props
): Record<string, Averages> => {
  const { averages }: { averages: AveragesState } = state;
  const { industry } = getCountryAverages(averages, countryISO);
  return industry;
};

export const getAveragesSelector = createSelector(
  [getAverageEntities, getSummary],
  (entities, summary) => {
    const countryISO = summary.exchangeCountryISO.toLowerCase();
    const industryId = summary.industryAvergageId;
    const { industry, market } = getCountryAverages(entities, countryISO);
    return {
      getIndustryAverage: getRawValueOrDefault(industry[industryId]),
      getMarketAverage: getRawValueOrDefault(market),
    };
  }
);

export const getIndustryAveragesByCountryISOSelector = createSelector(
  [getIndustryAveragesByCountryISO],
  (industryAverages) => industryAverages
);

const formatter = (value: null | number) => toPercentage(value || 0, 1);

interface Returns {
  return1YrAbs: null | number;
  return1YrTotalReturn: null | number;
  return3YrAbs: null | number;
  return3YrTotalReturn: null | number;
  return5YrAbs: null | number;
  return5YrTotalReturn: null | number;
  return7D: null | number;
  return30D: null | number;
  return90D: null | number;
  leveredBeta: null | number;
  returnDailyStdDev90Median: null | number;
  dailyReturnStdDev90P10: null | number;
  dailyReturnStdDev90P90: null | number;
}

export interface FormattedReturns extends Returns {
  return1YrAbsFormatted: string;
  return1YrTotalReturnFormatted: string;
  return3YrAbsFormatted: string;
  return3YrTotalReturnFormatted: string;
  return5YrAbsFormatted: string;
  return5YrTotalReturnFormatted: string;
  return7DFormatted: string;
  return30DFormatted: string;
  return90DFormatted: string;
  returnDailyStdDev90MedianFormatted: string;
  dailyReturnStdDev90P10Formatted: string;
  dailyReturnStdDev90P90Formatted: string;
}

const appendFormatted = (returns?: Returns): FormattedReturns | undefined => {
  if (!returns) return;
  return {
    ...returns,
    leveredBeta: returns.leveredBeta
      ? Number(toSignificant(returns.leveredBeta, 2))
      : 0,
    return1YrAbsFormatted: formatter(returns.return1YrAbs),
    return1YrTotalReturnFormatted: formatter(returns.return1YrTotalReturn),
    return3YrAbsFormatted: formatter(returns.return3YrAbs),
    return3YrTotalReturnFormatted: formatter(returns.return3YrTotalReturn),
    return5YrAbsFormatted: formatter(returns.return5YrAbs),
    return5YrTotalReturnFormatted: formatter(returns.return5YrTotalReturn),
    return7DFormatted: formatter(returns.return7D),
    return30DFormatted: formatter(returns.return30D),
    return90DFormatted: formatter(returns.return90D),
    returnDailyStdDev90MedianFormatted: formatter(
      returns.returnDailyStdDev90Median
    ),
    dailyReturnStdDev90P10Formatted: formatter(returns.dailyReturnStdDev90P10),
    dailyReturnStdDev90P90Formatted: formatter(returns.dailyReturnStdDev90P90),
  };
};

export const getIndustryPerformance = () =>
  createSelector([getAveragesSelector], (averages) => {
    const { getIndustryAverage, getMarketAverage } = averages;
    return {
      industry: appendFormatted({
        return1YrAbs: getIndustryAverage('return1yrAbs').value,
        return1YrTotalReturn: getIndustryAverage('return1yrTotalReturn').value,
        return3YrAbs: getIndustryAverage('return3yrAbs').value,
        return3YrTotalReturn: getIndustryAverage('return3yrTotalReturn').value,
        return5YrAbs: getIndustryAverage('return5yrAbs').value,
        return5YrTotalReturn: getIndustryAverage('return5yrTotalReturn').value,
        return7D: getIndustryAverage('return7d').value,
        return30D: getIndustryAverage('return30d').value,
        return90D: getIndustryAverage('return90d').value,
        leveredBeta: getIndustryAverage('leveredBeta').value,
        returnDailyStdDev90Median: getIndustryAverage(
          'dailyReturnStandardDeviation90dMedian'
        ).value,
        dailyReturnStdDev90P10: getIndustryAverage('dailyReturnStdDev90P10')
          .value,
        dailyReturnStdDev90P90: getIndustryAverage('dailyReturnStdDev90P90')
          .value,
      }),
      market: appendFormatted({
        return1YrAbs: getMarketAverage('return1yrAbs').value,
        return1YrTotalReturn: getMarketAverage('return1yrTotalReturn').value,
        return3YrAbs: getMarketAverage('return3yrAbs').value,
        return3YrTotalReturn: getMarketAverage('return3yrTotalReturn').value,
        return5YrAbs: getMarketAverage('return5yrAbs').value,
        return5YrTotalReturn: getMarketAverage('return5yrTotalReturn').value,
        return7D: getMarketAverage('return7d').value,
        return30D: getMarketAverage('return30d').value,
        return90D: getMarketAverage('return90d').value,
        leveredBeta: getMarketAverage('leveredBeta').value,
        returnDailyStdDev90Median: getMarketAverage(
          'dailyReturnStandardDeviation90dMedian'
        ).value,
        dailyReturnStdDev90P10: getMarketAverage('dailyReturnStdDev90P10')
          .value,
        dailyReturnStdDev90P90: getMarketAverage('dailyReturnStdDev90P90')
          .value,
      }),
    };
  });

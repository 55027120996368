import {
  showDeleteHoldingModal as ShowDeleteHoldingModal,
  hideDeleteHoldingModal as HideDeleteHoldingModal,
} from './routines';

export interface DeleteHoldingPayload {
  portfolioId: string;
  uniqueSymbol: string;
  companyId: string;
}

export function showDeleteHoldingModal(data: DeleteHoldingPayload) {
  return ShowDeleteHoldingModal.trigger(data);
}

export function hideDeleteHoldingModal() {
  return HideDeleteHoldingModal.trigger();
}

import { createContext } from 'react';
import type { ReactNode } from 'react';

export const Context = createContext<string>('');

interface Props {
  location?: string;
  children?: ReactNode;
}

export const Provider = ({ location = '', children }: Props) => {
  return (
    <Context.Provider value={location as string}>{children}</Context.Provider>
  );
};

export const Consumer = Context.Consumer;

import { createSelector } from 'reselect';
import type { State } from '../interface';
import { getCompanyId } from '@/pages/CompanyReport/redux/selectors';
import { getDefaultSummary } from '../reducers/summary';
export interface SelectorProps {
  companyId?: string;
}

export const getSummaryState = (state, ownProps: SelectorProps) => {
  const companyId = getCompanyId(state, ownProps);
  const company = state.company as State;
  return company.summary[companyId];
};

type SelectorReturnType = State['summary'][''] & {
  overallRating: number;
};

const getDefaultSelectorValue = () => ({
  ...getDefaultSummary(),
  overallRating: 0,
});

function outputSelector(
  companySummary: ReturnType<typeof getSummaryState>
): SelectorReturnType {
  if (!companySummary) return getDefaultSelectorValue();
  let overallRating = 0;
  if (companySummary.snowflakePoints) {
    overallRating = Math.round(
      companySummary.snowflakePoints.reduce((a, b) => a + b, 0) /
        companySummary.snowflakePoints.length
    );
  }
  return {
    ...getDefaultSummary(),
    ...companySummary,
    overallRating,
  };
}

export const getSummary = createSelector([getSummaryState], outputSelector);

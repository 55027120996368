import type IndustryAverageFieldEntity from '@industyAverages/interfaces/entity/IndustryAverageFieldEntity';
import type IndustryAverageConstituentDetailEntity from '@industyAverages/interfaces/entity/IndustryAverageConstituentDetailEntity';
import type IndustryAverageByIndustryCountryEntity from '@industyAverages/interfaces/entity/IndustryAverageByIndustryCountryEntity';

import {
  IndustryAverageFields,
  IndustryAverageFieldConstituents,
  IndustryAverageByCountry,
  IndustryAverageByCompany,
} from './action';

export const FIELDS_LAST_UPDATED = 'FIELDS_LAST_UPDATED';

export interface IndustryAverageStateData<T> {
  readonly keys: readonly string[];
  readonly entities: ReadonlyMap<string, T>;
}

export interface IndustryAverageState {
  readonly fields: IndustryAverageStateData<IndustryAverageFieldEntity>;
  readonly constituents: IndustryAverageStateData<
    readonly IndustryAverageConstituentDetailEntity[]
  >;
  readonly averagesByCountry: IndustryAverageStateData<IndustryAverageByIndustryCountryEntity>;
  readonly lastUpdatedTracker: IndustryAverageStateData<number>;
}

interface IndustryAverageFieldsPayload {
  fieldDetails: Map<string, IndustryAverageFieldEntity>;
}

interface IndustryAverageConstituentDetailsPayload {
  constituentDetails: Map<string, IndustryAverageConstituentDetailEntity[]>;
}

interface IndustryAverageByCountryPayload {
  averagesByCountryDetails: Map<string, IndustryAverageByIndustryCountryEntity>;
}

const getInitialState = (): IndustryAverageState => {
  return {
    fields: {
      keys: [],
      entities: new Map<string, IndustryAverageFieldEntity>([]),
    },
    constituents: {
      keys: [],
      entities: new Map<
        string,
        readonly IndustryAverageConstituentDetailEntity[]
      >([]),
    },
    averagesByCountry: {
      keys: [],
      entities: new Map<string, IndustryAverageByIndustryCountryEntity>([]),
    },
    lastUpdatedTracker: {
      keys: [],
      entities: new Map<string, number>([]),
    },
  };
};

export default (
  state = getInitialState(),
  action: ReducerAction
): IndustryAverageState => {
  switch (action.type) {
    case IndustryAverageFields.SUCCESS: {
      const { fieldDetails } = action.payload as IndustryAverageFieldsPayload;

      return {
        ...state,
        fields: { keys: state.fields.keys, entities: fieldDetails },
        lastUpdatedTracker: {
          keys: state.lastUpdatedTracker.keys,
          entities:
            state.lastUpdatedTracker.entities instanceof Map
              ? new Map<string, number>(state.lastUpdatedTracker.entities).set(
                  FIELDS_LAST_UPDATED,
                  Date.now()
                )
              : new Map<string, number>([]).set(
                  FIELDS_LAST_UPDATED,
                  Date.now()
                ),
        },
      };
    }
    case IndustryAverageFieldConstituents.SUCCESS: {
      const { constituentDetails } =
        action.payload as IndustryAverageConstituentDetailsPayload;

      const { constituents, lastUpdatedTracker } = state;
      const constituentEntities =
        constituents.entities instanceof Map
          ? new Map<string, readonly IndustryAverageConstituentDetailEntity[]>(
              constituents.entities
            )
          : new Map<string, readonly IndustryAverageConstituentDetailEntity[]>(
              []
            );
      const lastUpdatedFromState =
        lastUpdatedTracker.entities instanceof Map
          ? new Map<string, number>(lastUpdatedTracker.entities)
          : new Map<string, number>([]);

      constituentDetails.forEach((value, key) => {
        constituentEntities.set(key, value);
        lastUpdatedFromState.set(key, Date.now());
      });

      return {
        ...state,
        constituents: {
          keys: constituents.keys,
          entities: constituentEntities,
        },
        lastUpdatedTracker: {
          keys: state.lastUpdatedTracker.keys,
          entities: lastUpdatedFromState,
        },
      };
    }
    case IndustryAverageByCountry.SUCCESS:
    case IndustryAverageByCompany.SUCCESS: {
      const { averagesByCountryDetails } =
        action.payload as IndustryAverageByCountryPayload;

      const { averagesByCountry, lastUpdatedTracker } = state;
      const averagesByCountryEntities =
        averagesByCountry.entities instanceof Map
          ? new Map<string, IndustryAverageByIndustryCountryEntity>(
              averagesByCountry.entities
            )
          : new Map<string, IndustryAverageByIndustryCountryEntity>([]);
      const lastUpdatedFromState =
        lastUpdatedTracker.entities instanceof Map
          ? new Map<string, number>(lastUpdatedTracker.entities)
          : new Map<string, number>([]);

      averagesByCountryDetails.forEach((value, key) => {
        averagesByCountryEntities.set(key, value);
        lastUpdatedFromState.set(key, Date.now());
      });
      return {
        ...state,
        averagesByCountry: {
          keys: averagesByCountry.keys,
          entities: averagesByCountryEntities,
        },
        lastUpdatedTracker: {
          keys: state.lastUpdatedTracker.keys,
          entities: lastUpdatedFromState,
        },
      };
    }
    default:
      return state;
  }
};

//Portfolio Actions
export const ACTION_FETCH_PORTFOLIO_LIST = '@PORTFOLIO/FETCH_PORTFOLIO_LIST';
export const ACTION_PORTFOLIO_FETCH = '@PORTFOLIO/PORTFOLIO_FETCH';
export const ACTION_PUBLIC_PORTFOLIO_FETCH =
  '@PORTFOLIO/PUBLIC_PORTFOLIO_FETCH';
export const ACTION_PORTFOLIO_SHARE = '@PORTFOLIO/PORTFOLIO_SHARE';
export const ACTION_DEFAULT_PORTFOLIO_FETCH =
  '@PORTFOLIO/DEFAULT_PORTFOLIO_FETCH';
export const ACTION_PORTFOLIO_ID_UPDATED = '@PORTFOLIO/PORTFOLIO_ID_UPDATED';
export const ACTION_PORTFOLIO_ADD_COMPANY = '@PORTFOLIO/PORTFOLIO_ADD_COMPANY';
export const ACTION_PORTFOLIO_REMOVE_COMPANY =
  '@PORTFOLIO/PORTFOLIO_REMOVE_COMPANY';
export const ACTION_PORTFOLIO_NEWS_FETCH = '@PORTFOLIO/PORTFOLIO_NEWS_FETCH';
export const ACTION_PORTFOLIO_NOT_FOUND = '@PORTFOLIO/PORTFOLIO_NOT_FOUND';
export const ACTION_SAVE_CURRENT_PORTFOLIO_ID =
  '@PORTFOLIO/SAVE_CURRENT_PORTFOLIO_ID';
export const ACTION_PORTFOLIO_FETCH_PRICE_FOR_ITEM =
  '@PORTFOLIO/PORTFOLIO_FETCH_PRICE_FOR_ITEM';
export const ACTION_ADD_TRANSACTION_FOR_PORTFOLIO_ITEM =
  '@PORTFOLIO/ADD_TRANSACTION_FOR_PORTFOLIO_ITEM';
export const ACTION_REMOVE_TRANSACTION_FOR_PORTFOLIO_ITEM =
  '@PORTFOLIO/REMOVE_TRANSACTION_FOR_PORTFOLIO_ITEM';
export const ACTION_TRANSACTIONS_SHOW_MODAL =
  '@PORTFOLIO/TRANSACTIONS_SHOW_MODAL';
export const ACTION_TRANSACTIONS_HIDE_MODAL =
  '@PORTFOLIO/TRANSACTIONS_HIDE_MODAL';
export const ACTION_PORTFOLIO_ITEMS_FETCH = '@PORTFOLIO/PORTFOLIO_ITEMS_FETCH';
export const ACTION_PORTFOLIO_HOLDING_SELECTED_SEARCH_RESULT =
  '@PORTFOLIO/PORTFOLIO_HOLDING_SELECTED_SEARCH_RESULT';
export const ACTION_PORTFOLIO_HOLDING_CLEAR_SEARCH_RESULT =
  '@PORTFOLIO/PORTFOLIO_HOLDING_CLEAR_SEARCH_RESULT';
export const ACTION_PORTFOLIO_DELETE = '@PORTFOLIO/DELETE';
export const ACTION_PORTFOLIO_HOLDING_REINVEST_DIVIDENED =
  '@PORTFOLIO/PORTFOLIO_HOLDING_REINVEST_DIVIDENED';
// Number formats
export const DEFAULT_NUMBER_FORMAT = '0,0.00a';
export const TENURE_FORMAT = '0.0';

//Transaction Types
export const TRANSACTION_TYPE_BUY = 'Buy';
export const TRANSACTION_TYPE_SELL = 'Sell';

export const REGENERATE_SECRET_CODE = '@PORTFOLIO/REGENERATE_SECRET_CODE';

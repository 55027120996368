import { produce } from 'immer';
import type { ManagementState, CompanySuccessPayload } from '../interface';
import type { CompanyResponse } from '@/hooks/useCompany/types';
type CompanyAPIMember = NonNullable<
  NonNullable<
    CompanyResponse['data']['analysis']
  >['data']['extended']['data']['raw_data']['data']['members']['board']
>[string];
export function getDefault(): ManagementState {
  return {
    boardMembers: [],
    managementTeam: [],
    leaderId: '',
    boardAge: 0,
    boardTenure: 0,
    managementAge: 0,
    managementTenure: 0,
  };
}

export function managementReducer(
  state = getDefault(),
  companyData: CompanySuccessPayload
) {
  return produce(state, (draft) => {
    if (!companyData.analysis) {
      return;
    }
    try {
      const { members: membersRawData } =
        companyData.analysis.data.extended.data.raw_data.data;
      const { management: managementAnalysis } =
        companyData.analysis.data.extended.data.analysis;
      const { management, leader, board } = membersRawData;

      const sortMember = (a: CompanyAPIMember, b: CompanyAPIMember): number => {
        const first = a.pro_rank || 0;
        const second = b.pro_rank || 0;
        if (first === 0 && second > 0) return 1; //Return the second element. Keep rank=0 at the end
        if (first > 0 && second === 0) return -1; //Return the first element. Keep rank=0 at the end
        return first - second; //Return the smallest
      };

      if (management) {
        draft.managementTeam = Object.values(management)
          .sort(sortMember)
          .map((management) => management.id.toString());
      }

      if (board) {
        draft.boardMembers = Object.values(board)
          .sort(sortMember)
          .map((board) => board.id.toString());
      }
      if (leader) {
        draft.leaderId = leader.id.toString();
      }
      draft.boardAge = managementAnalysis.board_age;
      draft.boardTenure = managementAnalysis.board_tenure;
      draft.managementAge = managementAnalysis.management_age;
      draft.managementTenure = managementAnalysis.management_tenure;
    } catch (error: any) {
      console.error('Company state: Management reducer failed', {
        company: companyData.id,
        message: error.message,
        stack: error.stack,
      });
    }
  });
}

import { produce } from 'immer';
import type { State, CompanySuccessPayload } from '../interface';

type FormattingState = State['formatting'][''];

function getDefault(): FormattingState {
  return {
    primaryTradingItemCurrencyISO: 'USD',
    primaryTradingItemCurrencySymbol: '$',
    reportingCurrencyISO: 'USD',
    reportingCurrencySymbol: '$',
    reportingUnit: 0,
    reportingUnitAbs: 0,
    reportingUnitText: '',
    reportingUnitTextAbs: '',
    tradingItemCurrencyISO: 'USD',
    tradingItemCurrencySymbol: '$',
    isExchangeRateNeeded: false,
    exchangeRate: 0,
    secondaryListingExchangeRate: 1,
  };
}

export function formattingReducer(
  state = getDefault(),
  companyData: CompanySuccessPayload
) {
  return produce(state, (draft) => {
    if (!companyData.analysis) {
      // bail out
      return;
    }
    try {
      const { currency_info: currencyInfoRawData, prices: pricesRawData } =
        companyData.analysis.data.extended.data.raw_data.data;
      draft.primaryTradingItemCurrencyISO =
        currencyInfoRawData.primary_trading_item_currency_iso;
      draft.primaryTradingItemCurrencySymbol =
        currencyInfoRawData.primary_trading_item_currency_symbol;
      draft.reportingCurrencyISO = currencyInfoRawData.reporting_currency_iso;
      draft.reportingCurrencySymbol =
        currencyInfoRawData.reporting_currency_symbol;
      draft.reportingUnit = currencyInfoRawData.reporting_unit;
      draft.reportingUnitAbs = currencyInfoRawData.reporting_unit_abs;
      draft.reportingUnitText = currencyInfoRawData.reporting_unit_text;
      draft.reportingUnitAbs = currencyInfoRawData.reporting_unit_abs;
      draft.tradingItemCurrencyISO =
        currencyInfoRawData.trading_item_currency_iso;
      draft.tradingItemCurrencySymbol =
        currencyInfoRawData.trading_item_currency_symbol;
      draft.isExchangeRateNeeded =
        pricesRawData.primary_currrency_reported !== pricesRawData.primary;
      draft.exchangeRate =
        pricesRawData.primary_currrency_reported / pricesRawData.primary;
      draft.secondaryListingExchangeRate =
        typeof pricesRawData.primary_currrency_listing === 'number' &&
        pricesRawData.primary_currrency_listing > 0
          ? pricesRawData.primary_currrency_reported /
            pricesRawData.primary_currrency_listing
          : 1;
    } catch (error: any) {
      console.error('Company reducer: Formatting failed', {
        company: companyData.id,
        message: error.message,
        stack: error.stack,
      });
    }
  });
}

import { createSelector } from 'reselect';
import type { State } from '../interface';
import { getCompanyId } from './utils';

export interface CompetitorSelectorProps {
  companyId?: string;
}

const getCompanyCompetitorKeys = (state, props: CompetitorSelectorProps) => {
  const company = state.company as State;
  const companyId = getCompanyId(state, props);
  return company.competitors[companyId];
};

const getSummaryTable = (state) => {
  const company = state.company as State;
  return company.summary;
};

export const getCompetitors = createSelector(
  [getCompanyCompetitorKeys, getSummaryTable],
  (competitorKeys, summary) => {
    if (!competitorKeys) {
      return [];
    }
    return competitorKeys.map<{
      index: number;
      companyId: string;
      companyName: string;
      uniqueSymbol: string;
      canonicalUrl: string;
      points: [number, number, number, number, number];
    }>((key, index) => {
      try {
        const companySummary = summary[key];
        return {
          index,
          companyId: companySummary.companyId,
          companyName: companySummary.shortName,
          uniqueSymbol: companySummary.uniqueSymbol,
          canonicalUrl: companySummary.canonicalUrl,
          points: companySummary.snowflakePoints,
        };
      } catch (error) {
        console.error(error);
        return {
          index,
          companyId: '',
          companyName: '',
          uniqueSymbol: '',
          canonicalUrl: '',
          points: [0, 0, 0, 0, 0],
        };
      }
    });
  }
);

export const getFirst3Competitors = createSelector(
  [getCompetitors],
  (competitor) => competitor.slice(0, 3)
);

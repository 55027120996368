import { updateUser } from '@simplywallst/services';
import { withAuth } from '@utilities/queries';
import { isAxiosError } from 'axios';
import { UserHttpError } from './userHttpErrors';
import { isValidationError } from '@/components/AuthenticationSelect/components/AuthenticationErrorMessages/useAuthErrorMessage';

type Payload = Parameters<typeof updateUser>[0];

export default async function mutationFn(payload: Payload) {
  try {
    const response = await withAuth(updateUser, payload);
    return response.data.data;
  } catch (unknownError) {
    if (isAxiosError(unknownError)) {
      if (unknownError.response?.status === 409) {
        throw new UserHttpError(409, unknownError.response?.data?.title);
      }
      if (isValidationError(unknownError.response?.data)) {
        throw new Error(unknownError.response.data.errors[0].title);
      }
    }
    throw unknownError;
  }
}

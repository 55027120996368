import { keepPreviousData } from '@tanstack/react-query';
import type { QueryKey } from '@tanstack/react-query';

import { DASHBOARD_RECENTLY_VIEWED_QUERY_KEY } from '@/components/Dashboard/components/RecentlyViewed/hooks/useRecentlyViewedCompanies';
import { GET_INDUSTRY_QUERY_KEY } from '@/pages/Market/query/fetchIndustries/constants';

const persistQueries = [
  // Dashboard requests
  'DASHBOARD_FEED',
  'DASHBOARD_INTERNATIONAL_MARKETS',
  'DASHBOARD_UPCOMING_EVENTS',
  'dashboard-industry-performance',
  'dashboard-market-performance',
  GET_INDUSTRY_QUERY_KEY,
  'LATEST_ARTICLES',
  DASHBOARD_RECENTLY_VIEWED_QUERY_KEY,
] as const;

export function shouldDehydrateQuery(queryKey: QueryKey) {
  return persistQueries.some((toMatch) => queryKey.includes(toMatch));
}

export const persistedQueryConfig = {
  staleTime: 1000 * 60 * 60,
  placeholderData: keepPreviousData,
  refetchOnMount: true,
} as const;

import { createRoutine } from 'redux-saga-routines';
import type { SearchResults } from '@/hooks/useSearch';

export const cacheResults = createRoutine<
  SimplePayloadCreator<SearchResults>,
  SimplePayloadCreator,
  SimplePayloadCreator,
  SimplePayloadCreator,
  SimplePayloadCreator
>('@SEARCH/CACHE', {});

import { flow, get } from 'lodash';
import {
  capPastEPS,
  flatten,
  merge,
  pickProps,
  reduceTimeSeries,
  removeFirstIfZero,
  seriesArraySort,
} from './utils';
import type { CompanyResponseExtended } from '@/hooks/useCompany/types';
type CompanyAPIRaw = CompanyResponseExtended['raw_data'];
import type { AnalysisState } from '@/pages/CompanyReport/redux/interface';
type PastRawData = CompanyAPIRaw['data']['past'];
type InsiderTradingRawData = CompanyAPIRaw['data']['insider_trading'];
type InsiderTradingTransaction = InsiderTradingRawData['']['buy'];
type OwnershipRawData = CompanyAPIRaw['data']['ownership'];
type TradeType = AnalysisState['legacyChartData']['insiderTrading']['buy'][0];

export const reducePastEPS = (
  past?: PastRawData,
  lastEarningsUpdateTimeStamp?: number | null
) => {
  if (past && lastEarningsUpdateTimeStamp) {
    const getResult = flow(
      reduceTimeSeries<PastRawData>(['basic_eps']),
      seriesArraySort('ASC'),
      capPastEPS(lastEarningsUpdateTimeStamp)
    );
    return getResult(past);
  }
  return [];
};

export const reduceOwnershipBreakdown = (ownershipRawData: OwnershipRawData) =>
  Object.entries(ownershipRawData).reduce((a, b) => {
    a[b[1].owner_type_name] = b[1].shares_held;
    return a;
  }, {});

const createTradeObject = (trade: InsiderTradingTransaction): TradeType => {
  return {
    avgPrice: trade.avg_price,
    totalShares: trade.total_shares,
    companyNames: trade.company_names[0],
    individualNames: trade.individual_names[0],
    companyCount: trade.company_names.length,
    uniqueOwners: trade.unique_owners,
    individualCount: trade.individual_names.length,
    totalCompanyShares: trade.total_company_shares,
    totalIndividualShares: trade.total_individual_shares,
  };
};

export const reduceInsiderTrading = (rawData: InsiderTradingRawData) => {
  const { total_buy, total_sell, total_volume, ...rest } = rawData;
  const reduced = Object.entries(rest).reduce<{
    buy: TradeType[];
    sell: TradeType[];
  }>(
    (acc, trades) => {
      const [_key, trade] = trades;
      const { buy, sell } = trade;
      acc.buy.push(createTradeObject(buy));
      acc.sell.push(createTradeObject(sell));
      return acc;
    },
    {
      buy: [],
      sell: [],
    }
  );
  return {
    totalSell: total_sell,
    totalBuy: total_buy,
    totalVolume: total_volume,
    buy: reduced.buy,
    sell: reduced.sell,
  };
};

type SortingDirection = 'ASC' | 'DESC';

export const mapTimeSeries = <T extends Record<string, unknown>>(
  keys: (keyof T)[],
  sortingDirection: SortingDirection = 'ASC'
) =>
  flow(
    reduceTimeSeries<T>(keys),
    seriesArraySort(sortingDirection),
    removeFirstIfZero
  );

export const mapTimeSeriesArray = <T extends Record<string, any>>(
  keys: (keyof T)[],
  sortingDirection: SortingDirection = 'ASC'
) =>
  flow(
    pickProps<T>(keys),
    merge,
    flatten,
    seriesArraySort(sortingDirection),
    removeFirstIfZero
  );

type AnalystsMap = {
  [date: number]: [number, number];
};

export const reduceAnalystsTimeSeriesToMap =
  (keys: string[]) =>
  (analysis): AnalystsMap =>
    Object.entries<[string | number]>(analysis)
      .filter(([_date, entity]) => entity[keys[0]] !== undefined)
      .reduce((a, b) => {
        const effectiveDate = get(b[1], [keys[0], 'effective_date'], undefined);
        const value = Number(get(b[1], [keys[0], 'value']));
        if (value === undefined || effectiveDate === undefined) return a;
        a[b[0]] = [value, effectiveDate];
        return a;
      }, {});

import { get } from "../methods";
function getCompanyTopShareholders_default({ lookupVal, payload }, headers = {}, axiosConfig = {}) {
  return get({
    url: `api/company/ownership/shareholders/${decodeURIComponent(lookupVal)}`,
    payload,
    headers,
    ...axiosConfig
  });
}
export {
  getCompanyTopShareholders_default as default
};

import { connect } from 'react-redux';
import type { Props } from '../components/SimplyWallSt';
import SimplyWallSt from '../components/SimplyWallSt';

const mapStateToProps = (state): Props => {
  try {
    const {
      location: { routesMap, type },
    } = state;
    return {
      pageName: routesMap[type].pageName,
      routeName: type,
    };
  } catch (err) {
    return {
      pageName: '',
      routeName: state.location?.type ?? null,
    };
  }
};

export default connect(mapStateToProps)(SimplyWallSt);

import { useLocation } from '@/hooks/useLocation';
import { NOT_FOUND, pathToAction } from 'redux-first-router';
import routesGenerator from '@/router/routesGenerator';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { isLocaleRouteType, isSupportedLocale } from '@/utils/locale';

/**
 * Sets the current locale language, using a locale-enabled route to determine the value.
 *
 * Intended to be a singleton set at app level.
 */
export const LocaleUrlListener = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const localeParam: string | undefined = location.payload?.locale;
  const parsedLocale = localeParam?.substring(0, 2)?.toLowerCase();

  useEffect(() => {
    if (!isLocaleRouteType(location.type)) {
      return;
    }

    // Unsupported locale value, redirect to not found.
    if (parsedLocale !== undefined && !isSupportedLocale(parsedLocale)) {
      dispatch({
        type: NOT_FOUND,
        payload: {},
      });
      return;
    }

    // Locale is likely 'full format' of <lang>-<locale>, redirect to just show the language.
    if (localeParam?.length !== parsedLocale?.length) {
      const action = pathToAction(
        location.pathname,
        routesGenerator(),
        queryString
      );
      action.payload.locale = parsedLocale;
      dispatch(action);
      return;
    }
  }, [
    dispatch,
    localeParam?.length,
    location.pathname,
    location.payload.locale,
    location.type,
    parsedLocale,
  ]);

  return null;
};

import { createContext, useContext } from 'react';
import type { ReactNode } from 'react';

interface Props {
  cookies?: {
    plansVariant?: string;
  };
  children: ReactNode;
}

export const Context = createContext<Props['cookies']>(undefined);

/**
 *
 * Context provider to allow passing cookies regardless
 * whether the app is running on the server or browser
 *
 */
export const Provider = ({ cookies, children }: Props) => {
  return <Context.Provider value={cookies}>{children}</Context.Provider>;
};

export const Consumer = Context.Consumer;

export const useCookieContext = () => {
  const context = useContext(Context);
  if (typeof context === 'undefined') {
    throw new Error(
      'useCookieContext: Attempting to access undefined context, possibly outside of `CookieProvider`'
    );
  }
  return context;
};

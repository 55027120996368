function getServices() {
  return {
    SWS_API_SERVICE: "https://simplywall.st/",
    SWS_BILLING_SERVICE: "https://billing.simplywall.st/",
    SWS_STATEMENTS_API_SERVICE: "https://simplywall.st/backend/statements",
    SWS_STREAM_API_SERVICE: "https://stream.simplywall.st/",
    SWS_WARREN_SERVICE: "https://swr.simplywall.st/",
    SWS_CLOUDFLARE_WORKER: "https://workers.simplywall.st/",
    SWS_GQL_SERVICE: "https://simplywall.st/graphql",
    SWS_PRO_API_GQL_SERVICE: "https://simplywall.st/backend/pro-api/graphql",
    SWS_MONO_SERVICE: "https://simplywall.st/",
    SWS_IMAGE_SERVICE: "https://images.simplywall.st/"
  };
}
export {
  getServices
};

import { createSelector } from 'reselect';
import { flow, get, isNil } from 'lodash';
import {
  toPercentage,
  toCurrencyTruncated,
  toCurrency,
  Scale,
  toSignificant,
} from '@/utilities/formatting';
import {
  getEntities,
  getHoldings,
  getTransactions,
} from '@/components/DeprecatedPortfolios/redux/selectors';
import type {
  ComputedPortfolio,
  ComputedPortfolioHolding,
  PortfolioState,
} from '@/components/DeprecatedPortfolio/redux/interface';
import { getUi } from '@ducks/ui/selectors';
import { ROUTE_DEPRECATED_PUBLIC_PORTFOLIO } from '@/constants/routes';
import type { State as CompanyState } from '@/pages/CompanyReport/redux/interface';

export const getPortfolio = (state) => {
  const portfolio = getPortfolioState(state);
  const portfolios = getEntities(state);
  const { currentId } = portfolio;
  if (!currentId || !portfolios) return null;
  return portfolios[currentId.toString()];
};

export const getCurrentPortfolioId = (state) => {
  const portfolio = getPortfolioState(state);
  return portfolio.currentId;
};

export const getCurrentPortfolioName = (id: string) => (state) => {
  const portfolioEntities = getEntities(state);
  const matchedPortfolio = portfolioEntities[id];
  if (!matchedPortfolio) return '';
  return matchedPortfolio.name;
};

export const getPortfolioSecretCode = (id: string) => (state) => {
  const portfolioEntities = getEntities(state);
  const matchedPortfolio = portfolioEntities[id];
  if (!matchedPortfolio) return null;
  return matchedPortfolio.secretCode;
};

export const getPortfolioList = (state) => {
  const portfolioEntities = getEntities(state);
  return portfolioEntities;
};

const getPortfolioState = (state) => {
  const { portfolio }: { portfolio: PortfolioState } = state;
  return portfolio;
};

const getPortfolioAnalysis = (state) => {
  const portfolio = getPortfolioState(state);
  const portfolioEntities = getEntities(state);
  const { currentId } = portfolio;
  if (!currentId) return null;
  const matchedPortfolio = portfolioEntities[currentId.toString()];
  if (!matchedPortfolio || !matchedPortfolio.analysis) return null;
  return matchedPortfolio.analysis;
};

const getPortfolioHoldings = (state) => {
  const portfolio = getPortfolioState(state);
  const portfolioEntities = getEntities(state);
  const { currentId } = portfolio;
  if (!currentId) return null;
  const matchedPortfolio = portfolioEntities[currentId.toString()];
  if (!matchedPortfolio) return null;
  const { holdingsMap = [] } = matchedPortfolio;
  return holdingsMap;
};

const getItemPriceMap = (state) => {
  const portfolio = getPortfolioState(state);
  return portfolio.itemPriceMap;
};

const getItemPriceMapForPortfolioHolding = (state, itemId: string) => {
  const portfolio = getPortfolioState(state);
  return portfolio.itemPriceMap[itemId];
};

const getIsUpdatingItemPrice = (state) => {
  const portfolio = getPortfolioState(state);
  return portfolio.isUpdatingItemPrice;
};

const getIsAddingTransaction = (
  state,
  portfolioId: string,
  companyId: string
) => {
  const holdingsMap = getHoldings(state);
  const matchedHolding = holdingsMap[companyId];
  if (!matchedHolding) return false;
  return matchedHolding.isAddingTransaction || false;
};

const getHolding = (state, itemId: string) => {
  const holdings = getHoldings(state);
  const matchedHolding = holdings[itemId];
  if (!matchedHolding) return null;
  return matchedHolding;
};

const getIsUpdatingTransactions = (state) => {
  const portfolio = getPortfolioState(state);
  return get(portfolio, 'isUpdatingTransactions', false);
};

export const getDoesPortfolioNeedsRefresh = (state) => {
  const portfolio = getPortfolioState(state);
  return get(portfolio, 'doesPortfolioNeedRefresh', false);
};

const getDefaultAnalysis = () => ({
  sharePrice: 0,
  annualDividendReturn: 0,
  beta: 0,
  currentValue: 0,
  snowflakeScoreAxes: [0, 0, 0, 0, 0],
  snowflakeScoreDividend: 0,
  snowflakeScoreFuture: 0,
  snowflakeScoreHealth: 0,
  snowflakeScorePast: 0,
  snowflakeScoreTotal: 0,
  snowflakeScoreValue: 0,
  totalGain: 0,
  capitalGain: 0,
  capitalGainWeek: 0,
});
const formatCurrency = (amount = 0, currencyISO?: string) => {
  if (amount > 99999) {
    return toCurrencyTruncated(amount, 1, currencyISO);
  }
  return toCurrency(amount, 2, currencyISO);
};

function getCompanySummary(state) {
  const company = state.company as CompanyState;
  return company.summary;
}
// TODO: move getPortfolioNews to @ducks
export const portfolioSelector = createSelector(
  [
    getPortfolio,
    getPortfolioAnalysis,
    getPortfolioHoldings,
    getHoldings,
    getTransactions,
    getCompanySummary,
  ],
  (
    portfolio,
    portfolioAnalysis,
    portfolioHoldings,
    holdingsTable,
    transactionsTable,
    companies
  ): ComputedPortfolio | null => {
    if (!portfolio || !portfolioHoldings) return null;
    const {
      currencyISO: portfolioCurrencyISO,
      currencySymbol,
      marketISO2,
      excludedCompanyCount,
      id,
      isWatchlist,
      portfolioWithNoTransactions,
      lastUpdated,
      name,
      provider,
      secretCode,
      currentHoldingCount,
    } = portfolio;
    let mappedHoldings: any = [];
    try {
      mappedHoldings = portfolioHoldings.map((holdingId) => {
        const matchedHolding = holdingsTable[holdingId] || {};
        const {
          analysis,
          exclude,
          inception,
          isUpdating,
          transactionsMap = [],
          itemId,
          companyId,
          reinvestDividends,
          isUpdatingReinvest,
        } = matchedHolding;

        if (
          typeof companyId === 'undefined' ||
          companies[companyId.toLowerCase()] === undefined
        ) {
          return null;
        }

        const {
          canonicalUrl,
          currencyISO,
          isFund,
          shortName,
          industryData: { primaryId, secondaryId, tertiaryId },
          tickerSymbol,
          uniqueSymbol,
        } = companies[companyId.toLowerCase()];

        const {
          annualDividendReturn,
          beta,
          currentValue,
          snowflakeScoreAxes = [0, 0, 0, 0, 0],
          snowflakeScoreDividend,
          snowflakeScoreFuture,
          snowflakeScoreHealth,
          snowflakeScorePast,
          snowflakeScoreTotal,
          snowflakeScoreValue,
          totalGain,
          capitalGainWeek,
          sharePrice,
        } = analysis || getDefaultAnalysis();

        const dividendReturn =
          analysis && analysis.dividendReturn
            ? analysis.dividendReturn / 100
            : 0;
        const currentDividend = get(analysis, 'currentDividend', 0);

        const capitalGain =
          analysis && analysis.capitalGain ? analysis.capitalGain / 100 : 0;
        const mappedTransaction = transactionsMap.map(
          (transactionId) => transactionsTable[transactionId]
        );
        const isLinkedPortfolio = isFalseyProvider(portfolio.provider);
        const hasNullInception = !isLinkedPortfolio && isNil(inception);
        const isLiquidated = currentValue === 0 && !isNil(inception);

        const resolveLiquidatedValue =
          (isLiquidated: boolean) => (currentValue: string | number) =>
            isLiquidated ? 'Liquidated' : currentValue;

        const resolveTransactions =
          (hasNullInception: boolean) => (currentValue: string | number) =>
            hasNullInception || currentValue === 0
              ? 'No transactions'
              : currentValue;

        const formatToCurrency =
          (portfolioCurrencyISO?: string) =>
          (currentValue: string | number) => {
            if (typeof currentValue === 'number') {
              return currentValue >= Scale.Millions
                ? toCurrencyTruncated(
                    currentValue,
                    1,
                    /*
                     * 👻 BEWARE 👻
                     *
                     * The current value of a holding is based on the portfolio's current currency ISO.
                     *
                     * Therefore, although an individual holding's analysis (from the portfolio API) supplies a currency ISO
                     * that matches up with values like share price, it doesn't match up with the current value of the holding
                     */
                    portfolioCurrencyISO
                  )
                : toCurrency(currentValue, 0, portfolioCurrencyISO);
            } else {
              return currentValue;
            }
          };
        const currentValueFormatter = flow(
          resolveLiquidatedValue(isLiquidated),
          resolveTransactions(hasNullInception),
          formatToCurrency(portfolioCurrencyISO)
        );
        // Do not run formatter when !exclude
        const resolvedCurrentValue = !exclude
          ? currentValueFormatter(currentValue || 0)
          : 0;
        return {
          sharePrice: sharePrice || 0,
          sharePriceFormatted: formatCurrency(sharePrice, currencyISO),
          capitalGain,
          capitalGainWeek,
          capitalGainFormatted: toPercentage(capitalGain, 1),
          capitalGainWeekFormatted: toSignificant(capitalGainWeek || 0, 2),
          return: get(analysis, 'annualReturn', 0),
          itemId,
          name: shortName,
          isFund,
          exclude: exclude || false,
          companyId: companyId || '',
          canonicalUrl,
          inception,
          isUpdating: isUpdating || false,
          uniqueSymbol,
          tickerSymbol: tickerSymbol ? tickerSymbol.toString() : '',
          primaryIndustryId: primaryId,
          secondaryIndustryId: secondaryId,
          tertiaryIndustryId: tertiaryId,
          beta,
          totalGain,
          annualDividendReturn,
          snowflakeScoreAxes,
          snowflakeScorePast,
          snowflakeScoreTotal,
          snowflakeScoreValue,
          snowflakeScoreFuture,
          snowflakeScoreHealth,
          snowflakeScoreDividend,
          transactions: mappedTransaction,
          reinvestDividends,
          isUpdatingReinvest,
          currentDividend,
          currentDividendFormatted: toPercentage(currentDividend, 1),
          /** optional properties when company is excluded */
          ...(!exclude
            ? {
                currentValue,
                currentValueFormatted: resolvedCurrentValue,
                dividendReturn,
                dividendReturnFormatted: toPercentage(dividendReturn, 1),
              }
            : {}),
        };
      });
    } catch (e) {
      console.error(e);
    }

    const intrinsicDiscount =
      portfolioAnalysis?.totalFreeCashFlowValue === null ||
      typeof portfolioAnalysis?.totalFreeCashFlowValue === 'undefined' ||
      portfolioAnalysis?.totalFreeCashFlowDiscount === null ||
      typeof portfolioAnalysis?.totalFreeCashFlowDiscount === 'undefined'
        ? NaN
        : 1 -
          portfolioAnalysis?.totalFreeCashFlowValue /
            portfolioAnalysis?.totalFreeCashFlowDiscount;

    const intrinsicDiscountFormatted = toPercentage(
      Math.abs(intrinsicDiscount),
      1
    );

    const totalFreeCashFlowValueFormatted = toCurrency(
      portfolioAnalysis?.totalFreeCashFlowValue === null ||
        typeof portfolioAnalysis?.totalFreeCashFlowValue === 'undefined'
        ? NaN
        : portfolioAnalysis?.totalFreeCashFlowValue,
      2,
      portfolioCurrencyISO
    );
    const totalFreeCashFlowDiscountFormatted = toCurrency(
      portfolioAnalysis?.totalFreeCashFlowDiscount === null ||
        typeof portfolioAnalysis?.totalFreeCashFlowDiscount === 'undefined'
        ? NaN
        : portfolioAnalysis?.totalFreeCashFlowDiscount,
      2,
      portfolioCurrencyISO
    );

    const nonNullHoldings = mappedHoldings.filter(
      (holding) => holding !== null
    );

    return {
      id,
      name,
      currentHoldingCount,
      provider: provider || '',
      secretCode,
      excludedCompanyCount,
      portfolioWithNoTransactions,
      isWatchlist,
      currencySymbol,
      currencyISO: portfolioCurrencyISO,
      marketISO2,

      beta: portfolioAnalysis?.beta,
      PE: portfolioAnalysis?.PE || null,
      PB: portfolioAnalysis?.PB,
      PEG: portfolioAnalysis?.PEG,
      ROA: portfolioAnalysis?.ROA,
      ROE: portfolioAnalysis?.ROE,
      ROCE: portfolioAnalysis?.ROCE,
      fundValue: portfolioAnalysis?.fundValue,
      totalValue: portfolioAnalysis?.totalValue || null,
      return1yrTotalReturn: portfolioAnalysis?.return1yrTotalReturn || null,
      debtEquity: portfolioAnalysis?.debtEquity,
      snowflakeScorePast: portfolioAnalysis?.snowflakeScorePast,
      totalFreeCashFlowValue: portfolioAnalysis?.totalFreeCashFlowValue,
      totalFreeCashFlowDiscount: portfolioAnalysis?.totalFreeCashFlowDiscount,
      currentDividend: portfolioAnalysis?.currentDividend,
      netIncomeGrowthAnnual: portfolioAnalysis?.netIncomeGrowthAnnual,
      revenueGrowthAnnual: portfolioAnalysis?.revenueGrowthAnnual,
      netIncomeGrowth5y: portfolioAnalysis?.netIncomeGrowth5y,
      revenueGrowth5y: portfolioAnalysis?.revenueGrowth5y,
      payoutRatio: portfolioAnalysis?.payoutRatio,
      payoutRatio3y: portfolioAnalysis?.payoutRatio3y,
      chartData: portfolioAnalysis?.chartData || null,
      // up to here
      totalFreeCashFlowValueFormatted,
      totalFreeCashFlowDiscountFormatted,
      intrinsicDiscount,
      intrinsicDiscountFormatted,
      lastUpdatedmilliseconds: lastUpdated,
      holdings: nonNullHoldings as ComputedPortfolioHolding[],
    };
  }
);

const createToCurrency =
  (fractionDigits: number, currencyISO?: string) => (value: number) =>
    toCurrencyTruncated(value, fractionDigits, currencyISO);

export const portfolioSummarySelector = createSelector(
  [getPortfolio, getPortfolioAnalysis],
  (portfolio, portfolioAnalysis) => {
    if (!portfolio || !portfolioAnalysis) return null;

    const { isWatchlist, currencyISO, provider } = portfolio;
    const toCurrency = createToCurrency(2, currencyISO);
    const totalValue = isWatchlist ? null : portfolioAnalysis.totalValue;
    const totalGain = isWatchlist ? null : portfolioAnalysis.totalGain;
    const capitalGain = isWatchlist ? null : portfolioAnalysis.capitalGain;
    const dividendGain = isWatchlist ? null : portfolioAnalysis.dividendGain;
    const annualReturn = isWatchlist ? null : portfolioAnalysis.annualReturn;
    const currencyGain = isWatchlist ? null : portfolioAnalysis.currencyGain;
    const {
      totalReturn = 0,
      return1yrTotalReturn,
      currentDividend = 0,
      totalDividendReturn = 0,
      dividendReturn = 0,
      snowflakeScoreAxes = [0, 0, 0, 0, 0],
      currencyReturn = 0,
      snowflakeDescription,
      years,
    } = portfolioAnalysis;

    const formattedSnowflakeScoreAxes = snowflakeScoreAxes.map((score) =>
      Number(toSignificant(score, 2))
    );
    return {
      isWatchlist,
      currencyISO,
      provider,
      snowflakeDescription,
      snowflakeScoreAxes: formattedSnowflakeScoreAxes,
      totalValue,
      totalGain,
      return1yrTotalReturn,
      capitalGain,
      dividendGain,
      annualReturn,
      totalReturn,
      currentDividend,
      totalDividendReturn,
      dividendReturn,
      return1yrTotalReturnFormatted: toPercentage(return1yrTotalReturn || 0, 2),
      totalValueFormatted: (totalValue && toCurrency(totalValue)) || '0.00',
      totalGainFormatted: (totalGain && toCurrency(totalGain)) || '0.00',
      capitalGainFormatted: toCurrency(capitalGain || 0) || '0.00',
      // Need to sort out type conversions. It's a bit of a mess. This is in part due to conditions in the components being lazy (aka {dividendGain && <i>Bleh</i>} vs {dividendGain > 0 && <i>Bleh</i>})
      dividendGainFormatted: toCurrency(dividendGain || 0) || '0.00',
      annualReturnFormatted:
        isNil(annualReturn) || annualReturn === 0
          ? 'n/a'
          : toPercentage(annualReturn / 100, 2),
      currencyReturn,
      currencyGainFormatted: toCurrency(currencyGain || 0) || '0.00',
      totalReturnFormatted: toPercentage(totalReturn / 100, 2),
      currentDividendFormatted: toPercentage(currentDividend / 100, 2),
      totalDividendReturnFormatted: toPercentage(
        (totalDividendReturn || 0) / 100,
        2
      ),
      currencyReturnFormatted: toPercentage(currencyReturn || 0 / 100, 2),
      dividendReturnFormatted: toPercentage(dividendReturn / 100, 2),
      currencyGain,
      portfolioYearAge: years,
    };
  }
);

export const portfolioIdSelector = createSelector(
  [getPortfolio],
  (portfolio) => portfolio && portfolio.id
);

export const hasHoldingsInDifferentCurrencies = createSelector(
  [portfolioSummarySelector],
  (portfolioSummary): boolean =>
    portfolioSummary !== null &&
    portfolioSummary.currencyReturn !== null &&
    portfolioSummary.currencyReturn !== 0
);

export const currentPortfolioIdSelector = createSelector(
  [getCurrentPortfolioId],
  (currentPortfolioId) => currentPortfolioId
);

export const makeGetModifyPortfolioSelector = () => {
  return createSelector([getPortfolio, getUi], (portfolio, ui) => {
    if (!portfolio) return null;
    const { currencySymbol, currencyISO, name, id } = portfolio;
    const { loading, message } = ui;
    return {
      currencySymbol,
      currencyISO,
      portfolioName: name,
      portfolioId: id,
      isLoading: loading,
      error: message,
    };
  });
};

export const makeGetProvider = () => {
  return createSelector(
    [getPortfolio],
    (portfolio) => portfolio && portfolio.provider
  );
};

export const makeGetItemPriceForPortfolioHolding = () => {
  return createSelector([getItemPriceMapForPortfolioHolding], (prices) => {
    return prices;
  });
};
export const makeGetItemPrice = () => {
  return createSelector([getItemPriceMap], (itemPriceMap) => {
    return Object.entries(itemPriceMap).map(([key, priceMap]) => {
      const priceEntries = Object.entries(priceMap).map(([key, price]) => {
        return {
          date: Number(key),
          price,
        };
      });
      return {
        [key]: priceEntries,
      };
    });
  });
};

export const makeIsUpdatingItemPrice = () => {
  return createSelector([getIsUpdatingItemPrice], (isUpdatingItemPrice) => {
    return isUpdatingItemPrice;
  });
};

export const makeIsAddingTransaction = () => {
  return createSelector([getIsAddingTransaction], (isAddingTransaction) => {
    return isAddingTransaction;
  });
};

export const makeIsUpdatingTransaction = () => {
  return createSelector(
    [getIsUpdatingTransactions],
    (isUpdatingTransactions) => {
      return isUpdatingTransactions;
    }
  );
};

export const makeDoesPortfolioNeedsRefresh = () => {
  return createSelector(
    [getDoesPortfolioNeedsRefresh],
    (doesPortfolioNeedsRefresh) => {
      return doesPortfolioNeedsRefresh;
    }
  );
};

export const makeGetHolding = () => {
  return createSelector([getHolding], (holding) => {
    return holding;
  });
};

const isFalsey = (value: string | null) => isNil(value) || value === '';

/** TODO: production build was getting a weird circular dependency error */
const getLocation = (state) => state.location;

export const getPortfolioMutableState = createSelector(
  [getPortfolio, getPortfolioList, getLocation],
  (portfolio, userPortfolios, location) => {
    let isEditable = false;
    if (!portfolio) return isEditable;
    if (location.type === ROUTE_DEPRECATED_PUBLIC_PORTFOLIO) {
      isEditable = false;
    } else if (portfolio) {
      isEditable =
        userPortfolios.hasOwnProperty(portfolio.id.toString()) &&
        isFalsey(portfolio.provider);
    }
    return isEditable;
  }
);

const isFalseyProvider = (provider: null | string) =>
  !isNil(provider) && provider !== '';

export const getIsPortfolioLinked = createSelector(
  [getPortfolio],
  (portfolio) => (portfolio ? isFalseyProvider(portfolio.provider) : false)
);

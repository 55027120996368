import type { Routine } from 'redux-saga-routines';

export type Payload<T, K extends keyof T> = T[K] extends (
  ...args: any[]
) => infer R
  ? R
  : any;

export type RequestPayload<T extends Routine> = Payload<T, 'request'>;
export type TriggerPayload<T extends Routine> = Payload<T, 'trigger'>;
export type SuccessPayload<T extends Routine> = Payload<T, 'success'>;
export type FailurePayload<T extends Routine> = Payload<T, 'failure'>;
export type FulfillPayload<T extends Routine> = Payload<T, 'fulfill'>;

export interface ErrorObject {
  message: string;
  stack: string;
}

export const reducerErrorLogger =
  (reducer: string) => (routine: string, errorObject: unknown) => {
    if (errorObject instanceof Error) {
      console.error(`${reducer}: ${routine}`, {
        message: errorObject.message,
        stack: errorObject.stack,
      });
    }
  };

import { formatDistanceToNow } from 'date-fns';
import { uniq } from 'lodash';
import { normalise } from '@/state/utils/reducer';
import type { getPortfolios } from '@simplywallst/services';
import type {
  PortfolioHolding,
  PortfolioHoldingTransactions,
  PortfolioItemState,
  State,
} from './interface';
import { getDefaultPortfolioAnalysisState } from './analysisReducer';
import { holdingNormaliser } from './holdingReducer';
import type { CurrencyISO } from '@/constants/currencies';
type PublicPortfolioResponse = Awaited<
  ReturnType<typeof getPortfolios>
>['data']['data'][number];
type HoldingResponse = NonNullable<
  PublicPortfolioResponse['items']
>['data'][number];
/**
 * Reducer for portfolio list endpoint
 * @param state
 * @param payload
 */
function listReducer(state: State, payload?: PublicPortfolioResponse[]) {
  const {
    entities: currentEntities,
    holdingsTable: currentHoldings,
    holdingsItemIdToCompanyIdMap: currentHoldingsIdMap,
    transactionsTable: currentTransactionsTable,
  } = state;

  let holdingsTable: Record<string, PortfolioHolding> = currentHoldings;
  const holdingsIdMap: Record<string, string[]> = currentHoldingsIdMap;
  let transactionsTable: Record<string, PortfolioHoldingTransactions> =
    currentTransactionsTable;
  const { keys, entities } = normalise<
    PortfolioItemState,
    PublicPortfolioResponse
  >(payload)('id', (current: PublicPortfolioResponse): PortfolioItemState => {
    let holdingKeys: string[] = [];
    const holdingCompanyId: string[] = [];
    const holdingCompanyIdItemIdMap: Record<string, string> = {};
    let trueHoldingsCount = 0;
    const { items } = current;
    if (items) {
      trueHoldingsCount = items.data.length;
      const filtered = items.data.filter((item) => !!item.company);
      const { keys, entities } = normalise<PortfolioHolding, HoldingResponse>(
        filtered
      )(
        'id',
        holdingNormaliser(currentHoldings, (entities, longId, shortId) => {
          // side effects callback
          // gets called when iterating through each holding
          holdingCompanyId.push(longId);
          holdingCompanyIdItemIdMap[longId] = shortId;
          const matchedIdMap = holdingsIdMap[longId] || [];
          holdingsIdMap[longId] = uniq(matchedIdMap.concat(shortId));
          transactionsTable = {
            ...transactionsTable,
            ...entities,
          };
        })
      );
      holdingKeys = keys;
      holdingsTable = { ...holdingsTable, ...entities };
    }

    const matchedPortfolio = currentEntities[current.id.toString()];
    const analysis =
      matchedPortfolio === undefined
        ? getDefaultPortfolioAnalysisState()
        : matchedPortfolio.analysis;

    return {
      ...matchedPortfolio,
      analysis,
      id: current.id.toString(),
      lastUpdated: Date.now() - 86400000,
      name: current.name.toString(),
      provider: current.provider || '',
      dateGenerated:
        typeof current.date_generated === 'number' ? current.date_generated : 0,
      formattedDateGenerated:
        typeof current.date_generated === 'number'
          ? formatDistanceToNow(current.date_generated)
          : '',
      currencyISO: current.currency_iso as CurrencyISO,
      secretCode: current.secret_code || '',
      holdingsMap: holdingKeys,
      holdingsLongIdMap: holdingCompanyId,
      holdingCompanyIdItemIdMap,
      holdingsCount: trueHoldingsCount,
    };
  });
  return {
    keys,
    entities,
    holdingsTable,
    holdingsIdMap,
    transactionsTable,
  };
}

export default listReducer;

import {
  all,
  takeLatest,
  put,
  select,
  call,
  takeEvery,
  getContext,
} from 'redux-saga/effects';
import {
  fetchCompany,
  fetchCompanies,
  fetchCompanyAnalysts,
  fetchCompetitors,
  fetchCompanyTopShareholders,
  preFetchCompany,
} from '@/pages/CompanyReport/redux/routines';
import {
  getCompany as getCompanyService,
  getAnalystCoverage as getAnalystCoverageService,
  getCompetitors as getCompetitorsService,
  getCompanyTopShareholders,
} from '@simplywallst/services';
import type { CompanyResponse } from '@/hooks/useCompany/types';
import { ahoy } from '@ducks/saga';
import { get } from 'lodash';
import { fetchIndustryAverage } from '@ducks/averages/sagas';
import {
  getSummaryState,
  getIdFromUrl,
  getIdFromSymbol,
} from '@/pages/CompanyReport/redux/selectors';
import { getPath } from '@/router/utils';
import { ROUTE_COMPANY } from '@/constants/routes';
import type { Locale } from '@/hooks/useLocale';
import {
  getQueryKey,
  queryFn,
  STALE_TIME,
  RETRY,
} from '@/hooks/useCompany/useCompany';
import type { QueryClient } from '@tanstack/react-query';

function* getCompanyAnalysts(
  action: ReturnType<(typeof fetchCompanyAnalysts)['success']>
) {
  try {
    const companyId = action.payload.companyId.toLowerCase();
    if (!companyId) throw new Error('getCompanyAnalysts requires companyId');
    const caller = ahoy();
    const analysts = yield caller(getAnalystCoverageService, {
      lookupVal: companyId,
      payload: {
        version: '2.0',
      },
    });
    if (analysts.data.length > 0) {
      const brokers = analysts.data[0].brokers;
      yield put(
        fetchCompanyAnalysts.success({
          brokers: brokers.data,
          companyId: companyId,
        })
      );
    }
  } catch (error) {
    console.log(error);
    yield put(fetchCompanyAnalysts.failure());
  } finally {
    yield put(fetchCompanyAnalysts.fulfill());
  }
}

function* fetchCompanyWorker(
  action: ReturnType<(typeof fetchCompany)['trigger']>
) {
  const { symbol, payload } = action.payload;
  const companyId = action?.payload?.companyId?.toLowerCase();
  let pathname: string | undefined = undefined;
  if (payload) {
    pathname = getPath({
      type: ROUTE_COMPANY,
      payload: {
        isoCode2: payload.isoCode2,
        industry: payload.industry,
        exchange_ticker: payload.exchange_ticker,
        companyname: payload.companyname,
      },
    });
  }
  //
  try {
    if (!pathname && !symbol && !companyId) {
      throw new Error('No valid company lookup');
    }

    let selectedCompanyId = '';

    if (!companyId && pathname) {
      // attempt to fetch companyId from state;
      selectedCompanyId = yield select(getIdFromUrl);
    }

    if (!companyId && symbol) {
      selectedCompanyId = yield select(getIdFromSymbol, { symbol });
    }

    if (yield isCompanyAvailable(selectedCompanyId)) {
      console.log(
        '🎓 Reusing state in store. No need to refetch ',
        selectedCompanyId
      );
      return;
    }

    const selectedLookup = companyId || pathname || symbol || ''; // The '' will never happen as we null check for all three above.

    let responseCompanyId = '';

    try {
      yield put(fetchCompany.request({ companyId: selectedLookup }));
      responseCompanyId = yield call(
        companyCallPut,
        selectedLookup,
        payload?.locale || 'en'
      );
      // Convert companyId to lowercase
      responseCompanyId = responseCompanyId.toLowerCase();
    } catch (e) {
      throw e;
    }
    try {
      /** This can fail */
      yield all([
        call(fetchCompanyTopShareholdersWorker, responseCompanyId),
        call(fetchIndustryAverage, { type: '', payload: responseCompanyId }),
        call(competitorsCallPut, responseCompanyId),
      ]);
    } catch (error: any) {
      yield put(
        fetchCompany.failure({
          status: get(error, ['response', 'status'], 500),
          message: error.message,
        })
      );
    }
  } catch (error: any) {
    if (error && error.message.includes('404')) {
      yield put(
        fetchCompany.failure({
          status: 404,
          message: 'Invalid company identifier',
        })
      );
    }
  } finally {
    yield put(fetchCompany.fulfill());
  }
}

function* fetchCompaniesWorker(
  action: ReturnType<(typeof fetchCompanies)['trigger']>
) {
  try {
    const { payload } = action;
    const {
      payload: { symbols },
    } = payload;

    if (!symbols) {
      // landed on `/compare`
      return;
    }
    const companySymbols = decodeURIComponent(symbols).split(',');
    // concurrently fetch the companies booyah!
    yield all(
      companySymbols.map((symbol) =>
        fetchCompanyWorker(fetchCompany.trigger({ symbol }))
      )
    );
  } catch (e) {
    yield put(fetchCompanies.failure());
  } finally {
    yield put(fetchCompanies.fulfill());
  }
}

function* fetchCompanyTopShareholdersWorker(companyId: string) {
  try {
    yield put(
      fetchCompanyTopShareholders.trigger({
        companyId,
      })
    );
    const caller = ahoy();
    const companyTopShareholders = yield caller(getCompanyTopShareholders, {
      lookupVal: companyId,
    });
    yield put(
      fetchCompanyTopShareholders.success({
        companyId: companyId,
        companyTopShareholders: companyTopShareholders.data,
      })
    );
  } catch (error: any) {
    yield put(
      fetchCompanyTopShareholders.failure({
        status: get(error, ['response', 'status'], 500),
        message: error.message,
      })
    );
  }
}

export function downloadFile(file: Blob, fileName: string) {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

/** watchers */

function* fetchCompanyWatcher() {
  yield takeEvery([fetchCompany.TRIGGER], fetchCompanyWorker);
}

function* watchGetCompanyAnalystsSaga() {
  yield takeLatest(fetchCompanyAnalysts.TRIGGER, getCompanyAnalysts);
}

function* fetchCompaniesWatcher() {
  yield takeLatest(fetchCompanies.TRIGGER, fetchCompaniesWorker);
}

function* companyCallPut(
  lookupVal: string,
  locale: Locale | undefined = undefined
) {
  try {
    const caller = ahoy();
    const queryClient: QueryClient = yield getContext('queryClient');
    let companyData: CompanyResponse['data'];

    if (queryClient) {
      const queryKey = getQueryKey(lookupVal, locale);
      const fetchQueryCompany = async () => {
        return await queryClient.fetchQuery({
          queryKey,
          queryFn,
          staleTime: STALE_TIME,
          retry: RETRY,
        });
      };
      companyData = yield caller(fetchQueryCompany);
    } else {
      const companyDataRaw: CompanyResponse = yield caller(getCompanyService, {
        lookupVal,
        payload: {
          include:
            'info,score,score.snowflake,analysis.extended.raw_data,analysis.extended.raw_data.insider_transactions',
          version: '2.0',
          locale,
        },
      });
      companyData = companyDataRaw.data;
    }

    yield put(
      fetchCompany.success({
        companyData,
      })
    );

    return companyData.company_id;
  } catch (e) {
    throw e;
  }
}

function* competitorsCallPut(lookupVal: string) {
  try {
    const caller = ahoy();
    const response = yield caller(getCompetitorsService, {
      lookupVal,
      payload: {
        include: 'score,score.snowflake',
        version: '2.0',
      },
    });
    yield put(
      fetchCompetitors.success({
        companyId: lookupVal,
        competitors: response.data,
      })
    );
  } catch (error) {
    yield put(fetchCompetitors.failure());
    throw error;
  }
}

function* isCompanyAvailable(id = '') {
  try {
    if (!id) throw new Error('id is blank');
    const summary: ReturnType<typeof getSummaryState> = yield select(
      getSummaryState,
      {
        companyId: id,
      }
    );
    if (summary.refreshTime !== 0) {
      /* Company already exists in state */
      const now = Date.now();
      const timeLapsed = now - summary.refreshTime;
      const ONE_HOUR = 3600000;
      if (ONE_HOUR > timeLapsed) {
        console.log('🎓 Reusing state in store. No need to refetch ', id);
        return true;
      }
    }
    return false;
  } catch (e) {
    return false;
  }
}

function* prefetchCompanyWorker(
  action: ReturnType<(typeof preFetchCompany)['trigger']>
) {
  try {
    const { id } = action.payload;
    if (!id) throw new Error('cannot prefetch. id is undefined');

    if (yield isCompanyAvailable(id)) {
      console.warn('Company state is already in state');
      return;
    }

    // check if company needs pre-fetching at all
    yield all([
      call(companyCallPut, id),
      call(fetchCompanyTopShareholdersWorker, id),
    ]);

    // we need some info from competitorsCallPut before we
    // can fetch the industry average
    yield call(fetchIndustryAverage, { type: '', payload: id });
  } catch (e) {
    console.log(e);
  } finally {
  }
}

function* prefetchCompanyWatcher() {
  yield takeLatest(preFetchCompany.TRIGGER, prefetchCompanyWorker);
}

export const watchers = [
  fetchCompanyWatcher,
  watchGetCompanyAnalystsSaga,
  fetchCompaniesWatcher,
  prefetchCompanyWatcher,
];

import { createSelector } from 'reselect';
import { getCompanyId } from './selectors';
import type { State } from '../interface';

export interface SelectorProps {
  companyId?: string;
}

export type SelectorReturn = State['formatting'][''];

const getDefault = (): SelectorReturn => ({
  tradingItemCurrencyISO: 'USD',
  tradingItemCurrencySymbol: '$',
  reportingCurrencyISO: 'USD',
  reportingUnit: 0,
  reportingUnitAbs: 0,
  reportingCurrencySymbol: '$',
  reportingUnitText: 'Millions',
  reportingUnitTextAbs: 'Millions',
  primaryTradingItemCurrencyISO: 'USD',
  primaryTradingItemCurrencySymbol: '$',
  isExchangeRateNeeded: false,
  exchangeRate: 0,
  secondaryListingExchangeRate: 1,
});
const getCurrentCompanyFormatting = (state, ownProps: SelectorProps) => {
  const company = state.company as State;
  const companyId = getCompanyId(state, ownProps);
  if (company.formatting[companyId]) {
    return company.formatting[companyId];
  }
  return getDefault();
};

export const getFormatting = createSelector(
  [getCurrentCompanyFormatting],
  (formatting): SelectorReturn => formatting
);

import { createRoutine } from 'redux-saga-routines';
import {
  ACTION_APP_ONLINE,
  ACTION_APP_OFFLINE,
  ACTION_APP_ERROR_REPORTED,
  ACTION_APP_ERROR_CLEAR,
} from '@/constants';

export const appOnline = createRoutine(ACTION_APP_ONLINE);

export const appOffline = createRoutine(ACTION_APP_OFFLINE);

export const appErrorReported = createRoutine(ACTION_APP_ERROR_REPORTED);

export const appErrorClear = createRoutine(ACTION_APP_ERROR_CLEAR);

import { produce } from 'immer';
import { fetchDashboardMyMarketsAndIndustries } from './routines';
import { fetchPortfolioList } from '@/components/DeprecatedPortfolio/redux/routines';

export type State = {
  portfolioKeys: string[];
  myMarketKeys: string[];
  myIndustryKeys: string[];
  myMarketsAndIndustriesLastUpdated: number | null;
  portfoliosFetchStatus: 'uninitialised' | 'idle' | 'fetching';
};

function getDefault(): State {
  return {
    portfolioKeys: [],
    myMarketKeys: [],
    myIndustryKeys: [],
    myMarketsAndIndustriesLastUpdated: null,
    portfoliosFetchStatus: 'uninitialised',
  };
}

function reducer(
  state = getDefault(),
  action: ReturnType<
    | (typeof fetchPortfolioList)['success']
    | (typeof fetchDashboardMyMarketsAndIndustries)['success']
  >
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case fetchPortfolioList.REQUEST:
        draft.portfoliosFetchStatus = 'fetching';
        break;
      case fetchPortfolioList.FAILURE:
        draft.portfoliosFetchStatus = 'idle';
        break;
      case fetchPortfolioList.SUCCESS: {
        const {
          payload: { portfolios },
        } = action as ReturnType<(typeof fetchPortfolioList)['success']>;
        draft.portfolioKeys = portfolios.map((n) => n.id.toString());
        draft.portfoliosFetchStatus = 'idle';
        break;
      }
      case fetchDashboardMyMarketsAndIndustries.SUCCESS: {
        const {
          payload: { data },
        } = action as ReturnType<
          (typeof fetchDashboardMyMarketsAndIndustries)['success']
        >;
        draft.myMarketsAndIndustriesLastUpdated = Date.now();
        draft.myMarketKeys = data
          .filter(({ industry_id: industryId }) => industryId === 0)
          .map(({ country_iso: countryISO }) =>
            JSON.stringify([countryISO.toLowerCase(), 0])
          );

        draft.myIndustryKeys = data
          .filter(({ industry_id: industryId }) => industryId !== 0)
          .map(
            ({ industry_id: industryId, country_iso: countryISO, industry }) =>
              JSON.stringify([
                countryISO.toLowerCase(),
                industryId.toString(),
                industry,
              ])
          );
        break;
      }
    }
  });
}

export default reducer;

import { withAuth } from '@utilities/queries';
import { getPortfolios, getUacCheck } from '@simplywallst/services';
import { format } from 'date-fns';
import type { CurrencyISO } from '@/constants/currencies';

export function userPortfoliosSelector(
  data: Awaited<ReturnType<typeof queryFn>>
) {
  const normalised = data.portfolios.reduce<{
    keys: string[];
    entities: Record<
      string,
      {
        id: string;
        name: string;
        currencyISO: CurrencyISO;
        holdingsCount: number;
        formattedDateGenerated: string;
        provider: null | string;
      }
    >;
  }>(
    (prev, current) => {
      const { id, name, currency_iso, items, date_generated, provider } =
        current;
      const keys = prev.keys.concat([id.toString()]);
      return {
        keys,
        entities: Object.assign(prev.entities, {
          [id.toString()]: {
            id: id.toString(),
            name,
            currencyISO: currency_iso,
            holdingsCount: typeof items !== 'undefined' ? items.data.length : 0,
            formattedDateGenerated:
              date_generated === null
                ? 'n/a'
                : format(
                    typeof date_generated === 'number'
                      ? date_generated
                      : Number(date_generated.date),
                    'MMM d, yyyy'
                  ),
            provider,
          },
        }),
      };
    },
    {
      keys: [],
      entities: {},
    }
  );
  const { keys, entities } = normalised;
  const totalHoldingsCount = keys.reduce(
    (a, b) => a + (entities[b].holdingsCount || 0),
    0
  );
  const maxCreatePortfolio = data.userAccess.data.data[0]
    .create_portfolios as number;

  return {
    keys,
    entities,
    totalHoldingsCount,
    canCreatePortfolio:
      maxCreatePortfolio > data.portfolios.length || maxCreatePortfolio === -1,
  };
}
export async function queryFn() {
  const [userAccess, response] = await Promise.all([
    await withAuth(getUacCheck),
    await withAuth(getPortfolios, undefined),
  ]);
  const portfolios = response.data.data.concat().sort((a, b) => {
    let aDate = 0;
    let bDate = 0;
    if (typeof b.date_generated === 'number') {
      bDate = b.date_generated;
    }
    if (typeof a.date_generated === 'number') {
      aDate = a.date_generated;
    }
    return (bDate || 0) - (aDate || 0);
  });

  return {
    portfolios,
    userAccess,
  };
}

export const USER_PORTFOLIOS_QUERY_KEY = ['GET_USER_PORTFOLIOS'] as const;

import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

interface Normalised<T> {
  keys: string[];
  entities: Record<string, T>;
}
function initialiser<T>(): Normalised<T> {
  return {
    keys: [],
    entities: {},
  };
}

export function normalise<T, S>(source: S[] = []) {
  return (
    identifier: string | string[] = 'id',
    resolver = (current) => current
  ) => {
    const tempId = uuidv4();
    return source.reduce(
      (prev, current) => ({
        keys: [
          ...prev.keys,
          get(current, identifier, tempId).toString(),
        ] as string[],
        entities: {
          ...prev.entities,
          [get(current, identifier, tempId)]: resolver(current),
        },
      }),
      initialiser<T>()
    );
  };
}

export function extractKeys<S>(source: S[] = [], identifier = 'id'): string[] {
  return source.map((sour) => sour[identifier]);
}

import { addYears, subYears } from 'date-fns';
import { get, has, pick } from 'lodash';
import type { AnalysisState } from '@/pages/CompanyReport/redux/interface';

export function keyValueToList(table: null | Record<string, number>) {
  if (!table) return [];
  return Object.entries(table).map(([key, value]) => ({
    date: Number(key),
    value,
  }));
}

function seriesArraySortAsending(first: number[], second: number[]) {
  if (first[0] > second[0]) return 1;
  else if (first[0] < second[0]) return -1;
  else return 0;
}

function seriesArraySortDesending(first: number[], second: number[]) {
  if (first[0] < second[0]) return -1;
  else if (first[0] > second[0]) return 1;
  else return 0;
}

export const seriesArraySort =
  (direction: 'DESC' | 'ASC' = 'ASC') =>
  (targetArray: number[][]) => {
    if (direction === 'ASC') {
      return targetArray.sort(seriesArraySortAsending);
    }
    return targetArray.sort(seriesArraySortDesending);
  };

export function tweakPastEPSRange(
  pastEPSRange: number[][] = [],
  pastEPS: number[][] = []
) {
  try {
    const threeYearsForward = addYears(pastEPS[0][0], 3).getTime();
    const computedPastEPSRange = pastEPSRange.filter((item) => {
      return item[0] < pastEPS[0][0] || item[0] >= threeYearsForward
        ? false
        : true;
    });
    computedPastEPSRange.unshift([pastEPS[0][0], pastEPS[0][1], pastEPS[0][1]]);
    return computedPastEPSRange;
  } catch (error) {
    return [];
  }
}

const timeSeriesReducer =
  <T extends Record<string, unknown>>(props: (keyof T)[]) =>
  (acc: number[][], current: unknown) => {
    if (current instanceof Array) {
      const [time, value] = current;
      const nextValue: number[] = [];
      const hasAllProps = props.every((prop) => {
        if (has(value, prop)) {
          nextValue.push(Number(get(value, [prop, 'value'])));
          return true;
        }
        return false;
      });
      if (hasAllProps) {
        acc.push([Number(time), ...nextValue]);
      }
    }
    return acc;
  };

export const reduceTimeSeries =
  <T extends Record<string, unknown>>(props: (keyof T)[]) =>
  (map: T) => {
    const entries = Object.entries(map);
    return entries.reduce<number[][]>(timeSeriesReducer<T>(props), []);
  };

export const capPastEPS =
  (lastEarningsUpdateTimeStamp: number) => (pastEPS: number[][]) => {
    const thirdLastEarningsYear = subYears(
      lastEarningsUpdateTimeStamp,
      2
    ).getTime();

    const list = pastEPS.filter((item) => {
      const year = item[0];
      return (
        year <= lastEarningsUpdateTimeStamp && year > thirdLastEarningsYear
      );
    });
    return list;
  };

export const flatten = (series: Record<string, number[]>) => {
  const seriesEntries = Object.entries(series);
  return seriesEntries.reduce<number[][]>((acc, current) => {
    const [time, value] = current;
    acc.push([Number(time), ...value]);
    return acc;
  }, []);
};

export const pickProps =
  <T extends Record<string, unknown> = Record<string, unknown>>(
    props: (keyof T)[]
  ) =>
  (analysis: T) =>
    pick(analysis, props);

export const merge = <
  T extends { [key: string]: number | { [key: string]: number } },
>(
  analysis: T
) => {
  const entries = Object.entries(analysis);
  const mergedAnalysis = {};
  entries.forEach((entry) => {
    const [_key, analysisMap] = entry;
    if (typeof analysisMap !== 'number') {
      const analysisEntries = Object.entries(analysisMap);
      analysisEntries.forEach((analysisEntry) => {
        const [time, analysisValue] = analysisEntry;
        if (mergedAnalysis[time] instanceof Array) {
          mergedAnalysis[time].push(analysisValue);
        } else {
          mergedAnalysis[time] = [analysisValue];
        }
      });
    }
  });
  return mergedAnalysis;
};

export function calculateBestFit(
  annualRate = 0,
  max: number,
  min: number,
  timeSeries: number[][] = []
) {
  if (timeSeries.length > 0 && max > min) {
    let sum = 0;
    let sumABS = 0;
    timeSeries.forEach((series) => {
      sum += parseInt(series[1].toString(), 10);
      sumABS += Math.abs(parseInt(series[1].toString(), 10));
    });
    const avg = sum / timeSeries.length;
    const avgABS = sumABS / timeSeries.length;
    const gap = (max - min) / 2;
    const slope = (annualRate / 31556926000) * avgABS;
    const diff = slope * gap;
    return [
      [min, avg - diff],
      [max, avg + diff],
    ];
  }
  return [
    [0, 0],
    [0, 0],
  ];
}

export function firstIndexOrZero(list?: number[]) {
  return list ? list[0] : 0;
}

export function removeFirstIfZero(series: number[][]) {
  try {
    if (series[0][1] === 0 && series.length > 1) {
      return series.slice(1);
    }
    return series;
  } catch (e) {
    return series;
  }
}

export function getDefault(): AnalysisState {
  return {
    buybackYield: 0,
    totalShareholderYield: 0,
    dividendPaymentsGrowthAnnual: 0,
    earningsPerShare1y: 0,
    earningsPerShareGrowthAnnual: 0,
    lastPaymentDividendCurrency: 0,
    beta5y: 0,
    bookValuePerShare: 0,
    earningsGrowthAnnual: 0,
    earningsGrowthReq1Y: 0,
    futureROE3Y: 0,
    intrinsicDiscount: 0,
    intrinsicValue: 0,
    futureRevenueEstimateMax: 0,
    pastRevenueActualsMax: 0,
    pastRevenueActualsMin: 0,
    pastNetIncomeMax: 0,
    pastNetIncomeMin: 0,
    PB: 0,
    PE: 0,
    PEG: 0,
    PS: 0,
    preferredMultiple: null,
    preferredMultipleReason: null,
    preferredMultipleSecondary: null,
    preferredMultipleFairRatio: 0,
    preferredMultipleAveragePeerValue: null,
    shouldShowAptCalc: false,
    return1YrAbs: 0,
    return1YrTotalReturn: 0,
    return30D: 0,
    return3YrAbs: 0,
    return3YrTotalReturn: 0,
    return5YrAbs: 0,
    return5YrTotalReturn: 0,
    return7D: 0,
    return90D: 0,
    returnSinceIpoAbs: 0,
    revenueGrowthAnnual: 0,
    sharePrice: 0,
    model: '',
    unleveredBeta: 0,
    DE: 0,
    taxRate: 0,
    equityPremium: 0,
    riskFreeRate: 0,
    leveredBetaActual: 0,
    leveredBeta: 0,
    costOfEquity: 0,
    adrPerShare: 0,
    PV5Y: 0,
    PVTV: 0,
    terminalValue: 0,
    NPV: 0,
    NPVPerShare: 0,
    NPVPerShareReportedCurrency: 0,
    pastROE: 0,
    pastROA: 0,
    pastROCE1y: 0,
    pastROCE3y: 0,
    pastNetIncome: 0,
    netIncomeGrowth1y: 0,
    netIncomeGrowth5y: 0,
    revenueGrowth1y: 0,
    revenueGrowth5y: 0,
    pastRevenue: 0,
    pastRevenueUnit: 0,
    pastGrossProfit: 0,
    pastGrossProfitUnit: 0,
    pastEarnings: 0,
    pastEarningsUnit: 0,
    pastCostOfRevenue: 0,
    pastCostOfRevenueUnit: 0,
    pastBookValue: 0,
    pastBookValueUnit: 0,
    pastGrossProfitMargin: 0,
    pastNetIncomeMargin: 0,
    debtEquityRatio: 0,
    totalAssets: 0,
    currentAssets: 0,
    totalEquity: 0,
    totalLiabEquity: 0,
    totalCurrentLiab: 0,
    cashStInvestments: 0,
    receivables: 0,
    inventory: 0,
    PPE: 0,
    totalDebt: 0,
    accountsPayable: 0,
    dividendYield: 0,
    dividendYieldFuture: 0,
    dividendVolatility: false,
    dividendPayingYears: 0,
    payoutRatio: 0,
    payoutRatio3y: 0,
    cashPayoutRatio: 0,
    dividendYieldGrowthAnnual: 0,
    firstPayment: 0,
    lastPayment: 0,
    upcomingDividend: null,
    totalEmployees: 0,
    lastEstimatesConfirmationTimeStamp: 0,
    eodPriceUpdateTimeStamp: 0,
    lastAnnualEarninsUpdateTimeStamp: 0,
    lastEarningsUpdateTimeStamp: 0,
    nextEarningsUpdateTimeStamp: 0,
    lastFilingTimeStamp: 0,
    EPS: 0,
    primaryCurrencyReported: 0,
    primaryPrice: 0,
    marketCapRawData: {
      listing: 0,
      primary: 0,
      reported: 0,
      usd: 0,
      sharesOutstanding: 0,
      relativeListingSharesOutstanding: 0,
      originalMarketCap: 0,
      totalEnterpriseValueReported: 0,
    },
    excessReturns: undefined,
    twoStageFCF: undefined,
    legacyChartData: {
      dividendEstimate: [],
      pastFreeCashFlow: [],
      futureFreeCashFlow: [],
      dividenEstimatesAnalystsAvg: [],
      historicalDividendPayments: [],
      historicalDividendYield: [],
      mergedFutureDividendsPerShare: [],
      mergedFutureYield: [],
      totalEmployees: [],
      insiderTrading: {
        totalSell: 0,
        totalBuy: 0,
        totalVolume: 0,
        buy: [],
        sell: [],
      },
      generalAdministrativeExpense: [],
      futureCashFlow: [],
      futureEPS: [],
      EPSAnalystConfirmation: {},
      futureEPSAnalyst: [],
      futureEPSRange: [],
      futureNetIncome: [],
      futureRevenueAnalysts: [],
      futureRevenueAnalystsMap: {},
      futureNetIncomeAnalystsMap: {},
      futureFreeCashflowAnalystsMap: {},
      futureCashflowAnalystsMap: {},
      futureRevenueEstimate: [],
      pastCashFlow: [],
      pastEPS: [],
      uncappedPastEps: [],
      pastEPSRange: [],
      pastNetIncome: [],
      pastRevenueActuals: [],
      revenueBestFit: [
        [0, 0],
        [0, 0],
      ],
      netIncomeBestFit: [
        [0, 0],
        [0, 0],
      ],
      pastNetIncomeBestFit: [
        [0, 0],
        [0, 0],
      ],
      researchAndDevExpenses: [],
      totalDebtHistory: [],
      totalEquity: [],
      pastCashInvestments: [],
      healthOperatingExpensesTotal: [],
      ownershipBreakdown: {},
      ceoCompensationAnalysis: {
        salary: [],
        total: [],
      },
    },
    minPrice52w: 0,
    maxPrice52w: 0,
    dailyReturnStdDev90d: 0,
    forwardPE: 0,
    forwardPS: 0,
    forecastEarnings: 0,
    forecastSales: 0,
    forecastNetIncomeGrowth1y: 0,
    forecastRevenueGrowth1y: 0,
    valueRevenueRatio: 0,
    valueEbitdaRatio: 0,
    industryAnalysisPast: null,
  };
}

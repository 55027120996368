import { createSelector } from 'reselect';
import type { State } from './reducers';
import type { State as NewsState } from '@ducks/news';

const getNewsMap = (state) => {
  const news = state.news as NewsState;
  return news;
};

const getPortfolios = ({ portfolios }: { portfolios: State }) => portfolios;

export const getPublicPortfolios = createSelector(
  [getPortfolios],
  (portfolios) => {
    return {
      keys: portfolios.publicKeys,
      entities: portfolios.entities,
    };
  }
);

export const getUserPortfolioKeys = createSelector(
  [getPortfolios],
  (portfolios) => portfolios.userKeys
);

export const getUserPortfolioEntities = createSelector(
  [getPortfolios],
  (portfolios) => portfolios.entities
);

export const getPublicPortfolioKeys = createSelector(
  [getPortfolios],
  (portfolios) => portfolios.publicKeys
);

export const getEntities = createSelector(
  [getPortfolios],
  (portfolios) => portfolios.entities
);

export const getHoldings = createSelector(
  [getPortfolios],
  (portfolios) => portfolios.holdingsTable
);

export const getTransactions = createSelector(
  [getPortfolios],
  (portfolios) => portfolios.transactionsTable
);

const getNewsState = (state) => {
  const portfolios = state.portfolios as State;
  return portfolios.news;
};

const getCurrentPortfolioId = (state) => {
  return state.portfolio.currentId as string;
};

const getPortfolioNewsKeys = createSelector(
  [getNewsState, getCurrentPortfolioId],
  (news, portfolioId) => news[portfolioId]
);

const getPortfolioNews = createSelector(
  [getPortfolioNewsKeys, getNewsMap],
  (newsKeys, newsMap) => {
    if (newsKeys) {
      return newsKeys.map((key) => newsMap[key]);
    }
    return [];
  }
);

export const getLatestPortfolioNews = createSelector(
  [getPortfolioNews],
  (portfolioNews) => portfolioNews.slice(0, 3).filter(Boolean)
);

import { createSelector } from 'reselect';
import type { State } from '../interface';
import { getCompanyId } from './utils';
interface SelectorProps {
  companyId?: string;
}

const analystsSelector = (state, props: SelectorProps) => {
  const companyId = getCompanyId(state, props);
  const company = state.company as State;
  if (company.analysts[companyId]) {
    return company.analysts[companyId];
  }
  return {
    brokers: [],
    analyst: [],
  };
};

export const getAnalysts = createSelector(
  analystsSelector,
  (analystsState) => analystsState
);

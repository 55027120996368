import { getGlobali18nInstance } from '@simplywallst/hooks/useTranslation';
import { makeTranslationHelpers } from './makeTranslationHelpers';

export const { useTranslation: useStatementTranslation } =
  makeTranslationHelpers('statementGlossary');

export function termIsValid(
  term: string
): term is Parameters<ReturnType<typeof useStatementTranslation>>[0] {
  const i18n = getGlobali18nInstance();
  const translationExists = i18n.exists(term);
  return translationExists;
}

import { useUser } from './useUser';
import { getDefaultProfileState as reducerGetDefaultProfileState } from './reducer';
import { default as reducerDefault } from './reducer';

export type { CouponState, ProfileState, BillingState } from './reducer';
export const reducer = reducerDefault;
export const getDefaultProfileState = reducerGetDefaultProfileState;

export * from './useUser';
export default useUser;

import type { StatementName } from '@/hooks/useCompanyStatementsAPIFormatted/types';

//Company Actions
export const ACTION_COMPANY_ANALYSTS_MODAL_TOGGLE =
  'COMPANY_ANALYSTS_MODAL_TOGGLE';

// Grid View Options
export enum GridViewOptions {
  GRID_VIEW_POTENTIALLY_UNDERVALUED = '16650',
  GRID_VIEW_STRONG_PAST_PERFORMING = '419982',
  GRID_VIEW_HEALTHY = '16735',
  GRID_VIEW_HIGH_GROWTH = '16733',
  GRID_VIEW_DIVIDEND_PAYING = '16736',
  GRID_VIEW_DIVIDEND_NEW = '146',
  GRID_VIEW_IN_CONTEXT_VALUATION = '16455',
  GRID_VIEW_IN_CONTEXT_VALUATION_UNDERVALUED = '284',
}

export const PEERS_LIMIT = 4;

//TODO: @ric use this const to render section reports
export const REPORT_SECTION_TITLE = {
  summary: 'Stock Overview',
  valuation: 'Valuation',
  future: 'Future Growth',
  past: 'Past Earnings Performance',
  health: 'Balance Sheet Health',
  dividend: 'Dividend',
  management: 'Management',
  ownership: 'Ownership',
  information: 'Company Information',
};

//TODO: @ric use these constants in every report section so they're centralized.
export const VALUATION_STATEMENTS: StatementName[] = [
  'IsUndervaluedBasedOnDCF',
  'IsHighlyUndervaluedBasedOnDCF',
  'IsGoodValueComparingPreferredMultipleToPeersAverageValue',
  'IsGoodValueComparingPreferredMultipleToIndustry',
  'IsGoodValueComparingRatioToFairRatio',
  'IsAnalystForecastTrustworthy',
];

export const FUTURE_STATEMENTS: StatementName[] = [
  'IsExpectedProfitGrowthAboveRiskFreeRate',
  'IsExpectedAnnualProfitGrowthAboveMarket',
  'IsExpectedAnnualProfitGrowthHigh',
  'IsExpectedRevenueGrowthAboveMarket',
  'IsExpectedRevenueGrowthHigh',
  'IsReturnOnEquityForecastAboveBenchmark',
];

export const PAST_STATEMENTS: StatementName[] = [
  'HasHighQualityPastEarnings',
  'HasPastNetProfitMarginImprovedOverLastYear',
  'HasGrownProfitsOverPast5Years',
  'HasProfitGrowthAccelerated',
  'IsGrowingFasterThanIndustry',
  'IsReturnOnEquityAboveThreshold',
];

export const HEALTH_STATEMENTS_DEFAULT: StatementName[] = [
  'AreShortTermLiabilitiesCovered',
  'AreLongTermLiabilitiesCovered',
  'IsDebtLevelAppropriate',
  'HasDebtReducedOverTime',
  'IsDebtCoveredByCashflow',
  'IsInterestCoveredByProfit',
];

export const HEALTH_STATEMENTS_FINANCIAL: StatementName[] = [
  'HasAnAppropriateLevelOfAssets',
  'HasAppropriateBadLoanAllowance',
  'HasPrimarilyLowRiskFunding',
  'HasAppropriateLoanLevel',
  'HasPrimarilyDepositFunding',
  'HasAppropriateNonPerformingLoans',
];

export const HEALTH_STATEMENTS_LOSS_MAKING: StatementName[] = [
  'AreShortTermLiabilitiesCovered',
  'AreLongTermLiabilitiesCovered',
  'IsDebtLevelAppropriate',
  'HasDebtReducedOverTime',
  'HasCashRunwayIfStable',
  'HasCashRunwayIfGrowing',
];

export const DIVIDEND_STATEMENTS_DEFAULT: StatementName[] = [
  'IsDividendSignificant',
  'IsDividendYieldTopTier',
  'IsDividendStable',
  'IsDividendGrowing',
  'IsDividendCovered',
  'IsDividendCoveredByFreeCashFlow',
];

export const DIVIDEND_STATEMENTS_FINANCIAL: StatementName[] = [
  'IsDividendSignificant',
  'IsDividendYieldTopTier',
  'IsDividendStable',
  'IsDividendGrowing',
  'IsDividendCovered',
  'IsDividendCoveredIn3Years',
];

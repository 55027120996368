import { del } from "../methods";
function deleteCompanyFromPortfolio_default(companyPortfolioItemId, headers = {}) {
  return del({
    url: `api/user/portfolio/item/${companyPortfolioItemId}`,
    headers
    // requiresAuthentication: true,
    // resolveResponse: false,
  });
}
export {
  deleteCompanyFromPortfolio_default as default
};

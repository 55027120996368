import type { ReceivedAction } from 'redux-first-router';
import { actionToPath, pathToAction } from 'redux-first-router';
import queryString from 'query-string';

import routesGenerator from './routesGenerator';

const routesMap = routesGenerator();

export const getPath = (action: ReceivedAction) => {
  return actionToPath(action, routesMap, queryString);
};

export const getPathSafe = (action: ReceivedAction) => {
  try {
    return actionToPath(action, routesMap, queryString);
  } catch (error) {
    console.error('Unable to generate path', error);
    return null;
  }
};

export const getCurrentRouteObject = () => {
  const action = pathToAction(window.location.pathname, routesGenerator());
  return routesMap[action.type];
};

export const isAuthenticationNeeded = (routeName: string) =>
  Boolean(routesMap[routeName]?.needsAuthentication);

export const isCacheable = (action: ReceivedAction) =>
  Boolean(routesMap[action.type]?.cacheable);

export type CacheKeyParams = {
  pathname: string;
  query: Record<string, string | undefined>;
};
/**
 * Function to generate a cache key for the route. If not provided, the default cache key will be used.
 * This cache key is used to differentiate between different routes with the same path but different query parameters.
 */
export type RouteCacheKeyFn = (params: CacheKeyParams) => string;

const defaultCacheKey: RouteCacheKeyFn = ({ query, pathname }) => {
  const { page } = query;
  const pageQuery =
    typeof page !== 'undefined' && page !== '1' ? `/page/${page}` : '';

  return `${pathname}${pageQuery}`;
};
export const getCacheIdByRoute = (
  action: ReceivedAction,
  params: CacheKeyParams
) => {
  const cacheKeyFn = routesMap[action.type]?.cacheKey;
  return typeof cacheKeyFn === 'function'
    ? cacheKeyFn(params)
    : defaultCacheKey(params);
};

import { get } from "../methods";
import { removeLeadingSlash } from "../utils";
function getCompetitors_default({ lookupVal, payload }, headers = {}, baseURL) {
  return get({
    url: `api/competitors/${removeLeadingSlash(lookupVal)}`,
    payload,
    headers,
    baseURL
  });
}
export {
  getCompetitors_default as default
};

import { createRoutine } from 'redux-saga-routines';
import type { getCompetitors } from '@simplywallst/services';
type APIResponse = Awaited<ReturnType<typeof getCompetitors>>['data'];
export interface FetchCompetitorsSuccessPayload {
  companyId: string;
  competitors: APIResponse['data'];
}
export const fetchCompetitors = createRoutine<
  SimplePayloadCreator,
  SimplePayloadCreator,
  SimplePayloadCreator<FetchCompetitorsSuccessPayload>, // success
  SimplePayloadCreator,
  SimplePayloadCreator
>('@/pages/CompanyReport/fetchCompetitor', {});

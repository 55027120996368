import { postJson } from "../methods";
function addCompanyToPortfolio_default(payload, headers = {}) {
  return postJson({
    url: "api/user/portfolio/item?include=company.score.snowflake,company.info",
    payload,
    headers
  });
}
export {
  addCompanyToPortfolio_default as default
};

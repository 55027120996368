const REDIRECT_COUNT_KEY = "challenge_redirect_count";
const REDIRECT_COUNT_MAX = 3;
function getChallengeRedirectCount() {
  const value = parseInt(localStorage.getItem(REDIRECT_COUNT_KEY) ?? "0");
  return Number.isFinite(value) ? value : 0;
}
function incrementChallengeRedirectCount() {
  const value = getChallengeRedirectCount() + 1;
  localStorage.setItem(REDIRECT_COUNT_KEY, value.toString());
  return value;
}
function handleChallenge() {
  if (typeof window !== "undefined") {
    const redirectCount = getChallengeRedirectCount();
    if (redirectCount < REDIRECT_COUNT_MAX) {
      incrementChallengeRedirectCount();
      const params = new URLSearchParams();
      params.set("redirect", window.location.href);
      params.set("counter", redirectCount.toString());
      window.location.replace(`/challenge?${params.toString()}`);
    } else {
      console.warn("Maximum challenge redirect count reached.");
    }
  }
}
export {
  getChallengeRedirectCount,
  handleChallenge,
  incrementChallengeRedirectCount
};

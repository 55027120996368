import { createRoutine } from 'redux-saga-routines';
import type { getPortfolios } from '@simplywallst/services';
type Response = Awaited<ReturnType<typeof getPortfolios>>['data'];

export const PublicPortfolioFetch = createRoutine<
  SimplePayloadCreator,
  SimplePayloadCreator,
  SimplePayloadCreator<{ portfolios: Response['data'] }>,
  SimplePayloadCreator,
  SimplePayloadCreator
>('@PORTFOLIOS/PUBLIC-PORTFOLIOS-FETCH', {});

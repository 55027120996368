import type { PrimaryCountryISO } from '@/constants/countries';

export const mainIndex = [
  'ADX',
  'BAX',
  'BSE',
  'BRVM',
  'OMX',
  'GSE',
  'TA',
  'Amman SE',
  'NSE',
  'PR',
  'MASI',
  'SEMDEX',
  'MSE',
  'NSX',
  'NSE',
  'MSM',
  'Al-Quds',
  'QE',
  'Tadawul',
  'TUNINDEX',
  'BIST',
  'DSEI',
  'ALSIUG',
  'JSE',
  'LSE',
  'ZSE',
  'ASX',
  'DSE',
  'SSE',
  'HSI',
  'IDX',
  'NIFTY',
  'NIKKEI',
  'KOSPI',
  'KASE',
  'CSE',
  'KLSE',
  'NZX',
  'PSEi',
  'KSE',
  'SGX',
  'SET',
  'TAIEX',
  'HOSE',
  'ATX',
  'BEL20',
  'SOFIX',
  'CYSMMAPA',
  'PX',
  'DAX',
  'OMX',
  'OMX',
  'IBEX',
  'OMX',
  'CAC',
  'FTSE',
  'ASE',
  'CROBEX',
  'BUX',
  'ISEQ',
  'OMX',
  'Borsa Italiana',
  'OMXV',
  'LUXX',
  'OMX',
  'MSE',
  'AEX',
  'OSEAX',
  'WIG',
  'PSI',
  'BET',
  'BELEX',
  'MOEX',
  'OMX',
  'SBITOP',
  'SAX',
  'PFTS',
  'MERVAL',
  'BSX',
  'BOVESPA',
  'IPSA',
  'COLCAP',
  'JSE',
  'IPC',
  'BVL',
  'TTSE',
  'IBC',
  'TSX',
  'Dow Jones',
  'SMI',
] as const;

export const mainExchange = [
  'DFM',
  'BSE',
  'BSE',
  'BRVM',
  'EGX',
  'GSE',
  'TASE',
  'ASE',
  'NSE',
  'KSE',
  'CSE',
  'SEM',
  'MSE',
  'NSX',
  'NSE',
  'MSM',
  'PEX',
  'DSM',
  'Tadawul',
  'BVMT',
  'BIST',
  'DSE',
  'USE',
  'JSE',
  'LuSE',
  'ZSE',
  'ASX',
  'DSE',
  'SEHK',
  'IDX',
  'NSE',
  'TSE',
  'KRX',
  'CSE',
  'KLSE',
  'NZSX',
  'PSE',
  'KSE',
  'SGX',
  'SET',
  'TWSE',
  'VSE',
  'ASX',
  'BSE',
  'BSE',
  'CSE',
  'PSE',
  'Frankfurt Stock Exchange',
  'CSE',
  'OMX',
  'MAD',
  'Nasdaq Helsinki',
  'Euronext',
  'LSE',
  'ASE',
  'ZSE',
  'BSE',
  'Euronext Dublin',
  'XICE',
  'Borsa Italiana',
  'Vilnius Stock Exchange',
  'LuxSE',
  'OMX',
  'MSE',
  'AEX',
  'OBX',
  'WSE',
  'Euronext Lisbon',
  'BVB',
  'BELEX',
  'MICEX',
  'OMX',
  'LJSE',
  'BSSE',
  'PFTS',
  'BYMA',
  'BSX',
  'B3',
  'SSE',
  'BVC',
  'JSE',
  'BMV',
  'IGBVL',
  'TTSE',
  'IBVC',
  'TSX',
  'NYSE',
  'SMI',
] as const;

export const ORDERED_MARKETS: PrimaryCountryISO[] = [
  /*popular markets*/
  'us',
  'au',
  'gb',
  'ca',
  'in',
  'cn',
  /*remaining in alpabetical order*/
  'ar',
  'at',
  'bh',
  'bd',
  'be',
  'bm',
  'bw',
  'br',
  'bg',
  'cl',
  'co',
  'hr',
  'cy',
  'cz',
  'dk',
  'eg',
  'ee',
  'fi',
  'fr',
  'de',
  'gh',
  'gr',
  'hk',
  'hu',
  'is',
  'id',
  'ie',
  'il',
  'it',
  'ci',
  'jm',
  'jp',
  'jo',
  'ke',
  'kw',
  'lv',
  'lb',
  'lt',
  'lu',
  'mw',
  'my',
  'mt',
  'mu',
  'mx',
  'ma',
  'na',
  'nl',
  'nz',
  'ng',
  'no',
  'om',
  'pk',
  'ps',
  'pe',
  'ph',
  'pl',
  'pt',
  'qa',
  'ro',
  'ru',
  'sa',
  'rs',
  'sg',
  'sk',
  'si',
  'za',
  'kr',
  'es',
  'lk',
  'se',
  'ch',
  'tw',
  'tz',
  'th',
  'tt',
  'tn',
  'tr',
  'ug',
  'ua',
  'ae',
  've',
  'vn',
  'zm',
  'zw',
];

const secondaryMarket = [
  'adx',
  'dfm',
  'difx',
  'bax',
  'bsm',
  'brvm',
  'case',
  'ghse',
  'tase',
  'ase',
  'nase',
  'kwse',
  'bdb',
  'cbse',
  'muse',
  'mal',
  'nmse',
  'ngse',
  'msm',
  'plse',
  'dsm',
  'sase',
  'bvmt',
  'ibse',
  'dar',
  'ugse',
  'jse',
  'luse',
  'zmse',
  'asx',
  'chia',
  'nsx',
  'dse',
  'shse',
  'szse',
  'xsec',
  'xssc',
  'sehk',
  'shsc',
  'szsc',
  'idx',
  'bse',
  'nsei',
  'fkse',
  'jasdaq',
  'nse',
  'spse',
  'tse',
  'kosdaq',
  'kose',
  'xkon',
  'kas',
  'cose',
  'klse',
  'nzse',
  'pse',
  'kase',
  'catalist',
  'sgx',
  'set',
  'thamse',
  'gtsm',
  'tsec',
  'hnx',
  'hose',
  'unq-vnm',
  'wbag',
  'enxtbr',
  'bul',
  'brse',
  'swx',
  'cse',
  'sep',
  'bst',
  'db',
  'duse',
  'hmse',
  'mun',
  'xtra',
  'cpse',
  'tlse',
  'bdm',
  'bme',
  'hlse',
  'cobl',
  'enxtpa',
  'aim',
  'bats-chixe',
  'lse',
  'ofex',
  'unq-gbr',
  'atse',
  'zgse',
  'buse',
  'ise',
  'icse',
  'bit',
  'bite',
  'f-ita',
  'xmil',
  'nsel',
  'bdl',
  'rise',
  'mtse',
  'enxtam',
  'ob',
  'otcno',
  'wse',
  'enxtls',
  'bvb',
  'belex',
  'misx',
  'ngm',
  'omx',
  'xsat',
  'ljse',
  'bsse',
  'ukr',
  'base',
  'ber',
  'bovespa',
  'soma',
  'snse',
  'bvc',
  'gyse',
  'jmse',
  'bmv',
  'bvl',
  'ttse',
  'ccse',
  'cnsx',
  'tsx',
  'tsxv',
  'amex',
  'arca',
  'bats',
  'boin',
  'nasdaqcm',
  'nasdaqgm',
  'nasdaqgs',
  'nyse',
  'otcpk',
] as const;

export const SECONDARY_MARKETS = {
  adx: {
    label: 'ADX - Abu Dhabi Securities Exchange',
  },
  dfm: {
    label: 'DFM - Dubai Financial Market',
  },
  difx: {
    label: 'DIFX - NASDAQ Dubai Limited',
  },
  bax: {
    label: 'BAX - Bahrain Stock Exchange',
  },
  bsm: {
    label: 'BSM - Botswana Share Market',
  },
  brvm: {
    label: 'BRVM - Bourse Regionale des Valeurs Mobilieres',
  },
  case: {
    label: 'CASE - Cairo and Alexandria Stock Exchange',
  },
  ghse: {
    label: 'GHSE - Ghana Stock Exchange',
  },
  tase: {
    label: 'TASE - The Tel-Aviv Stock Exchange',
  },
  ase: {
    label: 'ASE - Amman Stock Exchange',
  },
  nase: {
    label: 'NASE - Nairobi Stock Exchange',
  },
  kwse: {
    label: 'KWSE - Kuwait Stock Exchange',
  },
  bdb: {
    label: 'BDB - Bourse de Beyrouth',
  },
  cbse: {
    label: 'CBSE - Casablanca Stock Exchange',
  },
  muse: {
    label: 'MUSE - The Stock Exchange of Mauritius Ltd.',
  },
  mal: {
    label: 'MAL - Malawi Stock Exchange',
  },
  nmse: {
    label: 'NMSE - Namibian Stock Exchange',
  },
  ngse: {
    label: 'NGSE - Nigerian Stock Exchange',
  },
  msm: {
    label: 'MSM - Muscat Securities Market',
  },
  plse: {
    label: 'PLSE - Palestine Securities Exchange',
  },
  dsm: {
    label: 'DSM - Doha Securities Market',
  },
  sase: {
    label: 'SASE - Saudi Arabian Stock Exchange',
  },
  bvmt: {
    label: 'BVMT - Bourse des Valeurs Mobilieres',
  },
  ibse: {
    label: 'IBSE - Borsa Istanbul (BIST)',
  },
  dar: {
    label: 'DAR - Tanzania Stock Exchange',
  },
  ugse: {
    label: 'UGSE - Uganda Stock Exchange',
  },
  jse: {
    label: 'JSE - The Johannesburg Securities Exchange',
  },
  luse: {
    label: 'LUSE - Lusaka Stock Exchange',
  },
  zmse: {
    label: 'ZMSE - Zimbabwe Stock Exchange',
  },
  asx: {
    label: 'ASX - Australian Securities Exchange',
  },
  chia: {
    label: 'CHIA - Chi-X Australia',
  },
  nsx: {
    label: 'NSX - National Stock Exchange of Australia',
  },
  dse: {
    label: 'DSE - Dhaka Stock Exchange Ltd.',
  },
  shse: {
    label: 'SHSE - Shanghai Stock Exchange',
  },
  szse: {
    label: 'SZSE - Shenzhen Stock Exchange',
  },
  xsec: {
    label: 'XSEC - Shenzhen Stock Exchange - Shenzhen-Hong Kong Stock Connect',
  },
  xssc: {
    label:
      'XSSC - Shanghai Stock Exchange - Shanghai - Hong Kong Stock Connect',
  },
  sehk: {
    label: 'SEHK - The Stock Exchange of Hong Kong Ltd.',
  },
  shsc: {
    label:
      'SHSC - Stock Exchange of Hong Kong Limited - Shanghai - Hong Kong Stock Connect',
  },
  szsc: {
    label:
      'SZSC - The Stock Exchange of Hong Kong - Shenzhen - Hong Kong Stock Connect',
  },
  idx: {
    label: 'IDX - Indonesia Stock Exchange',
  },
  bse: {
    label: 'BSE - Mumbai Stock Exchange',
  },
  nsei: {
    label: 'NSEI - National Stock Exchange of India',
  },
  fkse: {
    label: 'FKSE - Fukuoka Stock Exchange',
  },
  jasdaq: {
    label: 'JASDAQ - TSE JASDAQ',
  },
  nse: {
    label: 'NSE - Nagoya Stock Exchange',
  },
  spse: {
    label: 'SPSE - Sapporo Stock Exchange',
  },
  tse: {
    label: 'TSE - The Tokyo Stock Exchange',
  },
  kosdaq: {
    label: 'KOSDAQ - KOSDAQ',
  },
  kose: {
    label: 'KOSE - Korea Stock Exchange',
  },
  xkon: {
    label: 'XKON - Korea New Exchange',
  },
  kas: {
    label: 'KAS - Kazakhstan Stock Exchange',
  },
  cose: {
    label: 'COSE - Colombo Stock Exchange',
  },
  klse: {
    label: 'KLSE - Bursa Malaysia',
  },
  nzse: {
    label: 'NZSE - New Zealand Stock Exchange',
  },
  pse: {
    label: 'PSE - Philippines Stock Exchange',
  },
  kase: {
    label: 'KASE - Pakistan Stock Exchange Limited',
  },
  catalist: {
    label: 'Catalist - Singapore Exchange Catalist Market',
  },
  sgx: {
    label: 'SGX - Singapore Exchange',
  },
  set: {
    label: 'SET - The Stock Exchange of Thailand',
  },
  thamse: {
    label: 'THAMSE - Bangkok-Alien Market Stock Exchange',
  },
  gtsm: {
    label: 'GTSM - Taipei Exchange',
  },
  tsec: {
    label: 'TSEC - Taiwan Stock Exchange',
  },
  hnx: {
    label: 'HNX - Hanoi Stock Exchange',
  },
  hose: {
    label: 'HOSE - Ho Chi Minh Stock Exchange',
  },
  'unq-vnm': {
    label: 'UNQ-VNM - Unquoted - Vietnam',
  },
  wbag: {
    label: 'WBAG - Wiener Boerse AG',
  },
  enxtbr: {
    label: 'ENXTBR - Euronext Brussels',
  },
  bul: {
    label: 'BUL - Bulgaria Stock Exchange',
  },
  brse: {
    label: 'BRSE - Berne Stock Exchange',
  },
  swx: {
    label: 'SWX - SIX Swiss Exchange',
  },
  cse: {
    label: 'CSE - Cyprus Stock Exchange',
  },
  sep: {
    label: 'SEP - The Stock Exchange Prague Co. Ltd.',
  },
  bst: {
    label: 'BST - Boerse-Stuttgart',
  },
  db: {
    label: 'DB - Deutsche Boerse AG',
  },
  duse: {
    label: 'DUSE - Dusseldorf Stock Exchange',
  },
  hmse: {
    label: 'HMSE - Hamburg Stock Exchange',
  },
  mun: {
    label: 'MUN - Boerse Muenchen',
  },
  xtra: {
    label: 'XTRA - XETRA Trading Platform',
  },
  cpse: {
    label: 'CPSE - OMX Nordic Exchange Copenhagen',
  },
  tlse: {
    label: 'TLSE - OMX Nordic Exchange Tallinn',
  },
  bdm: {
    label: 'BDM - Bolsa de Madrid',
  },
  bme: {
    label: 'BME - Bolsas y Mercados Espanoles',
  },
  hlse: {
    label: 'HLSE - OMX Nordic Exchange Helsinki',
  },
  cobl: {
    label: 'COBL - Cote Bleu',
  },
  enxtpa: {
    label: 'ENXTPA - Euronext Paris',
  },
  aim: {
    label: 'AIM - London Stock Exchange AIM Market',
  },
  'bats-chixe': {
    label: 'BATS-CHIXE - BATS Chi-XEurope',
  },
  lse: {
    label: 'LSE - London Stock Exchange',
  },
  ofex: {
    label: 'OFEX - ICAP Securities & Derivatives Exchange (ISDX)',
  },
  'unq-gbr': {
    label: 'UNQ-GBR - Unquoted-United Kingdom',
  },
  atse: {
    label: 'ATSE - The Athens Stock Exchange',
  },
  zgse: {
    label: 'ZGSE - Zagreb Stock Exchange',
  },
  buse: {
    label: 'BUSE - Budapest Stock Exchange',
  },
  ise: {
    label: 'ISE - Irish Stock Exchange',
  },
  icse: {
    label: 'ICSE - OMX Nordic Exchange Iceland',
  },
  bit: {
    label: 'BIT - Borsa Italiana',
  },
  bite: {
    label: 'BITE - Borsa Italiana ETFplus',
  },
  'f-ita': {
    label: 'F-ITA - Fund - Italy',
  },
  xmil: {
    label: 'XMIL - Borsa Italiana Trading After Hours',
  },
  nsel: {
    label: 'NSEL - OMX Nordic Exchange Vilnius',
  },
  bdl: {
    label: 'BDL - Bourse de Luxembourg',
  },
  rise: {
    label: 'RISE - OMX Nordic Exchange Riga',
  },
  mtse: {
    label: 'MTSE - Malta Stock Exchange',
  },
  enxtam: {
    label: 'ENXTAM - Euronext Amsterdam',
  },
  ob: {
    label: 'OB - Oslo Bors',
  },
  otcno: {
    label: 'OTCNO - Norway OTC',
  },
  wse: {
    label: 'WSE - Warsaw Stock Exchange',
  },
  enxtls: {
    label: 'ENXTLS - Euronext Lisbon',
  },
  bvb: {
    label: 'BVB - Bucharest Stock Exchange',
  },
  belex: {
    label: 'BELEX - Belgrade Stock Exchange',
  },
  misx: {
    label: 'MISX - Micex - Rts',
  },
  ngm: {
    label: 'NGM - Nordic Growth Market',
  },
  omx: {
    label: 'OMX - OMX Nordic Exchange Stockholm',
  },
  xsat: {
    label: 'XSAT - Aktietorget',
  },
  ljse: {
    label: 'LJSE - Ljubljana Stock Exchange',
  },
  bsse: {
    label: 'BSSE - Bratislava Stock Exchange',
  },
  ukr: {
    label: 'UKR - PFTS Ukraine Stock Exchange',
  },
  base: {
    label: 'BASE - Buenos Aires Stock Exchange',
  },
  ber: {
    label: 'BER - The Bermuda Stock Exchange',
  },
  bovespa: {
    label: 'BOVESPA - Bolsa de Valores de Sao Paulo',
  },
  soma: {
    label: 'SOMA - Sociedade Operadora do Mercado de Ativos S A',
  },
  snse: {
    label: 'SNSE - Santiago Stock Exchange',
  },
  bvc: {
    label: 'BVC - Bolsa de Valores de Colombia',
  },
  gyse: {
    label: 'GYSE - Guayaquil Stock Exchange',
  },
  jmse: {
    label: 'JMSE - The Jamaica Stock Exchange',
  },
  bmv: {
    label: 'BMV - Bolsa Mexicana de Valores',
  },
  bvl: {
    label: 'BVL - Bolsa de Valores de Lima',
  },
  ttse: {
    label: 'TTSE - Trinidad & Tobago Stock Exchange',
  },
  ccse: {
    label: 'CCSE - Caracas Stock Exchange',
  },
  cnsx: {
    label: 'CNSX - Canadian National Stock Exchange',
  },
  tsx: {
    label: 'TSX - The Toronto Stock Exchange',
  },
  tsxv: {
    label: 'TSXV - TSX Venture Exchange',
  },
  amex: {
    label: 'AMEX - NYSE MKT LLC',
  },
  arca: {
    label: 'ARCA - NYSE Arca',
  },
  bats: {
    label: 'BATS - Better Alternative Trading System',
  },
  boin: {
    label: 'BOIN - Bond Indices or OTC Bonds',
  },
  nasdaqcm: {
    label: 'NasdaqCM - Nasdaq Capital Market',
  },
  nasdaqgm: {
    label: 'NasdaqGM - Nasdaq Global Market',
  },
  nasdaqgs: {
    label: 'NasdaqGS - Nasdaq Global Select',
  },
  nyse: {
    label: 'NYSE - New York Stock Exchange',
  },
  otcpk: {
    label: 'OTCPK - Pink Sheets LLC',
  },
} as const;

export const PRIMARY_MARKETS = {
  ae: {
    label: 'United Arab Emirates',
    demonym: 'Emirati',
    children: ['adx', 'dfm', 'difx'],
    mainIndex: 'ADX',
    mainExchange: 'DFM',
  },
  bh: {
    label: 'Bahrain',
    children: ['bax'],
    demonym: 'Bahraini',
    mainIndex: 'BAX',
    mainExchange: 'BSE',
  },
  bw: {
    label: 'Botswana',
    children: ['bsm'],
    demonym: 'Botswanan',
    mainIndex: 'BSE',
    mainExchange: 'BSE',
  },
  ci: {
    label: 'Ivory Coast',
    children: ['brvm'],
    demonym: 'Ivorian',
    mainIndex: 'BRVM',
    mainExchange: 'BRVM',
  },
  eg: {
    label: 'Egypt',
    children: ['case'],
    demonym: 'Egyptian',
    mainIndex: 'CASE/EGX',
    mainExchange: 'EGX',
  },
  gh: {
    label: 'Ghana',
    children: ['ghse'],
    demonym: 'Ghanaian',
    mainIndex: 'GSE',
    mainExchange: 'GSE',
  },
  il: {
    label: 'Israel',
    children: ['tase'],
    demonym: 'Israeli',
    mainIndex: 'TA',
    mainExchange: 'TASE',
  },
  jo: {
    label: 'Jordan',
    children: ['ase'],
    demonym: 'Jordanian',
    mainIndex: 'Amman SE',
    mainExchange: 'ASE',
  },
  ke: {
    label: 'Kenya',
    children: ['nase'],
    demonym: 'Kenyan',
    mainIndex: 'NSE',
    mainExchange: 'NSE',
  },
  kw: {
    label: 'Kuwait',
    children: ['kwse'],
    demonym: 'Kuwaiti',
    mainIndex: 'PR',
    mainExchange: 'KSE',
  },
  lb: { label: 'Lebanon', children: ['bdb'], demonym: 'Lebanese' },
  ma: {
    label: 'Morocco',
    children: ['cbse'],
    demonym: 'Moroccan',
    mainIndex: 'MASI',
    mainExchange: 'CSE',
  },
  mu: {
    label: 'Mauritius',
    children: ['muse'],
    demonym: 'Mauritian',
    mainIndex: 'SEMDEX',
    mainExchange: 'SEM',
  },
  mw: {
    label: 'Malawi',
    children: ['mal'],
    demonym: 'Malawian',
    mainIndex: 'MSE',
    mainExchange: 'MSE',
  },
  na: {
    label: 'Namibia',
    children: ['nmse'],
    demonym: 'Namibian',
    mainIndex: 'NSX',
    mainExchange: 'NSX',
  },
  ng: {
    label: 'Nigeria',
    children: ['ngse'],
    demonym: 'Nigerian',
    mainIndex: 'NSE',
    mainExchange: 'NSE',
  },
  om: {
    label: 'Oman',
    children: ['msm'],
    demonym: 'Omani',
    mainIndex: 'MSM',
    mainExchange: 'MSM',
  },
  ps: {
    label: 'Palestinian Authority',
    children: ['plse'],
    demonym: 'Palestinian',
    mainIndex: 'Al-Quds',
    mainExchange: 'PEX',
  },
  qa: {
    label: 'Qatar',
    children: ['dsm'],
    demonym: 'Qatari',
    mainIndex: 'QE',
    mainExchange: 'DSM',
  },
  sa: {
    label: 'Saudi Arabia',
    children: ['sase'],
    demonym: 'Saudi Arabian',
    mainIndex: 'Tadawul',
    mainExchange: 'Tadawul',
  },
  tn: {
    label: 'Tunisia',
    children: ['bvmt'],
    demonym: 'Tunisian',
    mainIndex: 'TUNINDEX',
    mainExchange: 'BVMT',
  },
  tr: {
    label: 'Turkey',
    children: ['ibse'],
    demonym: 'Turkish',
    mainIndex: 'BIST',
    mainExchange: 'BIST',
  },
  tz: {
    label: 'Tanzania',
    children: ['dar'],
    demonym: 'Tanzanian',
    mainIndex: 'DSEI',
    mainExchange: 'DSE',
  },
  ug: {
    label: 'Uganda',
    children: ['ugse'],
    demonym: 'Ugandan',
    mainIndex: 'ALSIUG',
    mainExchange: 'USE',
  },
  za: {
    label: 'South Africa',
    children: ['jse'],
    demonym: 'South African',
    mainIndex: 'JSE',
    mainExchange: 'JSE',
  },
  zm: {
    label: 'Zambia',
    children: ['luse'],
    demonym: 'Zambian',
    mainIndex: 'LSE',
    mainExchange: 'LuSE',
  },
  zw: {
    label: 'Zimbabwe',
    children: ['zmse'],
    demonym: 'Zimbabwean',
    mainIndex: 'ZSE',
    mainExchange: 'ZSE',
  },
  au: {
    label: 'Australia',
    children: ['asx', 'chia', 'nsx'],
    demonym: 'Australian',
    mainIndex: 'ASX',
    mainExchange: 'ASX',
  },
  bd: {
    label: 'Bangladesh',
    children: ['dse'],
    demonym: 'Bangladeshi',
    mainIndex: 'DSE',
    mainExchange: 'DSE',
  },
  cn: {
    label: 'China',
    children: ['shse', 'szse'],
    demonym: 'Chinese',
    mainIndex: 'SSE',
  },
  hk: {
    label: 'Hong Kong',
    children: ['sehk'],
    mainIndex: 'HSI',
    mainExchange: 'SEHK',
  },
  id: {
    label: 'Indonesia',
    children: ['idx'],
    demonym: 'Indonesian',
    mainIndex: 'IDX',
    mainExchange: 'IDX',
  },
  in: {
    label: 'India',
    children: ['bse', 'nsei'],
    demonym: 'Indian',
    mainIndex: 'NIFTY',
    mainExchange: 'NSE',
  },
  jp: {
    label: 'Japan',
    children: ['fkse', 'jasdaq', 'spse'],
    demonym: 'Japanese',
    mainIndex: 'NIKKEI',
    mainExchange: 'TSE',
  },
  kr: {
    label: 'South Korea',
    children: ['kosdaq', 'kose', 'xkon'],
    demonym: 'South Korean',
    mainIndex: 'KOSPI',
    mainExchange: 'KRX',
  },
  lk: {
    label: 'Sri Lanka',
    children: ['cose'],
    demonym: 'Sri Lankan',
    mainIndex: 'CSE',
    mainExchange: 'CSE',
  },
  my: {
    label: 'Malaysia',
    children: ['klse'],
    demonym: 'Malaysian',
    mainIndex: 'KLSE',
    mainExchange: 'KLSE',
  },
  nz: {
    label: 'New Zealand',
    children: ['nzse'],
    mainIndex: 'NZX',
    mainExchange: 'NZSX',
  },
  ph: {
    label: 'Philippines',
    children: ['pse'],
    demonym: 'Filipino',
    mainIndex: 'PSEi',
    mainExchange: 'PSE',
  },
  pk: {
    label: 'Pakistan',
    children: ['kase'],
    demonym: 'Pakistani',
    mainIndex: 'KSE',
    mainExchange: 'KSE',
  },
  sg: {
    label: 'Singapore',
    children: ['catalist', 'sgx'],
    demonym: 'Singaporean',
    mainIndex: 'SGX',
    mainExchange: 'SGX',
  },
  th: {
    label: 'Thailand',
    children: ['set'],
    demonym: 'Thai',
    mainIndex: 'SET',
    mainExchange: 'SET',
  },
  tw: {
    label: 'Taiwan',
    children: ['gtsm', 'tsec'],
    demonym: 'Taiwanese',
    mainIndex: 'TAIEX',
    mainExchange: 'TWSE',
  },
  vn: {
    label: 'Vietnam',
    children: ['hnx', 'hose'],
    demonym: 'Vietnamese',
    mainIndex: 'HOSE',
    mainExchange: 'VSE',
  },
  at: {
    label: 'Austria',
    children: ['wbag'],
    demonym: 'Austrian',
    mainIndex: 'ATX',
    mainExchange: 'ASX',
  },
  be: {
    label: 'Belgium',
    children: ['enxtbr'],
    demonym: 'Belgian',
    mainIndex: 'BEL20',
    mainExchange: 'BSE',
  },
  bg: {
    label: 'Bulgaria',
    children: ['bul'],
    demonym: 'Bulgarian',
    mainIndex: 'SOFIX',
    mainExchange: 'BSE',
  },
  cy: {
    label: 'Cyprus',
    children: ['cse'],
    demonym: 'Cypriot',
    mainIndex: 'CYSMMAPA',
    mainExchange: 'CSE',
  },
  cz: {
    label: 'Czech Republic',
    children: ['sep'],
    demonym: 'Czech',
    mainIndex: 'PX',
    mainExchange: 'PSE',
  },
  de: {
    label: 'Germany',
    children: ['bst', 'db', 'duse', 'hmse', 'mun', 'xtra'],
    demonym: 'German',
    mainIndex: 'DAX',
    mainExchange: 'Frankfurt Stock Exchange',
  },
  dk: {
    label: 'Denmark',
    children: ['cpse'],
    demonym: 'Danish',
    mainIndex: 'OMX',
    mainExchange: 'CSE',
  },
  ee: {
    label: 'Estonia',
    children: ['tlse'],
    demonym: 'Estonia',
    mainIndex: 'OMX',
    mainExchange: 'OMX',
  },
  es: {
    label: 'Spain',
    children: ['bdm', 'bme'],
    demonym: 'Spanish',
    mainIndex: 'IBEX',
    mainExchange: 'MAD',
  },
  fi: {
    label: 'Finland',
    children: ['hlse'],
    demonym: 'Finnish',
    mainIndex: 'OMX',
    mainExchange: 'Nasdaq Helsinki',
  },
  fr: {
    label: 'France',
    children: ['cobl', 'enxtpa'],
    demonym: 'French',
    mainIndex: 'CAC',
    mainExchange: 'Euronext',
  },
  gb: {
    label: 'United Kingdom',
    children: ['aim', 'bats-chixe', 'lse', 'ofex'],
    demonym: 'U.K.',
    mainIndex: 'FTSE',
    mainExchange: 'LSE',
  },
  gr: {
    label: 'Greece',
    children: ['atse'],
    demonym: 'Greek',
    mainIndex: 'ASE',
    mainExchange: 'ASE',
  },
  hr: {
    label: 'Croatia',
    children: ['zgse'],
    demonym: 'Croatian',
    mainIndex: 'CROBEX',
    mainExchange: 'ZSE',
  },
  hu: {
    label: 'Hungary',
    children: ['buse'],
    demonym: 'Hungarian',
    mainIndex: 'BUX',
    mainExchange: 'BSE',
  },
  ie: {
    label: 'Ireland',
    children: ['ise'],
    demonym: 'Irish',
    mainIndex: 'ISEQ',
    mainExchange: 'Euronext Dublin',
  },
  is: {
    label: 'Iceland',
    children: ['icse'],
    demonym: 'Icelandic',
    mainIndex: 'OMX',
    mainExchange: 'XICE',
  },
  it: {
    label: 'Italy',
    children: ['bit', 'bite', 'xmil'],
    demonym: 'Italian',
    mainIndex: 'Borsa Italiana',
    mainExchange: 'Borsa Italiana',
  },
  lt: {
    label: 'Lithuania',
    children: ['nsel'],
    demonym: 'Lithuanian',
    mainIndex: 'OMXV',
    mainExchange: 'Vilnius Stock Exchange',
  },
  lu: {
    label: 'Luxembourg',
    children: ['bdl'],
    mainIndex: 'LUXX',
    mainExchange: 'LuxSE',
  },
  lv: {
    label: 'Latvia',
    children: ['rise'],
    demonym: 'Latvian',
    mainIndex: 'OMX',
    mainExchange: 'OMX',
  },
  mt: {
    label: 'Malta',
    children: ['mtse'],
    demonym: 'Maltese',
    mainIndex: 'MSE',
    mainExchange: 'MSE',
  },
  nl: {
    label: 'Netherlands',
    children: ['enxtam'],
    demonym: 'Dutch',
    mainIndex: 'AEX',
    mainExchange: 'AEX',
  },
  no: {
    label: 'Norway',
    children: ['ob', 'otcno'],
    demonym: 'Norwegian',
    mainIndex: 'OSEAX',
    mainExchange: 'OBX',
  },
  pl: {
    label: 'Poland',
    children: ['wse'],
    demonym: 'Polish',
    mainIndex: 'WIG',
    mainExchange: 'WSE',
  },
  pt: {
    label: 'Portugal',
    children: ['enxtls'],
    demonym: 'Portuguese',
    mainIndex: 'PSI',
    mainExchange: 'Euronext Lisbon',
  },
  ro: {
    label: 'Romania',
    children: ['bvb'],
    demonym: 'Romanian',
    mainIndex: 'BET',
    mainExchange: 'BVB',
  },
  rs: {
    label: 'Serbia',
    children: ['belex'],
    demonym: 'Serbian',
    mainIndex: 'BELEX',
    mainExchange: 'BELEX',
  },
  ru: {
    label: 'Russia',
    children: ['misx'],
    demonym: 'Russian',
    mainIndex: 'MOEX',
    mainExchange: 'MICEX',
  },
  se: {
    label: 'Sweden',
    children: ['ngm', 'omx', 'xsat'],
    demonym: 'Swedish',
    mainIndex: 'OMX',
    mainExchange: 'OMX',
  },
  si: {
    label: 'Slovenia',
    children: ['ljse'],
    demonym: 'Slovenian',
    mainIndex: 'SBITOP',
    mainExchange: 'LJSE',
  },
  sk: {
    label: 'Slovakia',
    children: ['bsse'],
    demonym: 'Slovak',
    mainIndex: 'SAX',
    mainExchange: 'BSSE',
  },
  ua: {
    label: 'Ukraine',
    children: ['ukr'],
    demonym: 'Ukrainian',
    mainIndex: 'PFTS',
    mainExchange: 'PFTS',
  },
  ar: {
    label: 'Argentina',
    children: ['base'],
    demonym: 'Argentinian',
    mainIndex: 'MERVAL',
    mainExchange: 'BYMA',
  },
  bm: {
    label: 'Bermuda',
    children: ['ber'],
    demonym: 'Bermudian',
    mainIndex: 'BSX',
    mainExchange: 'BSX',
  },
  br: {
    label: 'Brazil',
    children: ['bovespa'],
    demonym: 'Brazilian',
    mainIndex: 'BOVESPA',
    mainExchange: 'B3',
  },
  cl: {
    label: 'Chile',
    children: ['snse'],
    demonym: 'Chilean',
    mainIndex: 'IPSA',
    mainExchange: 'SSE',
  },
  co: {
    label: 'Colombia',
    children: ['bvc'],
    demonym: 'Colombian',
    mainIndex: 'COLCAP',
    mainExchange: 'BVC',
  },
  jm: {
    label: 'Jamaica',
    children: ['jmse'],
    demonym: 'Jamaican',
    mainIndex: 'JSE',
    mainExchange: 'JSE',
  },
  mx: {
    label: 'Mexico',
    children: ['bmv'],
    demonym: 'Mexican',
    mainIndex: 'IPC',
    mainExchange: 'BMV',
  },
  pe: {
    label: 'Peru',
    children: ['bvl'],
    demonym: 'Peruvian',
    mainIndex: 'BVL',
    mainExchange: 'IGBVL',
  },
  tt: {
    label: 'Trinidad & Tobago',
    children: ['ttse'],
    mainIndex: 'TTSE',
    mainExchange: 'TTSE',
  },
  ve: {
    label: 'Venezuela',
    children: ['ccse'],
    demonym: 'Venezuelan',
    mainIndex: 'IBC',
    mainExchange: 'IBVC',
  },
  ca: {
    label: 'Canada',
    children: ['cnsx', 'tsx', 'tsxv'],
    demonym: 'Canadian',
    mainIndex: 'TSX',
    mainExchange: 'TSX',
  },
  us: {
    label: 'United States',
    children: [
      'amex',
      'arca',
      'bats',
      'nasdaqcm',
      'nasdaqgm',
      'nasdaqgs',
      'nyse',
      'otcpk',
    ],
    demonym: 'U.S.',
    mainIndex: 'Dow Jones',
    mainExchange: 'NYSE',
  },
  ch: {
    label: 'Switzerland',
    children: ['swx', 'brse'],
    demonym: 'Swiss',
    mainIndex: 'SMI',
    mainExchange: 'SMI',
  },
} as const;

export type MainIndex = (typeof mainIndex)[number];
export type MainExchange = (typeof mainExchange)[number];
type SecondaryMarketExchange = (typeof secondaryMarket)[number];
export type SecondaryMarket =
  (typeof SECONDARY_MARKETS)[SecondaryMarketExchange];
export type PrimaryMarket = {
  label: string;
  demonym?: string;
  children: SecondaryMarketExchange[];
  mainIndex?: MainIndex;
  mainExchange?: MainExchange;
};

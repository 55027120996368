import styled from 'styled-components';
import { media } from '@simplywallst/ui-core';

import {
  PORTFOLIO_SUMMARY_ANCHOR,
  PORTFOLIO_HOLDINGS_ANCHOR,
  PORTFOLIO_NEWS_ANCHOR,
  PORTFOLIO_RETURNS_ANCHOR,
  PORTFOLIO_DIVERSIFICATION_ANCHOR,
  PORTFOLIO_VALUATION_ANCHOR,
  PORTFOLIO_FUTURE_ANCHOR,
  PORTFOLIO_DIVIDEND_ANCHOR,
  PORTFOLIO_PAST_ANCHOR,
  PORTFOLIO_HEALTH_ANCHOR,
} from '@/constants/anchors';

import type { SubNavRoute } from '@/constants/routes';

const HideLgDown = styled('span')`
  ${media.lgDown`
		display: none;
	`};
`;

export const routes: SubNavRoute[] = [
  {
    anchor: PORTFOLIO_HOLDINGS_ANCHOR,
    label: 'Holdings',
  },
  {
    anchor: PORTFOLIO_SUMMARY_ANCHOR,
    tracking: 'Executive Summary',
    label: (
      <>
        <HideLgDown>Portfolio</HideLgDown> Summary
      </>
    ),
  },
  { anchor: PORTFOLIO_NEWS_ANCHOR, label: 'News' },
  {
    anchor: PORTFOLIO_RETURNS_ANCHOR,
    label: 'Returns',
  },
  {
    anchor: PORTFOLIO_DIVERSIFICATION_ANCHOR,
    label: 'Diversification',
  },
  {
    anchor: PORTFOLIO_VALUATION_ANCHOR,
    label: 'Valuation',
  },
  {
    anchor: PORTFOLIO_FUTURE_ANCHOR,
    tracking: 'Future Growth',
    label: (
      <>
        Future <HideLgDown>Growth</HideLgDown>
      </>
    ),
  },
  {
    anchor: PORTFOLIO_PAST_ANCHOR,
    tracking: 'Past Performance',
    label: (
      <>
        Past <HideLgDown>Performance</HideLgDown>
      </>
    ),
  },
  {
    anchor: PORTFOLIO_HEALTH_ANCHOR,
    tracking: 'Financial Health',
    label: (
      <>
        <HideLgDown>Financial</HideLgDown> Health
      </>
    ),
  },
  {
    anchor: PORTFOLIO_DIVIDEND_ANCHOR,
    label: 'Dividends',
  },
];

import { useEffect } from 'react';
import { HOTJAR_HJID, HOTJAR_HJSV } from '@/constants/env';
import { useDeferredScript } from './useScript';

export function useHotjar(): [
  Window['hj'],
  ReturnType<typeof useDeferredScript>,
] {
  const status = useDeferredScript(
    `https://static.hotjar.com/c/hotjar-${HOTJAR_HJID}.js?sv=${HOTJAR_HJSV}`,
    { crossOrigin: undefined }
  );
  useEffect(() => {
    if (typeof window.hj === 'function') {
      return;
    }

    window._hjSettings = { hjid: HOTJAR_HJID, hjsv: HOTJAR_HJSV };
    window.hj = (...args: unknown[]) => {
      if (typeof window.hj !== 'undefined') {
        (window.hj.q = window.hj.q || []).push(args);
      }
    };
  }, []);

  return [typeof window === 'undefined' ? undefined : window.hj, status];
}

import { getGraphQLClient } from '@simplywallst/services';
import type { TadaDocumentNode } from 'gql.tada';
import { getAuthCookie } from '@utilities/cookies';

import { SWSGQLError, isErrors } from './error';
import { getPreferredLanguage } from './language';
import { captureGqlError } from '../sentry';
import { PUBLIC_RELEASE_VERSION } from '@/constants/env';

interface SWSGQLHeaders {
  'x-sws-pro-api': string;
}

const getHeaders = (headers: Partial<SWSGQLHeaders> = {}) => {
  let preferredLanguage: string | undefined;
  try {
    preferredLanguage = window.navigator.language;
  } catch {
    preferredLanguage = getPreferredLanguage();
  }

  return {
    'apollographql-client-name': RUNTIME_ENV === 'native' ? 'mobile' : 'web',
    ...(typeof preferredLanguage === 'string'
      ? { 'Accept-Language': preferredLanguage }
      : {}),
    ...headers,
  };
};

export const handleError = (error: unknown): never => {
  // Handle non-Error types
  if (!(error instanceof Error)) {
    const message = `Unknown GraphQL Error: ${
      typeof error === 'object' ? JSON.stringify(error) : String(error)
    } (Type: ${typeof error})`;

    throw new Error(message);
  }

  try {
    // Try to parse error as GraphQL error
    const parsed = JSON.parse(JSON.stringify(error));

    if (parsed?.response?.errors && isErrors(parsed.response.errors)) {
      captureGqlError(error);
      throw new SWSGQLError(parsed.response.errors);
    }

    throw error;
  } catch (err: unknown) {
    if (err instanceof SyntaxError) {
      throw new Error(`Error processing GraphQL response: ${err.message}`);
    }

    console.error(err);

    throw err;
  }
};

function makeGQLClient() {
  return {
    request: async <
      Query extends TadaDocumentNode,
      Result = Query extends TadaDocumentNode<infer R> ? R : never,
      Variables = Query extends TadaDocumentNode<Result, infer V> ? V : never,
    >(
      query: TadaDocumentNode<Result, Variables>,
      variables: Variables,
      headers: Partial<SWSGQLHeaders> = {}
    ) => {
      const client = getGraphQLClient({
        headers: getHeaders(headers),
        baseURL:
          typeof SWS_INTERNAL_GQL_URL !== 'undefined'
            ? SWS_INTERNAL_GQL_URL
            : undefined,
        version: PUBLIC_RELEASE_VERSION,
      });

      const auth = await getAuthCookie();
      const accessToken = auth?.accessToken;

      if (accessToken) {
        client.setHeader('Authorization', `Bearer ${accessToken}`);
      }

      const response = await client
        .request<Result & { errors?: [{ message: string }] }, Variables>(
          query,
          variables
        )
        .catch(handleError);

      return response;
    },
  };
}

export const gqlClient = makeGQLClient();

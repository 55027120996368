import { showDeleteHoldingModal, hideDeleteHoldingModal } from './routines';
import type { DeleteHoldingModalState } from './interface';

export const getDefaultState = (): DeleteHoldingModalState => ({
  companyId: '',
  companyName: '',
  portfolioId: '',
  uniqueSymbol: '',
  deleteHoldingModalStatus: 'hidden',
});

export default (
  state = getDefaultState(),
  action: ReducerAction
): DeleteHoldingModalState => {
  if (!action || !action.type) return state;
  switch (action.type) {
    case showDeleteHoldingModal.TRIGGER: {
      const { companyId, portfolioId, uniqueSymbol, companyName } =
        action.payload;
      return {
        companyId,
        companyName,
        portfolioId,
        uniqueSymbol,
        deleteHoldingModalStatus:
          companyId.length > 0 &&
          portfolioId.length > 0 &&
          uniqueSymbol.length > 0
            ? 'visible'
            : 'hidden',
      };
    }
    case hideDeleteHoldingModal.TRIGGER: {
      return getDefaultState();
    }
    default:
      return state;
  }
};

import { del } from "../methods";
function deletePortfolioTransaction(id, headers = {}) {
  return del({
    url: `api/user/portfolio/transaction/${id}`,
    headers
  });
}
export {
  deletePortfolioTransaction as default
};

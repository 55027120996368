import styled from 'styled-components';

export const CompetitorsWrapper = styled.section`
  > h4 {
    ${({ theme }) => theme.typography.xSmall}
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.x1space}rem;
    opacity: 0.7;
  }
`;

import { getAuthCookie, canReadWriteCookies } from '@utilities/cookies';

export async function withAuth<Fn extends (...args: any[]) => any>(
  service: Fn,
  ...params: Parameters<Fn>
): Promise<ReturnType<Fn>> {
  if (!canReadWriteCookies()) throw new Error('Cannot read cookies');
  const auth = await getAuthCookie();
  const accessToken = auth?.accessToken;
  if (!accessToken) throw new Error('Auth cookie is invalid');
  return service(...params, {
    Authorization: `Bearer ${accessToken}`,
  });
}

export interface Affiliate {
  id: string;
  name: string;
  first_name: string;
  last_name: string;
  token: string;
}

export interface Coupon {
  id: string;
  name?: string | null;
  duration: 'forever' | 'once' | 'repeating';
  duration_in_months?: number | null;
  percent_off?: number | null;
  amount_off?: number | null;
  currency?: string | null;
}

interface RewardfulData {
  affiliate: Affiliate | false | undefined;
  coupon: Coupon | false | undefined;
  referral: string | undefined;
}

/**
 * getRewardfulData: Returns the global Rewardful data, which is defined on the Window.
 *
 * Note: this function is intended for use within the @/features/rewardful module only.
 * Application access must use the useRewardfulData hook.
 */
export function getRewardfulData() {
  if (
    typeof window === 'undefined' ||
    typeof window['Rewardful'] === 'undefined'
  ) {
    return undefined;
  }

  return window['Rewardful'] as RewardfulData;
}

import type { InitOptions, ReadCallback, Resource } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import {
  getGlobali18nInstance,
  initTranslations,
} from './utils/initTranslations';
import { ReactNode, useEffect } from 'react';

interface Props {
  locale?: 'en' | 'de' | 'es' | 'ja' | 'ko' | 'fr' | 'it' | 'nl' | 'tr' | 'sv';
  namespace: string[];
  children: ReactNode;
  importFn?: (
    language: string,
    namespace: string,
    callback: ReadCallback
  ) => void;
  resources?: Resource;
  supportedLngs?: string[];
  logLevel?: 'debug' | 'warn' | 'error';
  initOptions?: InitOptions;
  preI18nInstance?: ReturnType<typeof getGlobali18nInstance>;
}

export function I18NextProvider({
  locale = 'en',
  namespace,
  children,
  importFn,
  resources,
  supportedLngs,
  logLevel,
  initOptions,
  preI18nInstance,
}: Props) {
  const i18nInstance = preI18nInstance || getGlobali18nInstance();

  if (!i18nInstance.isInitialized) {
    initTranslations({
      locale,
      namespace,
      importFn,
      resources,
      supportedLngs,
      logLevel,
      initOptions,
    });
  } else {
    i18nInstance.changeLanguage(locale);
  }

  useEffect(() => {
    if (i18nInstance.language !== locale) {
      // Changing language will cause i18next to handle resource loading .etc.
      i18nInstance.changeLanguage(locale);
    }
  }, [i18nInstance, locale]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - FIXME: issues with multiple react versions and provider types - react 18 in deprecated-web, react 19 in news
    <I18nextProvider i18n={i18nInstance} defaultNS={namespace}>
      {children}
    </I18nextProvider>
  );
}

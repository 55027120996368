import type { ComponentType } from 'react';
import { type ComponentProps } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';
import { Provider as ReduxProvider } from 'react-redux';
import track from 'react-tracking';
import { isEmpty } from 'lodash';
import SimplyWallStContainer from './containers/SimplyWallStContainer';
import { Provider as OriginProvider } from '@/context/Origin';
import { Provider as IPCountryProvider } from '@/context/IPCountry';
import { UserAgentProvider } from '@simplywallst/hooks/useUserAgent';
import type { Store } from 'redux';
import type { QueryClient, DehydratedState } from '@tanstack/react-query';
import { HydrationBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { DefaultTheme } from 'styled-components';
import { ThemeProvider } from '@simplywallst/ui-core';
import { IntersectionObserverDisablerProvider } from '@/context/IntersectionObserverDisabler';
import { withProfiler } from '@sentry/react';
import { FlagsProvider } from './features/unleash';
import { SnackbarProvider } from './features/snackbar/context';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { persistQueryClientOptions } from '@/utilities/persistQueryClient';
import { LocaleUrlListener } from '@/components/LocaleUrlListener';
import { LocaleProvider } from '@/context/LocaleProvider';
import { UICoreNextProvider } from '@/context/UICoreNextProvider';
import { AppUpdateBoundaryApp } from './features/unleash/components/AppUpdateBoundary/components/AppUpdateBoundaryApp';
import { Provider as CookieProvider } from './context/CookieProvider';

import '@simplywallst/ui-core/next/tokens.css?preload';
import '@simplywallst/ui-core/next/reset.css?preload';
import './globals.css?preload';

interface Props {
  helmetContext?: Record<string, unknown>;
  store: Store<any>;
  serverState?: Store<any>;
  dehydratedState: DehydratedState;
  queryClient: QueryClient;
  theme: DefaultTheme;
  locationOrigin?: string;
  cfIpCountry?: string;
  ssrUserAgent?: string;
  setReduxSagaContext?(context: Record<string, unknown>): void;
  /**
   * Preloaded flags + variants from unleash.
   * Used to prime unleash flags on serverside render pass.
   */
  flags?: ComponentProps<typeof FlagsProvider>['flags'];
  cookies?: {
    sessionId?: string;
    plansVariant?: string;
    auth?: string;
  };
}

const App = ({
  store,
  serverState,
  queryClient,
  dehydratedState,
  theme,
  locationOrigin = '',
  cfIpCountry,
  ssrUserAgent,
  setReduxSagaContext,
  flags,
  cookies,
}: Props) => (
  <ReduxProvider store={store} serverState={serverState}>
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={persistQueryClientOptions}
    >
      <HydrationBoundary state={dehydratedState}>
        <UserAgentProvider ua={ssrUserAgent}>
          <OriginProvider location={locationOrigin}>
            <IPCountryProvider iso={cfIpCountry}>
              <FlagsProvider
                sessionId={cookies?.sessionId}
                flags={flags}
                isAnonymous={!cookies?.auth}
              >
                <UICoreNextProvider>
                  <StyleSheetManager
                    enableVendorPrefixes
                    shouldForwardProp={isPropValid}
                  >
                    <ThemeProvider theme={theme}>
                      <LocaleProvider>
                        <LocaleUrlListener />
                        <SnackbarProvider
                          setReduxSagaContext={setReduxSagaContext}
                        >
                          {/**
                           * TODO this is probably the wrong place to place this provider.
                           * Perf-wise it could potentially trigger an app wide rerender.
                           */}
                          <IntersectionObserverDisablerProvider enabled>
                            <AppUpdateBoundaryApp>
                              <CookieProvider cookies={cookies}>
                                <SimplyWallStContainer />
                              </CookieProvider>
                            </AppUpdateBoundaryApp>
                          </IntersectionObserverDisablerProvider>
                        </SnackbarProvider>
                      </LocaleProvider>
                    </ThemeProvider>
                  </StyleSheetManager>
                </UICoreNextProvider>
              </FlagsProvider>
            </IPCountryProvider>
          </OriginProvider>
        </UserAgentProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </HydrationBoundary>
    </PersistQueryClientProvider>
  </ReduxProvider>
);

const AppWithTracking = track(
  {},
  {
    dispatch: (event) => {
      const typedWindow = window as any;
      if (
        RUNTIME_ENV !== 'server' &&
        typedWindow.analytics &&
        !isEmpty(event)
      ) {
        typedWindow.analytics.dispatch(event);
      }
    },
  }
)(App) as ComponentType<Props>;

const AppWithProfiling = withProfiler(AppWithTracking, {
  name: 'mono',
  includeRender: true,
  includeUpdates: true,
});

export default AppWithProfiling;

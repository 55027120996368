import { createRoutine } from 'redux-saga-routines';

export interface ShowAddRemoveCompanyTrigger {
  companyId: string;
  companyName: string;
  tickerSymbol: string;
  uniqueSymbol?: string;
}

export const showAddRemoveCompany = createRoutine<
  SimplePayloadCreator<ShowAddRemoveCompanyTrigger>, // trigger
  SimplePayloadCreator, // request
  SimplePayloadCreator, //success
  SimplePayloadCreator, // failure
  SimplePayloadCreator // fulfill
>('@ADD_REMOVE_COMPANY/SHOW', {});

export const hideAddRemoveCompany = createRoutine<
  SimplePayloadCreator, // trigger
  SimplePayloadCreator, // request
  SimplePayloadCreator, //success
  SimplePayloadCreator, // failure
  SimplePayloadCreator // fulfill
>('@ADD_REMOVE_COMPANY/HIDE', {});

export interface AddCompanyTrigger {
  portfolioId: string;
  uniqueSymbol?: string;
  companyId?: string;
  fetchPortfolioOnSuccess?: boolean;
}

export interface AddCompanySuccess {
  companyId?: string;
}

export interface AddCompanyFulfill {
  companyId?: string;
  id: string;
}

export interface AddCompanyFailure {
  companyId?: string;
  message: string;
}

export const addCompany = createRoutine<
  SimplePayloadCreator<AddCompanyTrigger>, // trigger
  SimplePayloadCreator, // request
  SimplePayloadCreator<AddCompanySuccess>, //success
  SimplePayloadCreator<AddCompanyFailure>, // failure
  SimplePayloadCreator<AddCompanyFulfill> // fulfill
>('@ADD_REMOVE_COMPANY/ADD', {});

export interface RemoveCompanyTrigger {
  companyId: string;
  portfolioId: string;
  uniqueSymbol?: string;
}

export interface RemoveCompanySuccess {
  companyId: string;
}

export interface RemoveCompanyFailure {
  companyId: string;
  message: string;
}

export const removeCompany = createRoutine<
  SimplePayloadCreator<RemoveCompanyTrigger>, // trigger
  SimplePayloadCreator, // request
  SimplePayloadCreator<RemoveCompanySuccess>, //success
  SimplePayloadCreator<RemoveCompanyFailure>, // failure
  SimplePayloadCreator // fulfill
>('@ADD_REMOVE_COMPANY/REMOVE', {});

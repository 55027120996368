import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { Preferences } from '@capacitor/preferences';

/**
 *
 * Async storage persister using Capacitor Storage.
 *
 */
export const persister = createAsyncStoragePersister({
  storage: {
    async getItem(key: string) {
      const localState = await Preferences.get({ key });
      return localState.value;
    },
    async setItem(key: string, value: string) {
      Preferences.set({ key, value });
    },
    async removeItem(key: string) {
      Preferences.remove({ key });
    },
  },
});

import type { CompanyReportSection } from '@/pages/CompanyReport/useSection';
import { SUPPORTED_LOCALES, type Locale } from '@/utils/locale';
import { SWS_HOST } from '@/constants';

const createHref = (
  canonicalURL: string,
  section: CompanyReportSection,
  locale: Locale
) =>
  `${SWS_HOST}${locale === 'en' ? '' : '/' + locale}${canonicalURL}${
    section === 'summary' ? '' : '/' + section
  }`;

export const getCanonical = createHref;

// Potentially a future issue, but supported locales used to create all alternates does not include `en` by default
const LOCALES = ['en' as const, ...SUPPORTED_LOCALES];

export const getAlternates = (
  canonicalURL: string,
  section: CompanyReportSection
) => {
  const alternates: Array<{ lang: 'x-default' | Locale; href: string }> = [
    // Default alternate link should be the `en` locale, which is the base canonical URL.
    {
      lang: 'x-default',
      href: createHref(canonicalURL, section, 'en'),
    },
  ];

  for (const l of LOCALES) {
    alternates.push({
      lang: l,
      href: createHref(canonicalURL, section, l),
    });
  }

  return alternates;
};

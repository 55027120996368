import { createSelector } from 'reselect';
import type { State } from '../interface';
import { getCompanyId } from './utils';
import { getDefault } from '../reducers/management';
export type SelectorReturn = State['management'][''];
export interface SelectorProps {
  companyId?: string;
}
const getManagementState = (state, props: SelectorProps) => {
  const companyId = getCompanyId(state, props);
  const company = state.company as State;
  if (company.management[companyId]) {
    return company.management[companyId];
  }
  return null;
};

export const getManagement = createSelector(
  [getManagementState],
  (management): SelectorReturn => {
    if (management === null) return getDefault();
    return management;
  }
);

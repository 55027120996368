import styled, { css } from 'styled-components';
import { media } from '@simplywallst/ui-core';

import { Button, Row, Column } from '@simplywallst/ui-core';
import ReportSection from '@components/ReportSection';

export const SummaryReportSection = styled(ReportSection)`
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
`;

const H4 = css`
  ${({ theme }) => theme.typography.xSmall}
  text-transform: uppercase;
  opacity: 0.7;
`;

export const SnowflakeWrapper = styled(Column)`
  margin-bottom: ${({ theme }) => theme.x2spacePx}px;
  p {
    ${({ theme }) => theme.typography.xSmall}
    color: ${({ theme }) => theme.color.mediumGray};
  }
  h4 {
    margin-top: ${({ theme }) => theme.x2spacePx}px;
    margin-bottom: ${({ theme }) => theme.x1spacePx}px;
  }
  ${media.lgUp`
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
    h4 {
      margin-top: 0;
    }
	`};
`;

export const ColumnLeft = styled(Column)`
  > p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${media.lgUp`
		padding-right: ${({ theme }) => theme.x4spacePx}px;
  `};
`;

export const ShowMoreLink = styled(Button)`
  cursor: pointer;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.whiteRGBA(0.5)};
  padding-bottom: 2px;
  &:hover {
    box-shadow: inset 0px -1px 0 0 ${({ theme }) => theme.color.whiteRGBA(0.8)};
  }
`;

export const SummaryHeading = styled('header')`
  ${({ theme }) => theme.typography.xLargeStrong};
  color: ${({ theme }) => theme.color.spaceGray};
  padding-bottom: ${({ theme }) => theme.x2spacePx}px;
`;

export const RiskRewardWrapper = styled(ColumnLeft)`
  h4 {
    margin-top: ${({ theme }) => theme.x2spacePx}px;
    margin-bottom: ${({ theme }) => theme.x1spacePx}px;
  }
  ul {
    list-style: disc;
    padding: 0 ${({ theme }) => theme.x3spacePx}px 0
      ${({ theme }) => (theme.x3space - 0.4) * 10}px;
    margin-bottom: 0;
    > li {
      margin-bottom: ${({ theme }) => theme.x1spacePx / 2}px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ${media.lgUp`
		min-height: 260px;
    h4 {
      margin-top: ${({ theme }) => theme.x4spacePx}px;
    }
	`};
`;

export const SummaryWrapper = styled(Row)`
  position: relative;
  min-height: 380px;
  h4 {
    ${H4}
  }
`;

export const SnowflakeChart = styled('div')`
  max-width: 320px;
  margin: 0 auto;
`;

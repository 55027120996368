import { useEffect } from 'react';
import { useLazyScript } from './useLazyScript';
import type { Options } from './useLazyScript';

interface ScriptOptions extends Options {
  enabled?: boolean;
}

export function useScript(
  src: HTMLScriptElement['src'],
  options: ScriptOptions = { enabled: true }
) {
  const [load, status, cleanup] = useLazyScript(src, options);
  const { enabled = true } = options;

  useEffect(() => {
    const loadAsync = async () => {
      try {
        await load();
      } catch (err) {
        console.error(err);
      }
    };

    if (enabled) {
      loadAsync();
    }

    return cleanup;
  }, [load, cleanup, enabled]);

  return status;
}

import { useQuery } from '@tanstack/react-query';

import { persistedQueryConfig } from '@/utilities/persistQueryClient/utilities';

import selector from './selector';
import queryFn from './queryFn';

export const QUERY_KEY = 'DASHBOARD_RECENTLY_VIEWED' as const;

type Args = {
  enabled?: boolean;
};

export default function useRecentlyViewedCompanies(
  { enabled }: Args = { enabled: true }
) {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn,
    select: selector,
    enabled,
    ...persistedQueryConfig,
  });
}

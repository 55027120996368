/* eslint-disable no-restricted-imports */
import {
  toPercentage as _toPercentage,
  toSignificant as _toSignificant,
  toCurrency as _toCurrency,
  toTruncated as _toTruncated,
  toCurrencyTruncated as _toCurrencyTruncated,
  toFactor as _toFactor,
  separateByThreeDigit as _separateByThreeDigit,
} from '@simplywallst/formatting';
import { memoize } from 'lodash';

export * from '@simplywallst/formatting';

const cacheKeyResolver = (...args: (string | number | undefined)[]) =>
  args.join('~');

export const toPercentage = memoize(_toPercentage, cacheKeyResolver);
export const toSignificant = memoize(_toSignificant, cacheKeyResolver);
export const toCurrency = memoize(_toCurrency, cacheKeyResolver);
export const toTruncated = memoize(_toTruncated, cacheKeyResolver);
export const toCurrencyTruncated = memoize(
  _toCurrencyTruncated,
  cacheKeyResolver
);
export const toFactor = memoize(_toFactor, cacheKeyResolver);
export const separateByThreeDigit = memoize(
  _separateByThreeDigit,
  cacheKeyResolver
);

import axios from 'axios';
import { useMemo } from 'react';

type SWSLoginError = {
  error: string;
  error_description: string;
};

/**
 *
 * @todo: relocate to shared directory
 *
 */
export function isSWSError(
  errorResponse: unknown
): errorResponse is SWSLoginError {
  try {
    if (typeof errorResponse === 'object' && errorResponse) {
      return 'error' in errorResponse && 'error_description' in errorResponse;
    }
    return false;
  } catch {
    return false;
  }
}

type SWSValidationError = {
  errors: {
    status: number;
    source: { parameter: string };
    title: string;
    detail: string;
  }[];
};

/**
 *
 * @todo: relocate to shared directory
 *
 */
export function isValidationError(
  errorResponse: unknown
): errorResponse is SWSValidationError {
  if (typeof errorResponse === 'object' && errorResponse !== null) {
    if ('errors' in errorResponse) {
      if (errorResponse.errors instanceof Array) {
        try {
          const [error] = errorResponse.errors;
          return 'status' in error && 'title' in error && 'detail' in error;
        } catch {
          return false;
        }
      }
    }
  }
  return false;
}

export function useAuthErrorMessage(error: unknown) {
  const errorState = useMemo(() => {
    if (axios.isAxiosError(error) && typeof error.response !== 'undefined') {
      if (isSWSError(error.response.data)) {
        const { data: swsError } = error.response;
        if (swsError.error === 'invalid_grant') {
          return {
            type: 'generic',
            message: swsError.error_description,
          } as const;
        }
        if (swsError.error === 'invalid_request') {
          let message = swsError.error_description;
          switch (error.response.data.error_description.toLowerCase()) {
            case 'user account already exists: facebook':
              message = 'Account is accessible via Facebook sign in';
              break;
            case 'user account already exists: google':
              message = 'Account is accessible via Google sign in';
              break;
            case 'user account already exists: apple':
              message = 'Account is accessible via Apple sign in';
              break;
            case 'user account already exists: local':
              message = 'Account is accessible via Email sign in';
              break;
            case 'no new sign ups for auth provider: facebook':
              message =
                'Facebook user does not exist. Click "Sign up" to become a member';
              break;
          }
          return {
            type: 'generic',
            message,
          } as const;
        }
      }

      if (isValidationError(error.response.data)) {
        const [verificationError] = error.response.data.errors;
        const title = verificationError.title.toLowerCase();
        if (
          verificationError.status === 409 &&
          (title === 'unverified account' ||
            title === 'unverified registration')
        ) {
          return {
            type: 'pending',
            message:
              'Unverified account. Please verify your account by following instructions sent to your email.',
          } as const;
        }

        if (verificationError.status === 409 && title === 'stale account') {
          return {
            type: 'stale',
            message:
              'It appears that your account has become stale. Please recreate your account.',
          } as const;
        }
        return {
          type: 'generic',
          message: verificationError.detail,
        } as const;
      }
    }
    // catch-all error
    return {
      type: 'generic',
      message: 'An unknown error has occurred',
    } as const;
  }, [error]);
  return errorState;
}

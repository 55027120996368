import { takeLatest, put } from 'redux-saga/effects';
import { fetchDashboardMyMarketsAndIndustries } from './routines';
import { getDashboardMyMarketsAndIndustries as getDashboardMyMarketsAndIndustriesService } from '@simplywallst/services';
import { getAuthenticatedAhoy } from '@ducks/saga';
type MyMarketsAndIndustriesResponse = Awaited<
  ReturnType<typeof getDashboardMyMarketsAndIndustriesService>
>['data'];
function* fetchDashboardMyMarketsAndIndustriesWorker() {
  try {
    const caller = getAuthenticatedAhoy();
    const myMarketsAndIndustries: MyMarketsAndIndustriesResponse = yield caller(
      getDashboardMyMarketsAndIndustriesService
    );
    yield put(
      fetchDashboardMyMarketsAndIndustries.success({
        data: myMarketsAndIndustries.data,
      })
    );
  } catch (e: any) {
    yield put(
      fetchDashboardMyMarketsAndIndustries.failure({
        message: e.message || e.errors || 'Error fetching dashboard events',
      })
    );
  } finally {
    yield put(fetchDashboardMyMarketsAndIndustries.fulfill());
  }
}

function* watchFetchDashboardMyMarketsAndIndustriesWorker() {
  yield takeLatest(
    fetchDashboardMyMarketsAndIndustries.TRIGGER,
    fetchDashboardMyMarketsAndIndustriesWorker
  );
}

const watchers = [watchFetchDashboardMyMarketsAndIndustriesWorker];
export default watchers;

import { getCompaniesIdFromSymbols } from './utils';
import type { State } from '../interface';
import { createSelector } from 'reselect';

const getSummaryState = (state) => {
  const company = state.company as State;
  return company.summary;
};

export const getCompanyList = createSelector(
  [getCompaniesIdFromSymbols, getSummaryState],
  (companyIds, summaryState) => {
    return companyIds.map((id) => summaryState[id]);
  }
);

export const makeCompanyListSelector = () => getCompanyList;

export function pluralise(
  value: undefined | null | number,
  singular: string,
  plural: string
) {
  return value === null ||
    typeof value === 'undefined' ||
    value === 0 ||
    value > 1
    ? plural
    : singular;
}

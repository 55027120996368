import { createRoutine } from 'redux-saga-routines';

export interface TriggerPayload {
  payload: {
    symbols: string;
  };
}

export const fetchCompanies = createRoutine<
  SimplePayloadCreator<TriggerPayload>,
  SimplePayloadCreator,
  SimplePayloadCreator,
  SimplePayloadCreator,
  SimplePayloadCreator
>('@/pages/CompanyReport/fetchCompanies', {});

export const preFetchCompany = createRoutine<
  SimplePayloadCreator<{ id?: string }>,
  SimplePayloadCreator,
  SimplePayloadCreator,
  SimplePayloadCreator,
  SimplePayloadCreator
>('@/pages/CompanyReport/prefetch', {});

import { get } from "../methods";
function getPortfolio(id, secret, refresh, headers = {}) {
  return get({
    url: `api/portfolio/analysis/${id}`,
    headers,
    payload: {
      secret,
      include: "holdings.score.snowflake,items,score.snowflake,excluded_companies.score.snowflake,missing_companies,chart_data",
      refresh
    }
  });
}
export {
  getPortfolio as default
};

import { useCallback } from 'react';
import { ErrorBoundary } from '@sentry/react';
import type { FallbackRender } from '@sentry/react';
import loadable from '@loadable/component';
import type { SentryCaptureOptions } from './types';
import { sentryCaptureMessage } from './utils';

const Oops = loadable(() => import('@/components/Oops'));
const OutdatedApp = loadable(() => import('@/components/Oops/OutdatedApp'), {
  resolveComponent: (component) => component.OutdatedApp,
});

function errorHasNameProp(error: unknown): error is { name: string } {
  return error !== null && typeof error === 'object' && 'name' in error;
}
function errorHasMessageProp(error: unknown): error is { message: string } {
  return error !== null && typeof error === 'object' && 'message' in error;
}
interface Props {
  sentryCapture?: SentryCaptureOptions;
  customCopy?: React.ReactElement;
  globalDisabled?: boolean;
  isShownLogo?: boolean;
  isShownHeading?: boolean;
  children: React.ReactNode;
}
export function Sentry({
  customCopy,
  globalDisabled,
  sentryCapture,
  isShownLogo,
  isShownHeading,
  children,
}: Props) {
  const fallbackHandler = useCallback<FallbackRender>(
    ({ error, resetError }) => {
      if (
        errorHasNameProp(error) &&
        error.name.toLowerCase().includes('chunkloaderror')
      ) {
        return <OutdatedApp />;
      }
      if (sentryCapture) {
        // Capture a custom message with additional data for Dingoes - main owner of reports
        const { team, extra } = sentryCapture;
        sentryCaptureMessage({
          team,
          message: errorHasMessageProp(error)
            ? error.message
            : 'Error object has no message',
          extra,
        });
      }
      return (
        <Oops
          team={sentryCapture?.team}
          customCopy={customCopy}
          globalDisabled={globalDisabled}
          resetError={resetError}
          isShownLogo={isShownLogo}
          isShownHeading={isShownHeading}
        />
      );
    },
    [customCopy, globalDisabled, isShownLogo, isShownHeading, sentryCapture]
  );
  return <ErrorBoundary fallback={fallbackHandler}>{children}</ErrorBoundary>;
}

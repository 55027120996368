import type { FormatDistanceToken } from 'date-fns';
import type { Locale } from '@/hooks/useLocale';

export type FormatDistanceLocaleShort = Record<FormatDistanceToken, string>;

// TODO: Verify translations are correct
export const formatDistanceLocaleShort = new Map<
  Locale,
  FormatDistanceLocaleShort
>([
  [
    'en',
    {
      lessThanXSeconds: 'Updated {{count}}s ago',
      xSeconds: 'Updated {{count}}s ago',
      halfAMinute: 'Updated 30s ago',
      lessThanXMinutes: 'Updated {{count}}m ago',
      xMinutes: 'Updated {{count}}m ago',
      aboutXHours: 'Updated {{count}}h ago',
      xHours: 'Updated {{count}}h ago',
      xDays: 'Updated {{count}}d ago',
      aboutXWeeks: 'Updated {{count}}w ago',
      xWeeks: 'Updated {{count}}w ago',
      aboutXMonths: 'Updated {{count}}m ago',
      xMonths: 'Updated {{count}}m ago',
      aboutXYears: 'Updated {{count}}y ago',
      xYears: 'Updated {{count}}y ago',
      overXYears: 'Updated {{count}}y ago',
      almostXYears: 'Updated {{count}}y ago',
    },
  ],
  [
    'es',
    {
      lessThanXSeconds: 'Actualizado hace {{count}}s',
      xSeconds: 'Actualizado hace {{count}}s',
      halfAMinute: 'Actualizado hace 30s',
      lessThanXMinutes: 'Actualizado hace {{count}}m',
      xMinutes: 'Actualizado hace {{count}}m',
      aboutXHours: 'Actualizado hace {{count}}h',
      xHours: 'Actualizado hace {{count}}h',
      xDays: 'Actualizado hace {{count}}d',
      aboutXWeeks: 'Actualizado hace {{count}}sem',
      xWeeks: 'Actualizado hace {{count}}sem',
      aboutXMonths: 'Actualizado hace {{count}}m',
      xMonths: 'Actualizado hace {{count}}m',
      aboutXYears: 'Actualizado hace {{count}}a',
      xYears: 'Actualizado hace {{count}}a',
      overXYears: 'Actualizado hace {{count}}a',
      almostXYears: 'Actualizado hace {{count}}a',
    },
  ],
  [
    'de',
    {
      lessThanXSeconds: 'Vor {{count}}s aktualisiert',
      xSeconds: 'Vor {{count}}s aktualisiert',
      halfAMinute: 'Vor 30s aktualisiert',
      lessThanXMinutes: 'Vor {{count}}m aktualisiert',
      xMinutes: 'Vor {{count}}m aktualisiert',
      aboutXHours: 'Vor {{count}}h aktualisiert',
      xHours: 'Vor {{count}}h aktualisiert',
      xDays: 'Vor {{count}}Tg aktualisiert', // Mario gab ihm den Daumen hoch
      aboutXWeeks: 'Vor {{count}}w aktualisiert',
      xWeeks: 'Vor {{count}}w aktualisiert',
      aboutXMonths: 'Vor {{count}}m aktualisiert',
      xMonths: 'Vor {{count}}m aktualisiert',
      aboutXYears: 'Vor {{count}}j aktualisiert',
      xYears: 'Vor {{count}}j aktualisiert',
      overXYears: 'Vor {{count}}j aktualisiert',
      almostXYears: 'Vor {{count}}j aktualisiert',
    },
  ],
  [
    'ja',
    {
      lessThanXSeconds: '{{count}}秒前に更新',
      xSeconds: '{{count}}秒前に更新',
      halfAMinute: '30秒前に更新',
      lessThanXMinutes: '{{count}}分前に更新',
      xMinutes: '{{count}}分前に更新',
      aboutXHours: '{{count}}時間前に更新',
      xHours: '{{count}}時間前に更新',
      xDays: '{{count}}日前に更新',
      aboutXWeeks: '{{count}}週間前に更新',
      xWeeks: '{{count}}週間前に更新',
      aboutXMonths: '{{count}}ヶ月前に更新',
      xMonths: '{{count}}ヶ月前に更新',
      aboutXYears: '{{count}}年前に更新',
      xYears: '{{count}}年前に更新',
      overXYears: '{{count}}年前に更新',
      almostXYears: '{{count}}年前に更新',
    },
  ],
  [
    'ko',
    {
      lessThanXSeconds: '{{count}}초 전 업데이트',
      xSeconds: '{{count}}초 전 업데이트',
      halfAMinute: '30초 전 업데이트',
      lessThanXMinutes: '{{count}}분 전 업데이트',
      xMinutes: '{{count}}분 전 업데이트',
      aboutXHours: '{{count}}시간 전 업데이트',
      xHours: '{{count}}시간 전 업데이트',
      xDays: '{{count}}일 전 업데이트',
      aboutXWeeks: '{{count}}주 전 업데이트',
      xWeeks: '{{count}}주 전 업데이트',
      aboutXMonths: '{{count}}개월 전 업데이트',
      xMonths: '{{count}}개월 전 업데이트',
      aboutXYears: '{{count}}년 전 업데이트',
      xYears: '{{count}}년 전 업데이트',
      overXYears: '{{count}}년 전 업데이트',
      almostXYears: '{{count}}년 전 업데이트',
    },
  ],
  [
    'fr',
    {
      lessThanXSeconds: 'Mis à jour il y a {{count}}s',
      xSeconds: 'Mis à jour il y a {{count}}s',
      halfAMinute: 'Mis à jour il y a 30s',
      lessThanXMinutes: 'Mis à jour il y a {{count}}m',
      xMinutes: 'Mis à jour il y a {{count}}m',
      aboutXHours: 'Mis à jour il y a {{count}}h',
      xHours: 'Mis à jour il y a {{count}}h',
      xDays: 'Mis à jour il y a {{count}}j',
      aboutXWeeks: 'Mis à jour il y a {{count}}sem',
      xWeeks: 'Mis à jour il y a {{count}}sem',
      aboutXMonths: 'Mis à jour il y a {{count}}m',
      xMonths: 'Mis à jour il y a {{count}}m',
      aboutXYears: 'Mis à jour il y a {{count}}a',
      xYears: 'Mis à jour il y a {{count}}a',
      overXYears: 'Mis à jour il y a {{count}}a',
      almostXYears: 'Mis à jour il y a {{count}}a',
    },
  ],
  [
    'it',
    {
      lessThanXSeconds: 'Aggiornato {{count}}s fa',
      xSeconds: 'Aggiornato {{count}}s fa',
      halfAMinute: 'Aggiornato 30s fa',
      lessThanXMinutes: 'Aggiornato {{count}}m fa',
      xMinutes: 'Aggiornato {{count}}m fa',
      aboutXHours: 'Aggiornato {{count}}h fa',
      xHours: 'Aggiornato {{count}}h fa',
      xDays: 'Aggiornato {{count}}g fa',
      aboutXWeeks: 'Aggiornato {{count}}set fa',
      xWeeks: 'Aggiornato {{count}}set fa',
      aboutXMonths: 'Aggiornato {{count}}m fa',
      xMonths: 'Aggiornato {{count}}m fa',
      aboutXYears: 'Aggiornato {{count}}a fa',
      xYears: 'Aggiornato {{count}}a fa',
      overXYears: 'Aggiornato {{count}}a fa',
      almostXYears: 'Aggiornato {{count}}a fa',
    },
  ],
  [
    'nl',
    {
      lessThanXSeconds: '{{count}}s geleden bijgewerkt',
      xSeconds: '{{count}}s geleden bijgewerkt',
      halfAMinute: '30s geleden bijgewerkt',
      lessThanXMinutes: '{{count}}m geleden bijgewerkt',
      xMinutes: '{{count}}m geleden bijgewerkt',
      aboutXHours: '{{count}}u geleden bijgewerkt',
      xHours: '{{count}}u geleden bijgewerkt',
      xDays: '{{count}}d geleden bijgewerkt',
      aboutXWeeks: '{{count}}w geleden bijgewerkt',
      xWeeks: '{{count}}w geleden bijgewerkt',
      aboutXMonths: '{{count}}m geleden bijgewerkt',
      xMonths: '{{count}}m geleden bijgewerkt',
      aboutXYears: '{{count}}j geleden bijgewerkt',
      xYears: '{{count}}j geleden bijgewerkt',
      overXYears: '{{count}}j geleden bijgewerkt',
      almostXYears: '{{count}}j geleden bijgewerkt',
    },
  ],
  [
    'tr',
    {
      lessThanXSeconds: '{{count}}sn önce güncellendi',
      xSeconds: '{{count}}sn önce güncellendi',
      halfAMinute: '30sn önce güncellendi',
      lessThanXMinutes: '{{count}}dk önce güncellendi',
      xMinutes: '{{count}}dk önce güncellendi',
      aboutXHours: '{{count}}sa önce güncellendi',
      xHours: '{{count}}sa önce güncellendi',
      xDays: '{{count}}g önce güncellendi',
      aboutXWeeks: '{{count}}h önce güncellendi',
      xWeeks: '{{count}}h önce güncellendi',
      aboutXMonths: '{{count}}ay önce güncellendi',
      xMonths: '{{count}}ay önce güncellendi',
      aboutXYears: '{{count}}y önce güncellendi',
      xYears: '{{count}}y önce güncellendi',
      overXYears: '{{count}}y önce güncellendi',
      almostXYears: '{{count}}y önce güncellendi',
    },
  ],
]);

import type { State as CompanyState } from '@/pages/CompanyReport/redux/interface';
import type { LocationState } from 'redux-first-router';
import { shallowEqual, useSelector } from 'react-redux';
import { toCurrencyTruncated } from '@/utilities/formatting';
import { pathConstructor } from './utils';
type Selected =
  | { status: 'loading' }
  | {
      status: 'success';
      marketCapListing: number;
      formattedMarketCap: string;
    };

function selector(state: {
  location: LocationState;
  company: CompanyState;
}): Selected {
  const path = pathConstructor(state.location);
  const companyId = state.company.urlToIdMapping[path];
  if (companyId === '' || typeof companyId === 'undefined')
    return { status: 'loading' };
  const analysis = state.company.analysis[companyId];
  const formatting = state.company.formatting[companyId];
  try {
    return {
      status: 'success',
      marketCapListing: analysis.marketCapRawData.listing,
      formattedMarketCap: toCurrencyTruncated(
        analysis.marketCapRawData.listing,
        1,
        formatting.tradingItemCurrencyISO
      ),
    };
  } catch {
    return { status: 'loading' };
  }
}

export function useAnalysis() {
  const selected = useSelector<
    Parameters<typeof selector>[0],
    ReturnType<typeof selector>
  >(selector, shallowEqual);
  return selected;
}

/**
 * Get the current light / dark theme setting from query params.
 *
 * TODO: move to theme switching logic - temporary test flag for initial theme work.
 */
export const getThemeQuery = () => {
  let theme: 'light' | 'light-v1' | 'dark' | undefined = undefined;
  try {
    const themeQuery = new URLSearchParams(window?.location?.search).get(
      '_theme'
    );
    if (themeQuery === 'light') theme = 'light-v1';
    if (themeQuery === 'dark') theme = 'dark';
  } catch (error) {
    // Ignore errors as this is a temporary setup
    console.error(error);
  }
  return theme;
};

/**
 * Get the current theme for CSS
 *
 * TODO: move to theme switching logic - temporary test flag for initial theme work.
 */
export const getTheme = () => {
  try {
    if (window?.document?.body !== undefined) {
      return window.document.body.dataset.theme;
    }
  } catch (error) {
    // Ignore errors as this is a temporary setup
    console.error(error);
  }
  return;
};

/**
 * Set's the current theme for CSS
 *
 * TODO: move to theme switching logic - temporary test flag for initial theme work.
 */
export const setTheme = (theme: 'light' | 'light-v1' | 'dark') => {
  try {
    if (window?.document?.body !== undefined && theme !== 'light') {
      window.document.body.dataset.theme =
        theme === 'light-v1' ? 'light' : 'dark';
    }
  } catch (error) {
    // Ignore errors as this is a temporary setup
    console.error(error);
  }
  return;
};

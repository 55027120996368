import { useFlag } from '@/features/unleash';
import type { ReactNode } from 'react';
import loadable from '@loadable/component';

const AppUpdateBoundaryAppFallback = loadable(
  () => import('./AppUpdateBoundaryAppFallback'),
  {
    resolveComponent: (c) => c.AppUpdateBoundaryAppFallback,
  }
);

type Props = {
  children: ReactNode;
};

export const AppUpdateBoundaryApp = ({ children }: Props) => {
  const { enabled } = useFlag({ name: 'mono-force-update-app' });

  if (RUNTIME_ENV === 'native' && enabled) {
    return <AppUpdateBoundaryAppFallback />;
  }

  return children;
};

import type { State as CompanyState } from '@/pages/CompanyReport/redux/interface';
import type { LocationState } from 'redux-first-router';
import { shallowEqual, useSelector } from 'react-redux';
import { pathConstructor } from './utils';
import { ROUTE_COMPANY_WITH_EVENTS } from '@/constants/routes';
import type { getCompany } from '@simplywallst/services';
type Company = Awaited<ReturnType<typeof getCompany>>['data']['data'];
export type SummarySelected =
  | { status: 'loading'; canonicalUrl: string }
  | {
      status: 'success';
      companyId: string;
      uniqueSymbol: string;
      tickerSymbol: string;
      canonicalUrl: string;
      classificationStatus: Company['classification_status'];
      name: string;
      coverImage: string;
      score: number[];
      shortName?: string;
      allowCrawlers: boolean;
      website: string | undefined;
      hasLogo: boolean;
    };

function selector(state: {
  location: LocationState;
  company: CompanyState;
}): SummarySelected {
  const path = pathConstructor(state.location);
  const companyId = state.company.urlToIdMapping[path];
  if (companyId === '' || typeof companyId === 'undefined')
    return { status: 'loading', canonicalUrl: path };
  const summary = state.company.summary[companyId];

  return {
    status: 'success',
    companyId,
    uniqueSymbol: summary.uniqueSymbol,
    tickerSymbol: summary.tickerSymbol,
    canonicalUrl: summary.canonicalUrl,
    classificationStatus: summary.classificationStatus,
    name: summary.shortName,
    coverImage: summary.mainHeader,
    score: summary.snowflakePoints,
    shortName: summary.shortName,
    allowCrawlers:
      state.location.type === ROUTE_COMPANY_WITH_EVENTS
        ? false
        : summary.isSearchable && summary.isFund === false,
    website: summary.website,
    hasLogo: summary.hasLogo,
  };
}

export function useSummary() {
  const selected = useSelector<
    Parameters<typeof selector>[0],
    ReturnType<typeof selector>
  >(selector, shallowEqual);
  return selected;
}

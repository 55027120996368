import { setContext, all } from 'redux-saga/effects';
import { exportableSagas as industryAverageSagas } from '@components/IndustryAverages/redux/action';
import { exportableSagas as portfolioSagas } from '@/components/DeprecatedPortfolio/redux/sagas';
import { exportableSagas as addRemoveCompanySagas } from '@/components/AddRemoveCompany/redux';
import { exportableSagas as portfoliosSagas } from '@/components/DeprecatedPortfolios/redux/sagas';
import { exportableSagas as sharedSagas } from '@ducks/shared';
// todo: rename the export
import { watchers as averagesSagas } from '@ducks/averages/sagas';
import { watchers as companySagas } from '@/pages/CompanyReport/redux/sagas';
import { watchers as dashboardSagas } from '@components/Dashboard/redux';

export interface AppSagaContext {
  analytics: any;
}

const sagaMapper = (saga) => saga();

const appRootSaga = (context: AppSagaContext) => {
  return function* rootSaga() {
    try {
      yield setContext(context);
      yield all([
        ...companySagas.map(sagaMapper),
        ...industryAverageSagas.map(sagaMapper),
        ...portfolioSagas.map(sagaMapper),
        ...portfoliosSagas.map(sagaMapper),
        ...addRemoveCompanySagas.map(sagaMapper),
        ...averagesSagas.map(sagaMapper),
        ...sharedSagas.map(sagaMapper),
        ...dashboardSagas.map(sagaMapper),
      ]);
    } catch (error: any) {
      console.error('Error caught during appRootSaga', {
        message: error.message,
        stack: error.stack,
      });
      throw error;
    }
  };
};

export default appRootSaga;

import { useCallback } from 'react';

/**
 * Safe access to localStorage specifically, which is not supported on Capacitor.
 * Note: there is also usePersistentStorage, which (ideally - not yet) handles storage mechanism switching.
 */
export const useLocalStorage = () => {
  const setItem = useCallback((key: string, value: string) => {
    try {
      window.localStorage.setItem(key, value);
    } catch {
      console.warn('Unable to set item in storage');
    }
  }, []);

  const getItem = useCallback((key: string) => {
    try {
      return window.localStorage.getItem(key);
    } catch {
      return null;
    }
  }, []);

  const removeItem = useCallback((key: string) => {
    try {
      window.localStorage.removeItem(key);
    } catch {
      // silently fail
    }
  }, []);

  return { getItem, setItem, removeItem };
};

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

export const persister = createSyncStoragePersister({
  storage:
    RUNTIME_ENV === 'browser'
      ? window.localStorage
      : {
          getItem: () => null,
          setItem: () => null,
          removeItem: () => null,
        },
});

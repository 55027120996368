import { produce } from 'immer';

import { fetchCompanyAnalysts } from '@/pages/CompanyReport/redux/routines';

export interface AnalystsState {
  brokersMap: Record<string, BrokerEntity>;
  analystMap: Record<string, AnalystEntity>;
}

export interface BrokerEntity {
  id: string;
  name: string;
  analystKeys: string[];
}

export interface AnalystEntity {
  id: string;
  firstName: string;
  lastName: string;
  brokerId: string;
}

function getDefault(): AnalystsState {
  return {
    brokersMap: {},
    analystMap: {},
  };
}

export function analystReducers(
  state = getDefault(),
  action: ReturnType<(typeof fetchCompanyAnalysts)['success']>
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case fetchCompanyAnalysts.SUCCESS:
        const { payload } = action;
        payload.brokers.forEach((item) => {
          draft.brokersMap[item.estimate_broker_id] = {
            id: item.estimate_broker_id.toString(),
            name: item.broker_name,
            analystKeys: item.analysts.data.map((analyst) => {
              draft.analystMap[analyst.estimate_analyst_id] = {
                id: analyst.estimate_analyst_id.toString(),
                firstName: analyst.first_name,
                lastName: analyst.last_name,
                brokerId: item.estimate_broker_id.toString(),
              };
              return analyst.estimate_analyst_id.toString();
            }),
          };
        });
        break;
    }
  });
}

import { get } from "../methods";
function getSharePortfolioHoldings(id, secret, headers = {}) {
  return get({
    url: `api/user/portfolio/${id}`,
    headers,
    payload: {
      secret,
      include: "items,items.transactions,items.company"
    }
  });
}
export {
  getSharePortfolioHoldings as default
};

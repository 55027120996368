import {
  useTranslation as useI18nTranslation,
  Translation as I18nTranslation,
} from '@simplywallst/hooks/useTranslation';
import type { SUPPORTED_NAMESPACES } from '@/utils/locale';
import type * as WebTranslations from '@assets/locales/en/web.json';
import type * as PageCompanyReportTranslations from '@assets/locales/en/page.companyReport.json';
import type * as StatementTranslations from '@assets/locales/en/statementGlossary.json';
import type * as CompanyDividendTranslations from '@assets/locales/en/page.companyReport.dividend.json';
import type * as CompanyFutureTranslations from '@assets/locales/en/page.companyReport.future.json';
import type * as CompanyOverviewTranslations from '@assets/locales/en/page.companyReport.overview.json';
import type * as CompanyHealthTranslations from '@assets/locales/en/page.companyReport.health.json';
import type * as ManagementTranslations from '@assets/locales/en/page.companyReport.management.json';
import type * as CompanyPastTranslations from '@assets/locales/en/page.companyReport.past.json';
import type * as OwnershipTranslations from '@assets/locales/en/page.companyReport.ownership.json';
import type * as InformationTranslations from '@assets/locales/en/page.companyReport.information.json';
import type * as ValuationTranslations from '@assets/locales/en/page.companyReport.valuation.json';

type I18Namespace = (typeof SUPPORTED_NAMESPACES)[number];

type I18nKeys<NS extends I18Namespace> = NS extends 'web'
  ? keyof typeof WebTranslations
  : NS extends 'page.companyReport'
    ? keyof typeof PageCompanyReportTranslations
    : NS extends 'statementGlossary'
      ? keyof typeof StatementTranslations
      : NS extends 'page.companyReport.dividend'
        ? keyof typeof CompanyDividendTranslations
        : NS extends 'page.companyReport.future'
          ? keyof typeof CompanyFutureTranslations
          : NS extends 'page.companyReport.overview'
            ? keyof typeof CompanyOverviewTranslations
            : NS extends 'page.companyReport.health'
              ? keyof typeof CompanyHealthTranslations
              : NS extends 'page.companyReport.management'
                ? keyof typeof ManagementTranslations
                : NS extends 'page.companyReport.past'
                  ? keyof typeof CompanyPastTranslations
                  : NS extends 'page.companyReport.ownership'
                    ? keyof typeof OwnershipTranslations
                    : NS extends 'page.companyReport.information'
                      ? keyof typeof InformationTranslations
                      : NS extends 'page.companyReport.valuation'
                        ? keyof typeof ValuationTranslations
                        : never;

/**
 * Loads the i18n resources for the specified namespace.
 * Sets up matching typed translation helper utils to be used with the set namespace keys.
 *
 * e.g. to return a `useTranslation` hook that only allows subkeys of `page.companyReport`;
 *
 * @example
 * const { useTranslation } = makeTranslationHelpers('page.companyReport');
 *
 */
export const makeTranslationHelpers = <
  NS extends I18Namespace,
  K extends I18nKeys<NS>,
>(
  namespace: NS
) => {
  const useTranslation = () => {
    const i18nTranslate = useI18nTranslation<NS, K>(namespace);

    // Cast the translate function to enforce typing + autocomplete on the web translation keys.
    return (
      key: K,
      options: {
        count?: number;
        /**
         * Options for configuring interpolation logic.
         *
         * Explanation of options on i18next (some defaults are different in our implementation);
         * @link https://www.i18next.com/translation-function/interpolation#additional-options
         */
        interpolation?: {
          /**
           * Enable escape of passed in values to avoid xss injection.
           *
           * Disabling this prevents escaped html characters (e.g. &amp;) appearing in interpolated values within translated output.
           * Ensure to enable this if using user input' data as the interpolated value.
           *
           * @default false
           */
          escapeValue?: boolean;
        };
      } & {
        [key: string]: string | number | undefined | { escapeValue?: boolean };
      } = {}
    ) =>
      i18nTranslate.t(key, {
        ns: namespace,
        interpolation: { escapeValue: false },
        ...options,
      });
  };

  const Translation = I18nTranslation<K>;

  return {
    /**
     * Displays text translated using the current locale language.
     *
     * e.g. to return 'Dashboard' when language is `en` and 'Cuadro de mandos' when language is `es`
     *
     * @example
     * const t = useTranslation()
     *
     * t('nav.dashboard')
     */
    useTranslation,
    /**
     * Displays text translated using the current locale language.
     * Used to interpolate react components into language output.
     *
     * @example
     *
     * Given a key which has the following value;
     * `You've used <1>{{views}}/{{limit}}</1>  spots of your {{plan}}
     * Plan's company view limit`
     * And rendering the component `NotificationTag` in the space
     * matching the translation value `<1>` will cause the
     * `NotificationTag` component to be interpolated into the output
     * for any locale language.
     * ```
     * <Translation
     *   i18nKey="app.topbar.nav.account.viewLimit"
     *   values={{
     *     views: limits.views,
     *     limit: limits.limit,
     *     plan: startCase(userAccess.planType),
     *   }}
     * >
     *   You've used
     *   <NotificationTag alert data-cy-id="company-view-limit-count">
     *     {{views}}/{{limit}}
     *   </NotificationTag>
     *   spots of your {{plan}} Plan’s company view limit
     *   </span>
     * </Translation>
     * ```
     */
    Translation,
  };
};

import { GraphQLClient } from "graphql-request";
import { handleChallenge } from "../challenge";
import fetch from "cross-fetch";
function getGraphQLClient(config) {
  if (false) {
    throw new Error("Missing env variable SWS_GQL_SERVICE.");
  }
  const { version = "UNTAGGED" } = config;
  const client = new GraphQLClient(
    config.baseURL ?? "https://simplywall.st/graphql",
    {
      headers: {
        "apollographql-client-version": version,
        ...config.headers
      },
      fetch: async (input, init) => {
        const response = await fetch(input, init);
        if (!response.ok) {
          if (response.headers.get("cf-mitigated") === "challenge") {
            handleChallenge();
          }
        }
        return response;
      }
    }
  );
  return client;
}
export {
  getGraphQLClient as default
};

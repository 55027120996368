import styled from 'styled-components';

export const SnowflakeWrapper = styled.div`
  position: relative;
  &:before {
    position: absolute;
    display: block;
    content: ' ';
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 100%;
    padding-bottom: 100%;
  }
  min-width: 48px;
  width: 100%;
  h4,
  p {
    margin-bottom: 0;
    margin-bottom: 0;
  }
  > span {
    > p {
      svg {
        width: ${({ theme }) => theme.x2spacePx}px;
        height: ${({ theme }) => theme.x2spacePx}px;
        margin-bottom: -3px;
        * {
          fill: white;
        }
      }
    }
  }
  > div {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
  }
`;

export const MediaWrapper = styled('div')`
  width: 100%;
`;

import { race, call, delay } from 'redux-saga/effects';
import { getAuthCookie } from '@utilities/cookies';
import type { AxiosError } from 'axios';

interface Config {
  timeout?: number;
  authenticate?: boolean;
  dataOnly?: boolean;
  canRetry?: boolean;
}

export interface APIError {
  errors: {
    status: number;
    title: string;
    error: string;
    reason: string;
    linked: boolean;
  }[];
}

function generateErrorFromAxiosResponse(error: AxiosError<APIError>) {
  if (error.isAxiosError) {
    try {
      const { response } = error;
      if (typeof response !== 'undefined') {
        const [firstError] = response.data.errors;
        if (firstError.title === 'Portfolio Exception') {
          return firstError;
        } else {
          return new Error(`${firstError.status}: ${firstError.title}`);
        }
      }
    } catch (e) {
      return error;
    }
  }
  return error;
}

const getDefaultConfig = (): Config => ({
  timeout: 1000 * 60, // 1 minute until timeout
  authenticate: false,
  dataOnly: true,
  canRetry: true,
});

const isSWSNull = (toMatch?: string | null) =>
  toMatch === 'null' ||
  toMatch === '' ||
  toMatch === null ||
  toMatch === undefined;

type EndpointFunction = (...args: any) => any;
function* fetchRoutine(
  endpoint: EndpointFunction,
  parameters,
  headers,
  config: Config
) {
  try {
    const { response } = yield race({
      response: call(endpoint, ...parameters, headers),
      timeout: delay(config.timeout as number), //ffs
    });
    if (response) {
      return config.dataOnly ? response.data : response;
    } else {
      // Notify user request timed out. Attempt to retry?
      throw new Error('Timeout');
    }
  } catch (e) {
    throw e;
  }
}

function* getAccessToken() {
  try {
    const { accessToken } = yield getAuthCookie();
    const isAccessTokenNull = isSWSNull(accessToken);
    if (isAccessTokenNull)
      throw new Error(
        'Attempted an authenticated call with an empty accessToken state.'
      );
    return accessToken;
  } catch (error) {
    throw error;
  }
}

export /**
 * Yarrrrr!
 * */
function ahoy(incomingConfig: Config = getDefaultConfig()) {
  const config = {
    ...getDefaultConfig(),
    ...incomingConfig,
  };
  return function* (endpoint: EndpointFunction, ...parameters) {
    try {
      let additionalHeader: HeadersInit = {};
      if (config.authenticate) {
        additionalHeader = {
          Authorization: `Bearer ${yield getAccessToken()}`,
        };
      }
      return yield fetchRoutine(endpoint, parameters, additionalHeader, config);
    } catch (error: any) {
      throw generateErrorFromAxiosResponse(error);
    }
  };
}

export const getAuthenticatedAhoy = () => ahoy({ authenticate: true });

import { get } from "../methods";
function getDashboardMyMarketsAndIndustries(headers = {}) {
  return get({
    url: "dashboard/my-markets-and-industries",
    headers
  });
}
export {
  getDashboardMyMarketsAndIndustries as default
};

import { createSelector } from 'reselect';
import { getAnalysis } from './selectors';

export interface SelectorReturnType {
  futureCashFlow: number[][];
  futureEPS: number[][];
  futureEPSAnalyst: number[][];
  futureEPSRange: number[][];
  futureNetIncome: number[][];
  futureRevenueAnalysts: number[][];
  futureRevenueAnalystsMap: { [date: number]: [number, number] };
  futureCashflowAnalystsMap: { [date: number]: [number, number] };
  futureFreeCashflowAnalystsMap: { [date: number]: [number, number] };
  futureNetIncomeAnalystsMap: { [date: number]: [number, number] };
  futureRevenueEstimate: number[][];
  futureFreeCashFlow: number[][];
  netIncomeBestFit: number[][];
  pastCashFlow: number[][];
  pastFreeCashFlow: number[][];
  pastEPS: number[][];
  pastEPSRange: number[][];
  pastNetIncome: number[][];
  pastRevenueActuals: number[][];
  revenueBestFit: number[][];
  earningsGrowthAnnual: number | null;
  revenueGrowthAnnual: number | null;
  futureROE3Y: number;
  earningsGrowthReq1Y: number;
  futureRevenueEstimateMax: number;
  pastRevenueActualsMax: number;
  pastRevenueActualsMin: number;
  EPSAnalystConfirmation: {
    [date: number]: number;
  };
}
const getDefault = (): SelectorReturnType => ({
  futureCashFlow: [],
  futureEPS: [],
  futureEPSAnalyst: [],
  futureEPSRange: [],
  futureNetIncome: [],
  futureRevenueAnalysts: [],
  futureRevenueAnalystsMap: {},
  futureCashflowAnalystsMap: {},
  futureFreeCashflowAnalystsMap: {},
  futureNetIncomeAnalystsMap: {},
  futureRevenueEstimate: [],
  futureFreeCashFlow: [],
  pastFreeCashFlow: [],
  netIncomeBestFit: [],
  pastCashFlow: [],
  pastEPS: [],
  pastEPSRange: [],
  pastNetIncome: [],
  pastRevenueActuals: [],
  revenueBestFit: [],
  earningsGrowthAnnual: 0,
  revenueGrowthAnnual: 0,
  futureROE3Y: 0,
  earningsGrowthReq1Y: 0,
  futureRevenueEstimateMax: 0,
  pastRevenueActualsMax: 0,
  pastRevenueActualsMin: 0,
  EPSAnalystConfirmation: {},
});

export const getCompanyFutures = createSelector(
  getAnalysis,
  (companyAnalysis): SelectorReturnType => {
    if (companyAnalysis) {
      const {
        futureRevenueEstimateMax,
        pastRevenueActualsMax,
        pastRevenueActualsMin,
        earningsGrowthAnnual,
        revenueGrowthAnnual,
        futureROE3Y,
        earningsGrowthReq1Y,
        legacyChartData,
      } = companyAnalysis;
      const {
        futureCashFlow,
        futureEPS,
        futureEPSAnalyst,
        futureEPSRange,
        futureNetIncome,
        futureRevenueAnalysts,
        futureRevenueAnalystsMap,
        futureCashflowAnalystsMap,
        futureFreeCashflowAnalystsMap,
        futureNetIncomeAnalystsMap,
        futureRevenueEstimate,
        netIncomeBestFit,
        pastCashFlow,
        pastEPS,
        pastEPSRange,
        pastNetIncome,
        pastRevenueActuals,
        pastFreeCashFlow,
        revenueBestFit,
        EPSAnalystConfirmation,
        futureFreeCashFlow,
      } = legacyChartData;
      return {
        futureCashFlow,
        futureEPS,
        futureEPSAnalyst,
        futureEPSRange,
        futureNetIncome,
        futureFreeCashFlow,
        futureRevenueAnalysts,
        futureRevenueAnalystsMap,
        futureCashflowAnalystsMap,
        futureFreeCashflowAnalystsMap,
        futureNetIncomeAnalystsMap,
        futureRevenueEstimate,
        netIncomeBestFit,
        pastCashFlow,
        pastEPS,
        pastEPSRange,
        pastNetIncome,
        pastFreeCashFlow,
        pastRevenueActuals,
        revenueBestFit,
        futureRevenueEstimateMax,
        pastRevenueActualsMax,
        pastRevenueActualsMin,
        earningsGrowthAnnual,
        revenueGrowthAnnual,
        futureROE3Y,
        earningsGrowthReq1Y,
        EPSAnalystConfirmation,
      };
    }
    return getDefault();
  }
);

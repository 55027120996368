import type { QueryFunctionContext } from '@tanstack/react-query';
import { getCompanyStatements } from '@simplywallst/services';
export function getQueryKey(canonicalUrl: string, locale = 'en') {
  return ['COMPANY_STATEMENTS_API', canonicalUrl || '', locale] as const;
}

export const queryFn = async ({
  queryKey: [_keyof, canonicalURL, locale],
}: QueryFunctionContext<ReturnType<typeof getQueryKey>>) => {
  const response = await getCompanyStatements(
    {
      lookupVal: canonicalURL,
      payload: {
        include: 'statements.question,sentence',
        locale,
      },
    },
    {},
    {
      baseURL:
        typeof SWS_INTERNAL_STATEMENTS_URL !== 'undefined'
          ? SWS_INTERNAL_STATEMENTS_URL
          : undefined,
    }
  );
  return response.data.data;
};

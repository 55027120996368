import { get } from "../methods";
function getPortfolioNews(id, secret, headers = {}) {
  const payload = secret ? { secret } : "";
  return get({
    url: `api/user/portfolio/news/${id}`,
    headers,
    payload
  });
}
export {
  getPortfolioNews as default
};

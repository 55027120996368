import { patch } from "../methods";
function updatePortfolioItem(itemId, reinvestDividend, headers = {}) {
  return patch({
    url: `api/user/portfolio/item/${itemId}`,
    payload: {
      reinvest: reinvestDividend
    },
    headers
  });
}
export {
  updatePortfolioItem as default
};

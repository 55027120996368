import { PUBLIC_SWS_ENV } from './env';

//App Actions
export const ACTION_APP_START = 'APP_START';
export const ACTION_SOFT_RESET = 'SOFT_RESET';
export const ACTION_RESET = 'RESET';
export const ACTION_OTP_LINK = 'OTP_LINK';
export const ACTION_APP_INFO = 'APP_INFO';
export const ACTION_DATA_LOADED = 'DATA_LOADED';
export const ACTION_WEB_APP_REDIRECT = 'WEB_APP_REDIRECT';
export const ACTION_SORT_HOLDINGS = 'SORT_HOLDINGS';
export const ACTION_SORT_HOLDINGS_BY_SCORES = 'SORT_HOLDINGS_BY_SCORES';
export const ACTION_UPGRADE = 'UPGRADE';

//Side Bar Actions
export const ACTION_TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

//Snack bar Actions
export const ACTION_ADD_SNACKBAR = 'ADD_SNACKBAR';
export const ACTION_REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const ACTION_CLEAR_SNACKBAR = 'CLEAR_SNACKBAR';

//Introduction Actions
export const ACTION_SNOWFLAKE_INTRODUCTION_SEEN = 'SNOWFLAKE_INTRODUCTION_SEEN';

//Error Message Actions
export const ACTION_ERROR_MESSAGE_RESET = 'ERROR_MESSAGE_RESET';

//Device Event Actions
export const ACTION_DEVICE_READY_SUBSCRIBE = 'DEVICE_READY_SUBSCRIBE';
export const ACTION_DEVICE_READY_UNSUBSCRIBE = 'DEVICE_READY_UNSUBSCRIBE';
export const ACTION_DEVICE_READY_EVENT_RAISED = 'DEVICE_READY_EVENT_RAISED';
export const ACTION_APP_RESUME_SUBSCRIBE = 'APP_RESUME_SUBSCRIBE';
export const ACTION_APP_RESUME_UNSUBSCRIBE = 'APP_RESUME_UNSUBSCRIBE';
export const ACTION_APP_RESUME_EVENT_RAISED = 'APP_RESUME_EVENT_RAISED';
export const ACTION_APP_PAUSE_SUBSCRIBE = 'APP_PAUSE_SUBSCRIBE';
export const ACTION_APP_PAUSE_UNSUBSCRIBE = 'APP_PAUSE_UNSUBSCRIBE';
export const ACTION_APP_PAUSE_EVENT_RAISED = 'APP_PAUSE_EVENT_RAISED';
export const ACTION_APP_BACKBUTTON_SUBSCRIBE = 'APP_BACKBUTTON_SUBSCRIBE';
export const ACTION_APP_BACKBUTTON_UNSUBSCRIBE = 'APP_BACKBUTTON_UNSUBSCRIBE';
export const ACTION_APP_BACKBUTTON_EVENT_RAISED = 'APP_BACKBUTTON_EVENT_RAISED';
export const ACTION_APP_MENUBUTTON_SUBSCRIBE = 'APP_MENUBUTTON_SUBSCRIBE';
export const ACTION_APP_MENUBUTTON_UNSUBSCRIBE = 'APP_MENUBUTTON_UNSUBSCRIBE';
export const ACTION_APP_MENUBUTTON_EVENT_RAISED = 'APP_MENUBUTTON_EVENT_RAISED';
export const ACTION_APP_ONLINE = 'APP_ONLINE';
export const ACTION_APP_OFFLINE = 'APP_OFFLINE';
export const ACTION_APP_ERROR_REPORTED = 'APP_ERROR_REPORTED';
export const ACTION_APP_ERROR_CLEAR = 'APP_ERROR_CLEAR';
export const MinimumStateVersion = 3.0;
/**
 * Should this be environment sensitive?
 */
export const SWS_HOST = 'https://simplywall.st';
export const SWS_LEGACY_HOST =
  PUBLIC_SWS_ENV === 'dev' || PUBLIC_SWS_ENV === 'local'
    ? 'https://legacy-stable.dev.simplywall.st'
    : 'https://legacy.simplywall.st';
export const SWS_SUPPORT = `${SWS_HOST}/help`;
export const SWS_PRO_PROGRAM = `${SWS_HOST}/business-plans`;
export const SWS_TERMS_AND_CONDITIONS = `${SWS_HOST}/terms-and-conditions`;
export const SWS_ANALYSIS_MODEL =
  'https://github.com/SimplyWallSt/Company-Analysis-Model/blob/master';

export const SWS_IMAGES = 'https://images.simplywall.st';
export const SWS_EXPORT = SWS_IMAGES;

export const SWS_FINANCIAL_SERVICES_GUIDE =
  'https://media.simplywall.st/site/financials/Financial_Services_Guide_-_Simply_Wall_St_V2.0_dated_20_Jan_23.pdf';

export const SWS_WELCOME_LINK = `${SWS_HOST}/welcome`;
export const SWS_ABOUT_LINK = `${SWS_HOST}/about`;
export const SWS_COMPANY_LINK = `${SWS_HOST}/company`;
export const SWS_PLANS_LINK = `${SWS_HOST}/plans`;
export const SWS_NEWS_LINK = `${SWS_HOST}/news`;
export const SWS_SITEMAP_LINK = `${SWS_HOST}/sitemap`;
export const SWS_CAREERS_LINK = `${SWS_HOST}/careers`;
export const SWS_PRIVACY_LINK = `${SWS_HOST}/privacy-policy`;
export const SWS_HELP_LINK = SWS_SUPPORT;
export const SWS_PODCASTS_LINK = `${SWS_HOST}/podcasts`;
export const SWS_WHATS_NEW_LINK = `https://support.simplywall.st/hc/en-us/articles/7894830045199-What-s-New`;
export const APPSTORE_APP_LINK =
  'https://apps.apple.com/app/apple-store/id1075614972?pt=117976858&ct=website-footer&mt=8';
export const GOOGLE_PLAY_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.simplywallst.app&referrer=utm_source%3Dwebsite%26utm_medium%3Dfooter%26utm_content%3Dgoogle-play-logo';
export const SWS_FACEBOOK_LINK = 'https://www.facebook.com/simplywallst/';
export const SWS_TWITTER_LINK = 'https://twitter.com/simplywallst';
export const SWS_LINKEDIN_LINK =
  'https://www.linkedin.com/company/simply-wall-st';
export const SWS_YOUTUBE_LINK = 'https://www.youtube.com/c/Simplywallst';

export const footerRoutes = [
  {
    url: SWS_ABOUT_LINK,
    label: 'About Us',
  },
  {
    url: SWS_COMPANY_LINK,
    rel: 'nofollow',
    label: 'Company',
  },
  { url: SWS_PLANS_LINK, rel: 'nofollow', label: 'Plans' },
  { url: SWS_NEWS_LINK, rel: 'nofollow', label: 'Latest Coverage' },
  { url: SWS_PODCASTS_LINK, rel: 'nofollow', label: 'Podcasts' },
  {
    url: SWS_HELP_LINK,
    target: '_blank',
    rel: 'nofollow',
    label: 'Help Center',
  },
  {
    url: SWS_PRO_PROGRAM,
    target: '_blank',
    rel: 'nofollow',
    label: 'Business and Enterprise',
  },
  {
    url: SWS_TERMS_AND_CONDITIONS,
    rel: 'nofollow',
    target: '_blank',
    label: 'Terms and Conditions',
  },
  {
    url: SWS_PRIVACY_LINK,
    rel: 'nofollow',
    target: '_blank',
    label: 'Privacy Policy',
  },
];

export const COMPANY_ADDRESS = 'Level 7, 320 Pitt Street, Sydney';

export const SUPPORT_EMAIL = 'mailto:support@simplywallst.com';

export const SWS_DESCRIPTION_HOME =
  'Make confident investment decisions and stay up to date on 100k+ stocks with our unique fundamental analysis and in-depth visual reports.';

export const NUMBER_OF_ARTICLES_PER_PAGE = 24;

export const DEMO_PORTFOLIO_ID =
  PUBLIC_SWS_ENV === 'prod' || PUBLIC_SWS_ENV === 'staging'
    ? '066f9997-7d77-49fa-9581-09099d9e7a95'
    : 'dc2d241b-1a19-453d-a0f9-64db0f776d78';

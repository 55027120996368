import { get } from "../methods";
import { removeLeadingSlash } from "../utils";
function getCompanyStatements_default({ lookupVal, payload }, headers = {}, axiosConfig = {}) {
  if (axiosConfig.baseURL === void 0) {
    axiosConfig.baseURL = "https://simplywall.st/backend/statements";
  }
  return get({
    url: `/${removeLeadingSlash(lookupVal)}`,
    payload,
    headers,
    ...axiosConfig
  });
}
export {
  getCompanyStatements_default as default
};

export enum WatchlistSorting {
  RECENTLY_ADDED = '{field: createdAt direction: DESC}',
  MARKET_CAP_HIGH_TO_LOW = '{field: marketCap direction: DESC}',
  COMPANY_NAME_A_TO_Z = '{field: name direction: ASC}',
  TICKER_A_TO_Z = '{field: tickerSymbol direction: ASC}',
  PERFORMANCE_7D = '{field: return7d direction: DESC}',
  DISCOUNT_LOW_TO_HIGH = '{field: discount direction: ASC}',
  DISCOUNT_HIGH_TO_LOW = '{field: discount direction: DESC}',
  TOTAL_SCORE_HIGH_TO_LOW = '{field: totalScore direction: DESC}',
  VALUE_SCORE_HIGH_TO_LOW = '{field: valueScore direction: DESC}',
  FUTURE_SCORE_HIGH_TO_LOW = '{field: futureScore direction: DESC}',
  PAST_SCORE_HIGH_TO_LOW = '{field: pastScore direction: DESC}',
  DIVIDEND_SCORE_HIGH_TO_LOW = '{field: dividendScore direction: DESC}',
  HEALTH_SCORE_HIGH_TO_LOW = '{field: healthScore direction: DESC}',
}

import { produce } from 'immer';
import type { addCompany, removeCompany } from './routines';
import { showAddRemoveCompany, hideAddRemoveCompany } from './routines';

export interface AddRemoveCompanyState {
  readonly companyId: string;
  readonly companyName: string;
  readonly tickerSymbol: string;
  readonly uniqueSymbol?: string;
}

export const getDefaultState = (): AddRemoveCompanyState => ({
  companyId: '',
  companyName: '',
  tickerSymbol: '',
  uniqueSymbol: '',
});

export default (
  state = getDefaultState(),
  action: ReturnType<
    | (typeof showAddRemoveCompany)['trigger']
    | (typeof hideAddRemoveCompany)['trigger']
    | (typeof addCompany)['trigger']
    | (typeof removeCompany)['trigger']
    | (typeof addCompany)['success']
    | (typeof removeCompany)['success']
    | (typeof addCompany)['failure']
    | (typeof removeCompany)['failure']
  >
): AddRemoveCompanyState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case showAddRemoveCompany.TRIGGER: {
        const {
          payload: { companyId, companyName, tickerSymbol, uniqueSymbol },
        } = action as ReturnType<(typeof showAddRemoveCompany)['trigger']>;
        draft.companyId = companyId;
        draft.companyName = companyName;
        draft.tickerSymbol = tickerSymbol;
        draft.uniqueSymbol = uniqueSymbol;
        break;
      }
      case hideAddRemoveCompany.TRIGGER: {
        draft.companyId = '';
        draft.companyName = '';
        draft.tickerSymbol = '';
        draft.uniqueSymbol = '';
        break;
      }
    }
  });
};

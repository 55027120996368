import { createSelector } from 'reselect';
import { getAnalysis } from './selectors';
import type { AnalysisState } from '../interface';

type SelectorReturnType = Pick<
  AnalysisState,
  | 'beta5y'
  | 'PE'
  | 'PB'
  | 'PEG'
  | 'intrinsicValue'
  | 'intrinsicDiscount'
  | 'sharePrice'
>;

function getDefault(): SelectorReturnType {
  return {
    beta5y: 0,
    PE: 0,
    PB: 0,
    PEG: 0,
    intrinsicDiscount: 0,
    intrinsicValue: 0,
    sharePrice: 0,
  };
}

export const getValue = createSelector(
  [getAnalysis],
  (companyAnalysis): SelectorReturnType => {
    if (companyAnalysis) {
      const {
        beta5y,
        PE,
        PB,
        PEG,
        intrinsicValue,
        intrinsicDiscount,
        sharePrice,
      } = companyAnalysis;
      return {
        beta5y,
        PE,
        PB,
        PEG,
        intrinsicValue,
        intrinsicDiscount,
        sharePrice,
      };
    }
    return getDefault();
  }
);

import { get } from "../methods";
const getDefaultPayload = (isPublic = false, include = ["items", "items.transactions", "items.company"]) => {
  return {
    include: include.join(","),
    sharing: isPublic
  };
};
const getIdQuery = (id = "") => id !== "" ? `/${id}` : "";
function getPortfolios(options = {}, headers = {}) {
  const { id, isPublic, include } = options;
  const payload = getDefaultPayload(isPublic, include);
  return get({
    url: `api/user/portfolio${getIdQuery(id)}`,
    payload,
    headers
  });
}
export {
  getPortfolios as default,
  getIdQuery
};

import type { RequestDocument } from 'graphql-request';
import { getOperationName } from '../gqlQuery/query';
import type { TadaDocumentNode } from 'gql.tada';

const isTadaDocumentNode = (query: unknown): query is TadaDocumentNode => {
  return Boolean(
    query &&
      typeof query === 'object' &&
      'kind' in query &&
      query.kind === 'Document'
  );
};

export const getOperationNameFromStringOrDocument = (
  query: RequestDocument
) => {
  /**
   * Since we are using gql package from graphql-request to wrap our queries (Legacy queries),
   * we don't need to handle DocumentNode parsing as it returns a string with interpolated variables.
   */
  if (typeof query === 'string') {
    const matches = query.match(/(?:query|mutation|subscription)\s+(\w+)\s*/);

    return matches ? matches[1] : null;
  }

  // Extract operation name form DocumentNode (TadaDocumentNode)
  if (isTadaDocumentNode(query)) {
    return getOperationName(query)?.[0] ?? null;
  }

  return null;
};

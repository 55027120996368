import { get } from "../methods";
import { removeLeadingSlash } from "../utils";
function getCompany_default({ lookupVal, payload }, headers = {}, axiosConfig = {}) {
  const config = {
    url: `api/company/${removeLeadingSlash(lookupVal)}`,
    payload,
    headers: {
      ...headers,
      "Accept-Language": payload?.["locale"] || "en"
    },
    ...axiosConfig
  };
  return get(config);
}
export {
  getCompany_default as default
};

import { debounce } from 'lodash';

export const storeSubscriber = (store) =>
  debounce(() => {
    try {
      const allState = store.getState();
      const portfoliosJSON = JSON.stringify(allState.portfolios);
      localStorage.setItem('portfolios', portfoliosJSON);
      const portfolioJSON = JSON.stringify(allState.portfolio);
      localStorage.setItem('portfolio', portfolioJSON);
    } catch (err) {
      // noop
    }
  }, 1000);

export function readStore() {
  try {
    const portfoliosStore = localStorage.getItem('portfolios');
    const portfolioStore = localStorage.getItem('portfolio');

    const localStore = {
      portfolio: null,
      portfolios: null,
      company: null,
    };
    if (typeof portfoliosStore === 'string') {
      localStore['portfolios'] = JSON.parse(portfoliosStore);
    }
    if (typeof portfolioStore === 'string') {
      localStore['portfolio'] = JSON.parse(portfolioStore);
    }
    return localStore;
  } catch (error) {
    return null;
  }
}

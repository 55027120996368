import { createSelector } from 'reselect';
import {
  toTruncated,
  toPercentage,
  toSignificant,
} from '@/utilities/formatting';
import type { State } from '../interface';
import { getDefault } from '../reducers/analysis';
import { getSummary } from './getCompanySummary';
import { getCompanyId } from './utils';
import { format } from 'date-fns';

export interface AnalysisSelectorProps {
  companyId?: string;
}

type AnalysisReturn = State['analysis'][''] & {
  formattedTotalEmployees: string;
};

export const getAnalysis = (
  state,
  props: AnalysisSelectorProps
): AnalysisReturn => {
  const companyId = getCompanyId(state, props);
  const company = state.company as State;
  const current = company.analysis[companyId];
  let formattedTotalEmployees = '';
  try {
    formattedTotalEmployees = toTruncated(current.totalEmployees);
  } catch (e) {
    formattedTotalEmployees = '0';
  }
  if (current) {
    return {
      ...current,
      formattedTotalEmployees,
    };
  }
  return { ...getDefault(), formattedTotalEmployees: '' };
};

const formatter = (value: number | null) =>
  value !== null ? toPercentage(value, 1) : 'n/a';

export const getCompanyReturns = () =>
  createSelector(getAnalysis, (analysis) => {
    return {
      return1YrAbs: analysis.return1YrAbs,
      return1YrAbsFormatted: formatter(analysis.return1YrAbs),
      return1YrTotalReturn: analysis.return1YrTotalReturn,
      return1YrTotalReturnFormatted: formatter(analysis.return1YrTotalReturn),
      return3YrAbs: analysis.return3YrAbs,
      return3YrAbsFormatted: formatter(analysis.return3YrAbs),
      return3YrTotalReturn: analysis.return3YrTotalReturn,
      return3YrTotalReturnFormatted: formatter(analysis.return3YrTotalReturn),
      return5YrAbs: analysis.return5YrAbs,
      return5YrAbsFormatted: formatter(analysis.return5YrAbs),
      return5YrTotalReturn: analysis.return5YrTotalReturn,
      return5YrTotalReturnFormatted: formatter(analysis.return5YrTotalReturn),
      return7D: analysis.return7D,
      return7DFormatted: formatter(analysis.return7D),
      return30D: analysis.return30D,
      return30DFormatted: formatter(analysis.return30D),
      return90D: analysis.return90D,
      return90DFormatted: formatter(analysis.return90D),
      returnBeta5Yr: Number(toSignificant(analysis.beta5y, 2)),
    };
  });

export const getInsiderTransactionsKeys = (state, props) => {
  const companyId = getCompanyId(state, props);
  const company = state.company as State;
  if (company.insiderTransactions[companyId]) {
    return company.insiderTransactions[companyId];
  }
  return [];
};

export const getInsiderTransactionsMap = (state) => {
  const company = state.company as State;
  return company.insiderTransactionsMap;
};

const getListingsMap = (state) => {
  const company = state.company as State;
  return company.listings;
};
export const getMappedListings = createSelector(
  [getListingsMap, getSummary],
  (listingsMap, summary) => {
    const { listings } = summary;
    return listings
      .map((key) => {
        const listing = listingsMap[key];
        return {
          canonicalUrl: listing.canonicalUrl,
          tickerSymbol: listing.tickerSymbol,
          exchangeSymbol: listing.exchangeSymbol,
          exchangeName: listing.exchangeName,
          primarySecurity: listing.primarySecurity ? 'Yes' : '',
          securityName: listing.securityName,
          exchangeCountryIso: listing.exchangeCountryIso,
          currencyIso: listing.currencyIso,
          rawStartDate: listing.startDate,
          startDate: listing.startDate
            ? format(listing.startDate, 'MMM yyyy')
            : 'No data',
        };
      })
      .sort((listingA, listingB) => {
        if (listingA.rawStartDate === null) return -1;
        if (listingB.rawStartDate === null) return 1;
        return listingA.rawStartDate - listingB.rawStartDate;
      });
  }
);

export { getCompanyId };

export function getPossessive(
  noun?: string | number | null,
  locale:
    | 'en'
    | 'es'
    | 'fr'
    | 'de'
    | 'ja'
    | 'ko'
    | 'it'
    | 'nl'
    | 'tr'
    | 'sv' = 'en'
) {
  if (!noun) return '';
  switch (locale) {
    case 'en':
      const lastCharacter = noun?.toString().slice(-1).toLowerCase();
      return lastCharacter === 's' ? noun + "'" : noun + "'s";
    default:
      return noun;
  }
}

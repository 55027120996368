import type IndustryAverageStateEntity from '@industyAverages/interfaces/state/IndustryAverageStateEntity';
import type IndustryAverageByIndustryCountryEntity from '@industyAverages/interfaces/entity/IndustryAverageByIndustryCountryEntity';
import type IndustryAverageByIndustryCountry from '@industyAverages/interfaces/repository/IndustryAverageByIndustryCountry';
import type IIndustryAverageByIndustryAndCountryRepository from '@industyAverages/repository/IIndustryAverageByIndustryAndCountry';

import type IIndustryAverageByIndustryAndCountryProvider from './IIndustryAverageByIndustryAndCountryProvider';

class IndustryAverageByIndustryAndCountryProvider
  implements IIndustryAverageByIndustryAndCountryProvider
{
  private _industryAvgByIndustryCountryRepo: IIndustryAverageByIndustryAndCountryRepository;

  constructor(
    industryAvgByIndustryCountryRepo: IIndustryAverageByIndustryAndCountryRepository
  ) {
    if (!industryAvgByIndustryCountryRepo)
      throw new Error('Cannot instantiate Provider without Repository..');

    this._industryAvgByIndustryCountryRepo = industryAvgByIndustryCountryRepo;
  }

  getKeyForIndustryAverageByCountry(
    industryId: number,
    countryISO: string
  ): string {
    return `INDUSTRY_AVG_INDUSTRY_COUNTRY|${industryId}|${countryISO}`;
  }

  async getIndustryAveragesByCountry(
    industryId: number,
    countryISO?: string | undefined
  ): Promise<
    IndustryAverageStateEntity<IndustryAverageByIndustryCountryEntity>
  > {
    try {
      const {
        _industryAvgByIndustryCountryRepo,
        getKeyForIndustryAverageByCountry,
      } = this;

      const industryAvgByIdAndCountry: readonly IndustryAverageByIndustryCountry[] =
        await _industryAvgByIndustryCountryRepo.getIndustryAveragesByCountry(
          industryId,
          countryISO
        );

      const entities: Map<string, IndustryAverageByIndustryCountryEntity> =
        new Map([]);

      industryAvgByIdAndCountry.forEach((avgByCountry) => {
        const {
          industryId,
          industryName,
          countryName,
          countryType,
          countryISO2,
          ...restValues
        } = avgByCountry;

        const key = getKeyForIndustryAverageByCountry(industryId, countryISO2);

        entities.set(key, {
          key,
          industryId,
          industryName,
          countryIdentifier: countryISO2,
          countryName: countryName,
          countryType: countryType,
          ...restValues,
        });
      });
      return Promise.resolve({ entities });
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default IndustryAverageByIndustryAndCountryProvider;

import { useMemo } from 'react';
import { useTracking } from 'react-tracking';
import { useSelector } from 'react-redux';
import GlobalStyle from '@/styled/styled.default';
import { Sentry } from '@/components/Sentry';
import { useStatuspage } from '@/hooks/useStatuspage';
import { useDatadogRum } from '@/hooks/useDatadogRum';
import { useGTM } from '@/hooks/useGTM';
import { useUser } from '@/hooks/useUser';
import { useSetupRewardful } from '@/features/rewardful';
import { getCurrentRoute } from '@/utilities/sentry/utils';
import { useLocale } from '@/hooks/useLocale';
import { Helmet } from 'react-helmet';
import { useCanvasFix } from '@/hooks/useCanvasFix';
import { useHotjar } from '@/hooks/useHotjar';
import { ContentSimplyWallSt } from './components/ContentSimplyWallSt';

export interface Props {
  routeName: string;
  pageName: string;
}

const SetupScripts = () => {
  useDatadogRum(
    getCurrentRoute(),
    '@sws/web',
    '8a5a2c05-e709-4595-a937-018ad6ec962f'
  );
  useStatuspage();
  useSetupRewardful();
  useHotjar();
  useGTM();
  return null;
};

const BaseSimplyWallSt = ({ pageName, routeName }: Props) => {
  const MemoGlobalStyle = useMemo(() => <GlobalStyle />, [pageName]);
  const pageStatus = useSelector((state: any) => state.page.pageStatus);
  const locale = useLocale();
  useCanvasFix();

  return (
    <>
      {MemoGlobalStyle}
      <Helmet>
        <html lang={locale} />
      </Helmet>
      <Sentry>
        <ContentSimplyWallSt pageStatus={pageStatus} routeName={routeName} />
        <SetupScripts />
      </Sentry>
    </>
  );
};

const SimplyWallSt = (props: Props) => {
  const { pageName } = props;
  const { data: user } = useUser();
  const { Track } = useTracking(
    {
      page: pageName,
      user_id: user.id,
    },
    {
      dispatchOnMount: true,
    }
  );
  return (
    <Track>
      <BaseSimplyWallSt {...props} />
    </Track>
  );
};

export default SimplyWallSt;

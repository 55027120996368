import i18next from 'i18next';
import type {
  InitOptions,
  LoggerModule,
  ReadCallback,
  Resource,
} from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

const logger: (level?: Options['logLevel']) => LoggerModule = (level) => ({
  type: 'logger',
  log: (args) => {
    level === 'debug' && console.info('[i18next]: ' + args);
  },
  warn: (args) => {
    (level === 'debug' || level === 'warn') &&
      console.warn('[i18next]: ' + args);
  },
  error: (args) =>
    (level === 'debug' || level === 'warn' || level === 'error') &&
    console.error('[i18next]: ' + args),
});

/**
 * Keep i18next as a global instance, but don't initialise until we have init arguments for
 * namespace and language.
 */
const i18nInstance = i18next.use(initReactI18next);

/**
 * This function retrieves the global instance of i18n.
 *
 * @returns {i18n} The corresponding i18n instance.
 *
 * @example
 * const i18nInstance = getGlobali18nInstance();
 */
export function getGlobali18nInstance() {
  return i18nInstance;
}

interface Options {
  locale?: string;
  namespace: string[];
  importFn?: (
    language: string,
    namespace: string,
    callback: ReadCallback
  ) => void;
  resources?: Resource;
  supportedLngs?: string[];
  logLevel?: 'debug' | 'warn' | 'error';
  initOptions?: InitOptions;
}

export async function initTranslations({
  locale = 'en',
  namespace,
  importFn,
  resources,
  supportedLngs,
  logLevel = 'error',
  initOptions,
}: Options) {
  if (!i18nInstance.isInitialized) {
    await i18nInstance
      .use(logger(logLevel))
      .use(resourcesToBackend(importFn))
      .init({
        ...initOptions,
        partialBundledLanguages: true,
        ns: ['web', 'statementGlossary'],
        defaultNS: namespace,
        supportedLngs,
        lng: locale,
        resources,
      });
  }
  return i18nInstance;
}

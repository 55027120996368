import { get, uniq } from 'lodash';
import { normalise } from '@/state/utils/reducer';
import type { getPortfolios } from '@simplywallst/services';
import type {
  HoldingAnalysis,
  Item,
  PayloadHolding,
  PortfolioHolding,
} from './interface';

import { transactionNormaliser } from './transactionReducer';

type HoldingResponse = NonNullable<
  Awaited<ReturnType<typeof getPortfolios>>['data']['data'][number]['items']
>['data'][number];
type SideEffectCallback = (
  entities: Record<string, any>,
  longId: string,
  shortId: string
) => void;

export const getDefaultState = (): PortfolioHolding => ({
  isUpdating: false,
  reinvestDividends: false,
});

export const holdingNormaliser =
  (
    prevStateHolding: Record<string, PortfolioHolding>,
    sideEffect: SideEffectCallback
  ) =>
  (currentHolding: HoldingResponse): PortfolioHolding => {
    const { id, reinvest } = currentHolding;
    const safeId = id.toString();
    const { company_id } = currentHolding.company.data;

    const { keys, entities } = normalise(currentHolding.transactions.data)(
      'id',
      transactionNormaliser
    );

    sideEffect(entities, company_id, safeId);
    const prevHolding = get(prevStateHolding, safeId, {});
    const prevTransactionsMap = get(prevHolding, 'transactionsMap', []);
    return {
      ...prevHolding,
      companyId: company_id,
      itemId: currentHolding.id.toString(),
      reinvestDividends: reinvest || false,
      isUpdating: false,
      transactionsMap: uniq([...prevTransactionsMap, ...keys]),
    };
  };

export const addRemoveHoldingReducer =
  (
    itemId: string,
    companyId: string,
    holdingsTable: Record<string, PortfolioHolding>
  ) =>
  (current: Item): PortfolioHolding => {
    const { company, reinvest } = current;
    const prevHolding = get(holdingsTable, itemId, getDefaultState());
    if (!company) return prevHolding;
    return {
      ...prevHolding,
      companyId,
      reinvestDividends: reinvest || false,
      itemId,
    };
  };

export /* Normalises response from api/portfolio/analysis */
const holdingWithAnalysisNormaliser =
  (
    holdingsTable: Record<string, PortfolioHolding>,
    holdingsCompanyIdItemIdMap: Record<string, string>
  ) =>
  (current: PayloadHolding): PortfolioHolding => {
    const {
      company_id,
      current_value,
      annual_dividend_return,
      current_dividend,
      beta,
      dividend_return,
      total_gain,
      score: {
        data: {
          income,
          future,
          health,
          past,
          total,
          value,
          snowflake: {
            data: { axes },
          },
        },
      },
      inception,
      return: capital_gain,
      return_7d,
      total_shares,
      share_price,
    } = current;

    const analysis: HoldingAnalysis = {
      annualDividendReturn: annual_dividend_return,
      currentDividend: current_dividend,
      beta,
      currentValue: current_value,
      dividendReturn: dividend_return,
      snowflakeScoreAxes: axes,
      snowflakeScoreDividend: income,
      snowflakeScoreFuture: future,
      snowflakeScoreHealth: health,
      snowflakeScorePast: past,
      snowflakeScoreTotal: total,
      snowflakeScoreValue: value,
      totalGain: total_gain,
      capitalGain: capital_gain,
      capitalGainWeek: return_7d,
      totalShares: total_shares,
      sharePrice: share_price,
    };

    const itemId = holdingsCompanyIdItemIdMap[company_id];
    const prevHolding = get(holdingsTable, itemId, getDefaultState());

    return {
      ...prevHolding,
      companyId: company_id,
      analysis,
      inception,
    };
  };

import { get } from "../methods";
function getUacCheck(headers = {}) {
  return get({
    url: "api/uac/check",
    payload: {
      action: "get_config"
    },
    headers
  });
}
export {
  getUacCheck as default
};

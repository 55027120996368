import { v4 as uuidv4 } from 'uuid';

import type { SnackbarItem } from '../types';

type PartialFields<O, Keys extends keyof O> = Omit<O, Keys> & {
  [K in Keys]?: O[K];
};

export type NewSnackbarItem = PartialFields<SnackbarItem, 'id' | 'lifeTime'>;

type ReducerAction =
  | {
      type: 'ADD_SNACKBAR';
      payload: NewSnackbarItem;
    }
  | {
      type: 'REMOVE_SNACKBAR';
      payload: { id: string };
    }
  | {
      type: 'CLEAR_SNACKBAR';
    };

export type SnackbarState = {
  keys: string[];
  entities: Record<string, SnackbarItem>;
};

export const getSnackbarState = (): SnackbarState => ({
  keys: [],
  entities: {},
});

export function snackbarReducer(
  state: SnackbarState,
  action: ReducerAction
): SnackbarState {
  switch (action.type) {
    case 'ADD_SNACKBAR': {
      const { payload } = action;
      const id = payload.id ?? uuidv4();
      const snackbarItem = {
        id,
        type: payload.type,
        name: payload.name,
        message: payload.message,
        headline: payload.headline,
        lifeTime: payload.lifeTime ?? -1,
        actionLabel: payload.actionLabel,
        actionCallback: payload.actionCallback,
        secondaryActionLabel: payload.secondaryActionLabel,
        secondaryActionCallback: payload.secondaryActionCallback,
        layout: payload.layout ?? 'inline',
      };
      return {
        keys: [...state.keys, id],
        entities: {
          ...state.entities,
          [id]: snackbarItem,
        },
      };
    }
    case 'REMOVE_SNACKBAR': {
      const { payload } = action;
      const id = payload.id;
      const index = state.keys.findIndex((n) => n === id);
      const keys = [
        ...state.keys.slice(0, index),
        ...state.keys.slice(index + 1),
      ];
      const { [id]: kill, ...entities } = state.entities;

      return {
        keys,
        entities: {
          ...entities,
        },
      };
    }
    case 'CLEAR_SNACKBAR':
      return getSnackbarState();
    default:
      return state;
  }
}

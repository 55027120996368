import { produce } from 'immer';
import { fetchCompany } from '@/pages/CompanyReport/redux/routines';
import type { CompanyResponseExtended } from '@/hooks/useCompany/types';
type CompanyAPIMember = NonNullable<
  CompanyResponseExtended['raw_data']['data']['members']['board']
>[string];
export interface People {
  id: string;
  salutation: string;
  firstName: string;
  lastName: string;
  age: number;
  yearBorn: number;
  shortTitle: string;
  /** Ca-ching */
  compensation: number;
  leaderTenure: number;
  managementTenure: number;
  boardTenure: number;
  startDate: number;
  proTitle: string;
  biography: string;
  hasCEO?: boolean;
  percentSharesOutstanding: string;
  sharesHeld: string;
}

export function setDefaultMember(): People {
  return {
    id: '',
    salutation: '',
    firstName: '',
    lastName: '',
    age: 0,
    yearBorn: 0,
    shortTitle: '',
    compensation: 0,
    leaderTenure: 0,
    managementTenure: 0,
    boardTenure: 0,
    startDate: 0,
    proTitle: '',
    biography: '',
    hasCEO: false,
    percentSharesOutstanding: '',
    sharesHeld: '',
  };
}

const personReducer = (
  acc: Record<string, People>,
  current: [string, CompanyAPIMember]
) => {
  const [
    key,
    {
      salutation,
      first_name: firstName,
      last_name: lastName,
      age,
      year_born: yearBorn,
      short_title: shortTitle,
      compensation,
      tenure,
      pro_title: proTitle,
      percent_of_shares_outstanding: percentSharesOutstanding,
      shares_held: sharesHeld,
      biography,
      start_date: startDate,
      board_member: boardMember,
      key_exec: keyExec,
    },
  ] = current;
  if (!acc[key]) {
    acc[key] = setDefaultMember();
  }
  acc[key].id = key;
  acc[key].salutation = salutation;
  if (firstName !== null) {
    acc[key].firstName = firstName.replace('’', '\u0027');
  }
  if (lastName !== null) {
    acc[key].lastName = lastName.replace('’', '\u0027');
  }
  acc[key].age = age || 0;
  acc[key].yearBorn = yearBorn || 0;
  acc[key].shortTitle = shortTitle;
  acc[key].compensation = compensation || 0;
  acc[key].proTitle = proTitle;
  if (keyExec) {
    acc[key].managementTenure = tenure || 0;
  }
  if (boardMember) {
    acc[key].boardTenure = tenure || 0;
  }
  if (percentSharesOutstanding !== null) {
    acc[key].percentSharesOutstanding = percentSharesOutstanding.toString();
  }
  if (sharesHeld !== null) {
    acc[key].sharesHeld = sharesHeld.toString();
  }
  acc[key].biography = biography?.toString();
  if (startDate !== null) {
    acc[key].startDate = startDate;
  }
  return acc;
};

function getDefault(): Record<string, People> {
  return {};
}

export function reducer(
  state = getDefault(),
  action: ReturnType<(typeof fetchCompany)['success']>
) {
  return produce(state, (draft) => {
    const { type } = action;
    switch (type) {
      case fetchCompany.SUCCESS:
        const { payload } = action as ReturnType<
          (typeof fetchCompany)['success']
        >;

        if (!payload.companyData.analysis) {
          return;
        }
        const { members: membersRawData } =
          payload.companyData.analysis.data.extended.data.raw_data.data;

        const { management, leader, board } = membersRawData;

        let managementMembers: Record<string, People> = {};
        if (management) {
          const managementEntries = Object.entries(management);
          managementMembers = managementEntries.reduce(personReducer, {});
          draft = managementEntries.reduce(personReducer, draft);
        }
        let boardMembers: Record<string, People> = {};
        if (board) {
          const boardEntries = Object.entries(board);
          boardMembers = boardEntries.reduce(personReducer, {});
          draft = boardEntries.reduce(personReducer, draft);
        }

        if (leader) {
          draft[leader.id] = {
            id: leader.id.toString(),
            salutation: leader.salutation,
            firstName: leader.first_name !== null ? leader.first_name : '',
            lastName: leader.last_name !== null ? leader.last_name : '',
            age: leader.age || 0,
            yearBorn: leader.year_born || 0,
            shortTitle: leader.short_title,
            compensation: leader.compensation || 0,
            leaderTenure: leader.tenure || 0,
            managementTenure:
              leader.id in managementMembers
                ? managementMembers[leader.id].managementTenure
                : 0,
            boardTenure:
              leader.id in boardMembers
                ? boardMembers[leader.id].boardTenure
                : 0,
            proTitle: leader.pro_title,
            biography: leader.biography?.toString(),
            hasCEO: true,
            percentSharesOutstanding: leader.percent_of_shares_outstanding
              ? leader.percent_of_shares_outstanding.toString()
              : '',
            sharesHeld: leader.shares_held ? leader.shares_held.toString() : '',
            startDate:
              typeof leader.start_date === 'number' ? leader.start_date : 0,
          };
        }
        break;
    }
  });
}

import { createRoutine } from 'redux-saga-routines';
import type { getCompanyTopShareholders } from '@simplywallst/services';

type Shareholder = Awaited<
  ReturnType<typeof getCompanyTopShareholders>
>['data']['data'][number];

export interface FetchCompanyTopShareholdersTriggerPayload {
  companyId: string;
}

export interface FetchCompanyTopShareholdersSuccessPayload {
  companyId: string;
  companyTopShareholders: Shareholder[];
}

export interface FetchCompanyTopShareholdersFailurePayload {
  status?: number;
  message: string;
}

export const fetchCompanyTopShareholders = createRoutine<
  SimplePayloadCreator<FetchCompanyTopShareholdersTriggerPayload>, // trigger
  SimplePayloadCreator,
  SimplePayloadCreator<FetchCompanyTopShareholdersSuccessPayload>, // success
  SimplePayloadCreator<FetchCompanyTopShareholdersFailurePayload>,
  SimplePayloadCreator
>('@/pages/CompanyReport/fetchTopShareholders', {});

import { useMemo } from 'react';
import {
  useFlag as useUnleashFlag,
  useVariant,
  useFlagsStatus,
} from '@unleash/proxy-client-react';

export type UseFlagArgs = {
  name: string;
};

/**
 * @deprecated - prefer usage of typed `useFlag` from `@simplywallst/unleash`
 */
export const useFlag = ({ name }: UseFlagArgs) => {
  const enabled = useUnleashFlag(name);
  const variant = useVariant(name);
  const { flagsReady, flagsError } = useFlagsStatus();

  return useMemo(
    () => ({
      enabled,
      variant,
      flagsReady,
      flagsError,
    }),
    [enabled, variant, flagsReady, flagsError]
  );
};

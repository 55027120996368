import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import type { ReactNode } from 'react';

import { snackbarReducer, getSnackbarState } from './snackbarReducer';
import type { SnackbarState, NewSnackbarItem } from './snackbarReducer';

type Context = {
  state: SnackbarState;
  addSnackbar(item: NewSnackbarItem): void;
  clearSnackbar(): void;
  removeSnackbar(options: { id: string }): void;
};

const SnackbarContext = createContext<Context | null>(null);

export function useSnackbar() {
  const context = useContext(SnackbarContext);

  if (context) {
    return context;
  }

  throw new Error(
    'Attempted to access SnackbarContext outside of a SnackbarProvider'
  );
}

type ProviderProps = {
  children: ReactNode;
  setReduxSagaContext?(context: { snackbar: Context }): void;
};

export const SnackbarProvider = ({
  children,
  setReduxSagaContext,
}: ProviderProps) => {
  const [state, dispatch] = useReducer(snackbarReducer, getSnackbarState());

  const value = useMemo<Context>(
    () => ({
      state,
      addSnackbar(payload) {
        dispatch({ type: 'ADD_SNACKBAR', payload });
      },
      clearSnackbar() {
        dispatch({ type: 'CLEAR_SNACKBAR' });
      },
      removeSnackbar(payload) {
        dispatch({ type: 'REMOVE_SNACKBAR', payload });
      },
    }),
    [state]
  );

  useEffect(() => {
    /**
     * Sync context to Redux, so sagas can trigger snackbars.
     * Note: this should be temporary, until we remove Redux.
     */
    setReduxSagaContext?.({ snackbar: value });
  }, [setReduxSagaContext, value]);

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};

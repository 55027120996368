import type { LocationState } from 'redux-first-router';
import { get, has } from 'lodash';

import {
  fetchPortfolioList as FetchPortfolioList,
  fetchPortfolio as FetchPortfolio,
  fetchPortfolioNews as FetchPortfolioNews,
  fetchPriceForPortfolioItem,
  addTransactionForItem as AddTransactionForItem,
  fetchPortfolioItems as FetchPortfolioItems,
  removeTransactionForItem as RemoveTransactionForItem,
  deletePortfolio as deletePortfolioRoutine,
  reinvestDividend as ReinvestDividends,
  showTransactionsModal as ShowTransactionsModal,
  hideTransactionsModal as HideTransactionsModal,
} from './routines';

import {
  ROUTE_DEPRECATED_PORTFOLIO_RETURNS_REPORT,
  ROUTE_DEPRECATED_PORTFOLIO_EDIT_TXN,
  ROUTE_DEPRECATED_PORTFOLIO,
  ROUTE_DEPRECATED_PUBLIC_PORTFOLIO,
} from '@/constants/routes';

import { clearHoldingsSearchResult as ClearHoldingsSearchResult } from '@/components/DeprecatedPortfolio/redux/routines';

const milliSecondsBeforePortfolioListRefresh = 60 * 60 * 1000; // Refresh only if older than 1 hour, as all DML operations will cause the list to refresh

export function fetchPortfolioList() {
  return FetchPortfolioList.trigger();
}

export function fetchPortfolioListWithRefreshCheck(location, state) {
  if (IsPortfolioListStale(state)) {
    return FetchPortfolioList.trigger();
  } else {
    return {
      type: 'NO_OP',
      payload: {
        location,
        message: 'Portfolio List is fresh, skipping portfolio list fetch',
      },
    };
  }
}

export function showTransactionsModal(data: { itemId: string }) {
  return ShowTransactionsModal.trigger(data);
}

export function hideTransactionsModal() {
  return HideTransactionsModal.trigger(null);
}

export function fetchContentIfNeeded(f2data) {
  return FetchPortfolio.trigger(f2data);
}

export function fetchPortfolio(id) {
  return FetchPortfolio.trigger({
    id,
  });
}

export function fetchPortfolioNews(id) {
  return FetchPortfolioNews.trigger({
    id,
  });
}

interface ViewPortfolioRoute {
  portfolioId: string;
  secret?: string;
}

export function fetchPortfolioAtLoad(location: LocationState) {
  const { portfolioId } = location.payload as ViewPortfolioRoute;
  return FetchPortfolio.trigger({
    id: portfolioId,
    isPublic: location.type === ROUTE_DEPRECATED_PUBLIC_PORTFOLIO,
  });
}

export function fetchPortfolioNewsAtLoad(location) {
  const {
    payload: { portfolioId },
  } = location;
  return FetchPortfolioNews.trigger({ id: portfolioId });
}

export function fetchPublicPortfolioNewsAtLoad(location) {
  const {
    payload: { portfolioId },
  } = location;
  return FetchPortfolioNews.trigger({ id: portfolioId, isPublic: true });
}

export function fetchPriceForItem(payload) {
  const { itemId, companyId, requestDate } = payload;

  return fetchPriceForPortfolioItem.trigger({
    itemId,
    companyId,
    startDate: requestDate,
  });
}

export function addTransactionForPortfolioItem({
  portfolioId,
  companyId,
  itemId,
  transactionType,
  transactionDate,
  numberOfShares,
  transactionCost,
}) {
  return AddTransactionForItem.trigger({
    portfolioId,
    companyId,
    itemId,
    transactionType,
    transactionDate,
    shareCount: Number(numberOfShares),
    transactionCost,
  });
}

export function getAllPortfolioItems(portfolioId: string, isPublic = false) {
  return FetchPortfolioItems.trigger({
    portfolioId,
    isPublic,
  });
}

export function removeTransactionForPortfolioItem(payload) {
  return RemoveTransactionForItem.trigger(payload);
}

export function redirectToPortfolioReturnsReport(payload) {
  return { type: ROUTE_DEPRECATED_PORTFOLIO_RETURNS_REPORT, payload };
}

export function redirectToEditTransactions(payload) {
  return { type: ROUTE_DEPRECATED_PORTFOLIO_EDIT_TXN, payload };
}

export function handleIFrameRedirectToPortfolio(data) {
  let payload = {};

  if (data.path) {
    //Cleanup the query string
    const urlPath = data.path.split('?');

    //Split the path into tokens
    const pathTokens = urlPath[0].split('/');

    //Incoming path : /user/portfolio/<portfolio_id>/<portfolio_name
    if (pathTokens.length > 3) {
      const id = pathTokens[3];

      //Name in the url is optional
      const name = pathTokens.length > 4 ? pathTokens[4] : 'Portfolio';

      payload = { portfolioId: id, name };
    }
  }

  return { type: ROUTE_DEPRECATED_PORTFOLIO, payload };
}

const IsPortfolioListStale = (state) => {
  if (has(state, ['portfolio', 'lastListRefreshTime'])) {
    const lastListRefreshTime = get(
      state,
      ['portfolio', 'lastListRefreshTime'],
      0
    );
    return (
      Date.now() - lastListRefreshTime > milliSecondsBeforePortfolioListRefresh
    );
  }

  return true;
};

export function clearHoldingsSearchResult() {
  return ClearHoldingsSearchResult.trigger();
}

export function deletePortfolio(data: { id: string; redirectToList: boolean }) {
  return deletePortfolioRoutine.trigger(data);
}

export function updateReinvestDividends(payload) {
  return ReinvestDividends.trigger(payload);
}

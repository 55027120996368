/**
 * When React is trying to reconcile its virtual DOM with the actual DOM it calls native
 * removeChild and insertBefore functions on the Node prototype. These calls are unsafe by default
 * and if you provide invalid child or a reference element that isn't in the parent element,
 * an unhandled error will be thrown which will crash the app.
 *
 * This can happen due to a number of reasons:
 * - Google translate is directly mutating DOM
 * - Users are mutating DOM in order to bypass paywall
 *
 * In the above mentioned instances we can safely ignore this as it doesn't cause any issues.
 * The reason why React team didn't decide to patch this on their side is that it will cause a slight
 * slowdown as we no longer rely on native functions but call 2 additional operations on top of the native implementation.
 *
 * Based on measurements this has roughly 15% slowdown for the mentioned methods which isn't significant
 * given how many times these methods are called per page.
 * */

export function domNodeChildHotfix() {
  try {
    if (RUNTIME_ENV === 'native') return;

    /**
     * Apply hotfix
     * https://github.com/facebook/react/issues/11538#issuecomment-417504600 */
    if (typeof Node === 'function' && Node.prototype) {
      const originalRemoveChild = Node.prototype.removeChild;
      // eslint-disable-next-line
      // @ts-ignore
      Node.prototype.removeChild = function (child) {
        if (child.parentNode !== this) {
          return child;
        }
        // eslint-disable-next-line
        return originalRemoveChild.apply(this, arguments as any);
      };

      const originalInsertBefore = Node.prototype.insertBefore;

      // eslint-disable-next-line
      // @ts-ignore
      Node.prototype.insertBefore = function (newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
          return newNode;
        }
        // eslint-disable-next-line
        return originalInsertBefore.apply(this, arguments as any);
      };
    }
  } catch (e) {}
}

import { useCallback } from 'react';
import { produce } from 'immer';

import useUser from '@/hooks/useUser';

import type { HoldingsSorting } from './types';

const DEFAULT_HOLDINGS_SORTING: HoldingsSorting = 'performance-7d-desc';

export function useHoldingsSorting() {
  const {
    data: user,
    mutate: updateUserSettings,
    setQueryData: setUserSettings,
  } = useUser();

  const holdingsSorting =
    user.portfolio?.holdingsSorting ?? DEFAULT_HOLDINGS_SORTING;

  const setHoldingsSorting = useCallback(
    (sorting: HoldingsSorting) => {
      setUserSettings((state) =>
        produce(state, (draft) => {
          draft.portfolio = state.portfolio ?? {};
          draft.portfolio.holdingsSorting = sorting;
        })
      );

      updateUserSettings({
        settings: {
          portfolio: {
            holdings_sorting: sorting,
          },
        },
      });
    },
    [setUserSettings, updateUserSettings]
  );

  return [holdingsSorting, setHoldingsSorting] as const;
}

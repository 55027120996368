import { getLocation } from '@/redux/location/selectors';
import routesGenerator from '@/router/routesGenerator';
import { useSelector } from 'react-redux';
import { NOT_FOUND, pathToAction } from 'redux-first-router';
import queryString from 'query-string';

export function useLocation() {
  return useSelector(getLocation);
}

export function getActionFromPath(path?: string) {
  if (typeof path === 'string') {
    const routesMap = routesGenerator();
    const action = pathToAction(path, routesMap, queryString);
    // only redirect if path is valid
    if (action.type !== NOT_FOUND) {
      return action;
    }
  }
}

import styled from 'styled-components';
import { media } from '@simplywallst/ui-core';
import type { HeadingElements } from '@simplywallst/ui-core/lib/src/components/Heading/types';

interface ReportSubSectionWrapperProps {
  titleElement: HeadingElements;
  split?: boolean;
}

export // Note
// sectionH2 and section are css variables

const ReportSubSectionWrapper = styled('section')<ReportSubSectionWrapperProps>`
  > hr + div {
    margin-top: ${({ theme }) => theme.x4spacePx}px;
  }

  > div > div + div {
    margin-top: ${({ theme }) => theme.x3space}rem;
    ${media.lgUp`
      margin-top: ${({ theme }) => theme.x4space}rem;
      ${({ split }) =>
        split &&
        `
          &:last-child {
            margin-top: 0;
          }
        `}
    `};
  }
`;

export const SubSectionTitleWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 0;
`;

interface SubSectionTitleProps {
  unOrdered?: boolean;
}

export const SubSectionTitle = styled('h3')<SubSectionTitleProps>`
  ${({ theme }) => theme.typography.xLargeStrong}
  position: relative;
  display: flex;

  ${({ unOrdered, theme }) =>
    !unOrdered &&
    `
      &:before {
        counter-increment: sectionH2;
        content: counter(section) '.' counter(sectionH2);
        opacity: 0.2;
        display: block;
        padding-right: ${theme.x1spacePx}px;
        margin-right: ${theme.x1spacePx}px;
      }
		`};
`;

export const ChartActionWrapper = styled('div')`
  text-align: right;
  margin-top: ${({ theme }) => theme.x3spacePx}px;
`;

export const AlertWrapper = styled('div')`
  margin-top: ${({ theme }) => theme.x2spacePx}px;
  margin-bottom: ${({ theme }) => theme.x2spacePx}px;
`;

import type { ISO2 } from '@/constants/countries';
import {
  toPercentage,
  toCurrencyTruncated,
  toCurrency,
} from '@/utilities/formatting';
import type { getDashboardRecentlyViewed } from '@simplywallst/services';

type Company = Awaited<
  ReturnType<typeof getDashboardRecentlyViewed>
>['data']['data'][number];
export interface CompanyEntity {
  name: string;
  uniqueSymbol: string;
  canonicalUrl: string;
  tickerSymbol: string;
  sharePriceFormatted: string;
  return7DFormatted: string;
  return1YrAbsFormatted: string;
  return7D: number | null;
  return1YrAbs: number | null;
  url: string;
  countryIso2: ISO2;
}

const formatterPercentage = (value: number | null) =>
  value !== null ? toPercentage(value, 1) : 'n/a';

const formatCurrency = (amount = 0, currencyISO?: string) => {
  if (amount > 99999) {
    return toCurrencyTruncated(amount, 1, currencyISO);
  }
  return toCurrency(amount, 2, currencyISO);
};

export default function selector(data: Company[] = []) {
  const entities = data.reduce(
    (a, b) => {
      a[b.id] = {
        name: b.name,
        canonicalUrl: b.canonical_url,
        uniqueSymbol: b.unique_symbol,
        tickerSymbol: b.ticker_symbol,
        sharePriceFormatted: formatCurrency(b.last_share_price, b.currency_iso),
        return7D: b.return_7d,
        return7DFormatted: formatterPercentage(b.return_7d),
        return1YrAbs: b.return_1yr_abs,
        return1YrAbsFormatted: formatterPercentage(b.return_1yr_abs),
        url: b.url,
        countryIso2: b.exchange_country_iso,
      };
      return a;
    },
    {} as Record<string, CompanyEntity>
  );

  return {
    entities,
    keys: data.map((n) => n.id),
  };
}

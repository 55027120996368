import { createRoutine } from 'redux-saga-routines';
import type { CompanyResponse } from '@/hooks/useCompany/types';
import type { LocationState } from 'redux-first-router';
type Company = CompanyResponse['data'];
export interface FetchCompanySuccessPayload {
  companyData: Company;
}

export interface FetchCompanyTriggerPayload {
  symbol?: string;
  companyId?: string;
  payload?: LocationState['payload'];
  type?: string;
}

export interface FetchCompanyFailurePayload {
  message: string;
  status?: number;
}

export interface FetchCompanyRequestPayload {
  companyId: string;
}

export const fetchCompany = createRoutine<
  SimplePayloadCreator<FetchCompanyTriggerPayload>, // trigger
  SimplePayloadCreator<FetchCompanyRequestPayload>,
  SimplePayloadCreator<FetchCompanySuccessPayload>, // success
  SimplePayloadCreator<FetchCompanyFailurePayload>,
  SimplePayloadCreator
>('@/pages/CompanyReport/fetch', {});

import { useContext, useLayoutEffect, useMemo, useState } from 'react';
import { UAParser } from 'ua-parser-js';
import type { Browser, OS, UserAgent } from './types';

import type { ReactNode } from 'react';
import { createContext } from 'react';

export const Context = createContext<string | undefined>(undefined);

interface Props {
  ua?: string;
  children: ReactNode;
}

export const UserAgentProvider = ({ ua, children }: Props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - FIXME: issues with multiple react versions and provider types - react 18 in deprecated-web, react 19 in news
  return <Context.Provider value={ua}>{children}</Context.Provider>;
};

export const Consumer = Context.Consumer;

const BOT_UA = [
  '\\+https:\\/\\/developers.google.com\\/\\+\\/web\\/snippet\\/',
  'googlebot',
  'baiduspider',
  'gurujibot',
  'yandexbot',
  'slurp',
  'msnbot',
  'bingbot',
  'facebookexternalhit',
  'linkedinbot',
  'twitterbot',
  'slackbot',
  'telegrambot',
  'applebot',
  'pingdom',
  'tumblr',
];

export const useUserAgent = (): UserAgent => {
  let uaString = useContext(Context);
  if (uaString === undefined && typeof window !== 'undefined') {
    uaString = window.navigator.userAgent;
  }

  const [result, setResult] = useState(new UAParser(uaString).getResult());

  // This is a little messy, but required to ensure that ipad and other tablet-like devices
  // with PC-like user agent strings can be correctly determined as tablet/mobile on clientside.
  useLayoutEffect(() => {
    async function fetchUserAgent() {
      try {
        if (
          result !== undefined &&
          typeof result?.withFeatureCheck === 'function'
        ) {
          const featureCheckResult = await result?.withFeatureCheck();
          setResult(featureCheckResult);
        }
      } catch (error) {
        // No need to throw error as it's not critical. Can swallow and move on.
        console.error(error);
      }
    }
    fetchUserAgent();
  }, [result]);

  const userAgent = useMemo(() => {
    const regex = new RegExp(`(${BOT_UA.join('|')})`, 'ig');
    const isBot = result.ua ? regex.test(result.ua.toLowerCase()) : false;

    const browser = (result.browser.name || 'unidentified') as Browser;
    const deviceType = result.device.type || null;
    const os = (result.os.name || 'unidentified') as OS;
    const engine = result.engine.name || 'unidentified';
    const isMobile = deviceType === 'mobile';
    const isTablet = deviceType === 'tablet';
    return {
      browser,
      deviceType,
      os,
      engine,
      isMobile,
      isTablet,
      source: result.ua,
      isDesktop: !isMobile && !isTablet,
      isBot,
    };
  }, [result]);

  return userAgent;
};

import { takeLatest, put, select } from 'redux-saga/effects';
import { getSummary } from '@/pages/CompanyReport/redux/selectors';
import { getCompanyIndustryAverage } from '@simplywallst/services';
import { fetchIndustryMarketAverage } from './routines';
import { ahoy } from '@ducks/saga';

export function* fetchIndustryAverage(
  action: ReturnType<(typeof fetchIndustryMarketAverage)['trigger']>
) {
  // Convert companyId (lookup) to lowercase
  const lookUp = action.payload.toLowerCase();
  try {
    if (!lookUp) {
      throw new Error('Required payload is undefined');
    }
    const summary: ReturnType<typeof getSummary> = yield select(getSummary, {
      companyId: lookUp,
    });
    const industryId = summary.industryAvergageId;
    const exchangeCountryISO = summary.exchangeCountryISO;
    if (!industryId || !exchangeCountryISO) {
      throw new Error(
        `Error in companyIndustryMarketSelector: ${industryId} | ${exchangeCountryISO}`
      );
    }
    yield put(fetchIndustryMarketAverage.request());
    const caller = ahoy();
    const averageResponse = yield caller(
      getCompanyIndustryAverage,
      `${summary.companyId}`
    );
    yield put(
      fetchIndustryMarketAverage.success({
        companyId: lookUp,
        exchangeCountryISO,
        averages: averageResponse,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(fetchIndustryMarketAverage.failure());
  } finally {
    yield put(fetchIndustryMarketAverage.fulfill());
  }
}

function* watchIndustryAverageFetchTrigger() {
  yield takeLatest(fetchIndustryMarketAverage.TRIGGER, fetchIndustryAverage);
}

export const watchers = [watchIndustryAverageFetchTrigger];

import { takeLatest, put, getContext } from 'redux-saga/effects';
import { editPortfolio } from './routines';
import type { EditPortfolioPayload } from './interface';
import { updatePortfolio as updatePortfolioService } from '@simplywallst/services';
import { fetchPortfolio } from '@/components/DeprecatedPortfolio/redux/routines';
import { getAuthenticatedAhoy } from '@ducks/saga';

function* editPortfolioSaga(id: string, name: string, currency = 'USD') {
  try {
    const caller = getAuthenticatedAhoy();
    return yield caller(updatePortfolioService, {
      id,
      payload: {
        name: name,
        sharing: 'false',
        currency_iso: currency,
        include: 'items,items.transactions',
      },
    });
  } catch (reason: any) {
    console.error('editPortfolioSaga caught', reason);
    throw reason;
  }
}

export function* editAndFetchNewPortfolioSaga(
  action: ReducerAction<EditPortfolioPayload>
) {
  try {
    if (!action.payload) throw new Error('Received empty payload');
    const { currency, portfolioId, portfolioName } = action.payload;
    const snackbar = yield getContext('snackbar');
    snackbar?.addSnackbar({
      message: 'Updating your portfolio',
      isLoading: true,
      lifeTime: 4000,
    });
    const response = yield editPortfolioSaga(
      portfolioId,
      portfolioName,
      currency
    );
    yield put(editPortfolio.success());
    yield put(
      fetchPortfolio.trigger({
        id: response.data.id.toString(),
      })
    );
  } catch (reason) {
    console.error(reason);
    yield put(
      editPortfolio.failure({
        message: reason,
      })
    );
  } finally {
    yield put(editPortfolio.fulfill());
  }
}

/** Watchers */

function* watchEditPortfolioTrigger() {
  yield takeLatest(editPortfolio.TRIGGER, editAndFetchNewPortfolioSaga);
}

export const exportableSagas = [watchEditPortfolioTrigger];

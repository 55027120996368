import { produce } from 'immer';
import type { Summary, CompanySuccessPayload } from '../interface';
import type { fetchCompetitors } from '../routines';
import type { CurrencyISO } from '@/constants/currencies';
import type { ISO2 } from '@/constants/countries';
import type { CompanyResponseExtended } from '@/hooks/useCompany/types';
type CompanyAPIRaw = CompanyResponseExtended['raw_data'];
type CompetitorAPIPayload = ReturnType<
  (typeof fetchCompetitors)['success']
>['payload']['competitors'][0];

export function getDefaultSummary(): Summary {
  return {
    mainHeader: '',
    mainThumb: '',
    isSearchable: true,
    lastAnalysisUpdate: 0,
    address1: '',
    address2: '',
    analystCount: 0,
    beta: 0,
    betaIndustry: 0,
    canonicalUrl: '',
    city: '',
    companyId: '',
    country: '',
    countryISO: '',
    currencyISO: '',
    currencySymbol: '',
    description: '',
    exchangeCountryISO: '',
    exchangeSymbol: '',
    exchangeTicker: '',
    exchangeSymbolFiltered: '',
    fundamentals: '',
    industryId: '',
    industryAvergageId: '',
    isFund: false,
    name: '',
    postCode: '',
    primaryCanonicalUrl: '',
    refreshTime: 0,
    restricted: false,
    shortDescription: '',
    shortName: '',
    shortNamePlural: '',
    slug: '',
    state: '',
    tickerSymbol: '',
    totalEmployeesFigure: '',
    uniqueSymbol: '',
    website: '',
    yearFounded: 0,
    snowflakePoints: [0, 0, 0, 0, 0],
    score: {
      future: 0,
      health: 0,
      income: 0,
      management: 0,
      misc: 0,
      past: 0,
      total: 0,
      value: 0,
    },
    primaryListingExchangeSymbol: '',
    primaryListingTickerSymbol: '',
    listings: [],
    industryData: {
      primaryId: '',
      primaryName: '',
      secondaryId: '',
      secondaryName: '',
      tertiaryId: '',
      tertiaryName: '',
    },
    return7D: 0,
    return1YrAbs: 0,
    marketCap: 0,
    sharePrice: 0,
    analystsPriceTarget: 0,
    pb: 0,
    pe: 0,
    ps: 0,
    growthEstimate: 0,
    earningsGrowth: 0,
    salesGrowth: 0,
    dividendYield: 0,
    logoUrl: '',
    classificationStatus: 'active',
    hasLogo: false,
  };
}

export function competitorSummaryReducer(
  summary = getDefaultSummary(),
  competitor: CompetitorAPIPayload
) {
  return produce(summary, (draft) => {
    if (competitor) {
      // Convert companyId to lowercase
      draft.companyId = competitor.company_id.toLowerCase();
      draft.canonicalUrl = competitor.canonical_url;
      draft.countryISO = competitor.canonical_url
        .split('/')[2]
        .toUpperCase() as ISO2;
      draft.exchangeSymbol = competitor.exchange_symbol;
      draft.lastAnalysisUpdate = competitor.last_updated;
      draft.shortName = competitor.name;
      draft.primaryCanonicalUrl =
        competitor.primary_canonical_url || competitor.canonical_url;
      draft.snowflakePoints = competitor.score.data.snowflake.data.axes;
      draft.slug = competitor.slug;
      draft.tickerSymbol = competitor.ticker_symbol;
      draft.uniqueSymbol = competitor.unique_symbol;
    }
  });
}

const getPrimaryListingSymbols = (
  listings: CompanyAPIRaw['data']['listings']
) => {
  const primaryListing = Object.values(listings).filter(
    (listing) => listing.primary_security && listing.primary_trading_item
  );
  if (primaryListing[0])
    return [primaryListing[0].exchange_symbol, primaryListing[0].ticker_symbol];
  return ['', ''];
};

export function summaryReducer(
  summary = getDefaultSummary(),
  companyData: CompanySuccessPayload
): Summary {
  return produce(summary, (draft) => {
    try {
      const { data: infoData } = companyData.info;
      const { industry } = companyData.info.data;

      draft.name = infoData.legal_name;
      draft.companyId = companyData.company_id.toLowerCase();
      draft.isSearchable = companyData.is_searchable;
      if (industry.primary_id) {
        draft.industryId = industry.primary_id.toString();
      }
      draft.canonicalUrl = companyData.canonical_url;
      /** */
      draft.mainHeader = infoData.main_header;
      draft.mainThumb = infoData.main_thumb;
      /** */
      draft.lastAnalysisUpdate = companyData.last_updated;
      draft.slug = companyData.slug;
      draft.exchangeSymbol = companyData.exchange_symbol;
      draft.currencyISO =
        infoData.currency as CurrencyISO; /** or defaultListing.currency_iso? */
      draft.isFund = infoData.fund;
      draft.shortName = infoData.legal_name;
      draft.primaryCanonicalUrl = companyData.primary_canonical_url;
      draft.refreshTime = Date.now();
      draft.shortDescription = infoData.description;
      draft.tickerSymbol = companyData.ticker_symbol;
      draft.uniqueSymbol = companyData.unique_symbol;
      draft.yearFounded = infoData.year_founded || NaN;
      const pattern = /^((http|https|ftp):\/\/)/;
      if (infoData.url !== null && !pattern.test(infoData.url)) {
        draft.website = 'https://' + infoData.url;
      } else if (infoData.url !== null) {
        draft.website = infoData.url;
      }
      draft.countryISO = infoData.country as ISO2;

      if (!draft.industryData) {
        draft.industryData = {
          primaryId: '',
          primaryName: '',
          secondaryId: '',
          secondaryName: '',
          tertiaryId: '',
          tertiaryName: '',
        };
      }

      draft.industryData.primaryName = infoData.industry.name;
      if (infoData.industry.primary_id) {
        draft.industryData.primaryId = infoData.industry.primary_id.toString();
      }
      if (infoData.industry.secondary_id) {
        draft.industryData.secondaryId =
          infoData.industry.secondary_id.toString();
      }
      if (infoData.industry.tertiary_id) {
        draft.industryData.tertiaryId =
          infoData.industry.tertiary_id.toString();
      }

      /* Dependend on score */
      if (companyData.score) {
        const { data: scoreData } = companyData.score;
        /** Score */
        const { sentence, snowflake, ...rest } = scoreData;
        draft.fundamentals = sentence;
        draft.snowflakePoints = snowflake.data.axes;
        draft.score = rest;
      }

      /* Dependend on analysis */
      if (companyData.analysis) {
        const { data: analysisData } = companyData.analysis;
        const { value: analysisValue } =
          companyData.analysis.data.extended.data.analysis;
        const {
          company_info: companyInfoRawData,
          industries: industriesRawData,
        } = companyData.analysis.data.extended.data.raw_data.data;
        const { trading_item_id } = companyData;
        const { listings } =
          companyData.analysis.data.extended.data.raw_data.data;
        const { industry_averages: industryAveragesData } =
          companyData.analysis.data.extended.data;

        draft.industryData.primaryName = industriesRawData.primary.name;
        draft.industryData.secondaryName = industriesRawData.secondary.name;
        draft.industryData.tertiaryName = industriesRawData.tertiary.name;

        draft.shortName = companyInfoRawData.short_name;

        draft.shortNamePlural = companyInfoRawData.short_name_plural;

        const defaultListing = listings[trading_item_id];
        const [primaryListingExchangeSymbol, primaryListingTickerSymbol] =
          getPrimaryListingSymbols(listings);
        draft.primaryListingExchangeSymbol = primaryListingExchangeSymbol;
        draft.primaryListingTickerSymbol = primaryListingTickerSymbol;
        draft.exchangeCountryISO =
          defaultListing.exchange_country_iso as typeof draft.exchangeCountryISO;
        draft.exchangeSymbolFiltered =
          companyInfoRawData.exchange_symbol_filtered;
        draft.exchangeTicker = listings[trading_item_id].exchange_name;
        draft.address1 = companyInfoRawData.address || '';
        draft.address2 = companyInfoRawData.address2 || '';
        draft.analystCount = analysisData.analyst_count;
        draft.beta = analysisValue.beta_5y !== null ? analysisValue.beta_5y : 0;
        draft.city = companyInfoRawData.city;
        draft.country = companyInfoRawData.country;
        draft.description = companyInfoRawData.description;
        draft.postCode = (companyInfoRawData.zip_code || '').toString();
        draft.state = companyInfoRawData.state;
        draft.classificationStatus = companyData.classification_status;
        if (industryAveragesData.industryId) {
          draft.industryAvergageId = industryAveragesData.industryId.toString();
        }
        draft.hasLogo = companyInfoRawData.has_logo !== null;
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Company reducer: Summary failed', {
          company: companyData.id,
          message: error.message,
          stack: error.stack,
        });
      }
    }
  });
}

import { withAuth } from '@/utilities/queries';
import { getCompanyCsvFile } from '@simplywallst/services';
import { useMutation } from '@tanstack/react-query';

/** duplicated from company/redux/saga */
function downloadFile(file: string, fileName: string) {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}
export function useDownloadCSV(
  lookupVal?: string,
  filename = 'company-report'
) {
  const mutate = useMutation({
    mutationFn: async () => {
      if (typeof lookupVal === 'undefined')
        throw new Error('lookup value is not defined');
      const response = await withAuth(getCompanyCsvFile, {
        lookupVal,
      });
      downloadFile(response.data, `${filename}.csv`);
    },
  });
  if (typeof lookupVal === 'undefined') return undefined;
  return mutate;
}

import { camelCase } from 'lodash';

import type IndustryAverageByIndustryCountry from '@industyAverages/interfaces/repository/IndustryAverageByIndustryCountry';
import type IIndustryAverageByIndustryAndCountry from './IIndustryAverageByIndustryAndCountry'; // eslint-disable-line no-unused-vars
import { getIndustry } from '@simplywallst/services';
class IndustryAverageByIndustryAndCountry
  implements IIndustryAverageByIndustryAndCountry
{
  async getIndustryAveragesByCountry(
    id: number,
    country?: string,
    timestamp?: number,
    includedFields: readonly string[] = []
  ): Promise<readonly IndustryAverageByIndustryCountry[]> {
    try {
      const response = await getIndustry({
        action: `${id}${country ? `/${country}` : ''}`,
        payload: {
          timestamp,
          include: includedFields.join(','),
        },
      });

      if (response && response.data) {
        const { id, name, countries } = response.data.data;
        const industryAveragesByCountryList: IndustryAverageByIndustryCountry[] =
          [];

        if (countries && countries.data) {
          countries.data.forEach((x) => {
            const industryAvgByCountry: IndustryAverageByIndustryCountry = {
              industryId: id,
              industryName: name,
              countryName: x.name,
              countryType: x.type,
              countryISO2: x.iso2,
              countryISO3: x.iso3,
            };

            if (x.fields && x.fields.data) {
              x.fields.data.forEach((avg) => {
                industryAvgByCountry[camelCase(avg.name)] = {
                  value: avg.value,
                  count: avg.count,
                };
              });
            }

            industryAveragesByCountryList.push(industryAvgByCountry);
          });
        }

        return Promise.resolve(industryAveragesByCountryList);
      } else {
        return Promise.reject(response);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default IndustryAverageByIndustryAndCountry;

import { useSelector } from 'react-redux';
import type { LocationState } from 'redux-first-router';
import {
  ROUTE_ABOUT,
  ROUTE_CAREERS,
  ROUTE_HOME,
  ROUTE_PLANS,
} from '@/constants/routes';

import { useDeferredScript } from './useScript';

export function useStatuspage() {
  const route = useSelector<{ location: LocationState }, string>(
    (state) => state.location.type
  );

  const isMarketingPage = [
    ROUTE_CAREERS,
    ROUTE_HOME,
    ROUTE_PLANS,
    ROUTE_ABOUT,
  ].includes(route);

  const status = useDeferredScript(
    'https://swttkpx937lg.statuspage.io/embed/script.js',
    {
      enabled:
        !isMarketingPage &&
        typeof window !== 'undefined' &&
        !window?.TEST_RUNNER,
    }
  );

  return status;
}

import {
  SENTRY_DSN,
  SENTRY_CAPACITOR_DSN,
  PUBLIC_RELEASE_VERSION,
  PUBLIC_SWS_ENV,
} from '@/constants/env';
import type { BrowserOptions } from '@sentry/react';
import type { getUser } from '@simplywallst/services';
import { reportPreInitErrors } from '../preInitErrors';

type User = Awaited<ReturnType<typeof getUser>>['data']['data'];

// initialize configures the Sentry
export async function initialiseSentry() {
  if (
    PUBLIC_SWS_ENV === 'local' ||
    (typeof window !== 'undefined' && window?.TEST_RUNNER === 'cypress')
  ) {
    return;
  }

  const DEFAULT_TRACE_SAMPLE_RATE = 0.012;
  const config = {
    dsn: RUNTIME_ENV === 'native' ? SENTRY_CAPACITOR_DSN : SENTRY_DSN,
    tracesSampleRate: DEFAULT_TRACE_SAMPLE_RATE,
    release: PUBLIC_RELEASE_VERSION || 'unversioned-release',
    environment: PUBLIC_SWS_ENV || 'local',
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/simplywall\.st\/api/,
      /^https:\/\/simplywall\.st\/dashboard/,
      /^https:\/\/simplywall\.st\/graphql/,
      /^https:\/\/billing\.simplywall\.st\//,
      /^https:\/\/simplywall\.st\/backend\/statements/,
    ],
    replaysOnErrorSampleRate: 0.005, // we only get 50 replays
    /** As per https://docs.sentry.io/platforms/javascript/#decluttering-sentry */
    ignoreErrors: [
      // See: https://github.com/getsentry/sentry/issues/9331
      '_avast_submit',
      // See: https://stackoverflow.com/a/69576781/2248347
      'instantSearchSDKJSBridgeClearHighlight',
      //
      'ChunkLoadError',
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Google translate
      'a[b].target.className.indexOf is not a function',
      'Uncaught TypeError: a[b].target.className.indexOf is not a function',
      // Error generated by a bug in auto-fill library from browser
      // https://github.com/getsentry/sentry/issues/5267
      /Blocked a frame with origin/,
      /** Safe to ignore https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded */
      'ResizeObserver loop limit exceeded',
      /**
       * Unable to replicate, it is unlikely to be causing user issues as the observer has in-built
       * infinite loop prevention.
       * https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
       * */
      'ResizeObserver loop completed with undelivered notifications.',
      'UnhandledRejection: Non-Error promise rejection captured with value:',
      /Loading chunk [\d]+ failed/,
      `can't redefine non-configurable property "userAgent"`,
      /**
       * AdBlock Stands triggers this error, which doesn't seem to break anything apart from GTM.
       */
      'Cannot redefine property: googletag',
    ],
    denyUrls: [
      'translate.googleapis.com',
      'apis.google.com',
      'connect.facebook.net',
      'embed.typeform.com',
      'checkout.stripe.com',
      'js.stripe.com',
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  } satisfies BrowserOptions;
  if (RUNTIME_ENV === 'native') {
    const module = await import('./capacitor');
    module.initialiseSentry({
      ...config,
      enableWatchdogTerminationTracking: true,
    });
  } else {
    const module = await import('./web');
    module.initialiseSentry(config);
  }
  // Report any errors that occurred before Sentry was initialized
  reportPreInitErrors();
}

export async function setUser(user: User) {
  const config = {
    id: user.id,
  };
  if (RUNTIME_ENV === 'native') {
    const module = await import('./capacitor');
    module.setUser(config);
  } else {
    const module = await import('./web');
    module.setUser(config);
  }
}

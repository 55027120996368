import { createSelector } from 'reselect';
import type { LocationState } from 'redux-first-router';
import { ViewMode } from '@/pages/CompanyReport/enum';

export const getLocation = (state): LocationState => state.location;
export const getLocationQueryUnleashOverrides = (state): string =>
  state.location?.query?.unleashOverrides ?? '';

export const getLocationType = createSelector(
  [getLocation],
  (location) => location.type
);

export const getLocationPathname = createSelector(
  [getLocation],
  (location) => location.pathname
);

export const getLocationPayload = createSelector(
  [getLocation],
  (location) => location.payload
);

export const getLocationQueryToken = createSelector(
  [getLocation],
  (location) => {
    const token = location.query?.token;
    return typeof token === 'string' ? token : null;
  }
);

export const getViewMode = createSelector(
  [getLocation],
  (location): ViewMode => {
    return location.query &&
      location.query.viewmode &&
      location.query.viewmode === ViewMode.Print
      ? ViewMode.Print
      : ViewMode.Screen;
  }
);

export const makeViewModeSelector = () => getViewMode;

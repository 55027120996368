// Create a queue to store early errors
const preInitErrors: unknown[] = [];

// Function to handle errors before init
export function captureEarlyError(error: unknown) {
  preInitErrors.push(error);
}

export async function reportPreInitErrors() {
  const { captureException } = await import('@sentry/react');
  preInitErrors.forEach((error) => {
    captureException(error);
  });
  preInitErrors.length = 0; // Clear the queue
}

export enum CompanyAnchor {
  COMPANY_SUMMARY_ANCHOR = 'summary',
  COMPANY_ABOUT_ANCHOR = 'about',
  COMPANY_EXECUTIVE_SUMMARY_ANCHOR = 'executive-summary',
  COMPANY_PRICE_NEWS_ANCHOR = 'share-price-and-news',
  COMPANY_VALUE_ANCHOR = 'valuation',
  COMPANY_FUTURE_ANCHOR = 'future',
  COMPANY_PAST_ANCHOR = 'past',
  COMPANY_HEALTH_ANCHOR = 'health',
  COMPANY_DIVIDEND_ANCHOR = 'dividend',
  COMPANY_MANAGEMENT_ANCHOR = 'management',
  COMPANY_OWNERSHIP_ANCHOR = 'ownership',
  COMPANY_INFORMATION_ANCHOR = 'information',
}
export enum MarketAnchor {
  MARKET_VALUATION_ANCHOR = 'valuation',
  MARKET_NEWS_ANCHOR = 'news',
  MARKET_TRENDS_ANCHOR = 'trends',
  MARKET_GAINERS_ANCHOR = 'topgainers',
}

export const COMPANY_SUMMARY_ANCHOR = CompanyAnchor.COMPANY_SUMMARY_ANCHOR;
export const COMPANY_ABOUT_ANCHOR = CompanyAnchor.COMPANY_ABOUT_ANCHOR;
export const COMPANY_EXECUTIVE_SUMMARY_ANCHOR =
  CompanyAnchor.COMPANY_EXECUTIVE_SUMMARY_ANCHOR;
export const COMPANY_PRICE_NEWS_ANCHOR =
  CompanyAnchor.COMPANY_PRICE_NEWS_ANCHOR;
export const COMPANY_VALUE_ANCHOR = CompanyAnchor.COMPANY_VALUE_ANCHOR;
export const COMPANY_FUTURE_ANCHOR = CompanyAnchor.COMPANY_FUTURE_ANCHOR;
export const COMPANY_PAST_ANCHOR = CompanyAnchor.COMPANY_PAST_ANCHOR;
export const COMPANY_HEALTH_ANCHOR = CompanyAnchor.COMPANY_HEALTH_ANCHOR;
export const COMPANY_DIVIDEND_ANCHOR = CompanyAnchor.COMPANY_DIVIDEND_ANCHOR;
export const COMPANY_MANAGEMENT_ANCHOR =
  CompanyAnchor.COMPANY_MANAGEMENT_ANCHOR;
export const COMPANY_OWNERSHIP_ANCHOR = CompanyAnchor.COMPANY_OWNERSHIP_ANCHOR;
export const COMPANY_INFORMATION_ANCHOR =
  CompanyAnchor.COMPANY_INFORMATION_ANCHOR;

//Company sub-section anchors
export const SUMMARY_STOCK_OVERVIEW = 'overview';
export const SUMMARY_COMPETITORS = 'competitors';
export const SUMMARY_PRICE_CHART = 'price-chart';
export const SUMMARY_NEWS = 'news';
export const SUMMARY_ABOUT_COMPANY = 'about';
export const SUMMARY_FUNDAMENTALS = 'fundamentals';
export const SUMMARY_EARNINGS_REVENUE = 'earnings-revenue';
//new valuation section in a/b testing
export const VALUATION_KEY_METRICS = 'key-metrics';
export const VALUATION_RATIO_VS_PEERS = 'ratio-vs-peers';
export const VALUATION_RATIO_VS_INDUSTRY = 'ratio-vs-industry';
export const VALUATION_FAIR_RATIO = 'fair-ratio';
export const VALUATION_PRICE_VS_FAIR_RATIO = 'intrinsic-value';
export const ANALYST_PRICE_TARGETS = 'analyst-price-targets';
export const VALUATION_HISTORICAL_TIMESERIES =
  'valuation-historical-timeseries';

//current valuation section
export const VALUATION_PRICE_VS_FAIR_VALUE = 'intrinsic-value';
export const VALUATION_PE_RATIO = 'PE-gauge';
export const VALUATION_PE_GROWTH = 'PEG-gauge';
export const VALUATION_PB_RATIO = 'PB-gauge';

export const FUTURE_EARNINGS = 'future-profit';
export const FUTURE_GROWTH = 'growth-in-earnings';
export const FUTURE_EARNINGS_PER_SHARE = 'past-future';
export const FUTURE_RETURN_EQUITY = 'future-perf';

export const PAST_EARNINGS_REVENUE = 'income-statement';
export const PAST_EARNINGS_GROWTH = 'past-earnings-growth';
export const PAST_ROE = 'ROE-past';
export const PAST_ROA = 'ROA-past';

export const HEALTH_FINANCIAL_POSITION = 'net-worth';
export const HEALTH_DEBT_HISTORY = 'historical-debt';
export const HEALTH_BALANCE_SHEET = 'balance-sheet';
export const HEALTH_CASH_RUNWAY = 'cash-runway';
export const HEALTH_FINANCIAL_INSTITUTION = 'financial-institution';

export const DIVIDEND_YIELD = 'dividend-yield-market';
export const DIVIDEND_STABILITY_GROWTH = 'historical-dividend-yield';
export const DIVIDEND_PAYOUT_CURRENT = 'current-dividend-payout';
export const DIVIDEND_PAYOUT_FUTURE = 'future-dividend-payout';

export const MANAGEMENT_CEO_SALARY = 'ceo_salary';
export const MANAGEMENT_CEO = 'CEO-details';
export const MANAGEMENT_TENURE = 'management-tenure';
export const MANAGEMENT_BOARD_TENURE = 'board-tenure';

export const PORTFOLIO_SUMMARY_ANCHOR = 'summary';
export const PORTFOLIO_HOLDINGS_ANCHOR = 'holdings';
export const PORTFOLIO_NEWS_ANCHOR = 'news';
export const PORTFOLIO_RETURNS_ANCHOR = 'performance';
export const PORTFOLIO_FUTURE_ANCHOR = 'future';
export const PORTFOLIO_DIVIDEND_ANCHOR = 'dividend';
export const PORTFOLIO_DIVERSIFICATION_ANCHOR = 'diversification';
export const PORTFOLIO_VALUATION_ANCHOR = 'valuation';
export const PORTFOLIO_PAST_ANCHOR = 'past';
export const PORTFOLIO_HEALTH_ANCHOR = 'health';

export const MARKET_VALUATION_ANCHOR = MarketAnchor.MARKET_VALUATION_ANCHOR;
export const MARKET_NEWS_ANCHOR = MarketAnchor.MARKET_NEWS_ANCHOR;
export const MARKET_TRENDS_ANCHOR = MarketAnchor.MARKET_TRENDS_ANCHOR;
export const MARKET_GAINER_ANCHOR = MarketAnchor.MARKET_GAINERS_ANCHOR;

export const LATEST_NEWS_AND_UPDATES_ANCHOR = 'latest-news-and-updates';

/**
 * Note: These anchors could still be used by news or other external sources
 * but are no longer used in internal linking
 */
export enum LegacyAnchor {
  DEBT_EQUITY = 'debt_equity',
  HEADER_PRICE_EVENTS = 'price-chart',
  INCOME = 'income',
  INCOME_ESTIMATE = 'income-estimate',
  LAST_PERF = 'last-perf',
  OWNERSHIP_INSIDER_TRADING = 'insider_trading',
  OWNERSHIP_INSIDER_TRANSACTIONS = 'insider-transactions',
  OWNERSHIP = 'ownership_summary',
  PAST_FUTURE_EARNINGS = 'past-future-earnings',
  PAST_ROCE = 'ROCE-past',
  VALUE = 'value',
  VALUE_INTRINSIC_VALUE = 'intrinsic-value',
  VALUE_PE_PEG = 'PE-PEG-gauge',
}

//For company report split
export const ANCHOR_TO_SECTION_MAPPING = {
  //Summary
  [SUMMARY_STOCK_OVERVIEW]: CompanyAnchor.COMPANY_SUMMARY_ANCHOR,
  [SUMMARY_COMPETITORS]: CompanyAnchor.COMPANY_SUMMARY_ANCHOR,
  [SUMMARY_PRICE_CHART]: CompanyAnchor.COMPANY_SUMMARY_ANCHOR,
  [SUMMARY_NEWS]: CompanyAnchor.COMPANY_SUMMARY_ANCHOR,
  [SUMMARY_ABOUT_COMPANY]: CompanyAnchor.COMPANY_SUMMARY_ANCHOR,
  [SUMMARY_FUNDAMENTALS]: CompanyAnchor.COMPANY_SUMMARY_ANCHOR,
  [SUMMARY_EARNINGS_REVENUE]: CompanyAnchor.COMPANY_SUMMARY_ANCHOR,
  //Valuation
  [VALUATION_KEY_METRICS]: CompanyAnchor.COMPANY_VALUE_ANCHOR,
  [VALUATION_RATIO_VS_PEERS]: CompanyAnchor.COMPANY_VALUE_ANCHOR,
  [VALUATION_RATIO_VS_INDUSTRY]: CompanyAnchor.COMPANY_VALUE_ANCHOR,
  [VALUATION_FAIR_RATIO]: CompanyAnchor.COMPANY_VALUE_ANCHOR,
  [VALUATION_PRICE_VS_FAIR_RATIO]: CompanyAnchor.COMPANY_VALUE_ANCHOR,
  [ANALYST_PRICE_TARGETS]: CompanyAnchor.COMPANY_VALUE_ANCHOR,
  [LegacyAnchor.VALUE]: CompanyAnchor.COMPANY_VALUE_ANCHOR,
  [LegacyAnchor.VALUE_PE_PEG]: CompanyAnchor.COMPANY_VALUE_ANCHOR,
  //current valuation section
  [VALUATION_PE_RATIO]: CompanyAnchor.COMPANY_VALUE_ANCHOR,
  [VALUATION_PE_GROWTH]: CompanyAnchor.COMPANY_VALUE_ANCHOR,
  [VALUATION_PB_RATIO]: CompanyAnchor.COMPANY_VALUE_ANCHOR,
  //Future
  [FUTURE_EARNINGS]: CompanyAnchor.COMPANY_FUTURE_ANCHOR,
  [FUTURE_GROWTH]: CompanyAnchor.COMPANY_FUTURE_ANCHOR,
  [FUTURE_EARNINGS_PER_SHARE]: CompanyAnchor.COMPANY_FUTURE_ANCHOR,
  [FUTURE_RETURN_EQUITY]: CompanyAnchor.COMPANY_FUTURE_ANCHOR,
  [LegacyAnchor.INCOME_ESTIMATE]: CompanyAnchor.COMPANY_FUTURE_ANCHOR,
  //Past
  [PAST_EARNINGS_REVENUE]: CompanyAnchor.COMPANY_PAST_ANCHOR,
  [PAST_EARNINGS_GROWTH]: CompanyAnchor.COMPANY_PAST_ANCHOR,
  [PAST_ROE]: CompanyAnchor.COMPANY_PAST_ANCHOR,
  [PAST_ROA]: CompanyAnchor.COMPANY_PAST_ANCHOR,
  [LegacyAnchor.LAST_PERF]: CompanyAnchor.COMPANY_PAST_ANCHOR,
  [LegacyAnchor.PAST_FUTURE_EARNINGS]: CompanyAnchor.COMPANY_PAST_ANCHOR,
  [LegacyAnchor.PAST_ROCE]: CompanyAnchor.COMPANY_PAST_ANCHOR,
  //Health
  [HEALTH_FINANCIAL_POSITION]: CompanyAnchor.COMPANY_HEALTH_ANCHOR,
  [HEALTH_DEBT_HISTORY]: CompanyAnchor.COMPANY_HEALTH_ANCHOR,
  [HEALTH_BALANCE_SHEET]: CompanyAnchor.COMPANY_HEALTH_ANCHOR,
  [HEALTH_CASH_RUNWAY]: CompanyAnchor.COMPANY_HEALTH_ANCHOR,
  [HEALTH_FINANCIAL_INSTITUTION]: CompanyAnchor.COMPANY_HEALTH_ANCHOR,
  [LegacyAnchor.DEBT_EQUITY]: CompanyAnchor.COMPANY_HEALTH_ANCHOR,
  //Dividend
  [DIVIDEND_YIELD]: CompanyAnchor.COMPANY_DIVIDEND_ANCHOR,
  [DIVIDEND_STABILITY_GROWTH]: CompanyAnchor.COMPANY_DIVIDEND_ANCHOR,
  [DIVIDEND_PAYOUT_CURRENT]: CompanyAnchor.COMPANY_DIVIDEND_ANCHOR,
  [DIVIDEND_PAYOUT_FUTURE]: CompanyAnchor.COMPANY_DIVIDEND_ANCHOR,
  [LegacyAnchor.INCOME]: CompanyAnchor.COMPANY_DIVIDEND_ANCHOR,
  //Management
  [MANAGEMENT_CEO]: CompanyAnchor.COMPANY_MANAGEMENT_ANCHOR,
  [MANAGEMENT_CEO_SALARY]: CompanyAnchor.COMPANY_MANAGEMENT_ANCHOR,
  [MANAGEMENT_TENURE]: CompanyAnchor.COMPANY_MANAGEMENT_ANCHOR,
  [MANAGEMENT_BOARD_TENURE]: CompanyAnchor.COMPANY_MANAGEMENT_ANCHOR,
  //Ownership
  [LegacyAnchor.OWNERSHIP]: CompanyAnchor.COMPANY_OWNERSHIP_ANCHOR,
  [LegacyAnchor.OWNERSHIP_INSIDER_TRADING]:
    CompanyAnchor.COMPANY_OWNERSHIP_ANCHOR,
  [LegacyAnchor.OWNERSHIP_INSIDER_TRANSACTIONS]:
    CompanyAnchor.COMPANY_OWNERSHIP_ANCHOR,
  //Information
} as const;

import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { connectRoutes } from 'redux-first-router';
import createSagaMiddleware, { END } from 'redux-saga';
import AppStart from '@/routines/application/appStart';
import getRootSaga from '../sagas/rootSaga';
import { getDefaultState } from '../constants/loadDefaultState';
import * as reducers from '../reducers';
import rootReducer from '../reducers/rootReducer';
import { getRouterOptions } from '../router/options';
import routesGenerator from '../router/routesGenerator';
import { getDefaultState as getPortfolioDefaultState } from '@/components/DeprecatedPortfolio/redux/reducers';
import { getDefaultState as getUIDefaultState } from '@ducks/ui';
import { readStore, storeSubscriber } from '../utils/localStore';
import type { QueryClient } from '@tanstack/react-query';

const composeEnhancers = (...args) =>
  NODE_ENV === 'development'
    ? composeWithDevTools({ trace: true })(...args)
    : compose(...args);

interface Cookie {
  authCookies?: Record<string, unknown>;
  marketSelectionCookie: string;
  portfolioIdCookie: string;
}

const mappedRoutes = routesGenerator();
export default function configureStore(
  initialEntries,
  preloadedState,
  isServerSide,
  cookies: null | Cookie = null,
  ui: any = null,
  queryClient?: QueryClient
) {
  const defaultPreloadedState = {
    ...getDefaultState(),
    ...preloadedState,
  };

  if (typeof preloadedState === 'undefined') {
    const persistentPortfolioState = readStore();
    if (persistentPortfolioState) {
      const { portfolios, company, portfolio } = persistentPortfolioState;
      if (portfolios) {
        defaultPreloadedState['portfolios'] = portfolios;
      }
      if (company) {
        defaultPreloadedState['company'] = company;
      }
      if (portfolio) {
        defaultPreloadedState['portfolio'] = portfolio;
      }
    }
  }

  const sagaMiddleware = createSagaMiddleware({
    context: {
      queryClient,
    },
  });
  const options = getRouterOptions(
    initialEntries
      ? {
          initialEntries,
        }
      : {}
  );
  const { reducer, middleware, enhancer, thunk } = connectRoutes(
    mappedRoutes,
    options
  );

  const combinedMiddleware = [sagaMiddleware, middleware];
  const appReducer = combineReducers({
    ...reducers,
    location: reducer,
  });
  const middlewares = applyMiddleware(...combinedMiddleware);
  const enhancers = composeEnhancers(enhancer, middlewares) as any;
  const parsedPreloadedState = isServerSide
    ? {
        ...defaultPreloadedState,
        ...(cookies && cookies.marketSelectionCookie
          ? {
              user: {
                ...defaultPreloadedState.user,
                selectedMarket: cookies.marketSelectionCookie,
              },
            }
          : {}),
        ...(cookies && cookies.portfolioIdCookie
          ? {
              portfolio: {
                ...getPortfolioDefaultState(),
                ...defaultPreloadedState.portfolio,
                currentId: cookies.portfolioIdCookie,
                defaultId: cookies.portfolioIdCookie,
              },
            }
          : {}),
        ...(ui
          ? {
              ui: {
                ...getUIDefaultState(),
                ...defaultPreloadedState.ui,
                cfIpCountry: ui.cfIpCountry?.toLowerCase(),
              },
            }
          : {}),
      } // no preloaded state on server except for the cookies
    : {
        ...defaultPreloadedState,
        ...(ui
          ? {
              ui: {
                ...defaultPreloadedState.ui,
                ...(ui.appVersion && typeof ui.appVersion === 'string'
                  ? {
                      appVersion: ui.appVersion,
                    }
                  : {}),
              },
            }
          : {}),
      };

  const store = createStore(
    rootReducer(appReducer),
    parsedPreloadedState,
    enhancers
  );

  if (NODE_ENV === 'development') {
    if ((module as any).hot) {
      (module as any).hot.accept('../reducers/index', () => {
        // eslint-disable-next-line
        const reducers = require('../reducers/index');
        const appReducer = combineReducers({
          ...reducers,
          location: reducer,
        });
        store.replaceReducer(rootReducer(appReducer));
      });
    }
  }

  const { dispatch, subscribe } = store;
  subscribe(storeSubscriber(store));

  /** @type AppSagaContext */
  const context = {
    analytics: RUNTIME_ENV !== 'server' && (window as any).analytics,
    dispatch,
  };
  const rootSagas = getRootSaga(context);
  // Dispatch App Start event
  dispatch(AppStart.trigger({}));
  const runSaga = sagaMiddleware.run;
  const close = () => dispatch(END);

  return {
    store,
    thunk,
    sagas: {
      rootSagas,
      runSaga,
      close,
    },
  };
}

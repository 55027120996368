import { pathToAction } from 'redux-first-router';
import routesGenerator from '@/router/routesGenerator';
import { ROUTE_COMPANY } from '@/constants/routes';
import { isValidSection } from '@/pages/CompanyReport/useSection';
import type { captureEvent as captureEventReact } from '@sentry/react';
import type { captureEvent as captureEventCapacitor } from '@sentry/capacitor';
export function getCurrentRoute() {
  try {
    const action = pathToAction(window.location.pathname, routesGenerator());
    if (action.type === ROUTE_COMPANY) {
      const { payload } = action;
      const { section } = payload;
      //For company split routes
      //TODO: @victorc merge names back to @COMPANY for sentry later
      if (typeof section === 'string' && isValidSection(section)) {
        return `${action.type}/SPLIT/${section.toUpperCase()}`;
      }
      return `${action.type}/SPLIT`;
    }
    return action.type;
  } catch {
    return 'unmapped';
  }
}

type ReactOrCapacitorEvent =
  | Parameters<typeof captureEventReact>[0]
  | Parameters<typeof captureEventCapacitor>[0];

type ReactOrCapacitorHint =
  | Parameters<typeof captureEventReact>[1]
  | Parameters<typeof captureEventCapacitor>[1];

// captureContext differs between web and native, if needed in the future, further type massaging is needed
export async function captureEvent(
  event: ReactOrCapacitorEvent,
  hint?: ReactOrCapacitorHint
) {
  if (RUNTIME_ENV === 'native') {
    const module = await import('./capacitor');
    module.logEvent(
      event as Parameters<typeof captureEventCapacitor>[0],
      hint as Parameters<typeof captureEventCapacitor>[1]
    );
  } else {
    const module = await import('./web');
    module.logEvent(
      event as Parameters<typeof captureEventReact>[0],
      hint as Parameters<typeof captureEventReact>[1]
    );
  }
}

import { withAuth } from '@/utilities/queries';
import { getUacCheck } from '@simplywallst/services';
import { useQuery } from '@tanstack/react-query';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useUser } from '../useUser';

export function getQueryKey(id = '') {
  return ['USER_ACCESS', id] as const;
}

export const getDefaultData = () => {
  return {
    role: '',
    plan: '',
    planType: '',
    provider: 'stripe',
    companiesAnalysedInPortfolio: -1,
    createPortfolios: -1,
    viewCompaniesInView: -1,
    viewCompanyReports: -1,
    canGetCompanyReportPdf: false,
    canGetCompanyReportCsv: false,
    canGetPortfolioReportCsv: false,
    canSaveScreener: false,
    canDistributeCompanyReportPdf: false,
    canHaveProfileImage: false,
    brokerReferrer: null,
    numberOfViewableScreeners: null,
  } as const;
};

export async function queryFn(
  context: QueryFunctionContext<ReturnType<typeof getQueryKey>>
) {
  const [, id] = context.queryKey;
  let response: undefined | Awaited<ReturnType<typeof getUacCheck>>;
  if (id !== '') {
    response = await withAuth(getUacCheck);
  } else {
    response = await getUacCheck();
  }
  const [uac] = response.data.data;
  return {
    role: uac.role,
    plan: uac.plan,
    planType: uac.plan_type,
    provider: uac.provider,
    companiesAnalysedInPortfolio: uac.companies_analysis,
    viewCompaniesInView: uac.view_companies_in_view,
    viewCompanyReports: uac.view_company_reports,
    createPortfolios: uac.create_portfolios,
    canGetCompanyReportPdf:
      uac.company_pdf_printing === -1 || uac.company_pdf_printing === 1,
    canGetCompanyReportCsv:
      uac.company_csv_download === -1 || uac.company_csv_download === 1,
    canDistributeCompanyReportPdf:
      uac.company_pdf_distribution === -1 || uac.company_pdf_distribution === 1,
    canSaveScreener: uac.save_view === -1,
    canGetPortfolioReportCsv:
      uac.user_portfolio_analysis_csv === -1 ||
      uac.user_portfolio_analysis_csv === 1,
    canHaveProfileImage: uac.profile_image === -1 || uac.profile_image === 1,
    brokerReferrer: uac.broker_referrer,
    numberOfViewableScreeners: uac.number_of_viewable_screeners,
  };
}

export function useUserAccess() {
  const {
    data: { id },
    queryStatus,
  } = useUser();
  const { data, status } = useQuery({
    queryKey: getQueryKey(id),
    queryFn,
    staleTime: Infinity,
    enabled: queryStatus === 'success',
  });
  if (status === 'success') {
    return {
      status,
      data,
    };
  }

  return { status, data: getDefaultData() };
}

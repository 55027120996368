import { produce } from 'immer';
import type { TopShareholders } from '../interface/interface';
import type { getCompanyTopShareholders } from '@simplywallst/services';

type Shareholder = Awaited<
  ReturnType<typeof getCompanyTopShareholders>
>['data']['data'][number];

export function shareholderReducer(
  currentShareholders: Readonly<TopShareholders> = {},
  newShareholders: Shareholder[]
) {
  return produce(currentShareholders, (draft) => {
    try {
      let currentRankingNumber = 0;
      newShareholders.forEach((shareholder) => {
        /**
         *API response may include some entires with null ranking values
         only at the end of the list, in which case we use the last not null ranking plus one
         */
        currentRankingNumber =
          shareholder.rank_shares_held === null
            ? ++currentRankingNumber
            : shareholder.rank_shares_held;
        const newShareholder = {
          rankSharesHeld: currentRankingNumber,
          owner: {
            name: shareholder.owner.name,
            type: shareholder.owner.type,
            companyType: shareholder.owner.company_type || 0,
            objectId: shareholder.owner.object_id,
          },
          holdingDate: shareholder.holding_date,
          sharesHeld: shareholder.shares_held,
          percentOfPortfolio: shareholder.percent_of_portfolio || 0,
          percentOfSharesOutstanding: shareholder.percent_of_shares_outstanding,
          sharesChanged: shareholder.shares_changed,
          percentSharesChanged: shareholder.percent_shares_changed || 0,
          rankSharesBought: shareholder.rank_shares_bought || 0,
          rankSharesSold: shareholder.rank_shares_sold || 0,
          periodStartDate: shareholder.period_start_date,
          periodEndDate: shareholder.period_end_date,
        };

        draft[shareholder.owner.object_id] = newShareholder;
      });
    } catch (error) {
      if (error instanceof Error) {
        console.error('Shareholder Reducer failed', {
          message: error.message,
          stack: error.stack,
        });
      }
    }
  });
}

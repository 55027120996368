import { takeLatest, put } from 'redux-saga/effects';
import { PublicPortfolioFetch } from './routines';
import { getPortfolios } from '@simplywallst/services';
import { ahoy } from '@ducks/saga';
type Response = Awaited<ReturnType<typeof getPortfolios>>['data'];
export function* exploreFetchTrigger() {
  try {
    yield put(PublicPortfolioFetch.request());
    const caller = ahoy();
    const response: Response = yield caller(getPortfolios, { isPublic: true });
    yield put(PublicPortfolioFetch.success({ portfolios: response.data }));
  } catch (reason) {
    yield put(PublicPortfolioFetch.failure());
  } finally {
    yield put(PublicPortfolioFetch.fulfill());
  }
}

function* watchPublicPortfolioFetch() {
  yield takeLatest(PublicPortfolioFetch.TRIGGER, exploreFetchTrigger);
}

export const exportableSagas = [watchPublicPortfolioFetch];

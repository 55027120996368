import { getUser } from '@simplywallst/services';
import { withAuth } from '@utilities/queries';
import reducer from './reducer';
import { getAuthCookie } from '@utilities/cookies';
export default async function queryFn(): Promise<
  ReturnType<typeof reducer> | undefined
> {
  try {
    const hasCookie = await getAuthCookie();
    let response: Awaited<ReturnType<typeof getUser>> | undefined;
    if (typeof hasCookie === 'undefined') {
      response = await getUser();
    } else {
      response = await withAuth(getUser);
    }
    if (typeof response !== 'undefined') {
      const { data: profile } = response.data;
      return reducer(undefined, profile);
    }
  } catch (e) {
    return undefined;
  }
}

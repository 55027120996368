import { get } from "../methods";
import { removeLeadingSlash } from "../utils";
function getPriceHistory(options, headers = {}) {
  const { companyId, payload } = options;
  return get({
    url: `api/company/price/${removeLeadingSlash(companyId || "")}`,
    headers,
    payload
  });
}
export {
  getPriceHistory as default
};

import { produce } from 'immer';
import { fetchCompanyNews } from '@/pages/CompanyReport/redux/routines';
import { fetchPortfolioNews } from '@/components/DeprecatedPortfolio/redux/routines';

export interface Article {
  url: string /** which one to use? */;
  originalUrl: string;
  headline: string;
  providerName: string;
  date: number;
  dateDiffFriendly: string;
  topic: string;
  excerpt: string;
  thumbnail: string;
  activityId?: string;
}
export type State = Record<string, Article>;

function getDefaultArticle(): Article {
  return {
    url: '',
    originalUrl: '',
    headline: '',
    providerName: '',
    date: 0,
    dateDiffFriendly: '',
    topic: '',
    excerpt: '',
    thumbnail: '',
  };
}

function getDefault(): State {
  return {};
}

export function reducer(
  state = getDefault(),
  action: ReturnType<
    | (typeof fetchCompanyNews)['success']
    | (typeof fetchPortfolioNews)['success']
  >
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case fetchCompanyNews.SUCCESS: {
        const { payload } = action as ReturnType<
          (typeof fetchCompanyNews)['success']
        >;
        const { news } = payload;
        news.forEach((article) => {
          const id = article.original_url;
          if (!state[id] && !draft[id]) {
            draft[id] = getDefaultArticle();
          }
          draft[id].url = article.url;
          draft[id].originalUrl = id;
          draft[id].headline = article.headline;
          draft[id].providerName = article.provider_name;
          draft[id].date = article.date;
          draft[id].dateDiffFriendly = article.date_diff_friendly;
        });
        break;
      }
      case fetchPortfolioNews.SUCCESS: {
        const { payload } = action as ReturnType<
          (typeof fetchPortfolioNews)['success']
        >;
        const { portfolioNews } = payload;
        portfolioNews.forEach((article) => {
          const id = article.original_url;
          if (!state[id] && !draft[id]) {
            draft[id] = getDefaultArticle();
          }
          draft[id].url = article.url;
          draft[id].originalUrl = id;
          draft[id].headline = article.headline;
          draft[id].providerName = article.provider_name;
          draft[id].date = article.date;
          draft[id].dateDiffFriendly = article.date_diff_friendly;
        });
        break;
      }
    }
  });
}

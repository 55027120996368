interface RewardfulGlobal {
  (e: 'ready', o: () => void): void;
}

/**
 * getRewardfulGlobal: Returns the global Rewardful instance, which is defined on the Window.
 *
 * Note: this function is intended for use within the @/features/rewardful module only.
 * Application access must use the useRewardfulData hook.
 */
export function getRewardfulGlobal() {
  if (
    typeof window === 'undefined' ||
    typeof window['rewardful'] === 'undefined'
  ) {
    return undefined;
  }

  return window['rewardful'] as RewardfulGlobal;
}

function removeLeadingSlash(url) {
  return url.charAt(0) === "/" ? removeLeadingSlash(url.substr(1)) : url;
}
function removeTrailingSlash(url) {
  return url.replace(/\/$/, "");
}
export {
  removeLeadingSlash,
  removeTrailingSlash
};

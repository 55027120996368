import { useTranslation as useTranslationi18Next } from 'react-i18next';

/**
 * Only acts as a abstraction layer for react-i18next's useTranslation hook.
 */
export function useTranslation<
  NS extends string = 'web',
  Keys extends string = '',
>(ns: NS) {
  return useTranslationi18Next<NS, Keys>(ns);
}

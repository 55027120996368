import { post } from "../methods";
function refreshJson(req) {
  const payload = {
    ...req ? req : {}
  };
  return post({
    url: "/backend/auth/token",
    payload
  });
}
export {
  refreshJson as default
};

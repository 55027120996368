import { get } from 'lodash';
import type { PortfolioDataPayload, PortfolioAnalysis } from './interface';

export const getDefaultPortfolioAnalysisState = (): PortfolioAnalysis => {
  return {
    lastUpdated: null,
    currencyGain: null,
    currencyReturn: null,
    snowflakeScoreAxes: [0, 0, 0, 0, 0],
    return1yrTotalReturn: null,
    snowflakeDescription: '',
    chartData: null,
    years: 0,
    PE: null,
    totalValue: null,
    return7D: null,
    return1YrAbs: null,
    totalGain: null,
    totalDividendReturn: null,
  };
};

export /* Reduce holding analysis */
function portfolioAnalysisReducer(
  portfolioData: PortfolioDataPayload
): PortfolioAnalysis {
  const {
    annual_return,
    beta,
    capital_gain,
    currency_gain,
    currency_return,
    current_dividend,
    debt_equity,
    dividend_gain,
    dividend_return,
    fund_value,
    PB,
    PE,
    PEG,
    ROA,
    ROE,
    return_on_capital_employed,
    score: {
      data: {
        snowflake: {
          data: {
            axes, // 🤮
          },
        },
        past,
        total,
        future,
        health,
        income,
      },
    },
    snowflake_description: snowflakeDescription,
    return_1yr_total_return,
    return_7d,
    return_1yr_abs,
    total_dividend_return,
    total_free_cash_flow_discount,
    total_free_cash_flow_value,
    total_gain,
    total_return,
    total_value,
    net_income_growth_annual,
    net_income_growth_5y,
    revenue_growth_5y,
    revenue_growth_annual,
    payout_ratio,
    payout_ratio_3y,
    chart_data,
    years,
  } = portfolioData;
  return {
    lastUpdated: Date.now(),
    annualReturn: annual_return,
    beta,
    capitalGain: capital_gain,
    currencyGain: currency_gain,
    currencyReturn: currency_return,
    currentDividend: current_dividend,
    debtEquity: debt_equity,
    dividendReturn: dividend_return,
    dividendGain: dividend_gain,
    fundValue: fund_value,
    PB,
    PE,
    PEG,
    ROA: ROA / 100, // 💩 normalised due to the portfolio API's assumed coupling to HighCharts
    ROE: ROE / 100, // 💩 normalised due to the portfolio API's assumed coupling to HighCharts
    ROCE: return_on_capital_employed,
    return7D: return_7d,
    return1YrAbs: return_1yr_abs,
    return1yrTotalReturn: return_1yr_total_return,
    totalDividendReturn: total_dividend_return,
    totalFreeCashFlowDiscount: total_free_cash_flow_discount,
    totalFreeCashFlowValue: total_free_cash_flow_value,
    totalGain: total_gain,
    totalReturn: total_return,
    totalValue: total_value,
    snowflakeDescription,
    snowflakeScoreAxes: axes === null ? [0, 0, 0, 0, 0] : axes,
    snowflakeScorePast: past,
    snowflakeScoreTotal: total,
    snowflakeScoreFuture: future,
    snowflakeScoreHealth: health,
    snowflakeScoreDividend: income,
    netIncomeGrowthAnnual: net_income_growth_annual,
    netIncomeGrowth5y: net_income_growth_5y,
    revenueGrowth5y: revenue_growth_5y,
    revenueGrowthAnnual: revenue_growth_annual,
    payoutRatio: payout_ratio,
    payoutRatio3y: payout_ratio_3y,
    chartData: get(chart_data, 'data', []),
    years,
  };
}

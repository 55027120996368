import { StrictMode } from 'react';
import { QueryClient } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';

import { getUser, setVersion } from '@simplywallst/services';
import { theme, ScreenSize } from '@simplywallst/ui-core';

import Analytics from '@simplywallst/analytics';
import { getViewMode } from '@/redux/location/selectors';

import { migrateStorage } from '@/utilities/capacitor';
import { domNodeChildHotfix } from '@/utilities/domNodeChildHotfix';
import { initialiseSentry, setUser as setSentryUser } from '@/utilities/sentry';
import type { AuthTokens } from '@utilities/cookies';
import {
  getAuthCookie,
  canReadWriteCookies,
  isAccessTokenExpiring,
  doRefreshAccessToken,
  clearAllCookies,
  getCookie,
} from '@utilities/cookies';

import { ViewMode } from '@/pages/CompanyReport/enum';

import { SWS_LEGACY_HOST as IFRAME_DOMAIN } from '@/constants';

import { QUERY_KEY as USER_QUERY_KEY, reducer } from '@/hooks/useUser';

import configureStore from './App/store/configureStore';
import App from './App';
import { cacheTime } from '@/utilities/persistQueryClient';

import { setPreferredLanguage } from '@simplywallst/services';
import { setPreferredLanguage as setPreferredLanguageGQL } from '@/utilities/graphQLClient';
import { pathToAction } from 'redux-first-router';
import routesGenerator from '@/router/routesGenerator';
import { getThemeQuery, setTheme } from '@/utilities/lightTheme';
import { captureEarlyError } from '@/utilities/preInitErrors';
import { PUBLIC_RELEASE_VERSION, PUBLIC_SWS_ENV } from '@/constants/env';

type UserResponse = Awaited<ReturnType<typeof getUser>>['data'];

domNodeChildHotfix();

const fetchUserWithToken = async ({
  accessToken,
}: {
  accessToken?: string;
}): Promise<UserResponse> => {
  const response = await getUser({
    Authorization: `Bearer ${accessToken}`,
  });
  return response.data;
};

function getPlansVariantCookie() {
  const name = 'plans_variant';
  const plansVariant = getCookie(name);
  if (typeof plansVariant === 'string') {
    return plansVariant;
  }
  /**
   * Generate a random experiment group and set it as a cookie
   */
  const experimentGroup = Math.random() < 0.5 ? 'control' : 'experiment';
  document.cookie = `${name}=${experimentGroup};path=/`;
  return experimentGroup;
}

async function renderApp(
  Component: typeof App,
  authCookie?: NonNullable<AuthTokens>,
  userResponse?: UserResponse
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const typedWindow = window as any;

  const appVersion = PUBLIC_RELEASE_VERSION;

  // Initiate Analytics
  typedWindow.analytics = new Analytics(
    RUNTIME_ENV === 'native' ? 'mobile' : 'web',
    PUBLIC_SWS_ENV === 'prod'
      ? 'swssp.simplywall.st'
      : 'swssp.dev.simplywall.st'
  );

  const dehydratedState = typedWindow.__REACT_QUERY_STATE__;
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: cacheTime,
        // Default to no focus refetch for queries as this can cause race conditions with
        // auth refresh token logic if multiple queries call a fetch at the same time.
        refetchOnWindowFocus: RUNTIME_ENV === 'native' ? true : false,
      },
    },
  });
  if (typeof userResponse?.data !== 'undefined') {
    queryClient.setQueryData(
      USER_QUERY_KEY,
      reducer(undefined, userResponse.data)
    );
  }
  //
  const preloadedState = typedWindow.REDUX_STATE;

  /**
   * Needs to be done here before we configure redux store
   * so data fetching from redux will have the correct locale
   * */
  const routesMap = routesGenerator();
  const location = pathToAction(window.location.pathname, routesMap);
  const locale = location.payload?.locale || 'en';

  setPreferredLanguage(locale);
  setPreferredLanguageGQL(locale);

  const { store, sagas, thunk } = configureStore(
    null,
    Object.assign({}, preloadedState),
    false,
    null,
    {
      appVersion,
    },
    queryClient
  );

  const state = store.getState();
  const viewMode = getViewMode(state);

  // TODO: move this to feature flag and theme switching logic, temporary test setup.
  const currentTheme = getThemeQuery();
  if (currentTheme !== undefined) {
    setTheme(currentTheme);
  }

  const themeSettings = {
    theme: currentTheme,
    screenSize:
      viewMode === ViewMode.Screen ? ScreenSize.Default : ScreenSize.Print,
  };
  const appTheme = theme(themeSettings);
  /**
   * Run sagas and run routes thunk
   */
  const sagaTask = sagas.runSaga(sagas.rootSagas);

  /** This prevents route actions from refiring if SSR is available */
  if (typeof typedWindow.REDUX_STATE === 'undefined') {
    try {
      await thunk(store);
    } catch (error) {
      console.error(error);
    }
  }

  //Set Document domain for IFrames
  try {
    const host = typedWindow.location.hostname;
    if (host && host.indexOf(IFRAME_DOMAIN) > -1) {
      console.log(`Setting domain to ${IFRAME_DOMAIN}`);
      document.domain = IFRAME_DOMAIN;
    } else {
      console.log('Skipping domain setting for loop-back address');
    }
  } catch (err) {
    console.error('Failed to set domain..', err);
  }

  const plansVariant = getPlansVariantCookie();

  const rootEl = document.getElementById('root');
  if (!rootEl) throw new Error('No root element found');
  const root = createRoot(rootEl);
  root.render(
    <StrictMode>
      <Component
        theme={appTheme}
        store={store}
        serverState={preloadedState}
        queryClient={queryClient}
        dehydratedState={dehydratedState}
        locationOrigin={window.location.origin}
        setReduxSagaContext={sagaTask.setContext}
        cookies={{ plansVariant, sessionId: getCookie('_sws_flagsessid') }}
      />
    </StrictMode>
  );
}

async function startApp() {
  try {
    initialiseSentry();
  } catch (e) {
    console.error(e);
  }
  if (RUNTIME_ENV === 'native') {
    try {
      migrateStorage();
      /**
       * apply polyfills for native
       *
       */
      await import('core-js/features/string/replace-all');
      await import('abortcontroller-polyfill');
    } catch (e) {
      console.error(e);
    }
  }

  // set services version
  setVersion(PUBLIC_RELEASE_VERSION);

  let authCookie: AuthTokens | undefined;
  let userResponse: UserResponse | undefined;
  try {
    if (canReadWriteCookies()) {
      try {
        authCookie = await getAuthCookie();
      } catch (error) {
        authCookie = undefined;
        await clearAllCookies();
      }
      if (typeof authCookie !== 'undefined') {
        let accessToken: string | undefined = authCookie.accessToken;
        if (isAccessTokenExpiring(authCookie.expiresAt)) {
          try {
            authCookie = await doRefreshAccessToken(authCookie);
            accessToken = authCookie.accessToken;
          } catch (error) {
            console.error('Failed to refresh token');
            captureEarlyError(error);
            accessToken = undefined;
            await clearAllCookies();
          }
        }
        if (typeof accessToken !== 'undefined') {
          /** attempt to fetch api/user */
          try {
            userResponse = await fetchUserWithToken({ accessToken });
            const { logged_in } = userResponse.data;
            if (!logged_in) {
              try {
                authCookie = await doRefreshAccessToken(authCookie);
                accessToken = authCookie.accessToken;
                userResponse = await fetchUserWithToken({ accessToken });
                const { logged_in } = userResponse.data;
                if (!logged_in) {
                  throw new Error('Failed to authenticate');
                }
              } catch (error) {
                console.error('Failed to refresh token');
                await clearAllCookies();
                userResponse = undefined;
                authCookie = undefined;
              }
            }
          } catch {
            /**
             * User has cookie but `fetchUserWithToken` failed.
             * Attempt to refresh token and fetch user again.
             */
            authCookie = await doRefreshAccessToken(authCookie);
            console.info('authCookie is refreshed', authCookie);
            accessToken = authCookie.accessToken;
            userResponse = await fetchUserWithToken({ accessToken });
            const { logged_in } = userResponse.data;
            if (!logged_in) {
              throw new Error('Failed to authenticate');
            }
          }
        }
      } else {
        // Fetch unauthenticated user for default properties. Example: currencyISO, used for default market
        const { data } = await getUser();
        userResponse = data;
      }
    }
  } catch (error) {
    userResponse = undefined;
    console.error('Failed to read write cookies');
  }

  if (userResponse?.data?.logged_in) {
    setSentryUser(userResponse.data);
  }

  // if (NODE_ENV === 'development') {
  //   const { worker } = await import('@simplywallst/mocks/browser');
  //   await worker.start({ onUnhandledRequest: 'bypass' });
  // }

  if (
    NODE_ENV === 'development' &&
    typeof (module as any).hot !== 'undefined'
  ) {
    (module as any).hot.accept();
    renderApp(App, authCookie, userResponse);
  } else {
    loadableReady(() => renderApp(App, authCookie, userResponse));
  }
}

startApp();

import { useEffect } from 'react';

export const useCanvasFix = () => {
  useEffect(() => {
    const repaintCanvas = (canvas: HTMLCanvasElement) => {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = 'transparent';
        ctx.fillRect(0, 0, 1, 1);
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const canvases = document.querySelectorAll<HTMLCanvasElement>('canvas');
        canvases.forEach((canvas) => repaintCanvas(canvas));
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

import type { CompanyReportSection } from '@/pages/CompanyReport/useSection';
import { getAlternates, getCanonical } from './utils';
import { SWS_HOST } from '@/constants';
import { isNil } from '@/utilities/format';
import useCompanyStatementsAPIFormatted from '@/hooks/useCompanyStatementsAPIFormatted';
import { getPossessive } from '@/pages/CompanyReport/utils/grammar';
import { useTranslation } from '@/hooks/useTranslation';
import { useLocale } from '@/hooks/useLocale';
import { useSummaryV2 } from '@/hooks/useCompany/useSummaryV2';

export function useSEO(section: CompanyReportSection) {
  const summary = useSummaryV2();

  const canonicalUrlWithHost = `${SWS_HOST}${summary.canonicalUrl}`;
  const sectionSuffix = section === 'summary' ? '' : '/' + section;
  const canonicalUrlWithHostAndSection = `${canonicalUrlWithHost}${sectionSuffix}`;
  const { data: statements } = useCompanyStatementsAPIFormatted({
    canonicalURL: summary.canonicalUrl,
  });

  const { IsDividendGrowing, IsDividendCovered, IsBank } = statements;

  const dividendGrowing = IsDividendGrowing?.value === true;
  const dividendCovered = IsDividendCovered?.value === true;
  const isBank = IsBank?.value === true;

  const t = useTranslation();
  const locale = useLocale();

  if (summary.status !== 'success') {
    return { status: summary.status };
  }

  const {
    name,
    shortName,
    tickerSymbol,
    uniqueSymbol,
    exchangeSymbolFiltered,
    allowCrawlers,
    coverImage: mainHeader,
    latestDividendYield,
    payoutRatio: currentDividendPayoutRatio,
  } = summary;

  const exchangeTicker = `${exchangeSymbolFiltered}:${tickerSymbol}`;

  function getTitle() {
    switch (section) {
      case 'summary':
        return t('page.companyReport.title.summary', {
          company: shortName,
          exchangeTicker,
        });
      case 'valuation':
        return t('page.companyReport.title.valuation', {
          company: shortName,
          uniqueSymbol,
        });
      case 'future':
        return t('page.companyReport.title.future', {
          company: shortName,
          uniqueSymbol,
        });
      case 'past':
        return t('page.companyReport.title.past', {
          company: shortName,
          uniqueSymbol,
        });
      case 'health':
        return t('page.companyReport.title.health', {
          company: shortName,
          tickerSymbol,
        });
      case 'dividend':
        return t('page.companyReport.title.dividend', {
          company: shortName,
          exchangeTicker,
        });
      case 'management':
        return t('page.companyReport.title.management', {
          company: name,
          tickerSymbol,
        });
      case 'ownership':
        return t('page.companyReport.title.ownership', {
          company: name,
        });
      case 'information':
        return t('page.companyReport.title.information', {
          company: name,
          tickerSymbol,
        });
      default:
        return `${name} (${tickerSymbol})`;
    }
  }

  const title = getTitle();

  function getDescription() {
    switch (section) {
      case 'summary':
        return t('page.companyReport.description.summary', {
          company: getPossessive(shortName, locale),
          ticker: exchangeTicker,
        });
      case 'valuation':
        return t('page.companyReport.description.valuation', {
          company: getPossessive(shortName, locale),
          ticker: exchangeTicker,
        });
      case 'future':
        return t('page.companyReport.description.future', {
          company: getPossessive(shortName),
        });
      case 'past':
        return t('page.companyReport.description.past', {
          company: shortName,
        });
      case 'health':
        if (isBank) {
          return t('page.companyReport.description.health.bank', {
            company: getPossessive(shortName),
          });
        }
        return t('page.companyReport.description.health', {
          company: getPossessive(shortName),
        });

      case 'dividend':
        if (
          latestDividendYield === 0 ||
          latestDividendYield === null ||
          typeof latestDividendYield === 'undefined'
        ) {
          return t('page.companyReport.description.dividend.nil');
        } else {
          const dividendYield = latestDividendYield.toLocaleString(locale, {
            style: 'percent',
            minimumFractionDigits: 2,
          });
          const payoutRatio = isNil(currentDividendPayoutRatio)
            ? 'n/a'
            : currentDividendPayoutRatio.toLocaleString(locale, {
                style: 'percent',
                minimumFractionDigits: 2,
              });
          if (dividendGrowing && dividendCovered) {
            return t('page.companyReport.description.dividend.1', {
              company: getPossessive(shortName),
              ticker: exchangeTicker,
              yield: dividendYield,
              payoutRatio,
            });
          } else if (!dividendGrowing && dividendCovered) {
            return t('page.companyReport.description.dividend.2', {
              company: getPossessive(shortName),
              ticker: exchangeTicker,
              yield: dividendYield,
              payoutRatio,
            });
          } else if (dividendGrowing && !dividendCovered) {
            return t('page.companyReport.description.dividend.3', {
              company: getPossessive(shortName),
              ticker: exchangeTicker,
              yield: dividendYield,
              payoutRatio,
            });
          } else if (!dividendGrowing && !dividendCovered) {
            return t('page.companyReport.description.dividend.4', {
              company: getPossessive(shortName),
              ticker: exchangeTicker,
              yield: dividendYield,
              payoutRatio,
            });
          }
        }
        // technically should never happen
        return t('page.companyReport.description.dividend.nil');
      case 'management':
        return t('page.companyReport.description.management', {
          company: name,
          ticker: tickerSymbol,
        });
      case 'ownership':
        return t('page.companyReport.description.ownership', {
          company: name,
          ticker: tickerSymbol,
        });
      case 'information':
        return t('page.companyReport.description.information', {
          company: name,
          ticker: tickerSymbol,
        });
      default:
        return `${name} (${tickerSymbol})`;
    }
  }

  const description = getDescription();

  const canonical = getCanonical(summary.canonicalUrl, section, locale);
  const alternates = getAlternates(summary.canonicalUrl, section);

  return {
    status: summary.status,
    canonicalUrlWithHostAndSection: canonicalUrlWithHostAndSection,
    allowCrawlers,
    exchangeTicker,
    companyName: shortName,
    title,
    description,
    canonicalUrl: summary.canonicalUrl,
    mainHeader,

    /**
     * Actual canonical link for the current page, including locale.
     *
     * This currently can differ from the canonical URL used for ID purposes.
     */
    canonical,

    /**
     * Alternate canonical links for different locales.
     */
    alternates,
  };
}

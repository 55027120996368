import { postJson } from "../methods";
function getRewardfulAffiliateReferrals(payload) {
  return postJson({
    url: `api/affiliate/referrals`,
    payload
  });
}
export {
  getRewardfulAffiliateReferrals
};

import { captureMessage } from '@sentry/react';
import type { SentryCaptureProps } from './types';

export const sentryCaptureMessage = ({
  team,
  message,
  extra,
}: SentryCaptureProps) => {
  const errorMessage = team ? `${team}: ${message}` : message;
  captureMessage(errorMessage, {
    extra,
    tags: {
      team,
    },
  });
};

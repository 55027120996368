import { get, uniq, isNull } from 'lodash';
import type {
  ActionPayload,
  PayloadHolding,
  PortfolioHolding,
  PortfolioItemState,
  State,
} from './interface';
import {
  portfolioAnalysisReducer,
  getDefaultPortfolioAnalysisState,
} from './analysisReducer';
import { normalise } from '@/state/utils/reducer';
import {
  holdingWithAnalysisNormaliser,
  getDefaultState as getDefaultHoldingState,
} from './holdingReducer';
import getSymbolFromCurrency from 'currency-symbol-map';
import { produce } from 'immer';
import type { CurrencyISO } from '@/constants/currencies';

export const getDefaultPortfolioItemState = (): PortfolioItemState => {
  return {
    excludedCompanyCount: 0,
    id: '',
    lastUpdated: 0,
    name: '',
    isUpdating: false,
    provider: null,
    currentHoldingCount: 0,
    isWatchlist: false,
    portfolioWithNoTransactions: true,
    portfolioYearAge: null,
    marketISO2: '',
    excludedHoldingsMap: [],
    holdingsMap: [],
    analysis: getDefaultPortfolioAnalysisState(),
  };
};

function portfolioReducer(
  state: State,
  payload: ActionPayload
): Partial<State> {
  const { id, portfolioData } = payload;
  if (id && portfolioData) {
    const safeId = id.toString();
    const {
      holdingsTable: stateHoldings,
      entities: currentPortfolios,
      holdingsItemIdToCompanyIdMap,
    } = state;
    const existingPortfolio = get(currentPortfolios, safeId, {});

    let holdingsTable: Record<string, PortfolioHolding> = stateHoldings;
    const {
      holdings: { data: holdingsData },
      excluded_companies: { data: excludedCompaniesData },
      currency_iso,
      name,
      provider,
      is_watchlist,
      is_holdings,
      years,
      current_holding_count: currentHoldingCount,
      market_iso_2: marketISO2,
    } = portfolioData;

    const isLinkedPortfolio = !isNull(provider);
    const analysis = portfolioAnalysisReducer(portfolioData);
    const currentHoldingsMap: string[] = get(
      existingPortfolio,
      'holdingsMap',
      []
    );

    const currentHoldingsLongIdMap: string[] = get(
      existingPortfolio,
      'holdingsLongIdMap',
      []
    );

    const currentHoldingsCompanyIdItemIdMap = get(
      existingPortfolio,
      'holdingCompanyIdItemIdMap',
      {}
    );

    const filteredHoldingsData = isLinkedPortfolio
      ? holdingsData
      : /** Remove holdings with null `company_id` */
        holdingsData.filter((holding) => {
          if (NODE_ENV === 'development' && holding.company_id === null) {
            console.log('Ignoring holding with a null companyId: ', holding);
          }
          return holding.company_id !== null;
        });
    const { keys, entities } = normalise<PortfolioHolding, PayloadHolding>(
      filteredHoldingsData
    )(
      'company_id',
      holdingWithAnalysisNormaliser(
        holdingsTable,
        currentHoldingsCompanyIdItemIdMap
      )
    );
    /**
     * We need to fix normalised keys and entities
     * There is reason to this madness I simply cannot find the words to describe it
     * And now we have to generate a mapping key if portfolio is linked.
     */
    let remappedEntities: Record<string, PortfolioHolding> = {};
    const holdingsCompanyIdItemIdMap: Record<string, string> = {};
    const normalMapper = (key: string) => {
      try {
        const matchedMap = holdingsItemIdToCompanyIdMap[key];
        if (!matchedMap)
          throw new Error(
            `Cannot match: ${key} from ${JSON.stringify(
              holdingsItemIdToCompanyIdMap
            )}`
          );
        const matchedEntity = entities[key];
        let matchedHolding: PortfolioHolding = getDefaultHoldingState();
        matchedMap.forEach((mappedItemId) => {
          if (currentHoldingsMap.includes(mappedItemId.toString())) {
            matchedHolding = holdingsTable[mappedItemId] || matchedHolding;
            holdingsCompanyIdItemIdMap[key] = mappedItemId;
          }
        });
        const { itemId = '' } = matchedHolding;
        const currentPortfolioHolding = holdingsTable[itemId];
        remappedEntities = {
          ...remappedEntities,
          [itemId]: {
            ...currentPortfolioHolding,
            ...matchedEntity,
            exclude: false,
          },
        };
        return itemId;
      } catch (err) {
        console.error(err);
        return '';
      }
    };
    const linkedPortfolioMapper = (key: string) => {
      return safeId + '-' + key;
    };
    const remappedKeys = keys.map(
      isLinkedPortfolio ? linkedPortfolioMapper : normalMapper
    );

    /**
     * Damn you to hell Portfolio API
     */
    const excludedHoldingsMap = {};
    const excludedHoldingsKeys = excludedCompaniesData.map((n) => {
      const key = n.company_id;
      excludedHoldingsMap[key] = n;
      return key;
    });
    let excludedHoldingsEntities: Record<string, PortfolioHolding> = {};
    // skip exluding when linkedportfolio
    excludedHoldingsEntities = excludedHoldingsKeys
      .map((key) => {
        try {
          const companyId = key;
          const matchedMap = holdingsItemIdToCompanyIdMap[companyId];
          let excludedHolding: PortfolioHolding = getDefaultHoldingState();
          matchedMap.forEach((key) => {
            if (currentHoldingsMap.includes(key)) {
              excludedHolding = holdingsTable[key];
              excludedHolding = {
                ...excludedHolding,
                analysis: {
                  sharePrice: excludedHoldingsMap[companyId].share_price,
                  snowflakeScoreAxes:
                    excludedHoldingsMap[companyId].score.data.snowflake.data
                      .axes,
                },
              };
            }
          });
          return {
            ...excludedHolding,
            exclude: true,
          };
        } catch (err) {
          console.error(err);
        }
      })
      .reduce(
        (a, b, i) => ({
          ...a,
          [(b && b.itemId) || i.toString()]: b,
        }),
        {}
      );
    holdingsTable = {
      ...holdingsTable,
      ...remappedEntities,
      ...excludedHoldingsEntities,
    };
    const portfolio = produce(
      existingPortfolio as PortfolioItemState,
      (draftPortfolio) => {
        draftPortfolio.id = safeId;
        draftPortfolio.analysis = analysis;
        draftPortfolio.marketISO2 = marketISO2;
        draftPortfolio.excludedCompanyCount = excludedHoldingsKeys.length;
        draftPortfolio.excludedHoldingsMap = excludedHoldingsKeys;
        draftPortfolio.lastUpdated = Date.now();
        draftPortfolio.currencyISO = currency_iso as CurrencyISO;
        draftPortfolio.currencySymbol = getSymbolFromCurrency(currency_iso);
        draftPortfolio.currentHoldingCount = currentHoldingCount;
        draftPortfolio.name = name.toString();
        draftPortfolio.isWatchlist = is_watchlist;
        draftPortfolio.portfolioWithNoTransactions = is_holdings;
        draftPortfolio.portfolioYearAge = years;
        draftPortfolio.provider = provider || '';
        draftPortfolio.holdingsMap = uniq([
          ...currentHoldingsMap,
          ...remappedKeys,
        ]);
        draftPortfolio.holdingsLongIdMap = uniq([
          ...currentHoldingsLongIdMap,
          ...keys,
        ]);
        draftPortfolio.holdingCompanyIdItemIdMap = {
          ...currentHoldingsCompanyIdItemIdMap,
          ...holdingsCompanyIdItemIdMap,
        };
      }
    );
    const reducedState = produce(state, (draft) => {
      draft.holdingsTable = holdingsTable;
      draft.entities[safeId] = portfolio;
      if (isLinkedPortfolio) {
        /**
          Append mappings ids
         */
        keys.forEach((companyId) => {
          const currentIdMappings =
            draft.holdingsItemIdToCompanyIdMap[companyId] || [];
          const storeId = `${safeId}-${companyId}`;
          draft.holdingsItemIdToCompanyIdMap[companyId] = uniq([
            ...currentIdMappings,
            storeId,
          ]);
          // store holding with the correct itemId
          const currentCompany = entities[companyId];
          draft.holdingsTable[storeId] = {
            ...currentCompany,
            isUpdating: false,
            exclude: false,
            itemId: storeId,
            isAddingTransaction: false,
            isUpdatingReinvest: false,
          };
        });
      }
    });

    return reducedState;
  }
  return state;
}

export default portfolioReducer;

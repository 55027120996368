import { get } from "../methods";
const defaultConfig = () => ({
  action: ""
});
function getIndustry_default(config = defaultConfig()) {
  const { action = "", payload } = config;
  return get({
    url: `api/industry/${action}`,
    payload
  });
}
export {
  getIndustry_default as default
};

export const currencyISO = [
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BOV',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYR',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHE',
  'CHF',
  'CHW',
  'CLF',
  'CLP',
  'CNY',
  'COP',
  'COU',
  'CRC',
  'CUC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GBX',
  'GEL',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LTL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MRU',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MXV',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STD',
  'STN',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'USN',
  'UYI',
  'UYU',
  'UZS',
  'VEF',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XAG',
  'XAU',
  'XBA',
  'XBB',
  'XBC',
  'XBD',
  'XCD',
  'XDR',
  'XOF',
  'XPD',
  'XPF',
  'XPT',
  'XSU',
  'XTS',
  'XUA',
  'XXX',
  'YER',
  'ZAR',
  'ZMW',
  'ZMK',
  'ZWL',
] as const;

export type CurrencyISO = (typeof currencyISO)[number];

export const isCurrencyISO = (value: unknown): value is CurrencyISO =>
  currencyISO.includes(value as CurrencyISO);

export type Option = {
  option: CurrencyISO;
  label: string;
};

export const supportedCurrencies: Option[] = [
  /*most popular currencies, users love to own these*/
  { option: 'USD', label: 'USD - US Dollar' },
  { option: 'EUR', label: 'EUR - Euro' },
  { option: 'AUD', label: 'AUD - Australian Dollar' },
  { option: 'GBP', label: 'GBP - British Pound' },
  { option: 'CAD', label: 'CAD - Canadian Dollar' },
  { option: 'INR', label: 'INR - Indian Rupee' },
  { option: 'CNY', label: 'CNY - Chinese Renminbi (Yuan)' },
  /*now ordered alphabetically*/
  { option: 'AED', label: 'AED - United Arab Emirates Dirham' },
  { option: 'ARS', label: 'ARS - Argentine Peso' },
  { option: 'BDT', label: 'BDT - Bangladesh Taka' },
  { option: 'BGN', label: 'BGN - Bulgarian Lev' },
  { option: 'BHD', label: 'BHD - Bahraini Dinar' },
  { option: 'BMD', label: 'BMD - Bermuda Dollar' },
  { option: 'BRL', label: 'BRL - Brazilian Real' },
  { option: 'BWP', label: 'BWP - Botswana Pula' },
  { option: 'CHF', label: 'CHF - Swiss Franc' },
  { option: 'CLP', label: 'CLP - Chilean Peso' },
  { option: 'COP', label: 'COP - Colombian Peso' },
  { option: 'CZK', label: 'CZK - Czech Koruna' },
  { option: 'DKK', label: 'DKK - Danish Krone' },
  { option: 'EGP', label: 'EGP - Egyptian Pound' },
  { option: 'GHS', label: 'GHS - New Ghana Cedi' },
  { option: 'HKD', label: 'HKD - Hong Kong Dollar' },
  { option: 'HRK', label: 'HRK - Croatian Kuna' },
  { option: 'HUF', label: 'HUF - Hungarian Forint' },
  { option: 'IDR', label: 'IDR - Indonesian Rupiah' },
  { option: 'ILS', label: 'ILS - Israeli Shekel' },
  { option: 'ISK', label: 'ISK - Icelandic Krona' },
  { option: 'JMD', label: 'JMD - Jamaican Dollar' },
  { option: 'JOD', label: 'JOD - Jordanian Dinar' },
  { option: 'JPY', label: 'JPY - Japanese Yen' },
  { option: 'KES', label: 'KES - Kenya Shilling' },
  { option: 'KRW', label: 'KRW - South Korean Won' },
  { option: 'KWD', label: 'KWD - Kuwait Dinar' },
  { option: 'KZT', label: 'KZT - Kazakhstan Tenge' },
  { option: 'LKR', label: 'LKR - Sri Lanka Rupee' },
  { option: 'MAD', label: 'MAD - Moroccan Dirham' },
  { option: 'MUR', label: 'MUR - Mauritian Rupee' },
  { option: 'MWK', label: 'MWK - Malawi Kwacha' },
  { option: 'MXN', label: 'MXN - Mexican Peso' },
  { option: 'MYR', label: 'MYR - Malaysian Ringgit' },
  { option: 'NGN', label: 'NGN - Nigerian Naira' },
  { option: 'NOK', label: 'NOK - Norwegian Krone' },
  { option: 'NZD', label: 'NZD - New Zealand Dollar' },
  { option: 'OMR', label: 'OMR - Oman Rial' },
  { option: 'PEN', label: 'PEN - Peruvian Sol' },
  { option: 'PHP', label: 'PHP - Philippines Peso' },
  { option: 'PKR', label: 'PKR - Pakistan Rupee' },
  { option: 'PLN', label: 'PLN - Polish Zloty' },
  { option: 'QAR', label: 'QAR - Qatari Rial' },
  { option: 'RON', label: 'RON - Romanian Leu' },
  { option: 'RSD', label: 'RSD - Serbian Dinar' },
  { option: 'RUB', label: 'RUB - Russian Rouble' },
  { option: 'SAR', label: 'SAR - Saudi Arabian Riyal' },
  { option: 'SEK', label: 'SEK - Swedish Krona' },
  { option: 'SGD', label: 'SGD - Singapore Dollar' },
  { option: 'THB', label: 'THB - Thai Baht' },
  { option: 'TND', label: 'TND - Tunisian Dinar' },
  { option: 'TRY', label: 'TRY - Turkish Lira' },
  { option: 'TTD', label: 'TTD - Trinidad and Tobago Dollar' },
  { option: 'TWD', label: 'TWD - Taiwan Dollar' },
  { option: 'TZS', label: 'TZS - Tanzania Shilling' },
  { option: 'UAH', label: 'UAH - Ukraine Hryvna' },
  { option: 'UGX', label: 'UGX - Uganda Shilling' },
  { option: 'VEF', label: 'VEF - Venezuelan Bolivar Fuerte' },
  { option: 'VND', label: 'VND - Vietnamese Dong' },
  { option: 'XOF', label: 'XOF - CFA (BCEAO) Franc' },
  { option: 'ZAR', label: 'ZAR - South African Rand' },
  { option: 'ZMW', label: 'ZMW - Zambian New Kwacha' },
  { option: 'ZWL', label: 'ZWL - New Zimbabwe Dollar' },
];

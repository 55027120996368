import { ComponentProps } from 'react';
import { Trans } from 'react-i18next';

export function Translation<K extends string = string>({
  i18nKey,
  count = 0,
  values = {},
  children,
  components,
}: {
  i18nKey: K;
  // This typing is required as the interpolated strings look like the declaration of an object in JSX. As React 18 no longer allows
  // objects as children in its types, we need to add our own special children type for the Trans component.
  //
  // It can be replaced by allowObjectInHTMLChildren (https://www.i18next.com/overview/typescript#custom-type-options) but this monkey patches
  // all element types.
  //
  // See github issue for more info; https://github.com/i18next/react-i18next/issues/1483
  children: ComponentProps<typeof Trans>['children'];
  count?: number;
  values?: Record<string, unknown>;
  components?:
    | readonly React.ReactElement[]
    | { readonly [tagName: string]: React.ReactElement };
}) {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - FIXME: issues with multiple react versions and provider types - react 18 in deprecated-web, react 19 in news
    <Trans
      i18nKey={i18nKey}
      count={count}
      values={values}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      components={components}
    >
      {children as any}
    </Trans>
  );
}

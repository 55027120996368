import { PublicPortfolioFetch } from '@/components/DeprecatedPortfolios/redux/routines';
import { exploreFetchTrigger } from '@/components/DeprecatedPortfolios/redux/sagas';
import { exportableSagas as editPortfolioModalExportableSagas } from '@/components/DeprecatedPortfolio/components/EditPortfolioModal/redux/sagas';
import {
  getPublicPortfolios as getPublicPortfoliosSelector,
  getPublicPortfolioKeys,
  getUserPortfolioKeys,
} from '@/components/DeprecatedPortfolios/redux/selectors';
import { toSignificant } from '@/utilities/formatting';
import {
  takeLatest,
  takeEvery,
  select,
  put,
  take,
  fork,
  getContext,
} from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import {
  addTransactionForItem,
  deletePortfolio,
  fetchPortfolio,
  fetchPublicPortfolio,
  fetchPortfolioItems,
  fetchPortfolioList,
  fetchPortfolioNews,
  fetchPriceForPortfolioItem,
  portfolioNotFound,
  reinvestDividend,
  removeTransactionForItem,
  regenerateSecretCode,
  batchFetchPortfolio,
} from './routines';

import { ROUTE_MY_PORTFOLIOS } from '@/constants/routes';
import {
  createPortfolioTransaction as createPortfolioTransactionService,
  deletePortfolioTransaction as deletePortfolioTransactionService,
  getPortfolio as getPortfolioService,
  getPortfolioNews as getPortfolioNewsService,
  getPortfolios as getPortfoliosService,
  getPriceHistory as getPriceHistoryService,
  updatePortfolio as updatePortfolioService,
  deletePortfolio as deletePortfolioService,
  updatePortfolioItem as updatePortfolioItemService,
  getSecretCode as getSecretCodeService,
  getSharePortfolioHoldings as getSharePortfolioService,
} from '@simplywallst/services';
import {
  getCurrentPortfolioName,
  getDoesPortfolioNeedsRefresh,
  getPortfolioSecretCode,
} from './selectors';
import type { SagaAction } from '@/components/DeprecatedPortfolio/redux/interface';
import { getLastUpdatedForCountry } from '@/components/IndustryAverages/redux/selectors';
import {
  IndustryAverageByCountry,
  fetchIndustryAveragesForCountry,
} from '@/components/IndustryAverages/redux/action';
import { ahoy, getAuthenticatedAhoy } from '@ducks/saga';
import { replace } from 'redux-first-router';

const marketAveragesRefreshCutOff = 2 * 60 * 60 * 1000; // 2 hours before refresh
const isServerSide = RUNTIME_ENV === 'server';
/** Sagas */

function* getPortfolios() {
  try {
    const caller = getAuthenticatedAhoy();
    return yield caller(getPortfoliosService, undefined);
  } catch (reason) {
    throw reason;
  }
}

function* getSharePortfolioHoldings(id, secret: string) {
  try {
    const caller = ahoy();
    return yield caller(getSharePortfolioService, id, secret);
  } catch (reason) {
    throw reason;
  }
}

function* getPortfolio(
  id,
  isPublic = false,
  secret: string,
  refresh: number | undefined
) {
  try {
    const caller = ahoy({ authenticate: isPublic !== true });
    return yield caller(getPortfolioService, id, secret, refresh);
  } catch (reason) {
    console.error('getPortfolio caught', reason);
    throw reason;
  }
}

export function* refreshPortfoliosWorker() {
  const id = uuidv4();
  try {
    const snackbar = yield getContext('snackbar');
    snackbar?.addSnackbar({
      id,
      message: 'Refreshing your portfolios',
      isLoading: true,
    });
    const { data } = yield getPortfolios();
    yield put(
      fetchPortfolioList.success({
        portfolios: data,
      })
    );
  } catch (reason) {
    yield put(fetchPortfolioList.failure());
  } finally {
    const snackbar = yield getContext('snackbar');
    snackbar?.removeSnackbar({ id });
  }
}

export function* updateSharePortfolioHoldings(id, secret: string) {
  try {
    const snackbar = yield getContext('snackbar');
    snackbar?.addSnackbar({
      id,
      message: 'Refreshing portfolio',
      isLoading: true,
    });
    const response = yield getSharePortfolioHoldings(id, secret);
    yield put(
      fetchPortfolioList.success({
        portfolios: [response.data],
      })
    );
  } catch (reason) {
    yield put(fetchPortfolioList.failure());
  } finally {
    const snackbar = yield getContext('snackbar');
    snackbar?.removeSnackbar({ id });
  }
}

export function* fetchPublicPortfolioSaga({
  payload,
}: SagaAction | ReturnType<(typeof fetchPublicPortfolio)['success']>) {
  const {
    payload: { portfolioId, secret },
  } = payload;
  try {
    yield put(
      fetchPortfolio.trigger({ id: portfolioId, isPublic: true, secret })
    );
  } catch (error) {
    yield put(fetchPortfolioList.failure());
  }
}

function* batchFetchPortfolioWorker(
  action: ReturnType<(typeof batchFetchPortfolio)['trigger']>
) {
  try {
    yield fork(fetchPortfolioSaga, {
      type: action.type,
      payload: { ...action.payload, includeNews: false },
    });
  } catch (error: any) {
    yield put(batchFetchPortfolio.failure({ message: error }));
  }
}

export function* fetchPortfolioSaga(action: SagaAction) {
  const {
    payload: { id, isPublic, secret, includeNews = true, refresh },
  } = action;
  try {
    const doesPortfolioNeedRefresh = yield select(getDoesPortfolioNeedsRefresh);

    if (!doesPortfolioNeedRefresh)
      throw new Error(
        'Skipping fetch portfolio. doesPortfolioNeedRefresh is false.'
      );
    if (id) {
      const currentPortfolioName = yield select(getCurrentPortfolioName(id));
      if (currentPortfolioName) {
        // we'll only show the snackbar
        const snackbar = yield getContext('snackbar');
        snackbar?.addSnackbar({
          id,
          message: 'Updating your portfolio',
          isLoading: true,
        });
      }
    }
    const currentPortfolio = yield getPortfolio(
      id,
      isPublic,
      secret as string,
      refresh
    );
    const hasCurrentPortfolio =
      currentPortfolio && currentPortfolio.data && currentPortfolio.data[0];
    /**
     * Perf: The reducers are completely dependent
     * on fetching the portfolio list first.
     */
    if (
      isPublic ||
      (hasCurrentPortfolio && !currentPortfolio.data[0].is_owner)
    ) {
      const portfolios = yield select(getPublicPortfolioKeys);
      if (portfolios.length === 0) {
        // doesn't work on ssr
        // yield put(PublicPortfolioFetch());
        yield exploreFetchTrigger();
      }
      //Fetch share portfolio holdings
      if (secret) {
        yield updateSharePortfolioHoldings(id, secret);
      }
    } else {
      const portfolios = yield select(getUserPortfolioKeys);
      if (portfolios.length === 0) {
        yield refreshPortfoliosWorker();
      }
    }

    if (currentPortfolio && currentPortfolio.data && currentPortfolio.data[0]) {
      //This is a hack
      //Ideally the market averages should be computed after the portfolio is reduced
      //But as Highcharts don't reload on props change due to re-render issues
      //The market averages are needed before the component mounts
      //In future when a better charting library is used, this can be moved to a non-blocking stage
      try {
        const marketISO2 = currentPortfolio.data[0].market_iso_2;
        console.log(`fetchportfolioSaga: Portfolio MarketISO2: ${marketISO2}`);

        // Get the last updated time for the market averages
        const marketAveragesLastUpdated = yield select(
          getLastUpdatedForCountry(0, marketISO2)
        );

        console.log(
          `fetchportfolioSaga: Market Averages last updated: ${marketAveragesLastUpdated}`
        );

        //Check if stale
        if (
          Date.now() - marketAveragesLastUpdated >=
          marketAveragesRefreshCutOff
        ) {
          // Force Market Averages update
          console.warn(
            `fetchportfolioSaga: Market Averages ${marketISO2} is stale. Forcing update..`
          );

          if (isServerSide) {
            // Force Market Averages update
            console.log(`fetchportfolioSaga: Market Averages isServerSide`);
            yield fetchIndustryAveragesForCountry({
              type: IndustryAverageByCountry.TRIGGER,
              payload: { industryId: 0, countryISO: marketISO2 },
            });
          } else {
            yield put(
              IndustryAverageByCountry.trigger({
                industryId: 0,
                countryISO: marketISO2,
              })
            );
            yield take(IndustryAverageByCountry.FULFILL);
          }
        } else {
          console.log(
            'fetchportfolioSaga: Market averages are not stale, skipping refresh..'
          );
        }
      } catch (error) {
        console.error(
          'fetchportfolioSaga: Failed to fetch Market Averages...',
          error
        );
      }
      if (includeNews) {
        yield getPortfolioNews({
          type: fetchPortfolioNews.TRIGGER,
          payload: {
            id,
            secret,
            isPublic,
          },
        });
      }

      if (
        !isPublic ||
        (hasCurrentPortfolio && currentPortfolio.data[0].is_owner)
      ) {
        const secretCode = yield select(getPortfolioSecretCode(id as string));
        if (!secretCode) {
          console.log('Secret Code missing, forcing regeneration..');
          yield put(
            regenerateSecretCode.trigger({
              id,
            })
          );
        } else {
          console.log(
            'Secret Code: ' + secretCode + ' ..Skipping regeneration...'
          );
        }
      }

      console.log('Successfully updated portfolio : ' + id);
      yield put(
        fetchPortfolio.success({
          id,
          portfolioData: currentPortfolio.data[0],
          portfolioNews: [], // why do we need this?
          isPublic,
        })
      );
      if (id) {
        const snackbar = yield getContext('snackbar');
        snackbar?.removeSnackbar({ id });
      }
    }
  } catch (exception: any) {
    const { status } = exception;
    if (id) {
      const snackbar = yield getContext('snackbar');
      snackbar?.removeSnackbar({ id });
    }
    if (status === 404) {
      yield put(portfolioNotFound.trigger({}));
    } else {
      yield put(fetchPortfolio.failure(exception));
      if (
        exception.message
          ?.toLowerCase()
          .includes('you lack sufficient permissions to access this resource')
      ) {
        const snackbar = yield getContext('snackbar');

        snackbar?.addSnackbar({
          type: 'warning',
          message: 'You are not authenticated to view this page. Please login.',
          actionLabel: 'Login',
          actionCallback: () => {
            replace('/logout');
          },
        });
      } else {
        const snackbar = yield getContext('snackbar');
        snackbar?.addSnackbar({
          type: 'negative',
          message: 'Portfolio update failed',
          lifeTime: 2000,
        });
      }
    }
  }
}

export function* portfolioNotFoundSaga() {
  console.log('Processing Portfolio not found, redirecting to fresh list..');
  try {
    // Navigation Saga
    yield put({
      type: ROUTE_MY_PORTFOLIOS,
      payload: {},
    });

    yield put(fetchPortfolioList.trigger());

    const snackbar = yield getContext('snackbar');
    snackbar?.addSnackbar({
      type: 'negative',
      message: 'Portfolio not found',
      lifeTime: 4000,
    });
  } catch (reason) {
    console.error(reason);
  }
}

function* fetchNews(id, isPublic: boolean, secret: string) {
  try {
    const caller = ahoy({ authenticate: isPublic !== true });
    return yield caller(getPortfolioNewsService, id, secret);
  } catch (reason) {
    console.error(reason);
    throw reason;
  }
}
export function* getPortfolioNews(action: SagaAction) {
  try {
    const {
      payload: { id, secret, isPublic },
    } = action;
    if (!id) return;
    const portfolioNews = yield* fetchNews(
      id,
      typeof isPublic === 'undefined' ? false : isPublic,
      secret as string
    );
    const news =
      (portfolioNews &&
        portfolioNews.data &&
        portfolioNews.data.news &&
        portfolioNews.data.news.data) ||
      [];

    if (news) {
      console.log('Successfully fetched news for portfolio id: ' + id);
      yield put(
        fetchPortfolioNews.success({
          id,
          portfolioNews: news,
        })
      );
    } else {
      throw new Error('No news received from request');
    }
  } catch (e: any) {
    console.log('calling getPortfolioNews error: ' + e);
    yield put(
      fetchPortfolioNews.failure({
        message: e.message,
      })
    );
  }
}

export function* fetchPriceForPortfolioItemSaga(action: SagaAction) {
  const {
    payload: { itemId, companyId, startDate },
  } = action;

  try {
    if (!startDate || !companyId || !itemId) {
      throw new Error('Required parameter is undefined');
    }
    const startTimestamp = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0,
      0,
      0
    ).getTime();
    const endTimestamp = startTimestamp + 23 * 60 * 60 * 1000;
    const caller = getAuthenticatedAhoy();
    const priceHistory = yield caller(getPriceHistoryService, {
      companyId: companyId.toString(),
      payload: {
        start_timestamp: startTimestamp,
        end_timestamp: endTimestamp,
      },
    });

    if (priceHistory && priceHistory.data && priceHistory.data.length > 0) {
      yield put(
        fetchPriceForPortfolioItem.success({
          itemId,
          price: priceHistory.data[priceHistory.data.length - 1],
        })
      );
    } else {
      yield put(
        fetchPriceForPortfolioItem.failure({
          message: 'Price not found',
          itemId,
          startDate,
        })
      );
    }
  } catch (err: any) {
    yield put(
      fetchPriceForPortfolioItem.failure({
        message: err.message,
        itemId,
        startDate,
      })
    );
  }
}

export function* createTransactionForPortfolioItem(action) {
  const {
    portfolioId,
    companyId,
    itemId,
    transactionType,
    transactionDate,
    shareCount,
    transactionCost,
  } = action.payload;

  try {
    const caller = getAuthenticatedAhoy();
    const addResponse = yield caller(createPortfolioTransactionService, {
      item_id: itemId,
      type: transactionType,
      amount: toSignificant(shareCount, 2),
      date:
        typeof transactionDate === 'number'
          ? transactionDate
          : transactionDate.getTime(),
      cost: transactionCost,
    });

    if (addResponse && addResponse.data) {
      yield put(
        addTransactionForItem.success({
          id: addResponse.data.id,
          portfolioId,
          companyId,
          itemId,
          transactionType,
          transactionDate,
          shareCount,
          transactionCost,
        })
      );
    } else {
      yield put(
        addTransactionForItem.failure({
          portfolioId,
          companyId,
          itemId,
          transactionType,
          transactionDate,
          shareCount,
          transactionCost,
          message: 'Failed to add transaction',
          error: addResponse.errors,
        })
      );
    }
  } catch (err) {
    console.error(err);
    yield put(
      addTransactionForItem.failure({
        portfolioId,
        companyId,
        itemId,
        transactionType,
        transactionDate,
        shareCount,
        transactionCost,
        message: 'Failed to add transaction',
        error: err,
      })
    );
  }
}

function* getPortfolioItems(portfolioId?: string, isPublic?: boolean) {
  try {
    const caller = isPublic ? ahoy() : getAuthenticatedAhoy();
    return yield caller(getPortfoliosService, {
      id: portfolioId,
      isPublic,
    });
  } catch (reason) {
    console.error(reason);
    throw reason;
  }
}

export function* fetchPortfolioItemsSaga(action: SagaAction) {
  const { portfolioId, isPublic } = action.payload;
  try {
    if (isPublic) {
      const { keys } = yield select(getPublicPortfoliosSelector);
      if (keys.length) {
        yield put(PublicPortfolioFetch.trigger());
      }
    } else {
      /**
       * Scenario: User starts the app on `/portfolios/my-portfolios/:id`
       * This means the `/portfolio/analysis` endpoint will fire first
       * then `getPortfolioItems` (componentDidMount on the <Holdings/> component).
       *
       * `/portfolio/analysis` endpoint returns the holdings list but without the `id` (the number version)
       * only the `company_id` (the long string version)
       *
       * The response of `getPortfolioItems` also contains the holdings along with the transactions.
       * However, the response only has the `id` (the number version)
       *
       * So when we are reducing `fetchPortfolioItems.success` there's no way to map the
       * transactions to the `id` because it's not there.
       *
       * As a work around we will get the user portfolio list first.
       */
      const keys = yield select(getUserPortfolioKeys);
      /**
       * We will refresh if there's only 1 or 0 portfolios
       * (1 because the currently selected portfolio will be added to the list)
       * */
      if (keys.length < 2) {
        yield refreshPortfoliosWorker();
      }
      const portfolioItems = yield* getPortfolioItems(portfolioId, isPublic);
      if (portfolioItems && portfolioItems.data) {
        yield put(
          fetchPortfolioItems.success({
            items: portfolioItems.data.items.data,
            portfolioId,
          })
        );
      } else {
        yield put(
          fetchPriceForPortfolioItem.failure({
            message: 'Failed to find items',
            id: portfolioId,
          })
        );
      }
    }
  } catch (err: any) {
    yield put(
      fetchPortfolioItems.failure({
        id: portfolioId,
        message: err.message,
      })
    );
  } finally {
    yield put(fetchPortfolioItems.fulfill());
  }
}

export function* removeTransactionForItemSaga(action: SagaAction) {
  const {
    payload: { portfolioId, itemId, transactionId, companyId },
  } = action;

  try {
    if (!portfolioId || !itemId || !transactionId || !companyId)
      throw new Error('Required payload is missing');
    const caller = getAuthenticatedAhoy();
    yield caller(deletePortfolioTransactionService, transactionId);

    yield put(
      removeTransactionForItem.success({
        portfolioId,
        itemId,
        transactionId,
        companyId,
      })
    );
  } catch (err: any) {
    yield put(
      removeTransactionForItem.failure({
        portfolioId,
        itemId,
        transactionId,
        companyId,
        message: err.message,
      })
    );
  }
}

export function* reinvestDividendSaga(action: {
  type: string;
  payload: {
    portfolioId: string;
    itemId: string;
    companyId: string;
    reinvest: boolean;
  };
}) {
  const {
    payload: { portfolioId, itemId, companyId, reinvest },
  } = action;

  try {
    if (!portfolioId || !itemId || !companyId)
      throw new Error('Required payload is missing');
    const caller = getAuthenticatedAhoy();
    yield caller(updatePortfolioItemService, itemId, reinvest);

    yield put(
      reinvestDividend.success({
        portfolioId,
        itemId,
        companyId,
        reinvest,
      })
    );
  } catch (err: any) {
    yield put(
      reinvestDividend.failure({
        portfolioId,
        itemId,
        companyId,
        reinvest,
        message: err.message,
      })
    );
  } finally {
    yield put(reinvestDividend.fulfill({ itemId }));
  }
}

/** wuts dis? */
export function* postProcessOperation(action: SagaAction) {
  //Process Side-Effects of operations
  switch (action.type) {
    case addTransactionForItem.SUCCESS: {
      const { portfolioId } = action.payload;
      //Reload the transactions
      try {
        yield put(fetchPortfolioItems.trigger({ portfolioId }));
        //Add Snackbar
        const snackbar = yield getContext('snackbar');
        snackbar?.addSnackbar({
          type: 'positive',
          message: 'Successfully added transaction',
          lifeTime: 4000,
        });
      } catch (reason) {
        console.error(reason);
      }
      break;
    }
    case addTransactionForItem.FAILURE: {
      //Add Snackbar
      try {
        const snackbar = yield getContext('snackbar');
        snackbar?.addSnackbar({
          type: 'negative',
          message: 'Failed to add transaction',
          lifeTime: 4000,
        });
      } catch (reason) {
        console.error(reason);
      }

      break;
    }
    case removeTransactionForItem.SUCCESS: {
      //Add Snackbar
      try {
        const snackbar = yield getContext('snackbar');
        snackbar?.addSnackbar({
          type: 'positive',
          message: 'Successfully removed transaction',
          lifeTime: 4000,
        });
      } catch (reason) {
        console.error(reason);
      }

      break;
    }
    case removeTransactionForItem.FAILURE: {
      //Add Snackbar
      try {
        const snackbar = yield getContext('snackbar');
        snackbar?.addSnackbar({
          type: 'negative',
          message: 'Failed to remove transaction',
          lifeTime: 4000,
        });
      } catch (reason) {
        console.error(reason);
      }
      break;
    }
    default:
      break;
  }
}

export function* deletePortfolioWorker(action: SagaAction) {
  try {
    const {
      payload: { id, redirectToList },
    } = action;

    if (!id) throw new Error('Portfolio id is null');
    const snackbarId = uuidv4();
    const snackbar = yield getContext('snackbar');
    snackbar?.addSnackbar({
      id: snackbarId,
      message: 'Deleting Portfolio',
      isLoading: true,
    });

    const caller = ahoy({ authenticate: true, dataOnly: false });
    yield caller(deletePortfolioService, id.toString());
    snackbar?.removeSnackbar({ id: snackbarId });
    snackbar?.addSnackbar({
      type: 'positive',
      message: 'Successfully deleted Portfolio',
      lifeTime: 2000,
    });

    yield put(deletePortfolio.success());

    if (redirectToList) {
      console.log('Redirecting to portfolio list..');

      yield put({
        type: ROUTE_MY_PORTFOLIOS,
        payload: {},
      });
    }
  } catch (error) {
    console.error('Failed to delete portfolio..', error);

    try {
      const snackbar = yield getContext('snackbar');
      snackbar?.addSnackbar({
        type: 'negative',
        message: 'Failed to delete Portfolio',
        lifeTime: 4000,
      });

      yield put(deletePortfolio.failure(error));
    } catch (error) {}
  } finally {
    yield put(deletePortfolio.fulfill());
  }
}

export function* regenerateSecretCodeSaga(action: SagaAction) {
  try {
    const {
      payload: { id },
    } = action;

    console.log('Regenerating secret code for id: ', id);

    if (!id) throw new Error('Portfolio id is null');
    const unAuth = ahoy();
    const { data } = yield unAuth(getSecretCodeService);

    if (!data || !data.length || data.length === 0)
      throw new Error('Failed to generate secret Code');

    const secretCode = data[0];

    console.log('Secret Code: ', secretCode);
    const authCall = getAuthenticatedAhoy();
    const response = yield authCall(updatePortfolioService, {
      id,
      payload: {
        secret_code: secretCode,
      },
    });

    console.log('Secret code regeneration response..', response);

    yield put(
      regenerateSecretCode.success({
        id,
        secretCode,
      })
    );
  } catch (error) {
    console.error('Failed to regenerate secret code...', error);

    yield put(regenerateSecretCode.failure(error));
  } finally {
    yield put(regenerateSecretCode.fulfill());
  }
}

/** Watchers */

function* watchFetchPortfolioTrigger() {
  yield takeLatest(fetchPortfolio.TRIGGER, fetchPortfolioSaga);
}

function* watchBatchFetchPortfolioTrigger() {
  yield takeEvery(batchFetchPortfolio.TRIGGER, batchFetchPortfolioWorker);
}

function* watchFetchPublicPortfolioTrigger() {
  yield takeLatest(fetchPublicPortfolio.TRIGGER, fetchPublicPortfolioSaga);
}

function* watchFetchPortfolioListTrigger() {
  yield takeLatest(fetchPortfolioList.TRIGGER, refreshPortfoliosWorker);
}

function* watchPortfolioNotFoundTrigger() {
  yield takeLatest(portfolioNotFound.TRIGGER, portfolioNotFoundSaga);
}

function* watchFetchPortfolioNewsTrigger() {
  yield takeLatest(fetchPortfolioNews.TRIGGER, getPortfolioNews);
}

function* watchFetchPriceForPortfolioItem() {
  yield takeLatest(
    fetchPriceForPortfolioItem.TRIGGER,
    fetchPriceForPortfolioItemSaga
  );
}

function* watchAddTransactionForItemTrigger() {
  yield takeLatest(
    addTransactionForItem.TRIGGER,
    createTransactionForPortfolioItem
  );
}

function* watchFetchPortfolioItems() {
  yield takeLatest(fetchPortfolioItems.TRIGGER, fetchPortfolioItemsSaga);
}

function* watchRemoveTransactionForItemTrigger() {
  yield takeEvery(
    removeTransactionForItem.TRIGGER,
    removeTransactionForItemSaga
  );
}

function* watchAddTransactionForItem() {
  // Note: Watching both success and failure? This looks wrong.
  yield takeEvery(
    [
      addTransactionForItem.SUCCESS,
      addTransactionForItem.FAILURE,
      removeTransactionForItem.SUCCESS,
      removeTransactionForItem.FAILURE,
    ],
    postProcessOperation
  );
}

function* watchDeletePortfolioTrigger() {
  yield takeEvery(deletePortfolio.TRIGGER, deletePortfolioWorker);
}

function* watchReinvestDividend() {
  yield takeLatest(reinvestDividend.TRIGGER, reinvestDividendSaga);
}

function* watchRegenerateSecretCode() {
  yield takeLatest(regenerateSecretCode.TRIGGER, regenerateSecretCodeSaga);
}

export const exportableSagas = [
  watchAddTransactionForItem,
  watchAddTransactionForItemTrigger,
  watchFetchPortfolioItems,
  watchFetchPortfolioListTrigger,
  watchFetchPortfolioNewsTrigger,
  watchFetchPortfolioTrigger,
  watchFetchPriceForPortfolioItem,
  watchPortfolioNotFoundTrigger,
  watchRemoveTransactionForItemTrigger,
  watchDeletePortfolioTrigger,
  watchReinvestDividend,
  watchRegenerateSecretCode,
  watchFetchPublicPortfolioTrigger,
  watchBatchFetchPortfolioTrigger,
  ...editPortfolioModalExportableSagas,
];

import { css } from 'styled-components';

export const highchartsFlexboxHack = () => css`
  /* highcharts hack to deal with flexbox flow */
  .highcharts-container {
    width: 100% !important;
    > svg {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;

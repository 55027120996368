import { createSelector } from 'reselect';
import { getAnalysis } from './selectors';
import { getSummary } from './getCompanySummary';
import { format } from 'date-fns';
import { getUTCDate } from '@utilities/date';
export interface Timestamps {
  lastAnalysisUpdate: number;
  lastAnalysisUpdateISOString: string;
  lastAnalysisUpdateFormattedUTC: string;
  lastEstimatesConfirmation?: number;
  lastEstimatesConfirmationFormattedUTC: string;
  lastEODPriceUpdate?: number;
  lastEODPriceUpdateFormattedUTC: string;
  lastAnnualEarninsUpdate?: number;
  lastAnnualEarninsUpdateFormattedUTC: string;
  lastEarningsUpdate?: number;
  lastEarningsUpdateFormattedUTC: string;
}

const formatTimestamp = (
  timestamp: number,
  includeHoursAndMinutes = true
): string => {
  if (typeof timestamp !== 'number' || timestamp === 0) return 'N/A';
  return format(
    getUTCDate(timestamp),
    includeHoursAndMinutes ? 'yyyy/MM/dd HH:mm' : 'yyyy/MM/dd'
  );
};
const getDefaultTimestamp = (): Timestamps => ({
  lastAnalysisUpdate: 0,
  lastAnalysisUpdateISOString: '',
  lastAnalysisUpdateFormattedUTC: '',
  lastEstimatesConfirmation: 0,
  lastEstimatesConfirmationFormattedUTC: '',
  lastEODPriceUpdate: 0,
  lastEODPriceUpdateFormattedUTC: '',
  lastAnnualEarninsUpdate: 0,
  lastAnnualEarninsUpdateFormattedUTC: '',
  lastEarningsUpdate: 0,
  lastEarningsUpdateFormattedUTC: '',
});

export const getCompanyReportTimestamp = createSelector(
  [getSummary, getAnalysis],
  (summary, analysis): Timestamps => {
    try {
      const {
        lastEstimatesConfirmationTimeStamp,
        eodPriceUpdateTimeStamp,
        lastAnnualEarninsUpdateTimeStamp,
        lastEarningsUpdateTimeStamp,
      } = analysis;
      return {
        lastAnalysisUpdate: summary.lastAnalysisUpdate,
        lastAnalysisUpdateISOString: new Date(
          summary.lastAnalysisUpdate
        ).toISOString(),
        lastAnalysisUpdateFormattedUTC: formatTimestamp(
          summary.lastAnalysisUpdate
        ),
        lastEstimatesConfirmation: lastEstimatesConfirmationTimeStamp,
        lastEstimatesConfirmationFormattedUTC: formatTimestamp(
          lastEstimatesConfirmationTimeStamp
        ),
        lastEODPriceUpdate: eodPriceUpdateTimeStamp,
        lastEODPriceUpdateFormattedUTC: formatTimestamp(
          eodPriceUpdateTimeStamp
        ),
        lastAnnualEarninsUpdate: lastAnnualEarninsUpdateTimeStamp,
        lastAnnualEarninsUpdateFormattedUTC: formatTimestamp(
          lastAnnualEarninsUpdateTimeStamp,
          false
        ),
        lastEarningsUpdate: lastEarningsUpdateTimeStamp,
        lastEarningsUpdateFormattedUTC: formatTimestamp(
          lastEarningsUpdateTimeStamp,
          false
        ),
      };
    } catch (e) {
      console.error(e);
      return getDefaultTimestamp();
    }
  }
);

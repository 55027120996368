import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getLocationQueryUnleashOverrides } from '@/redux/location/selectors';

import type { IMutableContext } from '@unleash/proxy-client-react';

import { useUserAgent } from '@simplywallst/hooks/useUserAgent';

import { useUser } from '@/hooks/useUser';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useUserAccess } from '@/hooks/useUserAccess';

const formatDate = (d: string | number) => {
  const date = new Date(d);
  if (typeof date === 'undefined' || String(date) === 'Invalid Date') {
    return '';
  }
  return date.toISOString();
};

export const useEvaluateUnleashContext = (
  additionalProperties: IMutableContext['properties']
): IMutableContext => {
  const { data: user } = useUser();
  const { data: userAccess } = useUserAccess();
  const { brokerReferrer } = userAccess;

  const { isMobile } = useUserAgent();
  const localStorage = useLocalStorage();

  const sessionId =
    localStorage.getItem('unleash:repository:sessionId') || undefined;
  const overrides = useSelector(getLocationQueryUnleashOverrides);

  return useMemo(
    () => ({
      userId: user.id,
      sessionId,
      overrides,
      properties: {
        isAnonymous: String(!user.loggedIn),
        isNative: String(RUNTIME_ENV === 'native'),
        isMobile: String(isMobile),
        userBillingStatus: user.loggedIn ? user.billing.status : '',
        userRegistrationDate: user.loggedIn
          ? formatDate(user.registrationDate)
          : '',
        // PII is safe to use in Unleash as it is all handled within our internal systems.
        userCountryISO: user.countryISO,
        email: user.emailAddress,
        brokerReferrer: brokerReferrer || '',
        ...additionalProperties,
      },
    }),
    [
      user.id,
      sessionId,
      user.loggedIn,
      user.billing.status,
      user.registrationDate,
      user.countryISO,
      user.emailAddress,
      isMobile,
      overrides,
      brokerReferrer,
      additionalProperties,
    ]
  );
};

import { removeTrailingSlash, getServices } from '@simplywallst/services';
import { useScript } from '@/hooks/useScript';
import { useMount } from '@simplywallst/ui-core';

import type { RumGlobal } from '@datadog/browser-rum';
import { PUBLIC_RELEASE_VERSION, PUBLIC_SWS_ENV } from '@/constants/env';

export type DatadogRum = Partial<RumGlobal> & {
  q?: (() => void)[];
};

export function useDatadogRum(
  currentRoute: string,
  serviceName: '@sws/web' | '@sws/news',
  appId: string
) {
  const enabled =
    (PUBLIC_SWS_ENV === 'dev' ||
      PUBLIC_SWS_ENV === 'staging' ||
      PUBLIC_SWS_ENV === 'prod') &&
    typeof window !== 'undefined' &&
    !window?.TEST_RUNNER;

  useScript('https://swsdd.simplywall.st/swsdd20240528.js', {
    crossOrigin: undefined,
    enabled,
  });

  useMount(() => {
    if (typeof window.DD_RUM !== 'undefined') {
      return;
    }

    window.DD_RUM = {
      q: [],
      onReady(args) {
        if (typeof window.DD_RUM !== 'undefined') {
          (window.DD_RUM.q = window.DD_RUM.q || []).push(args);
        }
      },
    };

    if (enabled) {
      window.DD_RUM?.onReady?.(() => {
        const services = getServices();

        window.DD_RUM?.init?.({
          applicationId: appId,
          clientToken: 'pubcef4e9138f6507a47aad741e43363f73',
          site: 'datadoghq.com',
          proxy: 'https://swsdd.simplywall.st/swsddforward',
          service: serviceName,
          env: PUBLIC_SWS_ENV,
          version: PUBLIC_RELEASE_VERSION,
          sessionSampleRate: PUBLIC_SWS_ENV === 'dev' ? 100 : 10,
          sessionReplaySampleRate: 0,
          trackUserInteractions: true,
          trackViewsManually: true,
          allowedTracingUrls: [
            services.SWS_MONO_SERVICE,
            services.SWS_API_SERVICE,
            services.SWS_STREAM_API_SERVICE,
            services.SWS_WARREN_SERVICE,
            services.SWS_GQL_SERVICE,
            services.SWS_PRO_API_GQL_SERVICE,
            services.SWS_CLOUDFLARE_WORKER,
            services.SWS_IMAGE_SERVICE,
          ]
            .filter((str: unknown): str is string => typeof str === 'string')
            .map((endpoint) => {
              let match = removeTrailingSlash(endpoint);
              if (endpoint.endsWith('/graphql')) {
                match = endpoint.replace(/\/graphql$/, '');
              }
              return { match, propagatorTypes: ['datadog'] };
            }),
        });
        window.DD_RUM?.startView?.(currentRoute);
      });
    }
  });
}

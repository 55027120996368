import { ClientError } from 'graphql-request';
import { getOperationNameFromStringOrDocument } from '../graphQLClient/document';
import { captureEvent } from './utils';
import { getServices } from '@simplywallst/services';

const isClientError = (error: unknown): error is ClientError => {
  return error instanceof ClientError;
};

/**
 * We don't want to send the same error to Sentry multiple times for the same session
 */
const sentErrors = new Set<string>();
const createErrorKey = (operationName: string, status: number) =>
  `${operationName}-${status}`;

export const resetSentErrors = () => sentErrors.clear();

const IGNORED_ERROR_CODES = [404];

export const captureGqlError = (error: unknown) => {
  if (!isClientError(error)) return;

  const { request, response } = error;
  const { status, errors } = response;

  const isTrackedError =
    status >= 400 && status <= 599 && !IGNORED_ERROR_CODES.includes(status);

  if (!isTrackedError || !errors || !errors.length) return;

  const { query } = request;

  const firstError = errors[0];

  // Majority of cases will have only a single error
  if (!firstError) return;

  const services = getServices();
  // If we start batching, individual operations will be comma separated
  const operationName = Array.isArray(query)
    ? query.map(getOperationNameFromStringOrDocument).filter(Boolean).join(', ')
    : getOperationNameFromStringOrDocument(query);

  const errorKey = createErrorKey(operationName ?? 'unknownOperation', status);
  if (sentErrors.has(errorKey)) return;
  sentErrors.add(errorKey);

  const url =
    typeof SWS_INTERNAL_GQL_URL !== 'undefined' && SWS_INTERNAL_GQL_URL
      ? SWS_INTERNAL_GQL_URL
      : services.SWS_GQL_SERVICE;

  const payload = {
    level: 'error',
    tags: {
      gqlError: true,
    },
    request: {
      method: 'POST',
      url,
    },
    exception: {
      values: errors.map((error) => ({
        type: `GraphQL error ${operationName} ${status}: ${
          error.extensions?.code ?? 'UNKNOWN_ERROR'
        }`,
        value: error.message,
      })),
    },
    extra: {
      operationName,
      path: firstError.path,
      service: firstError.extensions?.serviceName,
      locations: firstError.locations,
      code: firstError.extensions?.code,
      traceId: firstError.extensions?.traceId,
      status,
    },
  } satisfies Parameters<typeof captureEvent>[0];

  return captureEvent(payload, {
    mechanism: {
      type: 'http.client',
      handled: true,
      source: 'graphql',
    },
  });
};

import { useLocation } from '@/hooks/useLocation';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getRewardfulData } from './getRewardfulData';
import { getRewardfulGlobal } from './getRewardfulGlobal';
import { getRewardfulAffiliateReferrals } from '@simplywallst/services';
import { REWARDFUL_API_KEY } from '@/constants/env';
import { actionToPath } from 'redux-first-router';
import queryString from 'query-string';

export function useRewardfulData() {
  const rewardful = getRewardfulGlobal();
  const rewardfulData = getRewardfulData();

  const [data, setData] = useState<typeof rewardfulData>();

  const location = useLocation();

  const path = useMemo(
    () => actionToPath(location, location.routesMap, queryString),
    [location]
  );

  const rewardfulQuery = useQuery({
    queryKey: ['REWARDFUL_AFFILIATE', path, location.query?.via] as const,
    queryFn: async (context) => {
      const { 1: pathname, 2: linkToken } = context.queryKey;
      /**
       *
       * react-query+ts narrowing limitation
       * in our `enabled` configuration we're already null-checking
       * the `via` query string
       *
       */
      if (typeof linkToken === 'undefined') {
        throw new Error('Id cannot be undefined');
      }
      const response = await getRewardfulAffiliateReferrals({
        api_key: REWARDFUL_API_KEY,
        link_token: linkToken,
        location: pathname,
      });
      return response.data;
    },
    enabled:
      typeof location.query?.via === 'string' &&
      typeof rewardfulData === 'undefined', // only enabled when rewardful didn't load
    staleTime: Infinity,
    retry: false,
  });

  useEffect(() => {
    if (typeof data === 'undefined') {
      // Ensure rewardful has loaded
      rewardful?.('ready', () => {
        setData(() => getRewardfulData());
      });
    }
  }, [rewardfulData, data, rewardful]);

  const mappedRewardfulData = useMemo(() => {
    if (data) {
      // Narrow Rewardful data from Data | undefined | false to Data | undefined
      return {
        affiliate: data.affiliate || undefined,
        coupon: data.coupon || undefined,
        referral: data.referral || undefined,
      };
    }
  }, [data]);

  const referralData = useMemo(() => {
    const referralCoupon = mappedRewardfulData?.coupon?.id;
    const referralId = mappedRewardfulData?.referral;
    const referralToken = mappedRewardfulData?.affiliate?.token;
    if (referralCoupon || referralId || referralToken) {
      return {
        referralId,
        referralToken,
        referralCoupon,
      };
    }
  }, [
    mappedRewardfulData?.affiliate?.token,
    mappedRewardfulData?.coupon?.id,
    mappedRewardfulData?.referral,
  ]);

  if (rewardfulQuery.status === 'success') {
    /**
     * We'll use rewardful query as source of truth and assume
     * rewardful has been blocked by adblock.
     *
     * In this case we are "rebuilding" the return value of this hook
     * that naturally comes from the rewardful js library.
     *
     */
    const { data } = rewardfulQuery;
    return {
      referralData: {
        referralCoupon: data.referral.coupon?.id,
        referralId: data.referral.id,
        referralToken: data.referral.affiliate.token,
      },
      rewardful: {
        affiliate: data.referral.affiliate,
        coupon: data.referral.coupon,
        referral: data.referral.id,
      },
    };
  }

  return { referralData, rewardful: mappedRewardfulData };
}

import type {
  ActionPayload,
  ItemTransaction,
  PortfolioHolding,
  PortfolioHoldingTransactions,
  State,
} from './interface';
import { normalise } from '@/state/utils/reducer';
import { uniq } from 'lodash';

function transactionReducer(state: State, payload: ActionPayload) {
  const { holdingsTable } = state;
  const { portfolioId, items } = payload;
  if (!portfolioId) return {};
  const safeItems = items || [];
  let transactionsTable: Record<string, PortfolioHoldingTransactions> = {};
  const reducedHoldings: Record<string, PortfolioHolding> = safeItems.reduce(
    (current, next) => {
      const holding = holdingsTable[next.id];
      // Todo: Need to look into why/how this is null
      if (!holding) return current;
      const { transactionsMap = [] } = holding;
      const currentTransactionMap = transactionsMap;
      const { keys, entities } = normalise<
        PortfolioHoldingTransactions,
        ItemTransaction
      >(next.transactions.data)('id', transactionNormaliser);
      transactionsTable = {
        ...transactionsTable,
        ...entities,
      };
      return {
        ...current,
        [next.id]: {
          ...holding,
          transactionsMap: uniq([...currentTransactionMap, ...keys]),
          itemId: next.id.toString(),
        },
      };
    },
    {}
  );
  return { holdingsTable: reducedHoldings, transactionsTable };
}

export function transactionNormaliser(
  currentTransaction
): PortfolioHoldingTransactions {
  const { amount, cost, date, id, type } = currentTransaction;
  return {
    transactionId: id.toString(),
    transactionType: type,
    transactionDate: date,
    transactionAmount: amount,
    transactionCost: cost,
  };
}

export default transactionReducer;

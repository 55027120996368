export interface QueryKeyword {
  label: string;
  demonym?: string;
  slug?: string;
  children?: string[] | readonly string[];
  mainIndex?: string;
  mainExchange?: string;
}

export const ORDERED_INDUSTRIES = [
  '4010000',
  '7010000',
  '3010000',
  '3100000',
  '4100000',
  '4200000',
  '7100000',
  '1010000',
  '5010000',
  '5100000',
  '6010000',
  '5200000',
  '7200000',
  '2010000',
  '4300000',
  '6100000',
  '7300000',
  '4400000',
  '8200000',
  '8010000',
  '8100000',
  '9010000',
  '3200000',
  '9510000',
];

export const SECTORS = {
  '1000000': {
    label: 'Energy',
    slug: 'energy',
    children: ['1010000'],
  } as const,
  '1033405': {
    label: 'Real Estate',
    slug: 'real-estate',
    children: ['7300000'],
  } as const,
  '2000000': {
    label: 'Materials',
    slug: 'materials',
    children: ['2010000'],
  } as const,
  '3000000': {
    label: 'Industrials',
    slug: 'industrials',
    children: ['3010000', '3100000', '3200000'],
  } as const,
  '4000000': {
    label: 'Consumer Discretionary',
    slug: 'consumer-discretionary',
    children: ['4010000', '4100000', '4200000', '4400000'],
  } as const,
  '5000000': {
    label: 'Consumer Staples',
    slug: 'consumer-staples',
    children: ['5010000', '5100000', '5200000'],
  } as const,
  '6000000': {
    label: 'Healthcare',
    slug: 'healthcare',
    children: ['6010000', '6100000'],
  } as const,
  '7000000': {
    label: 'Financials',
    slug: 'financials',
    children: ['7010000', '7100000', '7200000'],
  } as const,
  '8000000': {
    label: 'Tech',
    slug: 'tech',
    children: ['8010000', '8100000', '8200000'],
  } as const,
  '9000000': {
    label: 'Telecom',
    slug: 'telecom',
    children: ['4300000', '9010000'],
  } as const,
  '9500000': {
    label: 'Utilities',
    slug: 'utilities',
    children: ['9510000'],
  } as const,
};

export const PRIMARY_INDUSTRIES = {
  '1010000': {
    label: 'Energy',
    slug: 'energy',
    children: ['1020000', '1030000'],
  } as const,
  '2010000': {
    label: 'Materials',
    slug: 'materials',
    children: ['2020000', '2030000', '2040000', '2050000', '2060000'],
  } as const,
  '3010000': {
    label: 'Capital Goods',
    slug: 'capital-goods',
    children: [
      '3020000',
      '3030000',
      '3040000',
      '3050000',
      '3060000',
      '3070000',
      '3080000',
    ],
  } as const,
  '3100000': {
    label: 'Commercial Services',
    slug: 'commercial-services',
    children: ['3110000', '9612010'],
  } as const,
  '3200000': {
    label: 'Transportation',
    slug: 'transportation',
    children: ['3210000', '3220000', '3230000', '3240000', '3250000'],
  } as const,
  '4010000': {
    label: 'Automobiles',
    slug: 'automobiles',
    children: ['4011000', '4020000'],
  } as const,
  '4100000': {
    label: 'Consumer Durables',
    slug: 'consumer-durables',
    children: ['4110000', '4120000', '4130000'],
  } as const,
  '4200000': {
    label: 'Consumer Services',
    slug: 'consumer-services',
    children: ['9551701', '4210000'],
  } as const,
  '4300000': {
    label: 'Media',
    slug: 'media',
    children: [
      '4311000',
      '4312000',
      '9612013',
      '4313000',
      '4314000',
      '4300001',
    ],
  } as const,
  '4400000': {
    label: 'Retail',
    slug: 'retail',
    children: ['4410000', '4420000', '4430000', '4440000'],
  } as const,
  '5010000': {
    label: 'Consumer Retailing',
    slug: 'consumer-retailing',
    children: ['5021000', '5022000', '5023000', '5024000'],
  } as const,
  '5100000': {
    label: 'Food, Beverage & Tobacco',
    slug: 'food-beverage-tobacco',
    children: ['5110000', '5120000', '5130000'],
  } as const,
  '5200000': {
    label: 'Household',
    slug: 'household',
    children: ['5210000', '5220000'],
  } as const,
  '6010000': {
    label: 'Healthcare',
    slug: 'healthcare',
    children: ['9551729', '6020000', '6030000'],
  } as const,
  '6100000': {
    label: 'Pharmaceuticals & Biotech',
    slug: 'pharmaceuticals-biotech',
    children: ['6110000', '9551727', '6120000'],
  } as const,
  '7010000': {
    label: 'Banks',
    slug: 'banks',
    children: ['7011000', '7020000'],
  } as const,
  '7100000': {
    label: 'Diversified Financials',
    slug: 'diversified-financials',
    children: ['7130000', '7120000', '7110000', '9551733'],
  } as const,
  '7200000': {
    label: 'Insurance',
    slug: 'insurance',
    children: ['7211000', '7212000', '7213000', '7214000', '7215000'],
  } as const,
  '7300000': {
    label: 'Real Estate',
    slug: 'real-estate',
    children: [
      '7311000',
      '7310000',
      '9622292',
      '9622294',
      '9622295',
      '9622296',
      '9622297',
      '9622298',
      '9622299',
    ],
  } as const,
  '8010000': {
    label: 'Software',
    slug: 'software',
    children: ['8030000', '8020000', '8040000'],
  } as const,
  '8100000': {
    label: 'Tech',
    slug: 'tech',
    children: ['8110000', '8130000', '8120000'],
  } as const,
  '8200000': {
    label: 'Semiconductors',
    slug: 'semiconductors',
    children: ['8211000'],
  } as const,
  '9010000': {
    label: 'Telecom',
    slug: 'telecom',
    children: ['9020000', '9030000'],
  } as const,
  '9510000': {
    label: 'Utilities',
    slug: 'utilities',
    children: ['9520000', '9530000', '9551702', '9540000', '9550000'],
  } as const,
  '9622303': {
    label: 'Real Estate Management and Development',
    slug: 'real-estate-management-and-development',
    children: [],
  } as const,
};

export const SECONDARY_INDUSTRIES = {
  '1020000': {
    label: 'Energy Services',
    slug: 'energy-services',
    children: ['1021000', '1022000'],
  } as const,
  '1030000': {
    label: 'Oil and Gas',
    slug: 'oil-gas',
    children: ['2052001', '1031000', '1032000', '1033000', '9551704'],
  } as const,
  '2020000': {
    label: 'Chemicals',
    slug: 'chemicals',
    children: ['2021000', '2022000', '2023000', '2025000', '2024000'],
  } as const,
  '2030000': {
    label: 'Basic Materials',
    slug: 'basic-materials',
    children: ['2031000'],
  } as const,
  '2040000': {
    label: 'Packaging',
    slug: 'packaging',
    children: ['2041000', '2042000'],
  } as const,
  '2050000': {
    label: 'Metals and Mining',
    slug: 'metals-mining',
    children: [
      '2051000',
      '2052000',
      '2053000',
      '2054000',
      '9621065',
      '2055000',
    ],
  } as const,
  '2060000': {
    label: 'Forestry',
    slug: 'forestry',
    children: ['2061000', '2062000'],
  } as const,
  '3020000': {
    label: 'Aerospace and Defense',
    slug: 'aerospace-defense',
    children: ['3021000'],
  } as const,
  '3030000': {
    label: 'Building',
    slug: 'building',
    children: ['3031000'],
  } as const,
  '3040000': {
    label: 'Construction',
    slug: 'construction',
    children: ['3041000'],
  } as const,
  '3050000': {
    label: 'Electrical',
    slug: 'electrical',
    children: ['3051000', '3052000'],
  } as const,
  '3060000': {
    label: 'Industrials',
    slug: 'industrials',
    children: ['3061000'],
  } as const,
  '3070000': {
    label: 'Machinery',
    slug: 'machinery',
    children: ['9621067', '3071000', '3072000'],
  } as const,
  '3080000': { label: 'Trade Distributors', children: ['3081000'] } as const,
  '3110000': {
    label: 'Commercial Services',
    slug: 'commercial-services',
    children: ['3111000', '3112100', '3114000', '3115000', '9612009'],
  } as const,
  '3210000': {
    label: 'Logistics',
    slug: 'logistics',
    children: ['3211000'],
  } as const,
  '3220000': {
    label: 'Airlines',
    slug: 'airlines',
    children: ['3221000'],
  } as const,
  '3230000': {
    label: 'Shipping',
    slug: 'shipping',
    children: ['3231000'],
  } as const,
  '3240000': {
    label: 'Transportation',
    slug: 'transportation',
    children: ['3241000', '3242000', '9622304'],
  } as const,
  '3250000': {
    label: 'Infrastructure',
    slug: 'infrastructure',
    children: ['3251000', '3252000', '3253000'],
  } as const,
  '4011000': {
    label: 'Auto Components',
    slug: 'auto-components',
    children: ['4012000', '4013000'],
  } as const,
  '4020000': {
    label: 'Auto',
    slug: 'auto',
    children: ['4021000', '4022000'],
  } as const,
  '4110000': {
    label: 'Consumer Durables',
    slug: 'consumer-durables',
    children: ['4111000', '4112000', '4113000', '4114000', '4115000'],
  } as const,
  '4120000': { label: 'Leisure', children: ['4121000'] } as const,
  '4130000': {
    label: 'Luxury',
    children: ['4131000', '4132000', '4133000'],
  } as const,
  '4210000': {
    label: 'Hospitality',
    children: ['4211000', '4212000', '4213000', '4214000'],
  } as const,
  '4300001': {
    label: 'Entertainment',
    children: ['4313000', '8043000'],
  } as const,
  '4310000': {
    label: 'Media',
    children: ['4311000', '4312000', '4314000', '9612013'],
  } as const,
  '4311000': { label: 'Advertising', children: [] } as const,
  '4312000': { label: 'Broadcasting', children: [] } as const,
  '4313000': {
    label: 'Movies and Entertainment',
    slug: 'movies-entertainment',
    children: [],
  } as const,
  '4314000': { label: 'Publishing', children: [] } as const,
  '4410000': {
    label: 'Retail Distributors',
    slug: 'retail-distributors',
    children: ['4411000'],
  } as const,
  '4420000': {
    label: 'Online Retail',
    slug: 'online-retail',
    children: ['4422000', '4421000'],
  } as const,
  '4430000': {
    label: 'Multiline Retail',
    slug: 'multiline-retail',
    children: ['4431000', '4432000', '9622290'],
  } as const,
  '4440000': {
    label: 'Specialty Retail',
    slug: 'specialty-retail',
    children: [
      '4441000',
      '9551705',
      '4442000',
      '4444100',
      '4443000',
      '4444000',
    ],
  } as const,
  '5020000': {
    label: 'Consumer Retailing',
    slug: 'consumer-retailing',
    children: ['5021000', '5022000', '5023000', '5024000'],
  } as const,
  '5021000': {
    label: 'Drug Retail',
    slug: 'drug-retail',
    children: [],
  } as const,
  '5022000': {
    label: 'Food Distributors',
    slug: 'food-distributors',
    children: [],
  } as const,
  '5023000': {
    label: 'Food Retail',
    slug: 'food-retail',
    children: [],
  } as const,
  '5024000': {
    label: 'Hypermarkets and Super Centers',
    slug: 'big-box-super-stores',
    children: [],
  } as const,
  '5110000': {
    label: 'Beverage',
    children: ['5111000', '5112000', '5113000'],
  } as const,
  '5120000': { label: 'Food', children: ['5121000', '5122000'] } as const,
  '5130000': { label: 'Tobacco', children: ['5131000'] } as const,
  '5210000': {
    label: 'Household Products',
    slug: 'household-products',
    children: ['5211000'],
  } as const,
  '5220000': {
    label: 'Personal Products',
    slug: 'personal-products',
    children: ['5221000'],
  } as const,
  '6020000': {
    label: 'Medical Equipment',
    slug: 'medical-equipment',
    children: ['6021000', '6022000'],
  } as const,
  '6030000': {
    label: 'Healthcare',
    children: ['6031000', '6033000', '6032000', '6034000'],
  } as const,
  '6110000': {
    label: 'Biotechs',
    slug: 'biotech',
    children: ['6111000'],
  } as const,
  '6120000': { label: 'Pharmaceuticals', children: ['6121000'] } as const,
  '7011000': { label: 'Banks', children: ['7012000', '7013000'] } as const,
  '7020000': { label: 'Mortgage', children: ['7021000'] } as const,
  '7110000': {
    label: 'Diversified Financial',
    slug: 'diversified-financial',
    children: ['7112000', '7111000', '7113000', '9622291'],
  } as const,
  '7120000': {
    label: 'Consumer Finance',
    slug: 'consumer-finance',
    children: ['7121000'],
  } as const,
  '7130000': {
    label: 'Capital Markets',
    slug: 'capital-markets',
    children: ['7131000', '7133000', '7132000'],
  } as const,
  '7211000': { label: 'Insurance Brokers', children: [] } as const,
  '7212000': {
    label: 'Life and Health Insurance',
    slug: 'life-health-insurance',
    children: [],
  } as const,
  '7213000': { label: 'Multi-line Insurance', children: [] } as const,
  '7214000': {
    label: 'Property and Casualty Insurance',
    slug: 'property-casualty-insurance',
    children: [],
  } as const,
  '7215000': { label: 'Reinsurance', children: [] } as const,
  '7310000': {
    label: 'Real Estate',
    children: ['7312000', '9612015', '9612014', '9612016'],
  } as const,
  '7311000': {
    label: 'REITs',
    children: [
      '9551731',
      '9621071',
      '9621070',
      '9551732',
      '9551734',
      '9551735',
      '9551736',
      '9551737',
    ],
  } as const,
  '8020000': { label: 'Internet', children: [] } as const,
  '8030000': { label: 'IT', children: ['8032000', '8031000'] } as const,
  '8040000': {
    label: 'Software',
    children: ['8041000', '8043000', '8042000'],
  } as const,
  '8110000': { label: 'Communications', children: [] } as const,
  '8120000': {
    label: 'Tech',
    children: ['9621091'],
  } as const,
  '8130000': {
    label: 'Electronic',
    children: ['9612017', '8131000', '8132000', '8133000'],
  } as const,
  '8211000': { label: 'Semiconductor', children: [] } as const,
  '9020000': {
    label: 'Telecom',
    children: ['9021000', '9022000'],
  } as const,
  '9030000': {
    label: 'Wireless Telecom',
    slug: 'wireless-telecom',
    children: ['9031000'],
  } as const,
  '9520000': {
    label: 'Electric Utilities',
    slug: 'electric-utilities',
    children: ['9521000'],
  } as const,
  '9530000': {
    label: 'Gas Utilities',
    slug: 'gas-utilities',
    children: ['9531000'],
  } as const,
  '9540000': {
    label: 'Integrated Utilities',
    slug: 'other-utilities',
    children: ['9541000'],
  } as const,
  '9550000': {
    label: 'Water Utilities',
    slug: 'water-utilities',
    children: ['9551000'],
  } as const,
  '9551701': {
    label: 'Consumer Services',
    slug: 'consumer-services',
    children: ['3112400', '3112800'],
  } as const,
  '9551702': {
    label: 'Renewable Energy',
    slug: 'renewable-energy',
    children: ['9551703', '9621063'],
  } as const,
  '9551727': {
    label: 'Life Sciences',
    slug: 'life-sciences',
    children: ['9551728'],
  } as const,
  '9551729': {
    label: 'Healthcare Services',
    slug: 'healthtech',
    children: ['9551730'],
  } as const,
  '9551733': { label: 'Mortgage REITs', children: [] } as const,
  '9612010': {
    label: 'Professional Services',
    slug: 'professional-services',
    children: ['9612011', '9612012'],
  } as const,
  '9612013': {
    label: 'Cable and Satellite',
    slug: 'cable-satellite',
    children: [],
  } as const,
  '9622292': {
    label: 'Residential REITs',
    slug: 'residential-reits',
    children: ['9622293'],
  } as const,
  '9622294': {
    label: 'Industrial REITs',
    slug: 'industrial-reits',
    children: [],
  } as const,
  '9622295': {
    label: 'Hotel and Resort REITs',
    slug: 'hotel-and-resort-reits',
    children: [],
  } as const,
  '9622296': {
    label: 'Office REITs',
    slug: 'office-reits',
    children: [],
  } as const,
  '9622297': {
    label: 'Health Care REITs',
    slug: 'health-care-reits',
    children: [],
  } as const,
  '9622298': {
    label: 'Retail REITs',
    slug: 'retail-reits',
    children: [],
  } as const,
  '9622299': {
    label: 'Specialized REITs',
    slug: 'specialized-reits',
    children: ['9621877', '9622300', '9622301', '9622302'],
  } as const,
};

export const TERTIARY_INDUSTRIES = {
  '1021000': {
    label: 'Oil and Gas Drilling',
    slug: 'oil-gas-drilling',
  } as const,
  '1022000': {
    label: 'Oil and Gas Equipment and Services',
    slug: 'oil-gas-services',
  } as const,
  '1031000': { label: 'Integrated Oil and Gas', slug: 'integrated' } as const,
  '1032000': {
    label: 'Oil and Gas Exploration and Production',
    slug: 'exploration-production',
  } as const,
  '1033000': {
    label: 'Oil and Gas Refining and Marketing',
    slug: 'refining-marketing',
  } as const,
  '2021000': { label: 'Commodity Chemicals' } as const,
  '2022000': { label: 'Diversified Chemicals' } as const,
  '2023000': {
    label: 'Fertilizers and Agricultural Chemicals',
    slug: 'fertilizers-agricultural-chemicals',
  } as const,
  '2024000': { label: 'Specialty Chemicals' } as const,
  '2025000': { label: 'Industrial Gases' } as const,
  '2041000': {
    label: 'Metal and Glass Containers',
    slug: 'metal-glass-containers',
  } as const,
  '2042000': { label: 'Paper Packaging' } as const,
  '2051000': { label: 'Aluminum' } as const,
  '2052000': {
    label: 'Diversified Metals and Mining',
    slug: 'diversified-metals-mining',
  } as const,
  '2052001': {
    label: 'Coal and Consumable Fuels',
    slug: 'coal-fuels',
  } as const,
  '2053000': { label: 'Gold' } as const,
  '2054000': {
    label: 'Precious Metals and Minerals',
    slug: 'precious-metals-minerals',
  } as const,
  '2055000': { label: 'Steel' } as const,
  '2061000': { label: 'Forest Products' } as const,
  '2062000': { label: 'Paper Products' } as const,
  '3051000': {
    label: 'Electrical Components and Equipment',
    slug: 'electrical-components',
  } as const,
  '3052000': { label: 'Heavy Electrical Equipment' } as const,
  '3071000': {
    label: 'Construction Machinery and Heavy Trucks',
    slug: 'construction-machinery-vehicles',
  } as const,
  '3072000': { label: 'Industrial Machinery' } as const,
  '3111000': { label: 'Commercial Printing' } as const,
  '3112100': { label: 'Diversified Support Services' } as const,
  '3112400': { label: 'Education Services' } as const,
  '3112800': { label: 'Specialized Consumer Services' } as const,
  '3114000': {
    label: 'Environmental and Facilities Services',
    slug: 'environmental-facilities-services',
  } as const,
  '3115000': { label: 'Office Services and Supplies' } as const,
  '3241000': { label: 'Railroads' } as const,
  '3242000': { label: 'Trucking' } as const,
  '3251000': { label: 'Airport Services' } as const,
  '3252000': {
    label: 'Highways and Railtracks',
    slug: 'highways-railtracks',
  } as const,
  '3253000': {
    label: 'Marine Ports and Services',
    slug: 'marine-ports-services',
  } as const,
  '4012000': {
    label: 'Auto Parts and Equipment',
    slug: 'auto-parts-equipment',
  } as const,
  '4013000': { label: 'Tires and Rubber', slug: 'tires-rubber' } as const,
  '4021000': { label: 'Automobile Manufacturers' } as const,
  '4022000': { label: 'Motorcycle Manufacturers' } as const,
  '4111000': { label: 'Consumer Electronics' } as const,
  '4112000': { label: 'Home Furnishings' } as const,
  '4113000': { label: 'Homebuilding' } as const,
  '4114000': { label: 'Household Appliances' } as const,
  '4115000': {
    label: 'Housewares and Specialties',
    slug: 'housewares-specialties',
  } as const,
  '4131000': {
    label: 'Apparel, Accessories and Luxury Goods',
    slug: 'apparel-accessories-luxury-goods',
  } as const,
  '4132000': { label: 'Footwear' } as const,
  '4133000': { label: 'Textiles' } as const,
  '4211000': { label: 'Casinos and Gaming', slug: 'casinos-gaming' } as const,
  '4212000': {
    label: 'Hotels, Resorts and Cruise Lines',
    slug: 'hotels-resorts-cruise-lines',
  } as const,
  '4213000': { label: 'Leisure Facilities' } as const,
  '4214000': { label: 'Restaurants' } as const,
  '4421000': { label: 'Catalog Retail' } as const,
  '4422000': { label: 'Internet Retail' } as const,
  '4431000': { label: 'Department Stores' } as const,
  '4432000': { label: 'General Merchandise Stores' } as const,
  '4441000': { label: 'Apparel Retail' } as const,
  '4442000': {
    label: 'Computer and Electronics Retail',
    slug: 'computer-electronics-retail',
  } as const,
  '4443000': { label: 'Home Improvement Retail' } as const,
  '4444000': { label: 'Specialty Stores' } as const,
  '4444100': { label: 'Home Furnishing Retail' } as const,
  '5111000': { label: 'Brewers' } as const,
  '5112000': {
    label: 'Distillers and Vintners',
    slug: 'distillers-wine-makers',
  } as const,
  '5113000': { label: 'Soft Drinks' } as const,
  '5121000': { label: 'Agricultural Products' } as const,
  '5122000': {
    label: 'Packaged Foods and Meats',
    slug: 'packaged-foods-meats',
  } as const,
  '6021000': {
    label: 'Healthcare Equipment',
    slug: 'medical-equipment',
  } as const,
  '6022000': {
    label: 'Healthcare Supplies',
    slug: 'medical-supplies',
  } as const,
  '6031000': {
    label: 'Healthcare Distributors',
    slug: 'healthcare-distributors',
  } as const,
  '6032000': {
    label: 'Healthcare Services',
    slug: 'healthcare-services',
  } as const,
  '6033000': {
    label: 'Healthcare Facilities',
    slug: 'healthcare-facilities',
  } as const,
  '6034000': {
    label: 'Managed Healthcare',
    slug: 'managed-healthcare',
  } as const,
  '7012000': { label: 'Diversified Banks', slug: 'diversified-banks' } as const,
  '7013000': { label: 'Regional Banks', slug: 'regional-banks' } as const,
  '7111000': {
    label: 'Other Diversified Financial Services',
    slug: 'other-diversified-financial-services',
  } as const,
  '7112000': {
    label: 'Multi-Sector Holdings',
    slug: 'multi-sector-holdings',
  } as const,
  '7113000': {
    label: 'Specialized Finance',
    slug: 'specialized-finance',
  } as const,
  '7131000': {
    label: 'Asset Management and Custody Banks',
    slug: 'asset-management',
  } as const,
  '7132000': {
    label: 'Investment Banking and Brokerage',
    slug: 'investment-banking-brokerage',
  } as const,
  '7133000': {
    label: 'Diversified Capital Markets',
    slug: 'diversified-capital-markets',
  } as const,
  '7312000': {
    label: 'Diversified Real Estate Activities',
    slug: 'diversified-real-estate',
  } as const,
  '8031000': {
    label: 'IT Consulting and Other Services',
    slug: 'it-consulting',
  } as const,
  '8032000': {
    label: 'Data Processing and Outsourced Services',
    slug: 'data-processing-services',
  } as const,
  '8041000': { label: 'Application Software' } as const,
  '8042000': { label: 'Systems Software' } as const,
  '8043000': { label: 'Home Entertainment Software' } as const,
  '8131000': {
    label: 'Electronic Equipment and Instruments',
    slug: 'electronic-equipment-instruments',
  } as const,
  '8132000': { label: 'Electronic Manufacturing Services' } as const,
  '8133000': { label: 'Technology Distributors' } as const,
  '9021000': { label: 'Alternative Carriers' } as const,
  '9022000': { label: 'Integrated Telecommunication Services' } as const,
  '9551703': {
    label: 'Independent Power Producers and Energy Traders',
    slug: 'independent-power-producers-energy-traders',
  } as const,
  '9551704': {
    label: 'Oil and Gas Storage and Transportation',
    slug: 'storage-transportation',
  } as const,
  '9551705': { label: 'Automotive Retail' } as const,
  '9551731': { label: 'Diversified REITs' } as const,
  '9551732': { label: 'Industrial REITs' } as const,
  '9551734': { label: 'Office REITs' } as const,
  '9551735': { label: 'Residential REITs' } as const,
  '9551736': { label: 'Retail REITs' } as const,
  '9551737': { label: 'Specialized REITs' } as const,
  '9612009': {
    label: 'Security and Alarm Services',
    slug: 'security-alarm-services',
  } as const,
  '9612011': {
    label: 'Human Resource and Employment Services',
    slug: 'hr-services',
  } as const,
  '9612012': {
    label: 'Research and Consulting Services',
    slug: 'research-consulting-services',
  } as const,
  '9612014': { label: 'Real Estate Operating Companies' } as const,
  '9612015': { label: 'Real Estate Development' } as const,
  '9612016': { label: 'Real Estate Services' } as const,
  '9612017': { label: 'Electronic Components' } as const,
  '9621063': { label: 'Renewable Electricity' } as const,
  '9621065': { label: 'Silver' } as const,
  '9621067': {
    label: 'Agricultural and Farm Machinery',
    slug: 'agricultural-machinery',
  } as const,
  '9621070': {
    label: 'Hotel and Resort REITs',
    slug: 'hotel-resort-reits',
  } as const,
  '9621071': { label: 'Healthcare REITs' } as const,

  '9621877': {
    label: 'Self-Storage REITs',
    slug: 'self-storage-reits',
  } as const,
  '9622290': { label: 'Broadline Retail', slug: 'broadline-retail' } as const,
  '9622291': {
    label: 'Transaction and Payment Processing Services',
    slug: 'transaction-and-payment-processing-services',
  } as const,
  '9622293': {
    label: 'Single-Family Residential REITs',
    slug: 'single-family-residential-reits',
  } as const,
  '9622300': {
    label: 'Telecom Tower REITs',
    slug: 'telecom-tower-reits',
  } as const,
  '9622301': { label: 'Timber REITs', slug: 'timber-reits' } as const,
  '9622302': { label: 'Data Center REITs', slug: 'data-center-reits' } as const,
  '9622304': {
    label: 'Cargo Ground Transportation',
    slug: 'cargo-ground-transportation',
  } as const,
};

type Slugs<T extends Record<string, { slug: string }>> = T[keyof T]['slug'];

export type PrimaryIndustrySlug = Slugs<typeof PRIMARY_INDUSTRIES>;

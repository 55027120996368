import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useCompanyCanonicalUrl } from '@/hooks/useCompany';
import type { CompanySubSection } from './hooks/useReportUrlHandler';
import { ANCHOR_TO_SECTION_MAPPING } from '@/constants/anchors';
import { createSelector } from 'reselect';
import { getLocationPayload } from '@/redux/location/selectors';

export const sections = [
  'summary',
  'valuation',
  'future',
  'past',
  'health',
  'dividend',
  'management',
  'ownership',
  'information',
] as const;

export type CompanyReportSection = (typeof sections)[number];

export function isValidSection(
  section?: string
): section is CompanyReportSection {
  return sections.includes(section as CompanyReportSection);
}

//Maps company report sub sections to its section
export function getSectionFromAnchor(
  anchor: CompanySubSection
): CompanyReportSection | undefined {
  if (sections.includes(anchor as CompanyReportSection))
    return anchor as CompanyReportSection;
  return ANCHOR_TO_SECTION_MAPPING[anchor];
}

function isCompanyReportSection(
  section: string
): section is CompanyReportSection {
  return sections.includes(section as CompanyReportSection);
}

export const getCompanySection = createSelector(
  [getLocationPayload],
  ({ section }) => {
    return section && isCompanyReportSection(section) ? section : 'summary';
  }
);

export function useSection() {
  const selected = useSelector(getCompanySection);

  const [currentSection, setCurrentSection] = useState(selected);
  const [initialSection, setInitialSection] = useState(selected);
  // Reset initialSection when different company is viewed
  const canonicalUrl = useCompanyCanonicalUrl();
  useEffect(() => {
    if (!canonicalUrl) return;
    setInitialSection(selected);
    setCurrentSection(selected);
  }, [canonicalUrl, selected]);

  return useMemo(() => {
    const sectionIndex = sections.findIndex((v) => v === initialSection);
    const sectionsAbove = sections.slice(0, sectionIndex);
    const sectionsBelow = sections.slice(sectionIndex + 1);

    return {
      initialSection,
      currentSection,
      setCurrentSection,
      sectionsAbove,
      sectionsBelow,
    };
  }, [initialSection, currentSection]);
}

import type IIndustryAverageFields from './IIndustryAverageFields';
import {
  getIndustryFields,
  getIndustryFieldFilter,
} from '@simplywallst/services';

type IndustryField = Awaited<
  ReturnType<typeof getIndustryFields>
>['data']['data'][number];

type IndustryAverageConstituent = Awaited<
  ReturnType<typeof getIndustryFieldFilter>
>['data']['data'][number];
class IndustryAverageFields implements IIndustryAverageFields {
  async getAllFields() {
    return new Promise<readonly IndustryField[]>(async (resolve, reject) => {
      try {
        const response = await getIndustryFields();
        if (response && response.data) {
          const allFields = response.data.data;
          resolve(allFields);
        } else {
          reject(response);
        }
      } catch (reason) {
        reject(reason);
      }
    });
  }

  async getConstituentsForField(
    field: string,
    group: string,
    company?: string,
    countryISO?: string,
    industry?: number,
    offset?: number,
    limit?: number
  ) {
    return new Promise<readonly IndustryAverageConstituent[]>(
      async (resolve, reject) => {
        try {
          const response = await getIndustryFieldFilter({
            action: `${field}/${group}`,
            payload: {
              company,
              industry,
              offset,
              limit,
              country: countryISO,
            },
          });

          if (response && response.data) {
            const constituentDetails = response.data.data;
            resolve(constituentDetails);
          } else {
            reject(response);
          }
        } catch (reason) {
          console.error(reason);
          reject(reason);
        }
      }
    );
  }
}

export default IndustryAverageFields;

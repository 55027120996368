import { post } from "../methods";
function createPortfolioTransaction(payload, headers = {}) {
  return post({
    url: "api/user/portfolio/transaction",
    headers,
    payload
  });
}
export {
  createPortfolioTransaction as default
};

import { createSelector } from 'reselect';
import type { State } from '../interface';
import type { LocationState } from 'redux-first-router';
import { isEqual, omit, pick } from 'lodash';
import { shallowEqual } from 'react-redux';

const cleanLocation = (locationLike: unknown) => {
  const pathsToPick = ['payload', 'type', 'search', 'query'];
  const pathsToOmit = ['payload.section'];
  return omit(pick(locationLike, pathsToPick), pathsToOmit);
};

/**
 * Compares 2 redux states using shallowEqual for everything except for Location.
 * Location is compared with deep equality on selected fields whilst ignoring
 * section change in the payload.
 */
export const areStatesEqualCompanySection = <T extends Record<string, unknown>>(
  a: T,
  b: T
) => {
  const { location: locationA, ...restA } = a;
  const { location: locationB, ...restB } = b;

  const isLocationEqualish = isEqual(
    cleanLocation(locationA),
    cleanLocation(locationB)
  );

  return isLocationEqualish && shallowEqual(restA, restB);
};

export const getCanonicalUrlFromLocation = (state) => {
  if (!state.location) return undefined;
  const location = state.location as LocationState;
  const { isoCode2, industry, exchange_ticker, companyname } =
    location.payload as any;
  return `/stocks/${isoCode2}/${industry}/${exchange_ticker}/${companyname}`;
};

export const getUrlMapping = (state) => {
  const company = state.company as State;
  return company.urlToIdMapping;
};

export const getIdFromUrl = createSelector(
  [getCanonicalUrlFromLocation, getUrlMapping],
  (canonicalUrl, urlToIdMapping) => {
    if (!canonicalUrl) {
      return null;
    }
    if (urlToIdMapping[canonicalUrl]) {
      return urlToIdMapping[canonicalUrl];
    }
    return null;
  }
);

interface SymbolMappingProps {
  symbol: string;
}

const getSymbolMapping = (state, props: SymbolMappingProps) => {
  const company = state.company as State;
  return company.symbolToIdMapping[props.symbol];
};

export const getIdFromSymbol = createSelector(
  [getSymbolMapping],
  (companyId) => companyId
);

export const getCompanyId = (state, props: { companyId?: string }) => {
  if (props && props.companyId) return props.companyId;
  const currentCompanyId = getIdFromUrl(state);
  if (currentCompanyId === null) return '';
  return currentCompanyId;
};

export interface CompanyIdFromSymbolProps {
  /** Comma delimited, url encoded company unique symbols  */
  symbols?: string;
}

export const getCompaniesIdFromSymbols = createSelector(
  [
    (state: any, props: CompanyIdFromSymbolProps) => {
      if (!props.symbols) return [];
      const symbols = decodeURIComponent(props.symbols).split(',');
      const company = state.company as State;
      return symbols.reduce<string[]>((acc, current) => {
        if (current in company.symbolToIdMapping) {
          acc.push(company.symbolToIdMapping[current]);
        }
        return acc;
      }, []);
    },
  ],
  (ids) => ids
);

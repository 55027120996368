import { useState, useCallback, useRef } from 'react';

export enum State {
  READY,
  LOADING,
  IDLE,
  ERROR,
  UNKNOWN,
}
type LoadScript = () => Promise<unknown>;
type CleanupScript = () => void;

export interface Options {
  // HTML script element attributes. https://developer.mozilla.org/en-US/docs/Web/HTML/Element/script
  async?: boolean;
  defer?: boolean;
  crossOrigin?: 'anonymous' | 'use-credentials' | '';
  // additional generic 'data' attributes
  attributes?: Record<`data-${string}`, string>;
  id?: string;
}

export function useLazyScript(
  src: HTMLScriptElement['src'],
  options?: Options
): [LoadScript, State, CleanupScript] {
  const [status, setStatus] = useState<State>(src ? State.LOADING : State.IDLE);

  // Set default values & store options in a ref. Updates to options shouldn't re-load the script.
  const resolvedOptions = useRef({
    crossOrigin: 'anonymous',
    // Unless specified, load async. Fall back to 'defer' on browsers that don't support async.
    async: true,
    defer: true,
    ...options,
  });

  // Store all functions to clean up the script in an array & provide one function to call them all.
  const cleanups = useRef<(() => void)[]>([]);
  const cleanup = useCallback(() => {
    cleanups.current.forEach((fn) => fn());
    cleanups.current = [];
  }, []);

  const load = useCallback(async () => {
    if (!src) {
      setStatus(State.ERROR);
      return Promise.reject(new Error('No src, unable to load script'));
    }

    let script: HTMLScriptElement | null = document.querySelector(
      `script[src="${src}"]`
    );

    if (script === null) {
      script = document.createElement('script');
      script.src = src;
      script.async = resolvedOptions.current.async;
      script.defer = resolvedOptions.current.defer;
      script.crossOrigin = resolvedOptions.current.crossOrigin;
      if (resolvedOptions.current.id) {
        script.id = resolvedOptions.current.id;
      }
      script.setAttribute('data-status', State.LOADING.toString());

      if (resolvedOptions.current.attributes) {
        for (const [key, value] of Object.entries(
          resolvedOptions.current.attributes
        )) {
          script.setAttribute(key, value);
        }
      }

      document.body.appendChild(script);

      // Sync script attribute based on events
      const setAttributeFromEvent = (event: Event) => {
        if (script) {
          const attr = event.type === 'load' ? State.READY : State.ERROR;
          script.setAttribute('data-status', attr.toString());
        }
      };
      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
      cleanups.current.push(() => {
        if (script) {
          script.removeEventListener('load', setAttributeFromEvent);
          script.removeEventListener('error', setAttributeFromEvent);
        }
      });
    } else if (script !== null) {
      const rawAttr: unknown = script.getAttribute('data-status');
      const attrState = rawAttr ? (Number(rawAttr) as State) : State.UNKNOWN;
      setStatus(attrState);
      if (attrState === State.UNKNOWN || attrState === State.READY) {
        return Promise.resolve(attrState.toString());
      }
      if (attrState === State.ERROR) {
        const err = new Error(
          `Script already in the dom with status ${attrState}: ${src}`
        );
        return Promise.reject(err);
      }
    }

    // Track script loading with a promise. On load resolve the promise, on error reject it.
    let resolveLoad: (value: unknown) => void = () => undefined;
    let rejectLoad: (reason?: unknown) => void = () => undefined;
    const promise = new Promise((resolve, reject) => {
      resolveLoad = resolve;
      rejectLoad = reject;
    });

    // Resolve or reject promise based on events
    const resolvePromiseFromEvent = (event: Event) => {
      if (event.type === 'load') {
        resolveLoad(State.READY.toString());
      } else {
        rejectLoad(new Error(`Script failed to load: ${src}`));
      }
    };
    script.addEventListener('load', resolvePromiseFromEvent);
    script.addEventListener('error', resolvePromiseFromEvent);
    cleanups.current.push(() => {
      if (script) {
        script.removeEventListener('load', resolvePromiseFromEvent);
        script.removeEventListener('error', resolvePromiseFromEvent);
      }
    });

    // Sync state based on events
    const setStateFromEvent = (event: Event) => {
      const loadState = event.type === 'load' ? State.READY : State.ERROR;
      setStatus(loadState);
      if (script) {
        script.setAttribute('data-status', loadState.toString());
      }
    };
    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);
    cleanups.current.push(() => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    });

    return promise;
  }, [src]);

  return [load, status, cleanup];
}

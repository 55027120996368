import { useEffect } from 'react';

import { useScript } from '@/hooks/useScript';
import useUser from '@/hooks/useUser';

import { REWARDFUL_API_KEY, REWARDFUL_TRACKING_URL } from '@/constants/env';

export function useSetupRewardful() {
  const { data: user, isFetched } = useUser();

  useScript(REWARDFUL_TRACKING_URL, {
    attributes: { 'data-rewardful': REWARDFUL_API_KEY },
    crossOrigin: undefined,
    enabled:
      typeof window !== 'undefined' &&
      !window?.TEST_RUNNER &&
      isFetched &&
      !user.loggedIn,
  });

  useEffect(() => {
    if (typeof window['rewardful'] === 'function') {
      return;
    }

    window['_rwq'] = 'rewardful';
    window['rewardful'] = (...args: unknown[]) => {
      if (typeof window['rewardful'] !== 'undefined') {
        (window['rewardful']['q'] = window['rewardful']['q'] || []).push(args);
      }
    };
  }, []);
}

import { produce } from 'immer';
import SoftReset from '@/routines/application/softReset';
import WebAppRedirect from '@/routines/application/webAppRedirect';
import ToggleSideBar from '@/routines/sidebar/toggleSidebar';

import SnowFlakeIntroductionSeen from '@/routines/introduction/snowflakeSeen';
import ErrorMessageReset from '@/routines/errors/errorMessageReset';
import { appErrorReported, appErrorClear } from '@/routines/device/global';

import {
  fetchPortfolio as FetchPortfolio,
  fetchPortfolioList,
  portfolioNotFound as PortfolioNotFound,
  fetchPortfolioCsvReport,
} from '@/components/DeprecatedPortfolio/redux/routines';
import { fetchCompany } from '@/pages/CompanyReport/redux/routines';
import {
  addCompany,
  removeCompany,
  showAddRemoveCompany,
  hideAddRemoveCompany,
} from '@components/AddRemoveCompany/redux/routines';
import { toggleCompanyAnalystsModal as ToggleCompanyAnalystsModal } from '@/pages/CompanyReport/routines';
import { fetchCompanyAnalysts } from '@/pages/CompanyReport/redux/routines';

type AuthenticationProvider = 'google' | 'facebook' | 'sws';

type SearchStatusType = 'idle' | 'searching';
type AddRemoveCompanyModalStatusType = 'hidden' | 'visible';
export type RoutineStatus =
  | 'uninitialised'
  | 'idle'
  | 'fetching'
  | 'error'
  | 'success';

export enum AccountTypeChangeStatus {
  IDLE,
  PROCESSING,
  ERROR,
  SUCCESS,
}

type UpdatingHoldingsStatusType = 'idle' | 'adding' | 'removing';
type UpdatingPortfolioStatusType = 'idle' | 'creating' | 'editing' | 'removing';
type AnalystsFetchStatusType = 'idle' | 'fetching';
type AnalystsModalStatusType = 'hidden' | 'visible';
type CompanyFetchStatus = 'idle' | 'fetching' | 'failed';
export interface UiState {
  reload: boolean;
  message: string;
  loading: boolean;
  emailType: 'none' | 'local' | 'google' | 'facebook';
  sidebarOpen: boolean;
  appVersion: string;
  appLevelError: boolean;
  showOnboarding: boolean;
  hasSeenSnowflakeIntroduction: boolean;
  appError: boolean;
  appErrorMessage: string;
  searchStatus: SearchStatusType;
  analystsFetchStatus: AnalystsFetchStatusType;
  analystsModalStatus: AnalystsModalStatusType;
  addRemoveCompanyModalStatus: AddRemoveCompanyModalStatusType;
  updatingHoldingsStatus: UpdatingHoldingsStatusType;
  updatingPortfolioStatus: UpdatingPortfolioStatusType;
  fileIsDownloading: boolean;
  accountTypeChangeStatus: AccountTypeChangeStatus;
  accountTypeChangeMessage: '';
  registeredOnCompanyPage: boolean | undefined;
  /** TO DEPRECATE */
  gridIsLoading: boolean;
  gridSaveState: RoutineStatus;
  fetchingCompany: CompanyFetchStatus;
  portfolioListIsFetching: boolean;
  cfIpCountry?: string;
}

export interface UiReducerPayload {
  message?: string;
  provider?: AuthenticationProvider;
  sidebarOpen?: boolean;
  hasSeenSnowflakeIntroduction?: boolean;
  appErrorMessage?: string;
  errorMessage?: string;
  clearMessage?: boolean;
  registeredOnCompanyPage?: boolean;
}

export const getDefaultState = (): UiState => {
  return {
    reload: false,
    message: '',
    loading: false,
    emailType: 'none',
    sidebarOpen: false,
    showOnboarding: false,
    appLevelError: false,
    appVersion: '',
    fileIsDownloading: false,
    hasSeenSnowflakeIntroduction: true,
    appError: false,
    appErrorMessage: '',
    addRemoveCompanyModalStatus: 'hidden',
    analystsFetchStatus: 'idle',
    analystsModalStatus: 'hidden',
    updatingHoldingsStatus: 'idle',
    updatingPortfolioStatus: 'idle',
    searchStatus: 'idle',
    gridIsLoading: false,
    fetchingCompany: 'fetching',
    portfolioListIsFetching: false,
    gridSaveState: 'idle',
    accountTypeChangeStatus: AccountTypeChangeStatus.IDLE,
    accountTypeChangeMessage: '',
    registeredOnCompanyPage: undefined,
  };
};

export type UiAction = ReducerAction<UiReducerPayload>;
export default function ui(
  state = getDefaultState(),
  action: UiAction
): UiState {
  switch (action.type) {
    case SoftReset.TRIGGER:
      console.log('soft error set to true');
      return {
        ...state,
        appLevelError: true,
      };
    case SoftReset.FULFILL:
      console.log('soft error set to false');
      return {
        ...state,
        appLevelError: false,
      };
    /** Surely we don't need this? */
    case ToggleCompanyAnalystsModal.TRIGGER:
      return {
        ...state,
        analystsModalStatus:
          state.analystsModalStatus === 'hidden' ? 'visible' : 'hidden',
      };
    case fetchCompanyAnalysts.TRIGGER:
      return {
        ...state,
        analystsFetchStatus: 'fetching',
      };
    case fetchCompanyAnalysts.SUCCESS:
    case fetchCompanyAnalysts.FAILURE:
    case fetchCompanyAnalysts.FULFILL:
      return {
        ...state,
        analystsFetchStatus: 'idle',
      };
    case fetchCompany.REQUEST:
      return produce(state, (draft) => {
        draft.fetchingCompany = 'fetching';
      });
    case fetchCompany.FAILURE:
      return produce(state, (draft) => {
        draft.fetchingCompany = 'failed';
      });
    case fetchCompany.SUCCESS:
    case fetchCompany.FULFILL:
      const { fetchingCompany } = state;
      return produce(state, (draft) => {
        draft.fetchingCompany =
          fetchingCompany !== 'failed' ? 'idle' : fetchingCompany;
      });
    case PortfolioNotFound.TRIGGER:
      return {
        ...state,
        loading: false,
      };
    case ToggleSideBar.TRIGGER: {
      const { sidebarOpen } = action.payload as UiReducerPayload;
      return {
        ...state,
        sidebarOpen: sidebarOpen || false,
      };
    }
    case WebAppRedirect.TRIGGER:
      console.log('web app redirect requested');
      return {
        ...state,
        loading: false,
      };
    case FetchPortfolio.SUCCESS: {
      const stateHasSeenSnowflakeIntroduction =
        state.hasSeenSnowflakeIntroduction;
      const { hasSeenSnowflakeIntroduction } =
        action.payload as UiReducerPayload;
      return {
        ...state,
        loading: false,
        message: '',
        hasSeenSnowflakeIntroduction:
          typeof hasSeenSnowflakeIntroduction !== 'undefined'
            ? hasSeenSnowflakeIntroduction
            : stateHasSeenSnowflakeIntroduction,
      };
    }
    case SnowFlakeIntroductionSeen.TRIGGER:
      return {
        ...state,
        hasSeenSnowflakeIntroduction: true,
      };
    case ErrorMessageReset.TRIGGER:
      return {
        ...state,
        message: '',
      };
    case appErrorReported.TRIGGER: {
      const { appErrorMessage } = action.payload as UiReducerPayload;
      return {
        ...state,
        appError: true,
        appErrorMessage: appErrorMessage || 'Application Error',
      };
    }
    case appErrorClear.TRIGGER:
      return {
        ...state,
        appError: false,
        appErrorMessage: '',
      };

    case showAddRemoveCompany.TRIGGER:
      return {
        ...state,
        addRemoveCompanyModalStatus: 'visible',
      };
    case hideAddRemoveCompany.TRIGGER:
      return {
        ...state,
        addRemoveCompanyModalStatus: 'hidden',
      };
    case addCompany.TRIGGER:
      return {
        ...state,
        updatingHoldingsStatus: 'adding',
      };
    case removeCompany.TRIGGER:
      return {
        ...state,
        updatingHoldingsStatus: 'removing',
      };
    case addCompany.SUCCESS:
    case addCompany.FAILURE:
    case removeCompany.SUCCESS:
    case removeCompany.FAILURE:
      return {
        ...state,
        updatingHoldingsStatus: 'idle',
      };
    case fetchPortfolioList.TRIGGER:
      return {
        ...state,
        portfolioListIsFetching: true,
      };
    case fetchPortfolioList.SUCCESS:
    case fetchPortfolioList.FULFILL:
    case fetchPortfolioList.FAILURE:
      return {
        ...state,
        portfolioListIsFetching: false,
      };
    case fetchPortfolioCsvReport.TRIGGER: {
      return produce(state, (draft) => {
        draft.fileIsDownloading = true;
      });
    }
    case fetchPortfolioCsvReport.FAILURE:
    case fetchPortfolioCsvReport.SUCCESS: {
      return produce(state, (draft) => {
        draft.fileIsDownloading = false;
      });
    }
    case 'UI__START_ONBOARDING': {
      console.log('UI__START_ONBOARDING');
      const { registeredOnCompanyPage } = action.payload as UiReducerPayload;
      return produce(state, (draft) => {
        draft.showOnboarding = true;
        draft.registeredOnCompanyPage = registeredOnCompanyPage;
      });
    }
    case 'UI__COMPLETE_ONBOARDING': {
      console.log('UI__COMPLETE_ONBOARDING');
      return produce(state, (draft) => {
        draft.showOnboarding = false;
      });
    }
    default:
      return state;
  }
}

import styled from 'styled-components';
import { media } from '@simplywallst/ui-core';

export interface ReportSectionProps {
  sectionNumber?: number;
}

const ReportSection = styled('section')<ReportSectionProps>`
  ${({ theme }) =>
    theme.appTheme === 'light' &&
    `border-top: 2px solid ${theme.color.whiteRGBA(0.2)}`};
  color: ${({ theme }) => theme.color.white};
  position: relative;
  background-color: ${({ theme }) => theme.color.noir};
  padding: ${({ theme }) => `${theme.x3spacePx}px ${theme.x2spacePx}px`};
  margin-bottom: ${({ theme }) => theme.x1spacePx}px;
  ${media.mdUp`
    padding: ${({ theme }) => theme.x4spacePx}px;
    margin-bottom: ${({ theme }) => theme.x2spacePx}px;
  `}
  ${media.lgUp`
    border-radius: ${({ theme }) => theme.x1spacePx}px;
    margin-bottom: ${({ theme }) => theme.x3spacePx}px;
  `}
  ${({ sectionNumber }) =>
    sectionNumber &&
    `
		counter-reset: section ${sectionNumber} sectionH2;
  `};
`;

export { ReportSection as default };

import { createContext, useContext } from 'react';
import type { ReactNode } from 'react';

export const Context = createContext<
  `/stocks/${string}/${string}/${string}/${string}` | '' | undefined
>(undefined);

interface Props {
  children: ReactNode;
  canonicalUrl: string;
}
export function CompanyIDProvider({ children, canonicalUrl }: Props) {
  return (
    <Context.Provider
      value={
        canonicalUrl as `/stocks/${string}/${string}/${string}/${string}` | ''
      }
    >
      {children}
    </Context.Provider>
  );
}

export function useCompareCompanyId() {
  const context = useContext(Context);
  return context;
}

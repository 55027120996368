import { patch } from "../methods";
function updateUser(payload, headers = {}) {
  return patch({
    url: "api/user",
    payload,
    headers
  });
}
export {
  updateUser as default
};

import { format } from 'date-fns';

const period = [
  '7D',
  '1M',
  '3M',
  'YTD',
  '1Y',
  '2Y',
  '3Y',
  '5Y',
  '10Y',
  'MAX',
  'NOW',
] as const;

export type Period = (typeof period)[number];

export const isPeriod = (p: unknown): p is Period => {
  return period.includes(p as Period);
};

export const getTimeSeriesPeriodMap = (date?: Date) => {
  const now = date || new Date();
  const TY = now.getFullYear();
  const TM = now.getMonth();
  const TD = now.getDate();
  return {
    '7D': new Date(TY, TM, TD - 7).getTime(), // 7 days ago
    '1M': new Date(TY, TM - 1, TD).getTime(), // 1 month ago
    '3M': new Date(TY, TM - 3, TD).getTime(), // 3 months ago
    '1Y': new Date(TY - 1, TM, TD).getTime(), // 1 year ago
    '2Y': new Date(TY - 2, TM, TD).getTime(), // 2 years ago
    '3Y': new Date(TY - 3, TM, TD).getTime(), // 3 years ago
    '5Y': new Date(TY - 5, TM, TD).getTime(), // 5 years ago
    '10Y': new Date(TY - 10, TM, TD).getTime(), // 10 years ago
    YTD: new Date(TY, 0, 1).getTime(), // Year to the day
    MAX: Infinity, // A long, long time ago
    NOW: now.getTime(),
  };
};

export const getDateRangeByPeriod = (period: Period, date?: Date) => {
  const now = date || new Date();
  const endDate = format(now, 'yyyy-MM-dd');
  const periodMap = getTimeSeriesPeriodMap(now);
  const startDate =
    period === 'MAX'
      ? format(0, 'yyyy-MM-dd')
      : format(periodMap[period], 'yyyy-MM-dd');
  return { startDate, endDate };
};

export type PeriodMap = ReturnType<typeof getTimeSeriesPeriodMap>;

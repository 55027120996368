const PORTFOLIO = 'portfolio';

export const TRACKING_PORTFOLIO = PORTFOLIO;
export const TRACKING_PORTFOLIO_HOME = PORTFOLIO;
export const TRACKING_PORTFOLIO_LINKING_SUCCESS = 'portfolio_linking_success';
export const TRACKING_PORTFOLIO_NEW = 'new_portfolio';
export const TRACKING_PORTFOLIO_TRANSACTIONS_CREATE =
  'portfolio_transactions_create';
export const TRACKING_PORTFOLIO_TRANSACTIONS_EDIT =
  'portfolio_transactions_edit';
export const TRACKING_PORTFOLIO_TRANSACTIONS_EDIT_HOLDING =
  'portfolio_transactions_edit_holding';
export const TRACKING_PORTFOLIO_DEMO = 'demo_portfolio';
export const TRACKING_PORTFOLIO_PUBLIC = 'public_portfolio';

export const TRACKING_USER_PORTFOLIO = 'my_portfolios';

export const TRACKING_HOME = 'home';
export const TRACKING_DASHBOARD = 'dashboard';
export const TRACKING_COMPANY = 'company';
export const TRACKING_COMPARE_COMPANY = 'compare';

export const TRACKING_DISCOVER_INVESTING_IDEAS = 'investing_ideas';
export const TRACKING_DISCOVER_SAMPLE_PORTFOLIOS = 'sample_portfolios';
export const TRACKING_INVESTING_IDEA = 'investing_idea_view';
export const TRACKING_COMMUNITY_IDEA = 'community_idea';

export const TRACKING_STOCKS_HOME = 'stocks';
export const TRACKING_WATCHLIST = 'watchlist';
export const TRACKING_MARKET_PAGE = 'market';
export const TRACKING_MARKET_HUB = 'market_hub';
export const TRACKING_ARTICLE_PAGE = 'article';

export const TRACKING_SCREENER = 'screener';
export const TRACKING_NARRATIVE_COMMUNITY = 'narrative_community';
export const TRACKING_NARRATIVE_COMMUNITY_PROFILE =
  'narrative_community_profile';

export const TRACKING_GIFT_CARD_REDEEM = 'gift_card_redeem';
export const TRACKING_GIFT_CARD_PURCHASE = 'gift_card_purchase';

export const TRACKING_USER_PROFILE = 'profile';
export const TRACKING_USER_NOTIFICATIONS = 'notifications';
export const TRACKING_USER_BILLING = 'billing';
export const TRACKING_USER_PLAN = 'plan';
export const TRACKING_USER_SUBSCRIPTION = 'subscription';
export const TRACKING_USER_SUBSCRIPTION_AND_BILLING =
  'subscription_and_billing';
export const TRACKING_USER_BETA_LAB = 'beta_lab';
export const TRACKING_USER_LOGIN = 'log_in';
export const TRACKING_USER_LOGOUT = 'log_out';
export const TRACKING_USER_REGISTER = 'sign_up';

export type AnalyticsHandler = (
  label: string,
  legend: any,
  status: boolean
) => void;

import {
  UICoreNextProvider as _UICoreNextProvider,
  makeLinkComponent,
} from '@simplywallst/ui-core/next';
import type { LegacyRef, ReactNode } from 'react';
import Link from 'redux-first-router-link';

const LinkComponent = makeLinkComponent(({ href, children, ...props }, ref) => {
  if (href.startsWith('http'))
    return (
      <a href={href} ref={ref} target="_blank" rel="noreferrer" {...props}>
        {children}
      </a>
    );

  return (
    <Link to={href} ref={ref as LegacyRef<Link>} {...props}>
      {children}
    </Link>
  );
});

export const UICoreNextProvider = ({ children }: { children: ReactNode }) => {
  return (
    <_UICoreNextProvider linkComponent={LinkComponent}>
      {children}
    </_UICoreNextProvider>
  );
};

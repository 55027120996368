import { useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import queryFn from './queryFn';
import mutationFn from './mutation';
import type { ProfileState } from './reducer';
import reducer, { getDefaultProfileState } from './reducer';

export const QUERY_KEY = ['USER_PROFILE'] as const;

interface UseUserArgs {
  onMutationError?: (error: unknown) => void;
  onMutationSuccess?: (profile: Awaited<ReturnType<typeof mutationFn>>) => void;
}

export function useUser({
  onMutationError,
  onMutationSuccess,
}: UseUserArgs = {}) {
  const queryClient = useQueryClient();

  const {
    mutate,
    mutateAsync,
    error: mutationError,
    status: mutationStatus,
  } = useMutation({
    mutationFn,
    onSuccess: (data) => {
      onMutationSuccess?.(data);
      queryClient.setQueryData<ProfileState>(QUERY_KEY, (state) => {
        if (typeof data !== 'undefined') {
          return reducer(state, data);
        }
        if (typeof state !== 'undefined') {
          return state;
        }
        return getDefaultProfileState();
      });
    },
    onError: onMutationError,
  });

  const {
    data = getDefaultProfileState(),
    status: queryStatus,
    isFetched,
    refetch,
  } = useQuery({
    queryKey: QUERY_KEY,
    queryFn,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const setQueryData = useCallback(
    (updater: (input: ProfileState) => ProfileState) => {
      queryClient.setQueryData<ProfileState>(QUERY_KEY, (profile) => {
        if (typeof profile !== 'undefined') {
          return updater(profile);
        }
        return getDefaultProfileState();
      });
    },
    [queryClient]
  );

  return {
    data,
    queryStatus,
    isFetched,
    refetch,
    mutate,
    mutateAsync,
    mutationError,
    mutationStatus,
    setQueryData,
  };
}

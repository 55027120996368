import type { SubNavRoute } from '@/constants/routes';
import { ROUTE_DASHBOARD } from '@/constants/routes';
export const SUB_ROUTE_UPDATES = 'updates';
export const SUB_ROUTE_SCREENERS = 'screeners';
export const SUB_ROUTE_CALENDAR = 'calendar';

export const updatesRoute: SubNavRoute = {
  to: ROUTE_DASHBOARD,
  payload: { subRoute: SUB_ROUTE_UPDATES },
  label: 'My Updates',
};

export const screenersRoute: SubNavRoute = {
  to: ROUTE_DASHBOARD,
  payload: { subRoute: SUB_ROUTE_SCREENERS },
  label: 'My Screeners',
};

export const calendarRoute: SubNavRoute = {
  to: ROUTE_DASHBOARD,
  payload: { subRoute: SUB_ROUTE_CALENDAR },
  label: 'Calendar',
};

import {
  EditPortfolio,
  ShowEditPortfolioModal,
  HideEditPortfolioModal,
} from './index';
import type { EditPortfolioModalState } from './interface';

export const getDefaultState = (): EditPortfolioModalState => ({
  editStatus: 'idle',
  editPortfolioModalStatus: 'hidden',
});

export default (
  state = getDefaultState(),
  action: ReducerAction
): EditPortfolioModalState => {
  if (!action || !action.type) return state;
  switch (action.type) {
    case EditPortfolio.TRIGGER: {
      return {
        ...state,
        editStatus: 'updating',
      };
    }
    case EditPortfolio.SUCCESS: {
      return {
        ...state,
        editStatus: 'idle',
        editPortfolioModalStatus: 'hidden',
      };
    }
    case ShowEditPortfolioModal.TRIGGER: {
      return {
        ...state,
        editPortfolioModalStatus: 'visible',
      };
    }
    case HideEditPortfolioModal.TRIGGER: {
      return {
        ...state,
        editPortfolioModalStatus: 'hidden',
      };
    }
    default:
      return state;
  }
};

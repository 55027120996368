import {
  fetchPortfolio,
  updatePortfolioId,
  fetchPriceForPortfolioItem,
  addTransactionForItem,
  fetchPortfolioItems,
  removeTransactionForItem,
  selectedHoldingsSearchResult,
  clearHoldingsSearchResult,
  reinvestDividend,
  portfolioAddCompany,
  portfolioRemoveCompany,
  showTransactionsModal,
  hideTransactionsModal,
  deletePortfolio,
} from '@/components/DeprecatedPortfolio/redux/routines';

import deleteHoldingModalReducer, {
  ShowDeleteHoldingModal,
  HideDeleteHoldingModal,
  getDefaultState as getDefaultDeleteHoldingModalState,
} from '@/components/DeprecatedPortfolio/components/DeleteHoldingModal/redux';

import editPortfolioModalReducer, {
  EditPortfolio,
  ShowEditPortfolioModal,
  HideEditPortfolioModal,
  getDefaultState as getDefaultEditPortfolioModalState,
} from '@/components/DeprecatedPortfolio/components/EditPortfolioModal/redux';

import type {
  PorfolioReducerPayload,
  PortfolioState,
} from '@/components/DeprecatedPortfolio/redux/interface';

import { produce } from 'immer';

export const getDefaultState = (): PortfolioState => {
  return {
    currentId: null,
    companyToAddToPortfolioHoldings: '',
    isUpdatingItemPrice: false,
    itemPriceMap: {},
    doesPortfolioNeedRefresh: true,
    isAddingTransaction: false,
    isUpdatingTransactions: false,
    //  Transactions
    transactionsStatus: 'idle',
    transactionsModalStatus: 'hidden',
    transactionsCurrentItemId: '',
    //  DeleteHolding
    deleteHoldingModal: {
      ...getDefaultDeleteHoldingModalState(),
    },
    editPortfolioModal: {
      ...getDefaultEditPortfolioModalState(),
    },
  };
};

interface Action {
  type: string;
  payload: PorfolioReducerPayload;
}

export default function portfolio(
  state = getDefaultState(),
  action: Action
): PortfolioState {
  return produce(state, (draftState) => {
    switch (action.type) {
      case ShowDeleteHoldingModal.TRIGGER:
      case HideDeleteHoldingModal.TRIGGER: {
        draftState.deleteHoldingModal = deleteHoldingModalReducer(
          state.deleteHoldingModal,
          action
        );
        break;
      }
      case EditPortfolio.TRIGGER:
      case EditPortfolio.SUCCESS:
      case EditPortfolio.FAILURE:
      case ShowEditPortfolioModal.TRIGGER:
      case HideEditPortfolioModal.TRIGGER: {
        draftState.editPortfolioModal = editPortfolioModalReducer(
          state.editPortfolioModal,
          action
        );
        break;
      }
      case deletePortfolio.SUCCESS: {
        draftState.currentId = null;
        break;
      }
      case portfolioAddCompany.SUCCESS:
      case portfolioRemoveCompany.SUCCESS: {
        draftState.doesPortfolioNeedRefresh = true;
        break;
      }
      case fetchPortfolio.TRIGGER:
      case updatePortfolioId.SUCCESS: {
        const { id } = action.payload;
        draftState.currentId = id || null;
        break;
      }

      case fetchPortfolio.FAILURE:
        draftState.currentId = null;
        break;

      case fetchPriceForPortfolioItem.TRIGGER:
        draftState.isUpdatingItemPrice = true;
        break;

      case fetchPriceForPortfolioItem.SUCCESS: {
        const { itemId, price } = action.payload;
        if (!itemId || !price) return;
        const safeItemId = itemId.toString();
        if (!(safeItemId in state.itemPriceMap)) {
          draftState.itemPriceMap[safeItemId] = {};
        }
        draftState.itemPriceMap[safeItemId][price.date.toString()] =
          price.close;
        draftState.isUpdatingItemPrice = false;
        break;
      }

      case fetchPriceForPortfolioItem.FAILURE:
        draftState.isUpdatingItemPrice = false;
        break;

      case addTransactionForItem.TRIGGER:
        draftState.transactionsStatus = 'adding';
        break;

      case addTransactionForItem.SUCCESS:
        draftState.doesPortfolioNeedRefresh = true;
        draftState.transactionsStatus = 'idle';
        break;

      case fetchPortfolioItems.TRIGGER:
        draftState.isUpdatingTransactions = true;
        draftState.transactionsStatus = 'updating';
        break;
      case fetchPortfolioItems.FULFILL:
        draftState.isUpdatingTransactions = false;
        draftState.transactionsStatus = 'idle';
        break;

      case removeTransactionForItem.TRIGGER:
        draftState.transactionsStatus = 'removing';
        break;

      case removeTransactionForItem.SUCCESS:
        draftState.doesPortfolioNeedRefresh = true;
        draftState.transactionsStatus = 'idle';
        break;
      case showTransactionsModal.TRIGGER:
        draftState.transactionsModalStatus = 'visible';
        draftState.transactionsCurrentItemId = action.payload.itemId;
        break;
      case hideTransactionsModal.TRIGGER:
        draftState.transactionsModalStatus = 'hidden';
        draftState.transactionsCurrentItemId = '';
        break;
      case selectedHoldingsSearchResult.TRIGGER:
        draftState.companyToAddToPortfolioHoldings =
          action.payload.companyId || '';
        break;
      case clearHoldingsSearchResult.TRIGGER:
        draftState.companyToAddToPortfolioHoldings = '';
        break;
      case reinvestDividend.SUCCESS: {
        draftState.doesPortfolioNeedRefresh = true;
        break;
      }
    }
  });
}

import {
  toPercentage,
  toFactor,
  toCurrency,
  toCurrencyTruncated,
  toSignificant,
} from '@/utilities/formatting';
import { format } from 'date-fns';
import { getUTCDate } from '@utilities/date';

import type { CurrencyISO } from '@/constants/currencies';
import { isCurrencyISO } from '@/constants/currencies';
import { pluralise } from '../pluralise';

type NilOr<T> = T | null | undefined;

export const isNil = (value: unknown): value is null | undefined =>
  value === null || value === undefined;

export const normaliseCurrencyISO = (
  currencyISO?: string
): CurrencyISO | undefined => {
  if (isCurrencyISO(currencyISO)) return currencyISO;
};

export const formatterPercentage = (value: NilOr<number>) =>
  isNil(value) ? 'n/a' : toPercentage(value, 1);

export const formatterFactor = (value: NilOr<number>) =>
  isNil(value) ? 'n/a' : toFactor(value, 1);

export const formatterCurrency = (value: NilOr<number>, currencyISO: string) =>
  isNil(value)
    ? 'n/a'
    : toCurrency(value, 2, normaliseCurrencyISO(currencyISO));

export const formatterDate = (timestamp: NilOr<number>) =>
  isNil(timestamp) ? 'n/a' : format(getUTCDate(timestamp), 'dd MMM yyyy');

export const formatterCurrencyTruncated = (
  value: NilOr<number>,
  currencyISO: string,
  significance = 2,
  isNilText = 'n/a'
) =>
  isNil(value)
    ? isNilText
    : toCurrencyTruncated(
        value,
        significance,
        normaliseCurrencyISO(currencyISO)
      );

export const formatterTenure = (value: number | null) => {
  if (typeof value !== 'number' || value <= 0) return 'no data';
  if (value >= 1)
    return `${toSignificant(value, 1)}${pluralise(value, 'yr', 'yrs')}`;
  return 'less than a year';
};

import { subYears } from 'date-fns';
import { produce } from 'immer';
import { minBy, maxBy, get } from 'lodash';
import {
  keyValueToList,
  calculateBestFit,
  firstIndexOrZero,
  tweakPastEPSRange,
  getDefault,
} from './utils';
import {
  mapTimeSeries,
  mapTimeSeriesArray,
  reducePastEPS,
  reduceOwnershipBreakdown,
  reduceInsiderTrading,
  reduceAnalystsTimeSeriesToMap,
} from './subReducers';
import type { CompanySuccessPayload } from '@/pages/CompanyReport/redux/interface';

export /**
 *
 * This has the potential of reaching millions of lines of code.
 * While readability might (it will) suffer, this is by design to
 * easily catch duplication of mapped data. If we start abstracting or
 * compartmentalising state we risk running into re-inserting api
 * response over different slice of state.
 *
 */
function analysisReducer(
  state = getDefault(),
  companyData: CompanySuccessPayload
) {
  return produce(state, (draft) => {
    if (!companyData.analysis) {
      return;
    }
    try {
      /** Destructs */
      const {
        future: futureAnalysis,
        past: pastAnalysis,
        value: valueAnalysis,
        health: healthAnalysis,
        dividend: dividendAnalysis,
        management: managementAnaylsis,
        misc: miscAnalysis,
      } = companyData.analysis.data.extended.data.analysis;
      const {
        estimates: estimateRawData,
        past: pastRawData,
        members: membersRawData,
        ownership: ownershipRawData,
        insider_trading: insiderTradingRawData,
        market_cap: marketCapRawData,
        prices: pricesRawData,
      } = companyData.analysis.data.extended.data.raw_data.data;

      /** Analysis numbers */
      const { leader } = membersRawData;
      draft.earningsPerShare1y = futureAnalysis.earnings_per_share_1y;
      draft.beta5y = valueAnalysis.beta_5y !== null ? valueAnalysis.beta_5y : 0;
      draft.earningsPerShareGrowthAnnual =
        futureAnalysis.earnings_per_share_growth_annual;
      draft.earningsGrowthAnnual = futureAnalysis.net_income_growth_annual;
      draft.earningsGrowthReq1Y = futureAnalysis.minimum_earnings_growth;
      draft.futureROE3Y = futureAnalysis.return_on_equity_3y;
      draft.forwardPE = futureAnalysis.forward_pe_1y;
      draft.forwardPS = futureAnalysis.forward_price_to_sales_1y;
      draft.forecastEarnings = futureAnalysis.net_income_1y;
      draft.forecastSales = futureAnalysis.revenue_1y;
      draft.forecastNetIncomeGrowth1y = futureAnalysis.net_income_growth_1y;
      draft.forecastRevenueGrowth1y = futureAnalysis.revenue_growth_1y;
      draft.intrinsicDiscount = valueAnalysis.intrinsic_discount;
      draft.PB = valueAnalysis.pb;
      draft.PE = valueAnalysis.pe;
      draft.PEG = valueAnalysis.peg;
      draft.PS = valueAnalysis.price_to_sales;
      draft.valueRevenueRatio = valueAnalysis.ev_to_sales;
      draft.valueEbitdaRatio = valueAnalysis.ev_to_ebitda;
      draft.preferredMultiple = companyData.analysis.data.preferred_multiple;
      draft.preferredMultipleReason =
        companyData.analysis.data.preferred_multiple_reason;
      draft.preferredMultipleSecondary =
        companyData.analysis.data.preferred_multiple_secondary;
      draft.preferredMultipleFairRatio =
        companyData.analysis.data.justified_preferred_multiple_ratio;
      draft.preferredMultipleAveragePeerValue =
        companyData.analysis.data.preferred_relative_multiple_average_peer_value;
      draft.shouldShowAptCalc = companyData.analysis.data.show_new_apt_calc;

      draft.return1YrAbs = valueAnalysis.return_1yr_abs;
      draft.return1YrTotalReturn = valueAnalysis.return_1yr_total_return;
      draft.return30D = valueAnalysis.return_30d;
      draft.return3YrAbs = valueAnalysis.return_3yr_abs;
      draft.return3YrTotalReturn = valueAnalysis.return_3yr_total_return;
      draft.return5YrAbs = valueAnalysis.return_5yr_abs;
      draft.return5YrTotalReturn = valueAnalysis.return_5yr_total_return;
      draft.returnSinceIpoAbs = valueAnalysis.return_since_ipo_abs;
      draft.return7D = valueAnalysis.return_7d;
      draft.return90D = valueAnalysis.return_90d;
      draft.revenueGrowthAnnual = futureAnalysis.revenue_growth_annual;
      draft.sharePrice = valueAnalysis.last_share_price;
      draft.debtEquityRatio = companyData.analysis.data.debt_equity;
      draft.minPrice52w = miscAnalysis.min_price_52w;
      draft.maxPrice52w = miscAnalysis.max_price_52w;
      draft.dailyReturnStdDev90d =
        miscAnalysis.daily_return_standard_deviation_90d;
      const { intrinsic_value: valueAnalysisIntrinsicValue } = valueAnalysis;
      draft.intrinsicValue = valueAnalysisIntrinsicValue.npv_per_share;
      draft.model = valueAnalysisIntrinsicValue.model;
      draft.unleveredBeta = valueAnalysisIntrinsicValue.unlevered_beta;
      draft.DE = valueAnalysisIntrinsicValue.de;
      draft.taxRate = valueAnalysisIntrinsicValue.tax_rate;
      draft.equityPremium = valueAnalysisIntrinsicValue.equity_premium;
      draft.riskFreeRate = valueAnalysisIntrinsicValue.risk_free_rate;
      draft.leveredBetaActual = valueAnalysisIntrinsicValue.levered_beta_actual;
      draft.leveredBeta = valueAnalysisIntrinsicValue.levered_beta;
      draft.costOfEquity = valueAnalysisIntrinsicValue.cost_of_equity;
      draft.adrPerShare = valueAnalysisIntrinsicValue.adr_per_share;
      draft.PV5Y = valueAnalysisIntrinsicValue.pv_5y;
      draft.PVTV = valueAnalysisIntrinsicValue.pvtv;
      draft.terminalValue = valueAnalysisIntrinsicValue.terminal_value;
      draft.NPV = valueAnalysisIntrinsicValue.npv;
      draft.NPVPerShare = valueAnalysisIntrinsicValue.npv_per_share;
      if (valueAnalysisIntrinsicValue.npv_per_share_reported_currency) {
        draft.NPVPerShareReportedCurrency =
          valueAnalysisIntrinsicValue.npv_per_share_reported_currency;
      }
      if (valueAnalysisIntrinsicValue.excess_returns) {
        const { excess_returns: responseExcessReturns } =
          valueAnalysisIntrinsicValue;
        draft.excessReturns = {
          bookValue: responseExcessReturns.book_value,
          equityCost: responseExcessReturns.equity_cost,
          excessReturn: responseExcessReturns.excess_return,
          returnOnEquityAvg: responseExcessReturns.return_on_equity_avg,
          stableBookValue: responseExcessReturns.stable_book_value,
          stableBookValueSource: responseExcessReturns.stable_book_value_source,
          stableEPS: responseExcessReturns.stable_eps,
          stableEPSSource: responseExcessReturns.stable_eps_source,
        };
      }

      if (valueAnalysisIntrinsicValue.two_stage_fcf) {
        const { two_stage_fcf: response2StageFCF } =
          valueAnalysisIntrinsicValue;
        draft.twoStageFCF = {
          affoEstimates: response2StageFCF.affo_estimates,
          growthCAGR5y: response2StageFCF.growth_cagr_5y,
          scale: response2StageFCF.scale,
          scaleNumeric: response2StageFCF.scale_numeric,
          sharesOutstanding: response2StageFCF.shares_outstanding,
          fcfData: keyValueToList(response2StageFCF.fcf_data),
          fcfCount: keyValueToList(response2StageFCF.fcf_count),
          fcfLtm: response2StageFCF.fcf_ltm,
          firstStage: Object.entries(response2StageFCF.first_stage || {}).map(
            ([key, value]) => ({
              year: parseInt(key),
              data: value.data,
              source: value.source,
              discounted: value.discounted,
            })
          ),
        };
      }

      if (valueAnalysisIntrinsicValue.dividend_discount) {
        const { dividend_discount: responseDividendDiscount } =
          valueAnalysisIntrinsicValue;
        draft.dividendDiscount = {
          DPS: responseDividendDiscount.dps,
          payout: responseDividendDiscount.payout,
          ROE: responseDividendDiscount.roe,
          expectedGrowth: responseDividendDiscount.expected_growth,
          DDMGrowth: responseDividendDiscount.ddm_growth,
          DDMSource: responseDividendDiscount.ddm_source,
          NPVPerShare: responseDividendDiscount.npv_per_share,
        };
      }

      if (valueAnalysisIntrinsicValue.REIT) {
        const { REIT: responseREIT } = valueAnalysisIntrinsicValue;
        draft.REIT = {
          NAV: responseREIT.NAV,
          NAVCount: responseREIT.NAV_count,
        };
      }
      draft.EPS = pastAnalysis.earnings_per_share;
      draft.pastROE = pastAnalysis.return_on_equity;
      draft.pastROA = pastAnalysis.return_on_assets;
      draft.pastROCE1y = pastAnalysis.return_on_capital_employed;
      draft.pastROCE3y = pastAnalysis.return_on_capital_employed_past;
      draft.pastNetIncome = pastAnalysis.net_income;
      draft.netIncomeGrowth1y = pastAnalysis.net_income_growth_1y;
      draft.netIncomeGrowth5y = pastAnalysis.net_income_growth_5y;
      draft.revenueGrowth1y = pastAnalysis.revenue_growth_1y;
      draft.revenueGrowth5y = pastAnalysis.revenue_growth_5y;
      const { last_earnings_update: lastEarningUpdate } = pastAnalysis;
      if (lastEarningUpdate !== null && pastRawData[lastEarningUpdate]) {
        const {
          total_rev: revenue,
          gross_profit: grossProfit,
          ni_avail_excl: earnings,
          cogs: costOfRevenue,
          total_common_equity: bookValue,
        } = pastRawData[lastEarningUpdate];
        draft.pastRevenue = revenue?.value || 0;
        draft.pastRevenueUnit = revenue?.unit_numeric || 0;
        draft.pastGrossProfit = grossProfit?.value || 0;
        draft.pastGrossProfitUnit = grossProfit?.unit_numeric || 0;
        draft.pastEarnings = earnings?.value || 0;
        draft.pastEarningsUnit = earnings?.unit_numeric || 0;
        draft.pastCostOfRevenue = costOfRevenue?.value || 0;
        draft.pastCostOfRevenueUnit = costOfRevenue?.unit_numeric || 0;
        draft.pastBookValue = bookValue?.value || 0;
        draft.pastBookValueUnit = bookValue?.unit_numeric || 0;
      }

      draft.pastGrossProfitMargin = pastAnalysis.gross_profit_margin;
      draft.pastNetIncomeMargin = pastAnalysis.net_income_margin;
      /** Health analysis reducers */
      draft.totalAssets = healthAnalysis.total_assets;
      draft.currentAssets = healthAnalysis.current_assets;
      draft.totalEquity = healthAnalysis.total_equity;
      draft.totalLiabEquity = healthAnalysis.total_liab_equity;
      draft.totalCurrentLiab = healthAnalysis.total_current_liab;
      draft.cashStInvestments = healthAnalysis.cash_st_investments;
      draft.receivables = healthAnalysis.receivables;
      draft.inventory = healthAnalysis.inventory;
      draft.PPE = healthAnalysis.ppe;
      draft.totalDebt = healthAnalysis.total_debt;
      draft.accountsPayable = healthAnalysis.accounts_payable;
      draft.bookValuePerShare = healthAnalysis.book_value_per_share;
      /** Dividend analysis reducer */
      draft.buybackYield = dividendAnalysis.buyback_yield;
      draft.totalShareholderYield = dividendAnalysis.total_shareholder_yield;
      draft.dividendPaymentsGrowthAnnual =
        dividendAnalysis.dividend_payments_growth_annual;
      draft.dividendYield = dividendAnalysis.dividend_yield;
      draft.dividendYieldFuture = dividendAnalysis.dividend_yield_future;
      draft.dividendVolatility = dividendAnalysis.dividend_volatility;
      draft.dividendPayingYears = dividendAnalysis.dividend_paying_years;
      draft.payoutRatio = dividendAnalysis.payout_ratio;
      draft.payoutRatio3y = dividendAnalysis.payout_ratio_3y;
      draft.cashPayoutRatio = dividendAnalysis.cash_payout_ratio;
      draft.lastPaymentDividendCurrency =
        dividendAnalysis.last_payment_dividend_currency;
      draft.dividendYieldGrowthAnnual =
        dividendAnalysis.dividend_yield_growth_annual;
      draft.firstPayment = dividendAnalysis.first_payment;
      draft.lastPayment = dividendAnalysis.last_payment;

      if (!(dividendAnalysis.upcoming_dividend instanceof Array)) {
        draft.upcomingDividend = {
          adjustmentFactor:
            dividendAnalysis.upcoming_dividend.adjustment_factor,
          amount: dividendAnalysis.upcoming_dividend.amount,
          currencyId: dividendAnalysis.upcoming_dividend.currency_id,
          date: dividendAnalysis.upcoming_dividend.date,
          nextDate: dividendAnalysis.upcoming_dividend.next_date,
          payDate: dividendAnalysis.upcoming_dividend.pay_date,
          recordDate: dividendAnalysis.upcoming_dividend.record_date,
          splitAdjustedAmount:
            dividendAnalysis.upcoming_dividend.split_adjusted_amount,
        };
      } else {
        draft.upcomingDividend = null;
      }

      draft.marketCapRawData.listing = marketCapRawData.listing;
      draft.marketCapRawData.primary = marketCapRawData.primary;
      draft.marketCapRawData.reported = marketCapRawData.reported;
      /** These response properties needs better names. `usd`? Really? 🤨 */
      draft.marketCapRawData.usd = marketCapRawData.usd;
      draft.marketCapRawData.sharesOutstanding =
        marketCapRawData.shares_outstanding;
      draft.marketCapRawData.relativeListingSharesOutstanding =
        marketCapRawData.relative_listing_shares_outstanding;
      draft.marketCapRawData.originalMarketCap =
        marketCapRawData.original_market_cap || 0;
      draft.marketCapRawData.totalEnterpriseValueReported =
        marketCapRawData.total_enterprise_value_reported || 0;
      draft.totalEmployees = managementAnaylsis.total_employees;
      draft.lastEstimatesConfirmationTimeStamp =
        futureAnalysis.last_estimate_confirmed;
      draft.primaryCurrencyReported = pricesRawData.primary_currrency_reported;
      draft.primaryPrice = pricesRawData.primary;
      const historyEntries = Object.entries(pricesRawData.history);
      const historyTimestamp = historyEntries.map(
        ([_key, value]) => value.date
      );

      draft.eodPriceUpdateTimeStamp = Math.max(...historyTimestamp);
      draft.lastAnnualEarninsUpdateTimeStamp =
        pastAnalysis.last_earnings_update_annual;
      // Casts to prevent further changes. These values can be null in some circumstances, runtime seems to deal with it but
      // store typing does not match this.
      draft.lastEarningsUpdateTimeStamp =
        pastAnalysis.last_earnings_update as number;
      draft.nextEarningsUpdateTimeStamp =
        futureAnalysis.next_earnings_release as number;
      draft.lastFilingTimeStamp = pastAnalysis.last_filing_date;
      draft.industryAnalysisPast = pastAnalysis.industry_analysis;
      /** Legacy Charts */

      const { legacyChartData } = draft;

      legacyChartData.dividendEstimate = mapTimeSeries<typeof estimateRawData>([
        'dps_est',
      ])(estimateRawData);
      legacyChartData.dividenEstimatesAnalystsAvg = mapTimeSeries<
        typeof estimateRawData
      >(['dps_num_est'])(estimateRawData);
      legacyChartData.totalEmployees = mapTimeSeries<typeof pastRawData>([
        'total_employees',
      ])(pastRawData);
      legacyChartData.insiderTrading = reduceInsiderTrading(
        insiderTradingRawData
      );
      if (leader) {
        legacyChartData.ceoCompensationAnalysis.salary = mapTimeSeries<
          typeof leader.compensations
        >(['1'])(leader.compensations);
        legacyChartData.ceoCompensationAnalysis.total = mapTimeSeries<
          typeof leader.compensations
        >(['18'])(leader.compensations);
      }
      legacyChartData.ownershipBreakdown =
        reduceOwnershipBreakdown(ownershipRawData);
      legacyChartData.uncappedPastEps = mapTimeSeries<typeof pastRawData>([
        'basic_eps',
      ])(pastRawData);
      legacyChartData.totalDebtHistory = mapTimeSeriesArray<
        typeof healthAnalysis
      >(['total_debt_history'])(healthAnalysis);
      legacyChartData.healthOperatingExpensesTotal = mapTimeSeriesArray<
        typeof healthAnalysis
      >(['operating_expenses_total'])(healthAnalysis);
      legacyChartData.totalEquity = mapTimeSeries<typeof pastRawData>([
        'total_equity',
      ])(pastRawData);
      legacyChartData.pastCashInvestments = mapTimeSeries<typeof pastRawData>([
        'cash_st_invest',
      ])(pastRawData);
      legacyChartData.generalAdministrativeExpense = mapTimeSeries<
        typeof pastRawData
      >(['g_a_expense'])(pastRawData);

      const reducedPastEPSRange = mapTimeSeries<typeof estimateRawData>([
        'eps_reported_low_est',
        'eps_reported_high_est',
      ])(estimateRawData);

      const reducedFutureEPS = mapTimeSeriesArray<typeof futureAnalysis>([
        'merged_future_earnings_per_share',
      ])(futureAnalysis);
      legacyChartData.futureEPS = reducedFutureEPS;
      const reducedPastEPS = reducePastEPS(
        pastRawData,
        pastAnalysis.last_earnings_update
      );
      legacyChartData.pastEPS = reducedPastEPS;
      legacyChartData.pastEPSRange = tweakPastEPSRange(
        reducedPastEPSRange,
        reducedPastEPS
      );

      legacyChartData.pastFreeCashFlow = Object.entries(
        healthAnalysis.levered_free_cash_flow_history
      ).map((n) => [Number(n[0]), n[1]]);

      legacyChartData.futureFreeCashFlow = mapTimeSeries<
        typeof estimateRawData
      >(['fcf_est'])(estimateRawData);
      legacyChartData.futureEPSAnalyst = mapTimeSeries<typeof estimateRawData>([
        'eps_reported_num_est',
      ])(estimateRawData);

      legacyChartData.EPSAnalystConfirmation = Object.entries(estimateRawData)
        .filter((n) => n[1]['eps_reported_est'] !== undefined)
        .reduce((a, b) => {
          const value = get(
            b[1],
            ['eps_reported_est', 'effective_date'],
            undefined
          );
          if (value === undefined) return a;
          return { ...a, [b[0]]: value };
        }, {});

      legacyChartData.futureEPSRange = mapTimeSeriesArray<
        typeof futureAnalysis
      >([
        'merged_future_earnings_per_share_low',
        'merged_future_earnings_per_share_high',
      ])(futureAnalysis);

      legacyChartData.futureRevenueEstimate = mapTimeSeriesArray<
        typeof futureAnalysis
      >(['merged_future_revenue'])(futureAnalysis);
      legacyChartData.pastRevenueActuals = mapTimeSeries<typeof pastRawData>([
        'total_rev',
      ])(pastRawData);

      legacyChartData.futureRevenueAnalysts = mapTimeSeries<
        typeof estimateRawData
      >(['revenue_num_est'])(estimateRawData);

      legacyChartData.futureRevenueAnalystsMap = reduceAnalystsTimeSeriesToMap([
        'revenue_num_est',
      ])(estimateRawData);

      legacyChartData.futureNetIncomeAnalystsMap =
        reduceAnalystsTimeSeriesToMap(['ni_reported_num_est'])(estimateRawData);

      legacyChartData.futureFreeCashflowAnalystsMap =
        reduceAnalystsTimeSeriesToMap(['fcf_num_est'])(estimateRawData);

      legacyChartData.futureCashflowAnalystsMap = reduceAnalystsTimeSeriesToMap(
        ['cash_oper_num_est']
      )(estimateRawData);

      legacyChartData.futureNetIncome = mapTimeSeriesArray<
        typeof futureAnalysis
      >(['merged_future_net_income'])(futureAnalysis);
      legacyChartData.pastNetIncome = mapTimeSeries<typeof pastRawData>([
        'ni_avail_excl',
      ])(pastRawData);

      legacyChartData.futureCashFlow = mapTimeSeriesArray<
        typeof futureAnalysis
      >(['merged_future_cash_operations'])(futureAnalysis);
      legacyChartData.pastCashFlow = mapTimeSeries<typeof pastRawData>([
        'cash_oper',
      ])(pastRawData);
      let pastMax: undefined | number[];
      let pastMin: undefined | number[];

      if (legacyChartData.pastRevenueActuals.length > 0) {
        pastMin = minBy(legacyChartData.pastRevenueActuals, function (o) {
          return o[0];
        });
        pastMax = maxBy(legacyChartData.pastRevenueActuals, function (o) {
          return o[0];
        });
        /** Store past revenue actuals */
      } else if (legacyChartData.pastNetIncome.length > 0) {
        pastMin = minBy(legacyChartData.pastNetIncome, function (o) {
          return o[0];
        });
        pastMax = maxBy(legacyChartData.pastNetIncome, function (o) {
          return o[0];
        });
        /** Do a check if pastRevenueActuals.length === 0 */
      }
      if (pastMax) {
        draft.pastRevenueActualsMax = firstIndexOrZero(pastMax);
      }
      const pastMinLimit = subYears(draft.pastRevenueActualsMax, 3).getTime();
      draft.pastRevenueActualsMin = Math.max(
        pastMinLimit,
        firstIndexOrZero(pastMin)
      );

      if (legacyChartData.futureRevenueEstimate.length > 0) {
        const futureMax = maxBy(
          legacyChartData.futureRevenueEstimate,
          function (o) {
            return o[0];
          }
        );
        if (futureMax) {
          draft.futureRevenueEstimateMax = firstIndexOrZero(futureMax);
        }
      }

      if (futureAnalysis.revenue_growth_annual) {
        legacyChartData.revenueBestFit = calculateBestFit(
          futureAnalysis.revenue_growth_annual,
          draft.futureRevenueEstimateMax,
          draft.pastRevenueActualsMax,
          legacyChartData.futureRevenueEstimate
        );
      }

      legacyChartData.netIncomeBestFit = calculateBestFit(
        futureAnalysis.net_income_growth_annual || 0,
        draft.futureRevenueEstimateMax,
        draft.pastRevenueActualsMax,
        legacyChartData.futureNetIncome
      );

      if (legacyChartData.pastNetIncome.length > 0) {
        const pastMin = minBy(legacyChartData.pastNetIncome, function (o) {
          return o[0];
        });
        const pastMax = maxBy(legacyChartData.pastNetIncome, function (o) {
          return o[0];
        });

        draft.pastNetIncomeMax = firstIndexOrZero(pastMax);
        draft.pastNetIncomeMin = firstIndexOrZero(pastMin);

        legacyChartData.pastNetIncomeBestFit = calculateBestFit(
          pastAnalysis.net_income_growth_5y,
          draft.pastNetIncomeMax,
          draft.pastNetIncomeMin,
          legacyChartData.pastNetIncome
        );
      }

      legacyChartData.researchAndDevExpenses = mapTimeSeries<
        typeof pastRawData
      >(['r_d_expense'])(pastRawData);
      legacyChartData.mergedFutureYield = mapTimeSeriesArray<
        typeof dividendAnalysis
      >(['merged_future_yield'])(dividendAnalysis);
      legacyChartData.mergedFutureDividendsPerShare = mapTimeSeriesArray<
        typeof dividendAnalysis
      >(['merged_future_dividends_per_share'])(dividendAnalysis);
      legacyChartData.historicalDividendYield = mapTimeSeriesArray<
        typeof dividendAnalysis
      >(['historical_dividend_yield'])(dividendAnalysis);

      legacyChartData.historicalDividendPayments = mapTimeSeriesArray<
        typeof dividendAnalysis
      >(['historical_dividend_payments'])(dividendAnalysis);
    } catch (error: any) {
      console.error('Company reducer: Analysis failed', {
        company: companyData.id,
        message: error.message,
        stack: error.stack,
      });
    }
  });
}

export { getDefault };

import { useEffect } from 'react';
import { useLazyScript } from './useLazyScript';
import type { Options } from './useLazyScript';

/**
 * Leverage 'requestIdleCallback' to defer the callback until the browser is idle.
 * Test for browser compatibility - if unavailable, use `setTimeout(fn, 0)`.
 */
const deferCallback = (callback: () => unknown) => {
  if (typeof window !== 'undefined' && 'requestIdleCallback' in window) {
    const id = requestIdleCallback(callback, { timeout: 500 });
    return () => cancelIdleCallback(id);
  } else {
    const id = setTimeout(callback, 0);
    return () => clearTimeout(id);
  }
};

interface DeferredScriptOptions extends Options {
  enabled?: boolean;
}

/**
 * Leverage 'requestIdleCallback' to defer loading, so the script doesn't block critical resources.
 * For scripts that are needed from the start of a user's session, but are resilient enough to not
 * be loaded immediately.
 * Example: analytics scripts that can send events retroactively, if events are pooled into a store
 * then submitted when the script has loaded.
 */
export function useDeferredScript(
  src: HTMLScriptElement['src'],
  options: DeferredScriptOptions = { enabled: true }
) {
  const [load, status, cleanupScript] = useLazyScript(src, options);
  const { enabled = true } = options;

  useEffect(() => {
    if (enabled) {
      const cleanupCallback = deferCallback(async () => {
        try {
          await load();
        } catch (err) {
          console.error(err);
        }
      });

      return () => {
        cleanupCallback();
        cleanupScript();
      };
    }
  }, [enabled, load, cleanupScript]);

  return status;
}

import { useSelector } from 'react-redux';
import {
  ROUTE_COMPANY,
  ROUTE_COMPANY_WITH_EVENTS,
  ROUTE_COMPARE_COMPANY,
  ROUTE_COMPARE_HOME,
  ROUTE_LOCALE_COMPANY,
  ROUTE_LOCALE_COMPANY_WITH_EVENTS,
} from '@/constants/routes';
import { useCompareCompanyId } from '@/pages/CompanyReport/components/CompareCompanies/CompanyIDProvider';
import { createSelector } from 'reselect';
import { getLocation, getLocationType } from '@/redux/location/selectors';

type CannonicalUrl = `/stocks/${string}/${string}/${string}/${string}` | '';

const getCannonicalUrl = createSelector(
  [getLocation],
  (location): CannonicalUrl => {
    if (
      [
        ROUTE_COMPANY,
        ROUTE_COMPANY_WITH_EVENTS,
        ROUTE_LOCALE_COMPANY,
        ROUTE_LOCALE_COMPANY_WITH_EVENTS,
      ].includes(location.type)
    ) {
      const { isoCode2, industry, exchange_ticker, companyname } =
        location.payload;
      return `/stocks/${isoCode2}/${industry}/${exchange_ticker}/${companyname}`;
    }

    //TODO: @victorc make this work for compare pages
    /** I got your back Victor C */
    if ([ROUTE_COMPARE_COMPANY, ROUTE_COMPARE_HOME].includes(location.type)) {
      try {
        const { symbols } = location.payload;
        const safeSymbols = decodeURIComponent(symbols);
        return safeSymbols as `/stocks/${string}/${string}/${string}/${string}`;
      } catch {
        return '';
      }
    }
    return '';
  }
);

const noop = (): CannonicalUrl => '';

/**
 * The canonical url for a company, can be used as a resource identifier.
 *
 * If after an internal navigable route to the company report page, use the `useCompanyReportPageUrl` hook instead as
 * this correctly factors in locale.
 */
export function useCompanyCanonicalUrl() {
  const compareId = useCompareCompanyId();
  const locationType = useSelector(getLocationType);
  const isCompareIdUrl =
    [ROUTE_COMPARE_COMPANY, ROUTE_COMPARE_HOME].includes(locationType) &&
    typeof compareId === 'string' &&
    compareId !== '';

  const canonicalUrl = useSelector(isCompareIdUrl ? noop : getCannonicalUrl);

  if (isCompareIdUrl) {
    return compareId;
  }
  return canonicalUrl;
}

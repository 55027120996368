import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
  type UseQueryOptions,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query';
import type { TadaDocumentNode } from 'gql.tada';
import { Kind } from 'graphql';
import { gqlClient } from '../graphQLClient';
import type { QueryTag } from './types';

const isOperationDefinition = (definition: {
  kind?: unknown;
}): definition is { kind: 'OperationDefinition' } => {
  return 'kind' in definition && definition?.kind === Kind.OPERATION_DEFINITION;
};

export const getOperationName = (
  query: TadaDocumentNode<unknown, never, void>
) => {
  let operationName: string[] | undefined = undefined;
  for (const definition of query?.definitions) {
    if (
      isOperationDefinition(definition) &&
      definition.name?.value !== undefined
    ) {
      operationName = [definition.name.value];
      break;
    }
  }
  return operationName;
};

/**
 * Helper wrapper to `react-query` query hook.
 * Allows typesafe creation of a gql query that can internally handle its key and data fetcher.
 *
 * @example
 * const QUERY = gql(`
 *   query getNarrativeValuationCompany($companyId: String!) {
 *     ...
 *   }
 * `);
 *
 * const useNarrativeValuationQuery = makeGqlQuery(QUERY)
 *
 * const SomeComponent = () => {
 *    const { data } = useNarrativeValuationQuery({ companyId: '1234' });
 *    ...
 * }
 *
 * @example extra options
 *
 * const SomeComponent = () => {
 *    const { data } = useNarrativeValuationQuery(
 *      { companyId: '1234' },
 *      { enabled: false },
 *    );
 *    ...
 * }
 *
 * @example prefetch / invalidate queries
 *
 * // specific variables
 * client.invalidateQueries(useNarrativeValuationQuery.getOptions({ companyId: '1234' }));
 * // all variables
 * client.invalidateQueries(useNarrativeValuationQuery.getAllOptions());
 *
 * // in serverside
 * client.prefetchQuery(useNarrativeValuationQuery.getOptions({ companyId: '1234' }));
 */
export const makeGqlQuery = <
  Query extends TadaDocumentNode,
  QueryKey extends ReadonlyArray<unknown>,
  Result = Query extends TadaDocumentNode<infer R> ? R : never,
  Variables = Query extends TadaDocumentNode<Result, infer V> ? V : never,
>(
  query: TadaDocumentNode<Result, Variables>,
  options?: {
    queryKey?: QueryKey;
    /**
     * The time in milliseconds after data is considered stale.
     * If set to `Infinity`, the data will never be considered stale.
     */
    staleTime?: number;
    /**
     * The amount of time in milliseconds the data will be kept in cache after the query unmounts
     * If set to 0, removes the cached data immediately after the query unmounts.
     */
    gcTime?: number;
    /**
     * Tags to be used for invalidation. Tags will be stored in the query meta data.
     * Use this in conjunction with `useGqlQueryInvalidate` to invalidate queries based on tags.
     */
    tags?: QueryTag[];
  }
) => {
  let key: string[] = Array.isArray(options?.queryKey) ? options?.queryKey : [];

  // If no explicit key is provided, use the graphql operation name as this should only be used
  // by a single calling hook.
  // Caveats: presumes a single operation name per query, doesn't do auto collision detection on key names (here at least).
  if (key.length === 0) {
    const operationName = getOperationName(query);

    if (operationName === undefined)
      throw new Error(
        'Missing query key: either a unique operation name must be present in graph call, or a manual query key set.'
      );

    key = operationName;
  }

  const getOptions = (variables: Variables) =>
    queryOptions({
      queryKey: [...key, variables],
      queryFn: () =>
        gqlClient.request<Query, Result, Variables>(query, variables),
      staleTime: options?.staleTime,
      gcTime: options?.gcTime,
      meta: {
        tags: options?.tags,
      },
    });

  const useQueryHook = (
    variables?: Variables,
    options?: Omit<
      UseQueryOptions<
        Awaited<ReturnType<typeof gqlClient.request<Query, Result, Variables>>>,
        unknown,
        Variables
      >,
      'queryKey' | 'queryFn'
    >
  ) =>
    useQuery({
      ...getOptions((variables ?? {}) as Variables),
      ...((options as unknown) ?? {}),
    });

  // Overloads, you love to see it 🙃

  /**
   * Returns the query options for this query as if it were the base query. This can be passed into
   * any `react-query` function. This is for special cases where the base key might be needed, e.g.
   * in invalidation.
   *
   * In general prefer use of the query options API via `useMyQuery.getOptions` for passing into
   * `react-query` functions as this will return the correct typings and is overall safer.
   *
   * @example
   * const client = useQueryClient()
   * client.invalidateQueries(useMyQuery.getAllOptions())
   */
  useQueryHook.getAllOptions = () => queryOptions({ queryKey: key });

  /**
   * Returns the query options for this query. These can be passed into any `react-query` function
   * and will retain the query typings.
   *
   * @example
   * const client = useQueryClient()
   * client.invalidateQueries(useMyQuery.getOptions({ companyId: '1234' }))
   */
  useQueryHook.getOptions = getOptions;

  return useQueryHook;
};

/**
 * Helper wrapper to `react-query` query hook.
 * Allows typesafe creation of a gql query that can internally handle its key and data fetcher.
 *
 * @example
 * const QUERY = gql(`
 *   query getNarrativeValuationCompany($companyId: String!) {
 *     ...
 *   }
 * `);
 *
 * const useNarrativeValuationQuery = makeGqlSuspenseQuery(QUERY)
 *
 * const SomeComponent = () => {
 *    const { data } = useNarrativeValuationQuery({ companyId: '1234' });
 *    ...
 * }
 *
 * @example extra options
 *
 * const SomeComponent = () => {
 *    const { data } = useNarrativeValuationQuery(
 *      { companyId: '1234' },
 *      { enabled: false },
 *    );
 *    ...
 * }
 *
 * @example prefetch / invalidate queries
 *
 * // specific variables
 * client.invalidateQueries(useNarrativeValuationQuery.getOptions({ companyId: '1234' }));
 * // all variables
 * client.invalidateQueries(useNarrativeValuationQuery.getAllOptions());
 *
 * // in serverside
 * client.prefetchQuery(useNarrativeValuationQuery.getOptions({ companyId: '1234' }));
 */
export const makeGqlSuspenseQuery = <
  Query extends TadaDocumentNode,
  QueryKey extends ReadonlyArray<unknown>,
  Result = Query extends TadaDocumentNode<infer R> ? R : never,
  Variables = Query extends TadaDocumentNode<Result, infer V> ? V : never,
>(
  query: TadaDocumentNode<Result, Variables>,
  options?: {
    queryKey?: QueryKey;
    /**
     * The time in milliseconds after data is considered stale.
     * If set to `Infinity`, the data will never be considered stale.
     */
    staleTime?: number;
    /**
     * Tags to be used for invalidation. Tags will be stored in the query meta data.
     * Use this in conjunction with `useGqlQueryInvalidate` to invalidate queries based on tags.
     */
    tags?: QueryTag[];
  }
) => {
  let key: string[] = Array.isArray(options?.queryKey) ? options?.queryKey : [];

  // If no explicit key is provided, use the graphql operation name as this should only be used
  // by a single calling hook.
  // Caveats: presumes a single operation name per query, doesn't do auto collision detection on key names (here at least).
  if (key.length === 0) {
    const operationName = getOperationName(query);

    if (operationName === undefined)
      throw new Error(
        'Missing query key: either a unique operation name must be present in graph call, or a manual query key set.'
      );

    key = operationName;
  }

  const getOptions = (variables: Variables) =>
    queryOptions({
      queryKey: [...key, variables],
      queryFn: () =>
        gqlClient.request<Query, Result, Variables>(query, variables),
      staleTime: options?.staleTime,
      meta: {
        tags: options?.tags,
      },
    });

  const useQueryHook = (
    variables?: Variables,
    options?: Omit<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof gqlClient.request<Query, Result, Variables>>>,
        unknown,
        Variables
      >,
      'queryKey' | 'queryFn'
    >
  ) => {
    const result = useSuspenseQuery({
      ...getOptions((variables ?? {}) as Variables),
      ...((options as unknown) ?? {}),
    });

    // Throw upon error to trigger a surrounding error boundary
    // https://tanstack.com/query/v5/docs/framework/react/guides/suspense#throwonerror-default
    if (result.error && !result.isFetching) throw result.error;

    return result;
  };

  // Overloads, you love to see it 🙃

  /**
   * Returns the query options for this query as if it were the base query. This can be passed into
   * any `react-query` function. This is for special cases where the base key might be needed, e.g.
   * in invalidation.
   *
   * In general prefer use of the query options API via `useMyQuery.getOptions` for passing into
   * `react-query` functions as this will return the correct typings and is overall safer.
   *
   * @example
   * const client = useQueryClient()
   * client.invalidateQueries(useMyQuery.getAllOptions())
   */
  useQueryHook.getAllOptions = () => queryOptions({ queryKey: key });

  /**
   * Returns the query options for this query. These can be passed into any `react-query` function
   * and will retain the query typings.
   *
   * @example
   * const client = useQueryClient()
   * client.invalidateQueries(useMyQuery.getOptions({ companyId: '1234' }))
   */
  useQueryHook.getOptions = getOptions;

  return useQueryHook;
};

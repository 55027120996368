import { useEffect, useRef, useState } from 'react';
import {
  useUnleashContext,
  useUnleashClient,
} from '@unleash/proxy-client-react';
import { useTracking } from 'react-tracking';
import { captureException } from '@sentry/react';
import loadable from '@loadable/component';

import type { IMutableContext } from '@unleash/proxy-client-react';

import { useEvaluateUnleashContext } from './useEvaluateUnleashContext';
import { PUBLIC_SWS_ENV } from '@/constants/env';

const EvaluateCapacitorContext = loadable(
  () => import('./EvaluateCapacitorContext'),
  {
    resolveComponent: (c) => c.EvaluateCapacitorContext,
  }
);

type Props = {
  prevContext: IMutableContext;
  onContextUpdate: (c: IMutableContext) => void;
};

export const EvaluateContext = ({ prevContext, onContextUpdate }: Props) => {
  const [capacitorProps, setCapacitorProps] =
    useState<IMutableContext['properties']>();
  const updateContext = useUnleashContext();
  const context = useEvaluateUnleashContext(capacitorProps);
  const client = useUnleashClient();
  const hasStartedRef = useRef(false);

  const { trackEvent } = useTracking();

  useEffect(() => {
    try {
      const process = async () => {
        await updateContext(context);

        // Ensure client is started + only start client once
        if (!hasStartedRef.current) {
          await client.start();
          hasStartedRef.current = true;
        }

        // Tell Analytics what the user's current flag configurations are
        trackEvent({
          page: 'root',
          type: 'track',
          subject: 'unleash',
          action: 'evaluate',
          userFlags: client.getAllToggles(),
          environment: PUBLIC_SWS_ENV,
        });
      };

      if (JSON.stringify(prevContext) !== JSON.stringify(context)) {
        onContextUpdate(context);
        process();
      }
    } catch (error) {
      captureException(error);
    }
  }, [prevContext, context, updateContext]);

  return RUNTIME_ENV === 'native' ? (
    <EvaluateCapacitorContext onSuccess={setCapacitorProps} />
  ) : null;
};

import { get } from "../methods";
function getCompanyCsvFile_default({ lookupVal, payload }, headers = {}, axiosConfig = {}) {
  return get({
    url: `api/company/download/csv/${lookupVal}`,
    payload,
    headers,
    ...axiosConfig
  });
}
export {
  getCompanyCsvFile_default as default
};

import { createSelector } from 'reselect';
import IndustryAverageByIndustryAndCountryRepository from '@/components/IndustryAverages/repository/IndustryAverageByIndustryAndCountry';
import IndustryAverageByIndustryAndCountryProvider from '@/components/IndustryAverages/provider/IndustryAverageByIndustryAndCountryProvider';
import type { IndustryAverageState } from '@/components/IndustryAverages/redux';

const avgByCountryRepo = new IndustryAverageByIndustryAndCountryRepository();
const avgByCountryProvider = new IndustryAverageByIndustryAndCountryProvider(
  avgByCountryRepo
);

//Get averages by industry and country iso
export const getLastUpdatedForCountry =
  (industryId: number, marketISO2: string) => (state) => {
    const industryAverages: IndustryAverageState = state.industryAverages;

    if (
      industryAverages &&
      industryAverages.lastUpdatedTracker &&
      industryAverages.lastUpdatedTracker.entities &&
      industryAverages.lastUpdatedTracker.entities.has
    ) {
      const key = avgByCountryProvider.getKeyForIndustryAverageByCountry(
        industryId,
        marketISO2
      );

      return industryAverages.lastUpdatedTracker.entities.has(key)
        ? industryAverages.lastUpdatedTracker.entities.get(key)
        : 0;
    }

    return 0;
  };

const getIndustryAveragesByMarketISO2 = (
  { industryAverages },
  { industryId, marketISO2 }
) => {
  if (
    industryAverages &&
    industryAverages.averagesByCountry &&
    industryAverages.averagesByCountry.entities &&
    industryAverages.lastUpdatedTracker.entities.has
  ) {
    const key = avgByCountryProvider.getKeyForIndustryAverageByCountry(
      industryId,
      marketISO2
    );
    return industryAverages.averagesByCountry.entities.has(key)
      ? industryAverages.averagesByCountry.entities.get(key)
      : {};
  }
  return {};
};

export const getMarketAverageROASelector = createSelector(
  getIndustryAveragesByMarketISO2,
  (industryAverages) => industryAverages['roa']
);

export const getMarketAverageROESelector = createSelector(
  getIndustryAveragesByMarketISO2,
  (industryAverages) => industryAverages['roe']
);

export const getMarketAveragePESelector = createSelector(
  getIndustryAveragesByMarketISO2,
  (industryAverages) => industryAverages['pe']
);

export const getMarketAveragePBSelector = createSelector(
  getIndustryAveragesByMarketISO2,
  (industryAverages) => industryAverages['pb']
);

export const getMarketAveragePEGSelector = createSelector(
  getIndustryAveragesByMarketISO2,
  (industryAverages) => industryAverages['peg']
);

export const getMarketAverageCountryNameSelector = createSelector(
  getIndustryAveragesByMarketISO2,
  (industryAverages) => industryAverages['countryName']
);

export const getMarketAverageNetIncomeGrowthAnnualSelector = createSelector(
  getIndustryAveragesByMarketISO2,
  (industryAverages) => industryAverages['netIncomeGrowthAnnual']
);

export const getMarketAverageRevenueGrowthAnnualSelector = createSelector(
  getIndustryAveragesByMarketISO2,
  (industryAverages) => industryAverages['revenueGrowthAnnual']
);

import { produce } from 'immer';
import { isBoolean } from 'lodash';
import { differenceInMinutes } from 'date-fns';

import type { getUser } from '@simplywallst/services';

import type { PrimaryCountryISO } from '@/constants/countries';
import { PRIMARY_MARKETS } from '@/constants/markets';

import { isHoldingsSorting } from '@/hooks/portfolio/useHoldingsSorting';
import type { HoldingsSorting } from '@/hooks/portfolio/useHoldingsSorting';

import { isPeriod } from '@/utilities/timeSeriesPeriodMap';
import type { Period } from '@/utilities/timeSeriesPeriodMap';
import type { ProviderType } from '@/components/OurPlans/types';
import type { WatchlistSorting } from '@/pages/Watchlist/hooks/useWatchlistSorting/types';
import { isWatchlistSorting } from '@/pages/Watchlist/hooks/useWatchlistSorting/isWatchlistSorting';
type GetUserResponse = Awaited<ReturnType<typeof getUser>>['data'];
type FeedSortingOptions = 'newest' | '7D' | '1Y';
type DeprecatedHoldingsSortingOptions =
  | 'capitalGainWeek'
  | 'dividendReturn'
  | 'name'
  | 'currentValue'
  | 'snowflakeScoreTotal'
  | 'snowflakeScoreValue'
  | 'snowflakeScoreFuture'
  | 'snowflakeScoreDividend';

export interface CouponState {
  readonly id: string;
  readonly name: string;
  readonly percentOff: number;
  readonly amountOff: number;
  readonly duration: string;
  readonly durationMonths: number;
  readonly maxRedemptions: number;
  readonly redeemBy: number;
  readonly timesRedeemed: number;
  readonly deleted: boolean;
}
export interface BillingState {
  readonly brand: string;
  readonly canceled: boolean;
  readonly canceledAt: number;
  readonly currency: string;
  readonly currentPeriodEnd: number;
  readonly currentPeriodStart: number;
  readonly expiryMonth: number;
  readonly expiryYear: number;
  readonly interval: string;
  readonly lastFour: string;
  readonly plan: string;
  readonly start: number;
  readonly status: string;
  readonly trialEnd: number | null;
  readonly trialStart: number;
  readonly trialed: boolean;
  readonly coupon: CouponState;
  readonly trialDiscountExpiry: number;
  readonly provider: ProviderType;
  readonly consecutiveRenewalCount: number;
  readonly subscriptionDiscountPercentOff: number | null;
}

type StocksView = 'tile' | 'table';

export interface ProfileState {
  readonly id: string;
  readonly name: string;
  readonly group: number;
  readonly isLocal: boolean;
  readonly loggedIn: boolean;
  readonly currencyISO: string;
  readonly givenName: string;
  readonly familyName: string;
  readonly displayName: string;
  readonly updatingProfile: 'password' | 'details' | 'idle';
  readonly countryISO: string;
  readonly emailExists: boolean;
  readonly accountType: string;
  readonly authProvider: string;
  readonly emailAddress: string;
  readonly referralCode: string;
  readonly isCheckingEmail: boolean;
  readonly registrationDate: number;
  readonly imageUrl: string;
  readonly defaultMarketISO: PrimaryCountryISO | undefined;
  readonly defaultMarketISOWithUsFallback: PrimaryCountryISO;
  readonly defaultFeedSorting: FeedSortingOptions;
  readonly showLiquidated: boolean;
  readonly hasSeenSAModal: boolean;
  readonly hasSeenSubscriptionModal: boolean;
  readonly hasSeenTrialEndModal: boolean;
  readonly hasSeenReportViewModal: boolean;
  readonly hasSeenPlansPage: boolean;
  readonly redirectToPortfolio: boolean;
  readonly lastPortfolioRedirection: string;
  readonly defaultStocksView: StocksView;
  readonly defaultWatchlistId: string | null;
  readonly defaultWatchlistSorting?: WatchlistSorting;
  readonly defaultWatchlistPeriod?: Period;
  readonly holdingsSorting: DeprecatedHoldingsSortingOptions;
  readonly billing: BillingState;
  readonly picture?: string;
  readonly isWatchlistBannerDismissed?: boolean;
  readonly preferredLocale?: string;
  readonly portfolio?: {
    readonly holdingsPeriod?: Period;
    readonly holdingsSorting?: HoldingsSorting;
    readonly links?: Record<
      string,
      { hasDismissedError: boolean; hasDismissedSuccess: boolean }
    >;
    readonly hasDismissedDashboardOnboarding?: boolean;
    readonly hasDismissedManualPortfolioWarning?: boolean;
    readonly hasDismissedPartiallyLimitedPortfolioPlan?: boolean;
    readonly hasDismissedFullyLimitedPortfolioPlan?: boolean;
    readonly dashboardDefaultPortfolioId?: string | null;
  };
  readonly learningContent?: {
    readonly dismissedCards?: string[];
  };
  readonly narrative?: {
    readonly hasDismissedFairValueTooltip?: boolean;
  };
}
export const getDefaultProfileState = (): ProfileState => ({
  id: '',
  name: '',
  group: -1,
  isLocal: true,
  loggedIn: false,
  currencyISO: '',
  givenName: '',
  familyName: '',
  displayName: '',
  updatingProfile: 'idle',
  countryISO: '',
  emailExists: false,
  accountType: 'Simply Wall St',
  emailAddress: '',
  authProvider: '',
  referralCode: '',
  isCheckingEmail: false,
  registrationDate: 0,
  imageUrl: '',
  defaultMarketISO: undefined,
  defaultMarketISOWithUsFallback: 'us',
  defaultFeedSorting: 'newest',
  defaultStocksView: 'table',
  defaultWatchlistId: null,
  defaultWatchlistPeriod: undefined,
  defaultWatchlistSorting: undefined,
  showLiquidated: false,
  hasSeenSAModal: false,
  hasSeenSubscriptionModal: false,
  hasSeenTrialEndModal: true,
  hasSeenReportViewModal: false,
  hasSeenPlansPage: false,
  redirectToPortfolio: false,
  lastPortfolioRedirection: '',
  holdingsSorting: 'capitalGainWeek',
  isWatchlistBannerDismissed: false,
  preferredLocale: undefined,
  billing: {
    brand: '',
    canceled: false,
    canceledAt: 0,
    currency: 'USD',
    currentPeriodEnd: 0,
    currentPeriodStart: 0,
    expiryMonth: 0,
    expiryYear: 0,
    interval: 'yearly',
    lastFour: '',
    plan: '',
    start: 0,
    status: 'active',
    trialEnd: 0,
    trialStart: 0,
    trialed: false,
    trialDiscountExpiry: 0,
    provider: 'stripe',
    consecutiveRenewalCount: 0,
    subscriptionDiscountPercentOff: null,
    coupon: {
      id: '',
      name: '',
      percentOff: 0,
      amountOff: 0,
      duration: '',
      durationMonths: 0,
      maxRedemptions: 0,
      redeemBy: 0,
      timesRedeemed: 0,
      deleted: false,
    },
  },
});

function isFeedSorting(sorting: unknown): sorting is FeedSortingOptions {
  if (typeof sorting === 'string' && ['newest', '7D', '1Y'].includes(sorting)) {
    return true;
  }
  return false;
}

function isHoldingSortingOptions(
  sortingOptions: unknown
): sortingOptions is DeprecatedHoldingsSortingOptions {
  if (
    typeof sortingOptions === 'string' &&
    [
      'capitalGainWeek',
      'dividendReturn',
      'name',
      'currentValue',
      'snowflakeScoreTotal',
      'snowflakeScoreValue',
      'snowflakeScoreFuture',
      'snowflakeScoreDividend',
    ].includes(sortingOptions)
  ) {
    return true;
  }
  return false;
}

export default function reducer(
  state = getDefaultProfileState(),
  data: GetUserResponse['data']
): ProfileState {
  return produce(state, (draft) => {
    const isLocal = data.auth_provider === 'Local' ? true : false;
    const accountType = isLocal ? state.accountType : data.auth_provider;
    draft.loggedIn = data.logged_in;
    draft.currencyISO = data.currency;
    draft.countryISO = data.country_iso;
    if (data.logged_in) {
      const {
        default_market: defaultMarket,
        feed_sorting: feedSorting,
        show_liquidated: showLiquidated,
        holdings_sorting: holdingsSorting,
        has_seen_sa_modal: hasSeenSAModal,
        has_seen_subscription_modal: hasSeenSubscriptionModal,
        has_seen_trial_end_modal: hasSeenTrialEndModal,
        has_seen_report_view_modal: hasSeenReportViewModal,
        redirect_to_portfolio: redirectToPortfolio,
        last_portfolio_redirection: lastPortfolioRedirection,
        stocks_view: stocksView,
        default_watchlist_id: defaultWatchlistId,
        default_watchlist_sorting: defaultWatchlistSorting,
        default_watchlist_period: defaultWatchlistPeriod,
        has_dismissed_wl_banner: isWatchlistBannerDismissed,
        has_seen_plans_page,
        portfolio,
        learning_content,
        preferred_locale,
        narrative,
      } = data.settings?.data ?? {};
      /** If logged_in we can assume these values will be truthy */
      draft.isLocal = isLocal;
      draft.familyName = data.family_name || '';
      draft.displayName = data.display_name || '';
      draft.id = data.id as string;
      draft.name = data.name as string;
      draft.group = data.group as number;
      draft.givenName = data.given_name as string;
      draft.accountType = accountType as string;
      draft.emailAddress = data.email as string;
      draft.authProvider = data.auth_provider as string;
      draft.referralCode = data.referral_code as string;
      draft.registrationDate = data.register_date as number;
      draft.defaultStocksView =
        ((typeof stocksView === 'string' && stocksView) as StocksView) ||
        'table';
      draft.defaultMarketISO =
        typeof defaultMarket === 'string' &&
        defaultMarket.toLowerCase() in PRIMARY_MARKETS
          ? (defaultMarket.toLowerCase() as PrimaryCountryISO)
          : undefined;
      draft.defaultMarketISOWithUsFallback =
        typeof defaultMarket === 'string' &&
        defaultMarket.toLowerCase() in PRIMARY_MARKETS
          ? (defaultMarket.toLowerCase() as PrimaryCountryISO)
          : 'us';
      draft.defaultFeedSorting = isFeedSorting(feedSorting)
        ? feedSorting
        : 'newest';
      draft.hasSeenSAModal = isBoolean(hasSeenSAModal) ? hasSeenSAModal : false;
      draft.hasSeenPlansPage = isBoolean(has_seen_plans_page)
        ? has_seen_plans_page
        : false;
      draft.hasSeenSubscriptionModal = isBoolean(hasSeenSubscriptionModal)
        ? hasSeenSubscriptionModal
        : false;
      draft.hasSeenTrialEndModal = isBoolean(hasSeenTrialEndModal)
        ? hasSeenTrialEndModal
        : false;
      draft.hasSeenReportViewModal = isBoolean(hasSeenReportViewModal)
        ? hasSeenReportViewModal
        : false;
      draft.redirectToPortfolio = isBoolean(redirectToPortfolio)
        ? redirectToPortfolio
        : false;
      draft.lastPortfolioRedirection =
        typeof lastPortfolioRedirection === 'string'
          ? lastPortfolioRedirection
          : '';
      draft.showLiquidated = isBoolean(showLiquidated) ? showLiquidated : false;
      draft.holdingsSorting = isHoldingSortingOptions(holdingsSorting)
        ? holdingsSorting
        : 'capitalGainWeek';
      draft.defaultWatchlistId =
        (defaultWatchlistId && defaultWatchlistId.toString()) || null;
      draft.defaultWatchlistSorting = isWatchlistSorting(
        defaultWatchlistSorting
      )
        ? defaultWatchlistSorting
        : undefined;
      draft.defaultWatchlistPeriod = isPeriod(defaultWatchlistPeriod)
        ? defaultWatchlistPeriod
        : undefined;
      draft.picture =
        typeof data.picture === 'string' ? data.picture : undefined;
      draft.isWatchlistBannerDismissed = isBoolean(isWatchlistBannerDismissed)
        ? isWatchlistBannerDismissed
        : false;
      draft.preferredLocale = preferred_locale ?? 'en';
      draft.portfolio = {};
      draft.portfolio.holdingsPeriod = isPeriod(portfolio?.holdings_period)
        ? portfolio?.holdings_period
        : undefined;
      draft.portfolio.holdingsSorting = isHoldingsSorting(
        portfolio?.holdings_sorting
      )
        ? portfolio?.holdings_sorting
        : undefined;
      draft.portfolio.links = {};
      for (const portfolioId in portfolio?.links ?? {}) {
        const stateLink = state.portfolio?.links?.[portfolioId];
        const dataLink = portfolio?.links?.[portfolioId];

        draft.portfolio.links[portfolioId] = {
          hasDismissedError:
            dataLink?.has_dismissed_error ??
            stateLink?.hasDismissedError ??
            false,
          hasDismissedSuccess:
            dataLink?.has_dismissed_success ??
            stateLink?.hasDismissedSuccess ??
            false,
        };
      }
      draft.portfolio.hasDismissedDashboardOnboarding = isBoolean(
        portfolio?.has_dismissed_dashboard_onboarding
      )
        ? portfolio?.has_dismissed_dashboard_onboarding
        : false;

      draft.portfolio.hasDismissedPartiallyLimitedPortfolioPlan = isBoolean(
        portfolio?.has_dismissed_partially_limited_portfolio_plan
      )
        ? portfolio?.has_dismissed_partially_limited_portfolio_plan
        : false;

      draft.portfolio.hasDismissedFullyLimitedPortfolioPlan = isBoolean(
        portfolio?.has_dismissed_fully_limited_portfolio_plan
      )
        ? portfolio?.has_dismissed_fully_limited_portfolio_plan
        : false;

      draft.portfolio.hasDismissedManualPortfolioWarning = isBoolean(
        portfolio?.has_dismissed_manual_portfolio_warning
      )
        ? portfolio?.has_dismissed_manual_portfolio_warning
        : false;
      draft.portfolio.dashboardDefaultPortfolioId =
        portfolio?.dashboard_default_portfolioId ?? null;
      draft.learningContent = {};
      draft.learningContent.dismissedCards =
        learning_content?.dismissed_cards ?? [];
      if (data.billing) {
        draft.billing.consecutiveRenewalCount =
          data.billing.data.consecutive_renewal_count;
        draft.billing.subscriptionDiscountPercentOff =
          data.billing.data.subscription_discount_percent_off;
        draft.billing.brand = data.billing.data.brand as string;
        draft.billing.canceled = data.billing.data.canceled as boolean;
        draft.billing.canceledAt = data.billing.data.canceled_at as number;
        draft.billing.currentPeriodEnd = data.billing.data
          .current_period_end as number;
        draft.billing.currentPeriodStart = data.billing.data
          .current_period_start as number;
        draft.billing.expiryMonth = data.billing.data.expr_month as number;
        draft.billing.expiryYear = data.billing.data.expr_year as number;
        draft.billing.interval = data.billing.data.interval as string;
        draft.billing.lastFour = data.billing.data.last_four as string;
        draft.billing.plan = data.billing.data.plan as string;
        draft.billing.start = data.billing.data.start as number;
        draft.billing.status = data.billing.data.status as string;
        draft.billing.trialEnd = data.billing.data.trial_end as number;
        draft.billing.trialStart = data.billing.data.trial_start as number;
        draft.billing.trialed = data.billing.data.trialed as boolean;
        draft.billing.trialDiscountExpiry = data.billing.data
          .trial_discount_expires as number;
        draft.billing.provider =
          ((typeof data.billing.data.provider === 'string' &&
            data.billing.data.provider) as ProviderType) || 'stripe';

        if (data.billing.data.coupon) {
          const {
            id,
            name,
            percent_off: percentOff,
            amount_off: amountOff,
            duration,
            duration_months: durationMonths,
            max_redemptions: maxRedemptions,
            redeem_by: redeemBy,
            times_redeemed: timesRedeemed,
          } = data.billing.data.coupon.data;
          draft.billing.coupon.id = id;
          draft.billing.coupon.name = name || '';
          draft.billing.coupon.percentOff = percentOff;
          draft.billing.coupon.amountOff = amountOff || 0;
          draft.billing.coupon.duration = duration;
          draft.billing.coupon.durationMonths = durationMonths || 0;
          draft.billing.coupon.maxRedemptions = maxRedemptions || 0;
          draft.billing.coupon.redeemBy =
            data.billing.data.trial_discount_expires !== null &&
            differenceInMinutes(
              data.billing.data.trial_discount_expires,
              Date.now()
            ) > 0
              ? data.billing.data.trial_discount_expires
              : redeemBy;
          draft.billing.coupon.timesRedeemed = timesRedeemed;
        }

        draft.narrative = {};
        draft.narrative.hasDismissedFairValueTooltip = isBoolean(
          narrative?.has_dismissed_fair_value_tooltip
        )
          ? narrative?.has_dismissed_fair_value_tooltip
          : false;
      }
    }
  });
}

import { createGlobalStyle } from 'styled-components';
import {
  getBreakpoints,
  typography,
  font,
  focus as globalFocusCSS,
} from '@simplywallst/ui-core';
import { CompetitorsWrapper } from '@/components/Competitors/styled';
import { ReportSubSectionWrapper } from '@/components/ReportSubSection/styled';
import ReportSection from '@components/ReportSection';
import { SnowflakeWrapper } from '@/components/DeprecatedSnowflake/styled';
import { TwoColumnWithDesktopSidebar } from '@/components/Layout';
import { SnowflakeChart } from '@/pages/CompanyReport/components/SummaryCompare/styled';
/*Import components to target style change for @media print*/
import { DesktopSidebarWithSnowflakeWrapper } from '@/pages/CompanyReport/components/DesktopSidebarWithSnowflake/styled';
import { ChartActionWrapper } from '@/components/ChartActions/components/ChartActions/styled';

const media = getBreakpoints();

interface Props {
  background?: string;
}

const GlobalStyle = createGlobalStyle<Props>`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  @media (max-width: ${media.smMax}) {
    ::-webkit-scrollbar {
      width: 0px;  /* remove scrollbar space */
      height: 0px;  /* remove scrollbar space */
      background: transparent;  /* optional: just make scrollbar invisible */
    }
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
  }
  html, body {
    margin: 0;
    font-family: ${font.BASE};
    font-size: 10px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background: ${({ theme, background }) =>
      background ? background : theme.color.charcoal};
    overflow-anchor: auto;
    position: relative;
    height: 100%;
  }

  body {
    /* Avoid the horizontal jump between scrollable and unscrollable content */
    overflow-y: scroll;
  }

  h1, h2, h3, h4, p, blockquote, figure, ol, ul {
    margin: 0;
    padding: 0;
  }

  main {
    display: block;
  }


  h1 {
    ${typography.textStyleH1CSS}
    margin: ${({ theme }) => `${theme.x3spacePx}px 0 ${theme.x5spacePx}px`};
  }

  h2 {
    ${typography.textStyleH2CSS}
    margin: ${({ theme }) => `${theme.x2spacePx}px 0 ${theme.x3spacePx}px`};
  }

  h3 {
    ${typography.textStyleH3CSS}
    margin: ${({ theme }) => `${theme.x1spacePx}px 0 ${theme.x2spacePx}px`};
  }

  h4 {
    ${typography.textStylePCSS}
    margin: ${({ theme }) => `${theme.x1spacePx}px 0 ${theme.x2spacePx}px`};
  }

  strong, b {
    font-weight: 500;
  }

  a, button {
    color: inherit;
    transition: .3s;
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.brand01};
  }

  button {
    overflow: visible;
    border: 0;
    font: inherit;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    background: none;
    cursor: pointer;
    padding: 0;
  }

  ::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  :focus {
    outline: 0;
  }

  ${globalFocusCSS}

  img {
    max-width: 100%;
    height: auto;
    border: 0;
  }

  video {
    max-width: 100%;
    height: auto;
    border: 0;
  }

  hr {
    border: transparent;
    border-top: solid 1px ${({ theme }) => theme.color.white};
    opacity: 0.2;
    margin: ${({ theme }) => theme.x4spacePx}px 0;
    @media (max-width: ${media.mdMax}) {
      margin: ${({ theme }) => theme.x3spacePx}px 0;
    }
  }

  label,
  input {
    ${typography.textStyleBaseFontSize}
  }

  ol, ul {
    list-style-type: none;
    ${typography.textStyleBaseFontSize}
  }

  p {
    ${typography.textStylePCSS}
    margin-bottom: ${({ theme }) => theme.x2spacePx}px;
  }

  input {
    padding: 14px 10px;
    border: transparent;
    border-radius: 0;
  }

  input[type=search] {
    -webkit-appearance: none;
  }

  button,
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    -webkit-appearance: none;
  }

  label {
    margin-bottom: ${({ theme }) => theme.x1spacePx}px;
  }

  fieldset {
    padding: 0;
    border: transparent;
  }

  /* Hide navigation components for print as PDF on light theme */
  /* TODO: add these styles for print mode, but not light mode */
    body:not([data-theme="light"]) #desktop-topbar-wrapper,
    body:not([data-theme="light"]) #branding-wrapper,
    body:not([data-theme="light"]) #mobile-topbar-wrapper,
    body:not([data-theme="light"]) #mobile-footer-menu-wrapper,
    body:not([data-theme="light"]) #mobile-topbar-sub-nav-wrapper,
    body:not([data-theme="light"]) ${DesktopSidebarWithSnowflakeWrapper},
    body:not([data-theme="light"]) ${ChartActionWrapper} {
      ${({ theme }) => theme.appTheme === 'light' && 'display: none'}
    }

  /*Style for print as PDF*/
  @media print {

    html, body {
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
    }

    @page {
      margin: 2cm 1cm 3cm 1cm;
    }

    @page :first {
      margin-top: 1cm;
    }

    ${CompetitorsWrapper}, ${ReportSubSectionWrapper}, ${SnowflakeWrapper}, ${ReportSection} {
      break-inside: avoid;
    }

    /*Fix for Safari*/
    ${SnowflakeChart} {
      padding-bottom: 40px;
    }
    ${TwoColumnWithDesktopSidebar} {
      padding: 0;
    }
  }
`;

export default GlobalStyle;

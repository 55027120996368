import { takeLatest, put, select, getContext } from 'redux-saga/effects';
import { detectMarket } from './routines';
import { redirect } from 'redux-first-router';
import {
  ROUTE_STOCKS_MARKET,
  ROUTE_STOCKS_HOME,
  ROUTE_MARKET_HOME,
  ROUTE_MARKET,
  ROUTE_DISCOVER_INVESTING_IDEAS,
  ROUTE_DISCOVER_INVESTING_IDEAS_MARKET,
  ROUTE_INVESTING_IDEA_HOME,
  ROUTE_INVESTING_IDEA_MARKET,
} from '@/constants/routes';
import { getLocation } from '@ducks/location/selectors';
import type { QueryClient } from '@tanstack/react-query';
import type { ProfileState } from '@/hooks/useUser';
import { QUERY_KEY } from '@/hooks/useUser';
import type { SortBy } from '@/pages/Stocks/hooks/useSortByOptions';
import type { PrimaryCountryISO } from '@/constants/countries';

type Market = PrimaryCountryISO | undefined;
type Location = ReturnType<typeof getLocation>;

function* detectMarketWorker() {
  try {
    const queryClient: QueryClient = yield getContext('queryClient');
    const userData: ProfileState | undefined =
      typeof queryClient !== 'undefined'
        ? queryClient.getQueryData(QUERY_KEY)
        : undefined;
    let market: Market;
    /** use user countryISO or defaultMarketISOWithUsFallback */
    if (userData) {
      if (userData.loggedIn) {
        market = userData.defaultMarketISOWithUsFallback;
      } else {
        market = userData?.countryISO as Market;
      }
    }

    let hasRedirectFallback = true;
    const redirectTo = '';
    if (typeof market !== 'undefined') {
      const location: Location = yield select(getLocation);
      switch (location.type) {
        case ROUTE_STOCKS_HOME:
          const defaultSortBy: SortBy = 'market-cap-large';
          yield put(
            redirect({
              type: ROUTE_STOCKS_MARKET,
              payload: {
                market: market.toLowerCase(),
                sortBy: defaultSortBy,
              },
            })
          );
          break;
        case ROUTE_DISCOVER_INVESTING_IDEAS: {
          if (userData?.loggedIn === true) {
            yield put(
              redirect({
                type: ROUTE_DISCOVER_INVESTING_IDEAS_MARKET,
                payload: { market: market.toLowerCase() },
              })
            );
          }
          // Avoid fallback market redirect for logged out users
          hasRedirectFallback = false;

          break;
        }
        case ROUTE_INVESTING_IDEA_HOME:
          yield put(
            redirect({
              type: ROUTE_INVESTING_IDEA_MARKET,
              payload: {
                id: location.payload.id,
                name: location.payload.name,
                market: market.toLowerCase(),
              },
            })
          );
          break;
        case ROUTE_MARKET_HOME:
          yield put(
            redirect({
              type: ROUTE_MARKET,
              payload: {
                market: market.toLowerCase(),
              },
            })
          );
          break;
      }
    }
    if (hasRedirectFallback) {
      yield put(
        redirect({
          type: redirectTo,
          payload: {
            market: market,
          },
        })
      );
    }
  } catch (e) {
    console.log(e);
  } finally {
  }
}
function* detectMarketWatcher() {
  yield takeLatest(detectMarket.TRIGGER, detectMarketWorker);
}

export const exportableSagas = [detectMarketWatcher];

//Deprecated - Move everything to saga.ts and rename it to sagas.ts
import { takeEvery, put, select, getContext } from 'redux-saga/effects';
import { fetchPortfolio } from '@/components/DeprecatedPortfolio/redux/actions';
import type { QueryClient } from '@tanstack/react-query';
import { USER_PORTFOLIOS_QUERY_KEY } from '@/hooks/useDeprecatedUserPortfolios';
import {
  portfolioAddCompany,
  portfolioRemoveCompany,
} from '@/components/DeprecatedPortfolio/redux/routines';

import { addCompany, removeCompany } from './routines';

import {
  addCompanyToPortfolio as addCompanyToPortfolioService,
  deleteCompanyFromPortfolio as deleteCompanyService,
} from '@simplywallst/services';

import type { addCompanyToPortfolio } from '@simplywallst/services';

import {
  getCurrentPortfolioId as getStateCurrentPortfolioId,
  getPortfolioList as getStatePortfolioMap,
} from '@/components/DeprecatedPortfolio/redux/selectors';

import { ahoy, getAuthenticatedAhoy } from '@ducks/saga';

export function* addCompanyWorker(
  action: ReturnType<(typeof addCompany)['trigger']>
) {
  const {
    uniqueSymbol,
    companyId,
    portfolioId,
    fetchPortfolioOnSuccess = false,
  } = action.payload;
  try {
    const snackbar = yield getContext('snackbar');
    snackbar?.addSnackbar({
      isLoading: true,
      message: 'Adding company',
      id: 'add-company',
    });

    if (typeof companyId !== 'undefined') {
      yield put(
        portfolioAddCompany.trigger({
          id: portfolioId,
          companyId,
        })
      );
    }
    const caller = getAuthenticatedAhoy();
    const searchResults: Awaited<
      ReturnType<typeof addCompanyToPortfolio>
    >['data'] = yield caller(addCompanyToPortfolioService, {
      portfolio_id: portfolioId,
      unique_symbol: uniqueSymbol,
    });

    if (
      searchResults.errors &&
      searchResults.errors?.length > 0 &&
      searchResults.errors[0].status === 409
    ) {
      yield put(
        addCompany.failure({
          companyId,
          message: 'Added company already exists in the portfolio',
        })
      );
    } else if (!searchResults.data) {
      //TODO: Need to fix this leg as status is not defined anymore
      yield put(
        addCompany.failure({
          companyId,
          message:
            'message: ' + searchResults.statusText + ', with status: ' + status,
        })
      );
    } else {
      if (
        searchResults &&
        searchResults.data &&
        searchResults.data.company &&
        searchResults.data.company.data
      ) {
        searchResults.data.company.data.short_name =
          searchResults.data.company.data.name || '';
      }

      /** if routine was triggered with an undefined companyId */
      if (typeof companyId === 'undefined') {
        yield put(
          portfolioAddCompany.trigger({
            id: portfolioId,
            companyId: searchResults.data.company.data.company_id,
          })
        );
      }

      yield put(
        addCompany.success({
          companyId: searchResults.data.company.data.company_id,
        })
      );

      const queryClient: QueryClient = yield getContext('queryClient');
      queryClient.refetchQueries({ queryKey: USER_PORTFOLIOS_QUERY_KEY });

      yield put(
        portfolioAddCompany.success({
          id: portfolioId,
          companyId: searchResults.data.company.data.company_id,
          uniqueSymbol: searchResults.data.company.data.unique_symbol,
          data: searchResults.data,
        })
      );

      if (fetchPortfolioOnSuccess) {
        yield put(fetchPortfolio(portfolioId));
      }
    }
  } catch (e: any) {
    let message = e.message;
    try {
      /** make the message more friendly */
      message = e.message.split(':')[1].trim();
      if (message.toLowerCase() === 'portfolio item already exists') {
        message = `${uniqueSymbol} already exist in your portfolio`;
      }
    } catch (e) {}
    const snackbar = yield getContext('snackbar');
    snackbar?.addSnackbar({
      type: 'negative',
      message: message,
      lifeTime: 2000,
    });
    yield put(
      addCompany.failure({
        companyId,
        message:
          e.message || e.errors || 'We were unable to perform your request :(',
      })
    );
  } finally {
    yield put(addCompany.fulfill({ id: portfolioId, companyId }));
    yield put(portfolioAddCompany.fulfill({ id: portfolioId }));
    const snackbar = yield getContext('snackbar');
    snackbar?.removeSnackbar({
      id: 'add-company',
    });
  }
}

export function* removeCompanyWorker(action) {
  const { companyId, portfolioId } = action.payload;

  try {
    const portfolioMap = yield select(getStatePortfolioMap);

    const matchedPortfolio = portfolioMap[portfolioId.toString()];
    const itemId = matchedPortfolio.holdingCompanyIdItemIdMap[companyId];
    yield put(
      portfolioRemoveCompany.trigger({
        id: portfolioId,
        companyId,
      })
    );
    const caller = ahoy({ authenticate: true, dataOnly: false });
    const removeResponse = yield caller(deleteCompanyService, itemId);
    if (removeResponse.status === 204 || removeResponse.status === 404) {
      yield put(removeCompany.success({ companyId }));

      const queryClient: QueryClient = yield getContext('queryClient');
      queryClient.refetchQueries({ queryKey: USER_PORTFOLIOS_QUERY_KEY });

      yield put(
        portfolioRemoveCompany.success({
          id: portfolioId,
          companyId,
        })
      );

      const currentPortfolioId = yield select(getStateCurrentPortfolioId);
      if (portfolioId === currentPortfolioId) {
        yield put(fetchPortfolio(portfolioId));
      }
    } else {
      yield put(
        removeCompany.failure({
          companyId,
          message:
            'message: ' +
            removeResponse.statusText +
            ', with status: ' +
            removeResponse.status,
        })
      );
    }
  } catch (e: any) {
    yield put(
      removeCompany.failure({
        companyId,
        message:
          e.message || e.errors || 'We were unable to perform your request :(',
      })
    );
  } finally {
    yield put(
      portfolioRemoveCompany.fulfill({
        id: portfolioId,
      })
    );
  }
}

function* watchAddCompanyWorker() {
  yield takeEvery(addCompany.TRIGGER, addCompanyWorker);
}

function* watchRemoveCompanyWorker() {
  yield takeEvery(removeCompany.TRIGGER, removeCompanyWorker);
}

export default [watchAddCompanyWorker, watchRemoveCompanyWorker];

import { createRoutine } from 'redux-saga-routines';
import type { getAnalystCoverage } from '@simplywallst/services';
type Response = Awaited<ReturnType<typeof getAnalystCoverage>>;
type AnalystCoverage = Response['data'];
export interface TriggerPayload {
  companyId?: string;
}
export interface FetchCompanyAnalystsSuccessPayload {
  brokers: AnalystCoverage['data'][number]['brokers']['data'];
  companyId: string;
}
export const fetchCompanyAnalysts = createRoutine<
  SimplePayloadCreator<TriggerPayload>,
  SimplePayloadCreator,
  SimplePayloadCreator<FetchCompanyAnalystsSuccessPayload>,
  SimplePayloadCreator,
  SimplePayloadCreator
>('@/pages/CompanyReport/fetchAnalysts', {});

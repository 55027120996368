import {
  ROUTE_COMPANY,
  ROUTE_COMPANY_WITH_EVENTS,
  ROUTE_LOCALE_COMPANY,
  ROUTE_LOCALE_COMPANY_WITH_EVENTS,
} from '@/constants/routes';
import type { getCompany } from '@simplywallst/services';
import type { LocationState } from 'redux-first-router';
type Company = Awaited<ReturnType<typeof getCompany>>['data']['data'];

export function pathConstructor(locationState: LocationState) {
  if (
    locationState.type === ROUTE_COMPANY_WITH_EVENTS ||
    locationState.type === ROUTE_LOCALE_COMPANY_WITH_EVENTS ||
    locationState.type === ROUTE_COMPANY ||
    locationState.type === ROUTE_LOCALE_COMPANY
  ) {
    return `/stocks/${locationState.payload.isoCode2}/${locationState.payload.industry}/${locationState.payload.exchange_ticker}/${locationState.payload.companyname}`;
  }
  return locationState.pathname;
}

export function isSnowflakeScore(
  score: number[]
): score is [number, number, number, number, number] {
  if (Array.isArray(score) && score.length === 5) {
    return score.every((value) => typeof value === 'number');
  }
  return false;
}

export function getSnowflakePoints(data: Company) {
  let snowflakePoints: [number, number, number, number, number] = [
    0, 0, 0, 0, 0,
  ];
  if (data?.score) {
    const { axes } = data.score.data.snowflake.data;
    if (isSnowflakeScore(axes)) {
      snowflakePoints = axes;
    }
  }
  return snowflakePoints;
}

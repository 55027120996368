/* eslint-disable node/no-process-env */

/** Only public keys go here and nothing else! */

export const FB_APP_ID = '354904741335190';
export const GOOGLE_CLIENT_ID =
  '1092568288730-ec49d3un0ocr8kvld567ls2kea92f50t.apps.googleusercontent.com';
export const SWS_CLIENT_ID = '90989a0528ad4b238480f1ac0f5855e5';
export const STRIPE_KEY = 'pk_live_ji1OgCXmnDq00hPzClTbgnQZ';
export const STRIPE_KEY_DEV = 'pk_test_QGnVFbcYrrVg01bZ9NvOWoG6007qcPJfeQ';
export const HOTJAR_HJID = 44113;
export const HOTJAR_HJSV = 6;
export const REWARDFUL_API_KEY = 'd92841';
export const REWARDFUL_TRACKING_URL = 'https://r.wdfl.co/rw.js';
export const BRAZE_PROD_API_KEY = 'e32ab9b0-7a06-40f3-919b-6c08c1447e63';
export const BRAZE_PROD_SDK_ENDPOINT = 'sdk.iad-05.braze.com';
export const BRAZE_DEV_API_KEY = '1dd6db5d-62c0-4891-97ac-f2ca9d8d8423';
export const BRAZE_DEV_SDK_ENDPOINT = 'sdk.iad-05.braze.com';

/** dev */
// export const STRIPE_KEY = 'pk_test_QGnVFbcYrrVg01bZ9NvOWoG6007qcPJfeQ';
export const AUTH_SCOPE =
  'public read:user write:user read:portfolio write:portfolio';
export const SENTRY_DSN =
  'https://8d2df8974aac4e88bf2faa04c30679bc@o109478.ingest.sentry.io/1469220';
export const SENTRY_CAPACITOR_DSN =
  'https://5a95485974124435b545ea40e645c6b7@o109478.ingest.sentry.io/6000432';
export const ALGOLIA_API_KEY = 'be7c37718f927d0137a88a11b69ae419';
export const ALGOLIA_APP_ID = '17IQHZWXZW';
export const GETSTREAM_API_KEY = 'c2jdvmk9p547';
export const GETSTREAM_APP_ID = '73819';
export const CONTENTFUL_SPACE = 'n07nr2koi959';
export const CONTENTFUL_ACCESS_TOKEN =
  'ZIsU9i6Drg1khCUoISG89DpBVEVCM-OR93vjxNXwNHc';
export const AUTHOR_HUB_ID = '1P2S29V0zldGNE6PC5h2mU';
export const CONTENTFUL_GRAPQL =
  'https://graphql.contentful.com/content/v1/spaces/';
export const FASTCOMMENTS_APP_ID = 'W5Yi5xT_IaG';
export const I18NEXUS_API_KEY = '4gW-fH78xFWLtuKUePTNcw';

export const PUBLIC_RELEASE_VERSION =
  typeof window === 'undefined'
    ? process.env.CI_COMMIT_TAG
    : window.CI_COMMIT_TAG;

function isSwsEnv(env?: string): env is 'local' | 'dev' | 'staging' | 'prod' {
  if (typeof env === 'string') {
    return ['local', 'dev', 'staging', 'prod'].includes(env);
  }
  return false;
}
function getEnv() {
  const env = process.env.SWS_ENV;
  if (!isSwsEnv(env)) {
    throw new Error(`Invalid SWS_ENV: ${env}`);
  }
  return env;
}

export const PUBLIC_SWS_ENV =
  typeof window === 'undefined' ? getEnv() : window.SWS_ENV;
